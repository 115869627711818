<div class="main-container col-12" *ngIf="!changepassword">
    <div class="row mx-0 justify-content-center">
        <div class="card col-lg-4 col-10 mt-5 mb-3">
            <div class="text-center mt-2 pt-4 rounded">
                <h3 class="mb-2" style="color: var(--primary-color);">Forgot Your Password??</h3>
            </div>
            <div class="card-body">
                <p class="card-text fw-medium fs-6">To reset your password, enter the email address you use to sign in
                    to
                    SportsRuler.
                </p>
            </div>
            <!-- </div> -->
            <br>
            <!-- <div class="card col-lg-4 col-10 mt-3 mb-3"> -->
            <form id="validate-form" (ngSubmit)="emailAvailable()" [formGroup]="emailForm">
                <label for="email" class="form-label fw-medium ms-2 mt-2">EMAIL ADDRESS:</label><br>
                <input type="text" name="email" id="email" formControlName="email" placeholder="name@example.com"
                    required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    title="Please enter a valid email address" class="form-control mb-2"><br>
                <div class="card-body text-center error-messages" *ngIf="emailValidationToggle">
                    <p class="text-danger">{{errorMessage}}</p>
                </div>
                <div class="d-grid gap-2 mb-2">
                    <button class="btn btn-success  p-2 fs-6"  type="submit">SUBMIT</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="main-container col-12" *ngIf="changepassword">
    <div class="row justify-content-center p-2">
        <div class="card col-lg-4  col-10 m-5" style="width: 40vh;">
            <div class="text-center  my-2 pt-4 rounded">
                <h3 class="mb-3" style="color: var(--primary-color);" >Change Password</h3>
            </div>
            <form [formGroup]="passWordForm" id="login-form" class="form">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Temporary Password</mat-label>
                    <input matInput type="password" formControlName="temppassword" placeholder="Temporary Password" >
                    <mat-error *ngIf="passWordForm.get('temppassword')?.hasError('required')">
                        Temporary Password required
                      </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" formControlName="newpassword" placeholder="New Password">
                    <mat-error *ngIf="passWordForm.get('newpassword')?.hasError('minlength')">
                        Password must be at least 6 characters long
                      </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" formControlName="confirmpassword" placeholder="Confirm Password" >
                    <mat-error *ngIf="passWordForm?.get('confirmpassword')?.hasError('mismatch')">
                    password do's not match.
                </mat-error>
                </mat-form-field>

                <div *ngIf="showpassworError" class="error-message text-danger text-center m-3">
                   The password is invalid
                </div>
                <div class="d-grid gap-2 m-2">
                    <button type="submit"  (click)="submit()" class="btn btn-success p-2 fs-6">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>