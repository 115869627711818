import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from '../orgcode-filter.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {
  loginData: any;
  myForm!: FormGroup;
  email_id!: String;
  passwd!: String;
  showLoginError: boolean = false;
  loginDetails: any;
  Login_Type: any;
  orgCode:any;

  constructor(private commonService: CommonService, private http: HttpClient, private router: Router,private orgFilter:OrgcodeFilterService) { }

  ngOnInit() {  
    this.myForm = new FormGroup({
      email_id: new FormControl(''),
      passwd: new FormControl(''),
    });
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  onLoginFormSubmit() {
    if (this.myForm.valid) {
      const email = this.myForm.value['email_id'];
      const password = this.myForm.value['passwd'];
      const sha256Hash = CryptoJS.SHA256(this.myForm.value['email_id'] + this.myForm.value['passwd']);
      const hashedPassword = sha256Hash.toString(CryptoJS.enc.Hex);

      const userObj = {
        email: this.myForm.value['email_id'],
        password: hashedPassword,
      }
      this.commonService.login(userObj).subscribe((data: any) => {

        if (data.status == 'TRUE') {

          if (data.result.role === 'M') {
            const token = btoa(`${email}:${password}`); // Base64 encoding as a simple token
            this.orgCode == "SR" 
                 ? window.location.href = `https://www.sportsruler.com:9010/home?token=${token}` 
                 : this.orgCode == "SKA" 
                 ? window.location.href =`https://scores.superkingsacademy.com:9007/home?token=${token}` 
                 : null;

          }
          else if (data.result.role !== 'M') {
            localStorage.setItem('userId', data.result.userId);
            localStorage.setItem('firstName', data.result.firstName);
            localStorage.setItem('lastName', data.result.lastName);
            localStorage.setItem('Login_Type', data.result.role);
            this.Login_Type = data.result.role;
            this.showLoginError = false;
           if (this.Login_Type == 'T') {
              localStorage.setItem('email', this.myForm.value['email_id']);
              this.router.navigate([`${this.orgCode}/forgotPassword`, this.Login_Type])
            } else if(this.Login_Type == 'V'){
              const userId = data.result.userId
              this.router.navigate([`${this.orgCode}/verfication`],{state:{userId:userId}})
            } else {
              this.router.navigate([`${this.orgCode}/home`]).then(() => {
                window.location.reload();
              });
            }
          }
        } else if (data.status == "FALSE") {
          console.error('status false')
          this.showLoginError = true;

        }
      });
    } else {
      console.error('Invalid Credential');
      this.showLoginError = true;
    }
  }
  forgotPassword() {
    this.router.navigate([`${this.orgCode}/forgotPassword`]);
  }

}
