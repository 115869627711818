<div class="d-none d-lg-block d-md-block background-container-web">
    <div class="container py-5">
        <span class="col-12 d-flex name-detail justify-content-center align-items-center"
            style="top: 0;"><b>{{userdetail.F_name}}</b>&nbsp;<span
                style="color: rgba(255, 255, 255, 0.705);">(SR{{userdetail.User_id}})</span><br></span>
        <div class="card p-2"
            style="top: 45px; position: relative; z-index: 1; border-radius: 25px; padding-bottom: 50px !important;">
            <div class="col-12 d-flex justify-content-center" style="position: relative; bottom: 50px;">
                <div class="row">
                    <div class="col-12" style="padding: 4px 0;">
                        <img [src]="previewImageUrl" onerror="this.src='/assets/user-thumbnail.png';"
                            style="width: 85px; background-color: white; height: 85px;" class="rounded-circle img-fluid"
                            alt="">
                        <label *ngIf="myprofile"(click)="EditImg()" class="rounded-circle col-1 custom-button"
                            style="position: relative; left: 48px; bottom: 23px;">
                            <i  class="bi bi-camera-fill" style="font-size: 22px;"></i>
                        </label>
                        <span class="col-12 d-flex justify-content-center align-items-center"><i
                                class="bi bi-geo-alt-fill"></i>&nbsp;<span>Chennai</span><br></span>
                        <span class="col-12 d-flex justify-content-center align-items-center" *ngIf="!noAcademies"
                            (click)="redirectToAcademy()">{{Academies.Institue_name}}</span>
                        <span
                            class="col-12 d-flex justify-content-center align-items-center">Sports:&nbsp;<span>Cricket</span><br></span>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block d-md-block my-2 d-flex justify-content-center">
                <div *ngIf="nohome">
                    <ng-container *ngTemplateOutlet="navCardsTemplate"></ng-container>
                </div>
                <!-- <div class="d-flex justify-content-center" *ngIf="noAcademies">
                    <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
                </div> -->
                <div *ngIf="!nohome">
                    <ng-container *ngTemplateOutlet="detailcardpage"></ng-container>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="background-container d-block d-lg-none d-md-none" *ngIf="nohome">
    <div class="container">
        <span
            class="col-12 d-flex name-detail justify-content-center align-items-center"><b>{{userdetail.F_name}}</b>&nbsp;<span
                style="color: rgba(255, 255, 255, 0.705);">(SR{{userdetail.User_id}})</span><br></span>
        <div class="card p-2"
            style="top: 130px; position: relative; z-index: 1; border-radius: 25px; padding-bottom: 50px !important;">
            <div class="col-12 d-flex justify-content-center" style="position: relative; bottom: 50px;">
                <div class="row">
                    <div class="col-12" style="padding: 4px 0;">
                        <img [src]="previewImageUrl" onerror="this.src='/assets/user-thumbnail.png';"
                            style="width: 85px; background-color: white; height: 85px;" class="rounded-circle img-fluid"
                            alt="">
                        <label *ngIf="myprofile" (click)="EditImg()" class="rounded-circle col-1 custom-button"
                            style="position: relative; left: 48px; bottom: 23px;">
                            <i class="bi bi-camera-fill" style="font-size: 22px;"></i>
                        </label>
                        <span class="col-12 d-flex justify-content-center align-items-center"><i
                                class="bi bi-geo-alt-fill"></i>&nbsp;<span>Chennai</span><br></span>
                        <!-- <span class="col-12 d-flex justify-content-center align-items-center"
                            (click)="redirectToAcademy()">{{Academies.Institue_name}}</span> -->
                        <span
                            class="col-12 d-flex justify-content-center align-items-center">Sports:&nbsp;<span>Cricket</span><br></span>
                    </div>
                </div>
            </div>
            <div class="d-block  d-lg-none">
                <ng-container *ngTemplateOutlet="navCardsTemplate"></ng-container>
            </div>

        </div>
    </div>
</div>

<div class="d-block d-lg-none d-md-none" *ngIf="!nohome">
    <ng-container *ngTemplateOutlet="detailcardpage"></ng-container>
</div>

<ng-template #detailcardpage >
<div class="container  d-block" style="padding-left: 0px !important; padding-right: 0px !important;">



    <div class="container my-2" *ngIf="userdetails && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">About Me</h4></div>
        <div class="card col-12 p-1 border-0 shadow">
            <div class="row mx-0">
                <div class="clo-12 mt-3 mb-3 d-flex justify-content-start">
                    <h5 class="col-11 d-flex justify-content-start"><b><span>About Me !</span></b></h5>
                    <span class="col d-flex justify-content-center" *ngIf="myprofile"><i class="bi bi-pencil"
                            (click)="updateMe()"></i></span>
                </div>
                <div class="clo-10 mt-3 mb-1 d-flex justify-content-start">
                    <h5 class="col-11 d-flex justify-content-start"><b><span>Strengths </span></b></h5>
                    <span class="col d-flex justify-content-center" *ngIf="myprofile"><i class="bi bi-pencil"
                            (click)="Strengths()"></i></span>
                </div>
                <div class="col-12 d-flex justify-content-start"> {{userdetail.Strengths}} </div>
                <div class="clo-10 mt-3 mb-1 d-flex justify-content-start">
                    <h5 class="col-11 d-flex justify-content-start"><b><span>Weakness</span></b></h5>
                    <span class="col d-flex justify-content-center" *ngIf="myprofile"><i class="bi bi-pencil"
                            (click)="Weakness()"></i></span>
                </div>
                <div class="col-12 mb-3 d-flex justify-content-start"> {{userdetail.Weakness}}</div>
            </div>
        </div>
    </div>
    <div class=" mt-1" *ngIf="isScores  && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Stats</h4></div>
        <div class="card col-12 border-0 shadow">
            <div class="row">
                <!-- <div class="clo-12 mt-3 mb-1 d-flex justify-content-around">
                    <h5 class="col-7 d-flex justify-content-start" style="font-size: larger;"><b><span>Upload recent
                                scores </span></b>
                    </h5>
                    <button class="btn btn-success" style="color: white;">View All</button>
                </div>
                <div class="clo-10 mt-3 mb-1 d-flex justify-content-start">
                    <ul style="width: 450px;list-style-type: none;">
                        <li>
                            <img class="" style="width: 35px;height: 35px;" src="assets/img/cricketA.png">
                        </li>
                    </ul>
                </div> -->
                <app-stats [playerName]="userdetail.F_name" [userId]="userId"></app-stats>
            </div>
        </div>
    </div>
    <div class="container mt-1" *ngIf="photos  && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Photos</h4></div>
        <div class="card col-12 border-0 shadow">
            <div class="row mx-0">
                <div class="mt-2 col-12 align-items-center">
                    <div class="row mx-0">
                        <h5 class="col-10 d-flex align-items-center mb-0"><b><i class="bi bi-image-fill"
                                    style="font-size: medium;"></i>&nbsp;Photos</b></h5>
                        <span class="col-2 d-flex justify-content-end">
                            <button class="btn " style="border: none;" mat-button *ngIf="myprofile"
                                (click)="uploadimg()"><img src="assets/img/AddImage.png" style="width: 90px;"
                                    alt=""></button>
                        </span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="col-4" *ngFor="let image of UserImages">
                        <img [src]="SPORTS_RULER_WEB_URL+image.link" class=" img-fluid mb-2" alt=""
                            *ngIf="UserImages.length>0">
                    </div>
                </div>
                <div class="col-12 my-2 d-flex justify-content-center" *ngIf="UserImages.length==0">
                    <p class="col-8 d-flex justify-content-center fw-bold"> No Images Found</p>
                </div>
            </div>
        </div>
    </div>
    <div class=" container mt-1" *ngIf="video  && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Video</h4></div>
        <div class="card col-12 border-0 shadow">
            <div class="row m-0">
                <div class="mt-2 mx-2 col-12 d-flex justify-content-between align-items-center">
                    <h5 class="d-flex align-items-center mb-0" style="font-size: larger;"><b><i
                                class="bi bi-play-btn-fill"></i>&nbsp;Video</b></h5>
                </div>
                <div class="col-12 my-2 d-flex justify-content-center " *ngFor="let video of videoUrl">
                    <iframe style="width: 350px;height: 230px;" allowfullscreen [src]="video.link" frameborder="0"
                        *ngIf="videoUrl.length>0"></iframe>
                </div>
                <div class="col-12 my-2 d-flex justify-content-center" *ngIf="videoUrl.length==0">
                    <p class="col-8 d-flex justify-content-center fw-bold"> No Video Found</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="LIVE&&!nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Live</h4></div>
        <div class="card mt-1 border-0 shadow">
            <div class="col-12">
                <div class="row mx-0">
                    <div class="mt-2 col-12 d-flex justify-content-between align-items-center">
                        <h5 class="d-flex align-items-center mb-0"><b><i class="bi bi-broadcast"
                                    style="font-size: medium;color: #FF0000;"></i></b></h5>
                    </div>
                    <div class="col-12  my-2  d-flex justify-content-center" *ngFor="let video of videourls| slice:0:1">
                        <iframe class="Live_video" allowfullscreen [src]="video.url" frameborder="0"About Me
                            *ngIf="videourls.length>0"></iframe>
                        <div class=""></div>
                    </div>
                    <div class="col-12 my-2 d-flex justify-content-center" *ngIf="videourls.length==0">
                        <p class="col-8 d-flex justify-content-center fw-bold"> No Video Found</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="ACHIEVEMENTS && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Achievements</h4></div>
        <div class="card mt-1 border-0 shadow">
            <div class="container"  >
                <!-- Show for batting -->
                <div class="col-12"  *ngIf="personalbatting.message!=='No date found'">
                    <h4 class="my-2 col-12" style="background-color: var(--secondary-color); color: white;"><b class="mx-1">Batting:</b></h4>
                    <div class="col-12 my-2" *ngFor="let batter of personalbatting.result">
                        <div class="my-2 col-12" style="color: #00A65A;border-bottom: 1px solid #e2e8e9;">&nbsp;
                            Acheived
                            rank {{batter.rank}}
                            in {{batter.Table_header}} by scoring
                            {{batter.runs}} Runs in {{batter.balls_faced}} balls</div>
                    </div>
                </div>
                <!-- Show for bowling -->
                <div class="col-12 my-3" *ngIf="personalbowling.message!=='No date found'" >
                    <h4 class="my-2 col-12" style="background-color: var(--secondary-color); color: white;"><b class="mx-1">Bowling:</b></h4>
                    <div class="col-12 my-2" *ngFor="let bowler of personalbowling.result">
                        <div class="my-2 col-12" style="color: #00A65A;border-bottom: 1px solid #e2e8e9;">&nbsp;
                            Acheived
                            rank {{bowler.rank}}
                            in {{bowler.Table_header}} by bowling
                            {{bowler.balls_faced}}conceding {{bowler.runs}}runs</div>
                    </div>
                </div>
            </div>
          <ng-container *ngTemplateOutlet="nodatafound"></ng-container>
          
            
            <div class="container">
                <ul class="products-list  product-list-in-box" style="font-weight: 600;">
                    <li class="item" *ngFor="let notes of userAchievementNotes">
                        <span (click)="Acheivement()" class="product-title"
                            style="color: #337ab7; text-decoration:none;cursor: pointer;">
                            <div class="product-info margin_left_5px">
                                {{notes.content}}
                                <span class="label"
                                    style="float: right;background-color:#f39c12 ">{{notes.achievement_time}}</span>
                                <span class="product-description">
                                    {{notes.achievement_date}}
                                </span>
                            </div>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="FAMILY&&!nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Family</h4></div>
        <div class="card mt-1 border-0 shadow">
            <div *ngIf="!isfamily">
                <div class="row mx-0" *ngFor="let family of userChild">
                    <div class="col-12 mb-2 d-flex " style="border-bottom: 1px solid #e2e8e9;">
                        <div class="col-5">
                            <img *ngIf="family.parent_relationship =='Son'" class=""
                                src="../../../../assets/boy-plain.png">
                            <img *ngIf="family.parent_relationship =='Daughter'" class=""
                                src="../../../../assets/girl-plain.png">
                        </div>
                        <div class="col-7" style="display: flex;align-items: center;">
                            <div class="row">
                                <a class="col-12 mb-1" style="text-decoration: none;font-size: large;"
                                    href="">{{family.F_name}}</a>
                                <div class="col-12">
                                    {{family.parent_relationship}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf="isfamily">
                <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="SCORES && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Scores</h4></div>
        <div class="card mt-2 border-0 shadow">
            <div class="my-2 col-12">
                <div class="col-12 mt-2">
                    <ul class="horizontal-list m-0">
                        <li *ngFor="let sport of Sprots" style="padding: 10px;"
                            class="d-flex flex-column align-items-center">
                            <img [src]="sport?.sportImg" alt=" " style="width: 20px;cursor: pointer;"
                                (click)="selectedsport()">
                            <p class="m-0">{{sport.sports}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="my-2 col-12">
                <div class="col-12 mt-2">
                    <ul class="rca-tab-list">
                        <li class="rca-tab-link" [ngClass]="{'activeCricket': live_selected === 'live'}"
                            (click)="showTab('live')">LIVE</li>
                        <li class="rca-tab-link" [ngClass]="{'activeCricket': live_selected === 'upcoming'}"
                            (click)="showTab('upcoming')">UPCOMING</li>
                        <li class="rca-tab-link" [ngClass]="{'activeCricket': live_selected === 'completed'}"
                            (click)="showTab('completed')">COMPLETED</li>
                    </ul>
                </div>
            </div>
            <div class="my-2 col-12">
                <div *ngIf="live_selected === 'live'" style="padding-bottom: 50px;">
                    <div *ngIf="!cricketmatchdetaillive">
                        <div *ngFor="let liveMatchDetails of CricketLivematch.slice(0, itemsToShow)"
                            class="card mb-3 mx-1 border-0 shadow"
                            (click)="navigateToScorecardDetail(liveMatchDetails.Match_id)">
                            <div class="card-body">
                                <div class="cursor-pointer margingap border-left pl-2">
                                    <h5 class="card-title">{{liveMatchDetails.teamone}} VS
                                        {{liveMatchDetails.teamtwo}}</h5>
                                    <p class="card-text events">
                                        <strong>{{liveMatchDetails.Event_name}}</strong>
                                    </p>
                                    <p class="card-text">
                                        <span>{{liveMatchDetails.teamone}}</span>:&nbsp;
                                        <span
                                            *ngIf="liveMatchDetails.innings_1_overs != null && liveMatchDetails.innings_1_overs > 0">
                                            <label>{{liveMatchDetails.innings_1_score}}/{{liveMatchDetails.innings_1_wkts}}</label>
                                            ({{liveMatchDetails.innings_1_overs}} ovr)
                                        </span>
                                    </p>
                                    <p class="card-text">
                                        <span>{{liveMatchDetails.teamtwo}}</span>:&nbsp;
                                        <span
                                            *ngIf="liveMatchDetails.innings_2_overs != null && liveMatchDetails.innings_2_overs > 0">
                                            <label>{{liveMatchDetails.innings_2_score}}/{{liveMatchDetails.innings_2_wkts}}</label>
                                            ({{liveMatchDetails.innings_2_overs}} ovr)
                                        </span>
                                        <span
                                            *ngIf="liveMatchDetails.innings_2_overs == null || liveMatchDetails.innings_2_overs <= 0">
                                            <label>Yet to bat</label> ({{liveMatchDetails.innings_2_overs}} ovr)
                                        </span>
                                    </p>
                                    <div class=""><b>Venue:&nbsp;{{liveMatchDetails.Venue}}</b></div>
                                    <p class="card-text tosswon">
                                        Toss won by {{liveMatchDetails.tossWon}} and elected to
                                        {{liveMatchDetails.elected_to}}
                                        first
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button (click)="showMoreItems()" type="button" 
                        [disabled]="itemsToShow >= CricketLivematch.length" 
                        [ngClass]="{'btn-success': itemsToShow < CricketLivematch.length, 'btn-secondary': itemsToShow >= CricketLivematch.length}"
                        class="btn btn-sm float-right mt-3 mx-2">
                        More Matches
                    </button>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="cricketmatchdetaillive">
                        <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
                    </div>
                </div>
                <div *ngIf="live_selected === 'upcoming'" style="padding-bottom: 50px;">

                    <div *ngIf="!cricketmatchdetailupcoming" class="matches-container">
                        <div *ngFor="let upComingMatchDetails of CricketupcomingMatch.slice(0, itemsToShow)"
                            class="match-card card border-0 shadow">
                            <div class="match-details cursor-pointer border-left pl-2">
                                <div class="teams">{{upComingMatchDetails.teamone}} VS {{upComingMatchDetails.teamtwo}}
                                </div>
                                <div class="event-name">{{upComingMatchDetails.Event_name}}</div>
                                <div class=""><b>Venue:&nbsp;{{upComingMatchDetails.Venue}}</b></div>
                                <div class="start-time">Match:&nbsp;{{upComingMatchDetails.Match_Date}}</div>
                                <div class="start-time">
                                    Starts on {{upComingMatchDetails.createdOn}} at {{upComingMatchDetails.Match_Time}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <!-- <button (click)="showMoreItems()" type="button"
                                class="btn btn-success btn-sm float-right mt-3 mx-2">
                                More Matches
                            </button> -->
                            <button (click)="showMoreItems()" type="button" 
                                [disabled]="itemsToShow >= CricketupcomingMatch.length" 
                                [ngClass]="{'btn-success': itemsToShow < CricketupcomingMatch.length, 'btn-secondary': itemsToShow >= CricketupcomingMatch.length}"
                                class="btn btn-sm float-right mt-3 mx-2">
                                More Matches
                            </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="cricketmatchdetailupcoming">
                        <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
                    </div>
                </div>
                <div *ngIf="live_selected === 'completed'" style="padding-bottom: 50px;">

                    <div *ngIf="!cricketmatchdetail" class="matches-container">
                        <div *ngFor="let match of completedMatchs.slice(0, itemsToShow)"
                            class="match-card card border-0 shadow" (click)="navigateToScorecardDetail(match.Match_id)">
                            <div class="match-details border-left pl-2">
                                <div class="teams">{{match.teamone}} VS {{match.teamtwo}}</div>
                                <div class="event-name">{{match.Event_name}}</div>
                                <p class="card-text m-0">
                                    <span *ngIf="(match.Team1===match.batting_first)" >{{match.teamone}}:&nbsp;</span>
                                    <span *ngIf="(match.Team2===match.batting_first)" >{{match.teamtwo}}:&nbsp;</span>
                                    <span>
                                            <label>{{match.innings_1_score}}/{{match.innings_1_wkts}}</label>
                                            ({{match.innings_1_overs}} ovr)
                                        </span>
                                </p>
                                <p class="card-text m-0">
                                    <span *ngIf="match.Team1!==match.batting_first" >{{match.teamone}:&nbsp;}</span>
                                    <span *ngIf="match.Team2!==match.batting_first" >{{match.teamtwo}}:&nbsp;</span>
                                    <span>
                                        <label>{{match.innings_2_score}}/{{match.innings_2_wkts}}</label>
                                        ({{match.innings_2_overs}} ovr)
                                    </span>
                                </p>
                                <p class="card-text m-0" *ngIf="match.toss_won==match.Team1">
                                    Toss won by {{match.teamone}} and elected to
                                    {{match.Elected_to}}
                                    first
                                </p>
                                <p class="card-text m-0" *ngIf="match.toss_won==match.Team2">
                                    Toss won by {{match.teamtwo}} and elected to
                                    {{match.Elected_to}}
                                    first
                                </p>
                                <div class=""><b>Venue:&nbsp;{{match.Venue}}</b></div>
                                <div class="">Match Date:&nbsp;{{match.Match_Date}}</div>
                                <div class="">Match Time:&nbsp;{{match.Match_Time}}</div>
                                <div class="result">{{match.result}}</div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button (click)="showMoreItems()" type="button" 
                            [disabled]="itemsToShow >= completedMatchs.length" 
                            [ngClass]="{'btn-success': itemsToShow < completedMatchs.length, 'btn-secondary': itemsToShow >= completedMatchs.length}"
                            class="btn btn-sm float-right mt-3 mx-2">
                            More Matches
                        </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="cricketmatchdetail">
                        <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="POSTS && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Posts</h4></div>
        <div role="tabpanel" class="tab-pane card" id="posts">
            <div class=" overflow-hidden p-3" *ngIf="!isposts">
                <div class="row py-2" *ngFor="let post of posts">
                    <div class="col-12 d-flex flex-row">
                        <div class="col-3 my-2 text-center mb-3 mb-md-0">
                            <img class="img-fluid rounded-circle"
                                [src]="posts.path_to_pic" alt="User Picture"
                                style="width: 85px; height: 85px;">
                        </div>
                        <div class="col-9 ms-2 my-2">
                            <h5 class="font-weight-bold mb-1">{{ post.comm_name }}</h5>
                            <h6 class="mb-1">{{ post.message_title }}</h6>
                            <p class="mb-1">{{ post.message_content }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf="isposts">
                <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="FRIENDS && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Friends</h4></div>
        <div class="card border-0 shadow">
            <div class="d-flex justify-content-center">
                <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="COMMUNITIES && !nohome">
        <div class="col-12 d-flex justify-content-start d-block align-items-center"><button type="button" class="btn col-1 "
                style="border: none;" (click)="exit()"><i class="bi bi-arrow-left-circle fs-2"></i></button>&nbsp;<h4 class="align-items m-0 d-flex">Communities</h4></div>
        <div class="card border-0 shadow">
            <div class="d-flex justify-content-center">
                <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
            </div>
        </div>
    </div>
</div>
</ng-template>
<ng-template #navCardsTemplate >
    <div class="d-flex justify-content-center flex-wrap homecard gap-lg-3 gap-5 gap-md-3 my-3 my-lg-2">
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('details')"
            style="background: var(--primary-color);color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-person-vcard" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>About Me</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('Achievements')"
            style="background: var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-trophy" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Achievements</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('Scores')"
            style="background: var(--primary-color);color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-clipboard-data" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Scores</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('stats')"
            style="background: var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-file-bar-graph" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Stats</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('Posts')"
            style="background: var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-play-btn" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Posts</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('Family')"
            style="background: var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <img src="../../../../assets/family1.png" alt="" style="width: 45px;">
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Family</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('Live')"
            style="background: var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-broadcast" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Live</b></span>
        </div>

        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('photos')"
            style="background: var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-images" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Photos</b></span>
        </div>
        <div class="card col-4 col-lg-3 col-md-3 navCardWidth" (click)="Onchange('video')"
            style="background:var(--primary-color); color: white;box-shadow: 4px 4px 4px 4px #bbbcc1;cursor: pointer;">
            <span class="col-12 d-flex justify-content-center mt-2">
                <i class="bi bi-play-btn" style="font-size:45px;"></i>
            </span>
            <span class="col-12 mt-2 d-flex justify-content-center"><b>Video</b></span>
        </div>
    </div>
</ng-template>
<ng-template #nodatafound>
    <div class="d-flex justify-content-center" *ngIf="personalbatting.message==='No date found' && personalbowling.message==='No date found'">
        <span class="fw-medium" style="margin: 10% 0;">No Record Found</span>
    </div>
</ng-template>