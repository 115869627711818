import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { EventService } from 'src/app/service/event.service';
import { PlayerService } from 'src/app/service/player.service';

@Component({
  selector: 'app-cricket-player-info',
  templateUrl: './cricket-player-info.component.html',
  styleUrls: ['./cricket-player-info.component.css']
})
export class CricketPlayerInfoComponent implements OnInit, AfterViewInit {
  userId: any;
  sportId: any = 1;
  playerinfo: any;
  teams: any;
  lastResults: any[] = [];
  leaderboard: any;
  battingRecord: any[] = [];
  bowlingRecord: any[] = [];
  fieldingRecord: any[] = [];
  tournament: boolean = true;
  stats:boolean = false;
  social: boolean = false;
  isShow: boolean = true;
  hideBattingRecords: boolean = true;
  hideFieldingRecord: boolean = true;
  hideBowlingRecord: boolean = true;
  disableSelect = new FormControl('All');
  years: (number | string)[];
  selectedYear: number | string = 'All';
  hidePersonalDetails: boolean = true;
  hideRecentPerformance: boolean = true;
  hideLeaderBoard: boolean = true;
  mainScreen: boolean = true;
  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] = ['position', 'name','opponent', 'Scorecard'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  totalRecords: number = 0;
  pagesize = 25;
  currentPage = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSize: number = 25;
  pageIndex: number = 0;
  playerId:any;
  paginatedTeams: any;
  filteredTeams: any;
  filteredData: PeriodicElement[] = []; 
  playername: any;
  orgCode: any;
  

  constructor(private playerService: PlayerService, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router,
    private orgFilter:OrgcodeFilterService
  ) {
    this.years = this.getLastTenYears();
    this.route.queryParams.subscribe(params => {
      this.userId = params['Id'];
    });
  }
  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
    this.getPlayerPersonalInfo(this.sportId, this.userId);
    this.displaySportTeam(this.userId, this.sportId);
    this.cricketUserLastResults(this.userId);
    this.getUserBattingRecord(this.userId, this.selectedYear);
    this.getUserBowlingRecord(this.userId, this.selectedYear);
    this.getUserFieldingRecord(this.userId, this.selectedYear);

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredData = this.ELEMENT_DATA.filter(element => 
      element.name.toLowerCase().includes(filterValue) || 
      element.Scorecard.toString().toLowerCase().includes(filterValue)
    );
    this.totalRecords = this.filteredData.length;
    this.updatePaginatedTeams();
  }

  showFullPerformance() {
    this.ELEMENT_DATA = this.lastResults.map((result, index) => ({
      position: index + 1,
      name: result.result + ' vs '+result.team2,
      opponent:result.team2,
      Scorecard: result.match_id
    }));
    this.filteredData = this.ELEMENT_DATA;
    this.totalRecords = this.ELEMENT_DATA.length;
    this.updatePaginatedTeams();
  }


  navigateToScoreCardDetails(matchId: any) {
    this.router.navigate([`${this.orgCode}/scorecardDetails`, matchId]);
  }
  tournamentOrsocial(tab: string) {
    this.tournament = tab === 'tournaments';
    this.stats=tab=='stats'
  }

  
  getLastTenYears(): number[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i > currentYear - 10; i--) {
      years.push(i);
    }
    return years;
  }
  isShowmainScreen(){
    this.mainScreen = !this.mainScreen;
  }

  onYearSelected(year: number) {
    this.selectedYear = year;
    this.getUserBattingRecord(this.userId, year);
    this.getUserBowlingRecord(this.userId, year);
    this.getUserFieldingRecord(this.userId, year);
    this.cdr.detectChanges();
  }

  getPlayerPersonalInfo(sportId: any, userId: any) {
    this.playerService.getPlayerPersonalDetails(sportId, userId).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.playerinfo = response.result[0];
          this.playername=this.playerinfo.F_name
        } else {
          this.hidePersonalDetails = false;
        }
      },
      error => {
        console.error('Error fetching player personal details:', error);
      }
    );
  }

  displaySportTeam(userId: any, sportsId: any) {
    this.playerService.displaySportTeam(userId, sportsId).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.teams = response.result;
        }
      },
      error => {
        this.hideRecentPerformance = false;
        console.error('Error fetching sport teams:', error);
      }
    );
  }

  cricketUserLastResults(userId: any) {
    this.playerService.cricketUserLastResults(userId).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.lastResults = response.result;
          this.showFullPerformance();
        }
      },
      error => {
        console.error('Error fetching last cricket results:', error);
      }
    );
  }

  getuserLeaderBoard(userId: any) {
    this.playerService.getuserLeaderBoard(userId).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.leaderboard = response.result;
          let result
          if(response.result){
           result = response.result.map((item: { record: string }) => ({ record: parseFloat(item.record) }));
           result.forEach((item: { record: any }, index: number) => {
            if (this.battingRecord[index]) {
                this.battingRecord[index]['record'] = item.record;
            }
          });
        }

          
        } else {
          this.hideLeaderBoard = false;
        }
      },
      error => {
        console.error('Error fetching user leaderboard:', error);
      }
    );
  }


  getUserBattingRecord(userId: any, year: any) {
    this.playerService.getUserBattingRecord(userId, year).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.battingRecord = response.result;
          this.hideBattingRecords = true;
        } else {
          this.battingRecord = [];
          this.hideBattingRecords = false;
        }
        this.getCricketBatsmanUserGraph(userId,5,response.result[0].Sports_subcat_id,year)
      },
      error => {
        console.error('Error fetching user batting record:', error);
      }
    );
    
  }

  getUserBowlingRecord(userId: any, year: any) {
    this.playerService.getUserBowlingRecord(userId, year).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.bowlingRecord = response.result;
          this.hideBowlingRecord = true;
        } else {
          this.bowlingRecord = [];
          this.hideBowlingRecord = false;
        }
        this.getCricketBowlingUserGraph(userId,5,response.result[0].Sports_subcat_id,year)
      },
      error => {
        console.error('Error fetching user bowling record:', error);
      }
    );
    
  }

  getUserFieldingRecord(userId: any, year: any) {
    this.playerService.getUserFieldingRecord(userId, year).subscribe(
      (response: any) => {
        if (response.status === 'TRUE') {
          this.fieldingRecord = response.result;
          this.hideFieldingRecord = true;
        } else {
          this.fieldingRecord = [];
          this.hideFieldingRecord = false;
        }
      },
      error => {
        console.error('Error fetching user fielding record:', error);
      }
    );
  }
  getCricketBatsmanUserGraph(batsmanUserId: any, limit: any, subCatName: any, year: any){
    this.getuserLeaderBoard(this.userId);
    this.getAvgForBatting()
    this.playerService. getCricketBatsmanUserGraph(batsmanUserId, limit, subCatName, year).subscribe((response:any)=>{    
    })
  }
  getCricketBowlingUserGraph(batsmanUserId: any, limit: any, subCatName: any, year: any){
    this.getuserLeaderBoard(this.userId);
    this.getAvgForBowling()
    
  }
  getTrendGraphBattingDatails(batsmanUserId: any, limit: any, subCatName: any, year: any,aggregationType:any){
    this.playerService. getTrendGraphBattingDatails(batsmanUserId, limit, subCatName, year,aggregationType).subscribe((response:any)=>{
      if (response.status === "TRUE") {
        let result = response.result.map((item: { strike_rate: any }) => ({ strike_rate: item.strike_rate }));
    
        result.forEach((item: { strike_rate: any }, index: number) => {
            if (this.battingRecord[index]) {
                this.battingRecord[index]['strike_rate'] = item.strike_rate;
            }
        });
    
    }
    
    })

  }
  getTrendGraphBowlingDatails(batsmanUserId: any, limit: any, subCatName: any, year: any,aggregationType:any){
    this.getAvgForBowling()
    this.playerService. getTrendGraphBowlingDatails(batsmanUserId, limit, subCatName, year,aggregationType).subscribe((response:any)=>{
      if (response.status === "TRUE") {
        let result = response.result.map((item: { strike_rate: any }) => ({ strike_rate: item.strike_rate }));
        result.forEach((item: { strike_rate: any }, index: number) => {
            if (this.bowlingRecord[index]) {
                this.bowlingRecord[index]['strike_rate'] = item.strike_rate;                
            }
        });
    
    }
    
    })

  }

  showPerformance() {
    this.mainScreen = !this.mainScreen;
  }
  updatePaginatedTeams() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedTeams = this.filteredData.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePaginatedTeams();
  }

  getAvgForBatting(){
    
    for(let i = 0; i < this.battingRecord.length; i++){
      let avg: any;
      let sRate: any;
      
      let InnRuns = (this.battingRecord[i].innings)  -  (this.battingRecord[i].notout);
      InnRuns = InnRuns | 0;
      
      if(InnRuns === 0){
          avg = "-";
      } else {
          avg = parseFloat((this.battingRecord[i].runs / (this.battingRecord[i].innings - this.battingRecord[i].notout)).toFixed(2));
      }
      this.battingRecord[i]["avg"] = avg;
      
      if(this.battingRecord[i].runs === 0){
          sRate = "-";
      } else {
          sRate = parseFloat(((100 / this.battingRecord[i].balls_faced) * this.battingRecord[i].runs).toFixed(2));
      }
      if (isNaN(sRate)) {
        sRate = "-";
    } else {
        sRate = parseFloat(((100 / this.battingRecord[i].balls_faced) * this.battingRecord[i].runs).toFixed(2));
    }
      this.battingRecord[i]["sRate"] = sRate;
      
      
  }
  
}
getAvgForBowling() {
  for (let i = 0; i < this.bowlingRecord.length; i++) {
      let avg: string | number;
      let sRate: any;

      let InnRuns = (this.bowlingRecord[i].innings) - (this.bowlingRecord[i].notout);
      InnRuns = InnRuns | 0;

      if (InnRuns === 0) {
          avg = "-";
      } else {
          avg = parseFloat((this.bowlingRecord[i].runs / (this.bowlingRecord[i].innings - this.bowlingRecord[i].notout)).toFixed(2));
      }
      this.bowlingRecord[i]["avg"] = avg;

      if (this.bowlingRecord[i].runs === 0) {
          sRate = "-";
      } else {
          sRate = parseFloat(((100 / this.bowlingRecord[i].balls_faced) * this.bowlingRecord[i].runs).toFixed(2));
      }
      if (isNaN(sRate)) {
          sRate = "-";
      } else {
          sRate = parseFloat(((100 / this.bowlingRecord[i].balls_faced) * this.bowlingRecord[i].runs).toFixed(2));
      }
      this.bowlingRecord[i]["sRate"] = sRate;

  }
}
// navigateTo(teamId:any){
//   // this.router.navigate(["/cricketPlayerSumm",teamId]);
//   const navigationExtras: NavigationExtras = {
//     state: {
//       teamId: teamId,
//     }
//   };
//   // this.router.navigate([`/cricketTeamSumm`], navigationExtras);
//   this.router.navigate([`/SR/cricketTeamSumm`])

// }
navigateTo(teamId: any) {
  this.router.navigate([`${this.orgCode}/cricketTeamSumm`], { queryParams: { Id: teamId } });
}
}

 

export interface PeriodicElement {
  name: string;
  position: number;
  Scorecard: any;
  opponent:any;
}
