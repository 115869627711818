import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrgcodeFilterService {
  public storageItems = new BehaviorSubject<{ orgPath: string, background: string, icon: string }[]>([
    { orgPath: '', background: '', icon: '' },
  ]);
  public orgcode: BehaviorSubject<string> = new BehaviorSubject<string>('NA');
  codeValue: any;
  orgDetails$ = this.storageItems.asObservable();

  getAppBaseHref(data: any) {
    const pathname = window.location.pathname;
    const pathnameArray = pathname.split('/').filter(x => x !== '');
    
    let code = data.filter((x: any) => {
      return x.organizationCode == pathnameArray[0];
    });
    const currentItems = this.storageItems.value;

    if (code.length == 0 ) {
      this.codeValue = "SR";
      let value = data.filter((x: any) => {
        return x.organizationCode == "SR";
      });
      currentItems[0].orgPath = value[0].organizationCode;
      currentItems[0].icon = value[0].logo;
      currentItems[0].background = value[0].primaryColor;
    } else {
      if(code[0].organizationCode!="SR"){
        code[0].organizationCode="SR"
        this.codeValue="SR" 
        currentItems[0].orgPath = "SR"
        let value = data.filter((x: any) => {
          return x.organizationCode == "SR";
        });
      }else{
      this.codeValue = code[0].organizationCode;
      currentItems[0].orgPath = code[0].organizationCode;
      }
      currentItems[0].icon = code[0].logo;
      currentItems[0].background = code[0].primaryColor;
    }
    this.storageItems.next(currentItems);

    return this.codeValue;   
  }

  redirect() {
    const url = window.location.href;
    return url;
  }
}