import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderParameterService {

  private firstName = new Subject<any>();
  private lastName = new Subject<any>();
  private userImg = new Subject<any>();

  constructor() { }

  setfirstName(param:any){
    this.firstName.next(param);
  }
  setlastName(param:any){
    this.lastName.next(param);
  }
  setuserImg(param:any){
    this.userImg.next(param);
  }
}
