import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { VideoService } from 'src/app/service/video.service';

@Component({
  selector: 'app-home-section-video',
  templateUrl: './home-section-video.component.html',
  styleUrls: ['./home-section-video.component.css']
})
export class HomeSectionVideoComponent {
  @Input() videoList: any
}