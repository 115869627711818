import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { catchError } from 'rxjs';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-wagon-wheel',
  templateUrl: './wagon-wheel.component.html',
  styleUrls: ['./wagon-wheel.component.css']
})
export class WagonWheelComponent implements OnInit {

  // @Input() matchId:any;
  @Input() teamOneName:any;
  @Input() teamTwoName:any;
  @Input() wagonFor:any
  selectedRun: any;
  wagonCoordinates: any;
  wagonNotavailable:boolean= false;
  ballHit:any;
  selectedTabIndex= 0;
  matchId:any;
  intervel:any;
  @Input() wagonCoordinatesForPlayer:any;
  @Input() showTeams= true;

  constructor(private scoreCardService: CricketScoreCardService,private route: ActivatedRoute) {
  }

  ngOnInit() {  
    this.matchId = this.route.snapshot.paramMap.get('matchId');
    if(this.wagonFor == 'player'){
      this.getWagonWheelForPlayers();
      this.selectedRun = 'All';
    } else {
        this.getWagonData(1);
        this.selectedRun = 'All';
        // this.intervel =setInterval(()=>{
        //   this.getWagonData(this.selectedTabIndex == 2 ? 2 : 1);
        // },5000) 
    }
    // if(this.matchStatus == 'C'){
    //   clearInterval(this.intervel);
    // }
    
  }


  // ngOnDestroy(): void {
  //   if (this.intervel) {
  //     clearInterval(this.intervel);
  //   }
  // }

  getWagonData(innings: any) {  
    this.ballHit = [];
    this.scoreCardService.getWagOnWheelById(this.matchId,innings).subscribe((response: any) => {   
      this.ballHit = response.result;
      // this.pitchMapResponse = this.ballHit.filter((data:any)=> data.where_pitch_x != '0.0' && data.where_pitch_x != '0.0');
      this.ballHit=this.ballHit.filter((data:any)=>data.where_hit_x !='0' && data.where_hit_y !='0')
      if(this.ballHit?.length == 0){
        this.wagonNotavailable = true;
      } else{
        this.wagonNotavailable = false;
      }
    });

  }
  changeTab(event: any) {    
    this.selectedTabIndex = event.index + 1;
    this.getWagonData(this.selectedTabIndex);
  }

  lineStyles: { [key: number]: string } = {
    1: 'stroke:#e6ac0d;',
    2: 'stroke:#bb20da;',
    3: 'stroke:#06c7ad;',
    4: 'stroke:#020b41;',
    6: 'stroke:#c50f0f;',
  };

  getLineStyle(runs: number): string {
    return this.lineStyles[runs];
  }

  scaleMobileX = 350 / 620;
  scaleMobileY = 350 / 620;

  scaleFoldX = 280 / 620;
  scaleFoldY = 280 / 620;

  getShortenedEndpoint(x1: number, y1: number, x2: number, y2: number, scale: number = 0.85): { x: number, y: number } {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return {
      x: x1 + dx * scale,
      y: y1 + dy * scale
    };
  }
  getWagonWheelForPlayers(){
    this.ballHit = this.wagonCoordinatesForPlayer;
    console.log( this.ballHit,'wagonwheel');
    
    if(this.ballHit?.length == 0){
      this.wagonNotavailable = true;
    } else{
      this.wagonNotavailable = false;
    }
  }
}
