<div class="container p-3">
    <div class="row">
        <div class="col-lg-6 col-12 p-3">
            <ul class="rhombus nav nav-tabs nav-pills border-0 fw-semibold text-white justify-content-start ml-auto">
                <li class="nav-item w nav-item-spacing" *ngIf="showLiveMatch">
                    <a class="nav-link text-decoration-none" (click)="showData('LIVE')"
                        [ngClass]="{'active': activeTab === 'LIVE'}">
                        <h3 [class]="activeTab === 'LIVE' ? 'text-white m-0' : 'text-dark m-0'">LIVE</h3>
                    </a>
                </li>
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link  text-decoration-none" (click)="showData('RESULT')"
                        [ngClass]="{'active': activeTab === 'RESULT'}">
                        <h3 [class]="activeTab === 'RESULT' ? 'text-white m-0' : 'text-dark m-0'">RESULTS</h3>
                    </a>
                </li>
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link text-decoration-none " (click)="showData('UPCOMING')"
                        [ngClass]="{'active': activeTab === 'UPCOMING'}">
                        <h3 [class]="activeTab === 'UPCOMING' ? 'text-white m-0' : 'text-dark m-0'">UPCOMING</h3>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-lg-6 col-12 d-flex justify-content-end text-center p-lg-4">
            <div class="searchBtn">
                <input class="" type="search" placeholder="Search" [(ngModel)]="searchQuery" (input)="search()"
                    aria-label="Search" /><i class="bi bi-search"></i>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="isloading" class="d-flex justify-content-center align-items-center" style="height: 20vh"></app-loader>
<div class="container" *ngIf="activeTab === 'LIVE' && !isloading">
    <div *ngIf="liveMatch?.length === 0" class="text-center fs-5 d-flex justify-content-center name"
        style="height: 50vh;align-items: center;font-family:'Assist-Bold';">
        No record found
    </div>
    <div *ngIf="liveMatch?.length > 0">
        <div *ngFor="let data of liveMatch | slice: 0:displayCountForLive; let i = index">
            <app-scorecard-card [matchData]="data" [index]="i"></app-scorecard-card>
        </div>
    </div>
    <div *ngIf="liveMatch?.length > 4" class="d-flex justify-content-center mt-3 mb-3">
        <button type="button" class="btn btn-secondary" (click)="loadMoreForLive()" [disabled]="displayCountForLive>liveMatch?.length">LOAD MORE</button>
    </div>
</div>


<div class="container" *ngIf="activeTab === 'RESULT'&& !isloading">
    <div *ngIf="completedMatch?.length === 0" class="text-center fs-5 d-flex justify-content-center name"
        style="height: 50vh;align-items: center;font-family:'Assist-Bold';">
        No record found
    </div>
    <div *ngIf="completedMatch?.length > 0">
        <div *ngFor="let data of completedMatch | slice: 0:displayCountForCompleted; let i = index">
            <app-scorecard-card [matchData]="data" [index]="i"></app-scorecard-card>
        </div>
    </div>
    <div *ngIf="completedMatch?.length > 4" class="d-flex justify-content-center mt-3 mb-3">
        <button type="button" class="btn btn-secondary" (click)="loadMoreForCompleted()" [disabled]="displayCountForCompleted>completedMatch?.length">LOAD MORE</button>
    </div>
</div>
<div class="container" *ngIf="activeTab === 'UPCOMING' && !isloading">
    <div *ngIf="upcomingMatch?.length === 0" class="text-center fs-5 d-flex justify-content-center name"
        style="height: 50vh;align-items: center;font-family:'Assist-Bold';">
        No record found
    </div>
    <div *ngIf="upcomingMatch?.length > 0">
        <div *ngFor="let data of upcomingMatch| slice: 0:displayCountForUpcoming; let i = index">
            <app-scorecard-card [matchData]="data" [index]="i"></app-scorecard-card>
        </div>
    </div>
    <div *ngIf="upcomingMatch?.length > 4" class="d-flex justify-content-center mt-3 mb-3">
        <button type="button" class="btn btn-secondary" (click)="loadMoreForUpcoming()" [disabled]="displayCountForUpcoming>upcomingMatch?.length">LOAD MORE</button>
    </div>
</div>
