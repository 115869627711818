import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BadmintonMatchStatusComponent } from '@components/badminton/badminton-match-status/badminton-match-status.component';
import { BadmintonScorecardCardComponent } from '@components/badminton/badminton-scorecard-card/badminton-scorecard-card.component';
import { BatsmanScorecardComponent } from '@components/cricket/batsman-scorecard/batsman-scorecard.component';
import { BowlerScorecardComponent } from '@components/cricket/bowler-scorecard/bowler-scorecard.component';
import { CommentaryCardComponent } from '@components/cricket/commentary-card/commentary-card.component';
import { CommentaryComponent } from '@components/cricket/commentary/commentary.component';
import { FullScorecardComponent } from '@components/cricket/full-scorecard/full-scorecard.component';
import { LiveScoreCardCarousalComponent } from '@components/cricket/live-score-card-carousal/live-score-card-carousal.component';
import { LiveSectionCardComponent } from '@components/cricket/live-section-card/live-section-card.component';
import { ManhattanComponent } from '@components/cricket/manhattan/manhattan.component';
import { MatchInfoComponent } from '@components/cricket/match-info/match-info.component';
import { MiniMatchesComponent } from '@components/cricket/mini-matches/mini-matches.component';
import { PlayingXiComponent } from '@components/cricket/playing-xi/playing-xi.component';
import { RelatedVideosCardComponent } from '@components/cricket/related-videos-card/related-videos-card.component';
import { RelatedVideosSectionComponent } from '@components/cricket/related-videos-section/related-videos-section.component';
import { ScoreCardCarouselComponent } from '@components/cricket/score-card-carousel/score-card-carousel.component';
import { ScoreCardComponent } from '@components/cricket/score-card/score-card.component';
import { ScorecardCardComponent } from '@components/cricket/scorecard-card/scorecard-card.component';
import { UpcomingMatchComponent } from '@components/cricket/upcoming-match/upcoming-match.component';
import { VideoDetailCardComponent } from '@components/cricket/video-detail-card/video-detail-card.component';
import { WagonWheelComponent } from '@components/cricket/wagon-wheel/wagon-wheel.component';
import { WormComponent } from '@components/cricket/worm/worm.component';
import { HomeSectionCarousalCardComponent } from '@components/home/home-section-carousal-card/home-section-carousal-card.component';
import { HomeSectionCarousalComponent } from '@components/home/home-section-carousal/home-section-carousal.component';
import { HomeSectionLiveScoreCardComponent } from '@components/home/home-section-live-score-card/home-section-live-score-card.component';
import { HomeSectionVideoComponent } from '@components/home/home-section-video/home-section-video.component';
import { OtpVerificationComponent } from '@components/otp-verification/otp-verification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BadmintonScorecardDetailComponent } from '@pages/badminton/badminton-scorecard-detail/badminton-scorecard-detail.component';
import { BadmintonScorecardComponent } from '@pages/badminton/badminton-scorecard/badminton-scorecard.component';
import { HomePageComponent } from '@pages/cricket/home/home-page.component';
import { ScorecardDetailsComponent } from '@pages/cricket/scorecard-details/scorecard-details.component';
import { ScorecardComponent } from '@pages/cricket/scorecard/scorecard.component';
import { PlayerProfileComponent } from '@pages/users/player-profile/player-profile.component';
import { VideoDetailPageComponent } from '@pages/videos/video-detail-page/video-detail-page.component';
import { VideoLandingPageComponent } from '@pages/videos/video-landing-page/video-landing-page.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonAlertsComponent } from './common/common-alerts/common-alerts.component';
import { FooterComponent } from './common/footer/footer.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { HeaderContentsComponent } from './common/header-contents/header-contents.component';
import { HeaderComponent } from './common/header/header.component';
import { LoaderComponent } from './common/loader/loader.component';
import { LoginPageComponent } from './common/login-page/login-page.component';
import { SignUpComponent } from './common/sign-up/sign-up.component';
import { PlayerInfoComponent } from './components/badminton/player-info/player-info.component';
import { RecentMatchesComponent } from './components/badminton/recent-matches/recent-matches.component';
import { BoundaryTrackerComponent } from './components/cricket/boundary-tracker/boundary-tracker.component';
import { PartnershipComponent } from './components/cricket/partnership/partnership.component';
import { PlayervsPlayerComponent } from './components/cricket/playervs-player/playervs-player.component';
import { FactSheetComponent } from './components/fact-sheet/fact-sheet.component';
import { LiveStreamCardComponent } from './components/live-stream-card/live-stream-card.component';
import { PitchMapComponent } from './components/pitch-map/pitch-map.component';
import { PointsTableComponent } from './components/points-table/points-table.component';
import { TournamentsComponent } from './components/tournaments/tournaments.component';
import { BadmintonPlayerProfileComponent } from './pages/badminton/badminton-player-profile/badminton-player-profile.component';
import { CricketPlayerInfoComponent } from './pages/cricket/cricket-player-info/cricket-player-info.component';
import { TopPerformersComponent } from './pages/cricket/top-performers/top-performers.component';
import { TableTennisScorecardComponent } from './pages/tableTennis/table-tennis-scorecard/table-tennis-scorecard.component';
import { TennisCardDetailComponent } from './pages/tennis/tennis-card-detail/tennis-card-detail.component';
import { TennisCardComponent } from './pages/tennis/tennis-card/tennis-card.component';
import { TennisScorecardComponent } from './pages/tennis/tennis-scorecard/tennis-scorecard.component';
import { PopModelComponent } from './pages/users/pop-model/pop-model.component';
import { LoaderService } from './service/loader.service';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { StatsComponent } from './components/stats/stats.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { UpdateProfilepicComponent } from './pages/update-profilepic/update-profilepic.component';
import { TopPerformersCardComponent } from './components/cricket/top-performers-card/top-performers-card.component';
import { TeamSummaryComponent } from '@components/team-summary/team-summary.component';
import { HomeSearchComponent } from './components/home-search/home-search.component';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ScoreCardComponent,
    UpcomingMatchComponent,
    RelatedVideosCardComponent,
    RelatedVideosSectionComponent,
    HomeSectionLiveScoreCardComponent,
    HomeSectionVideoComponent,
    HomeSectionCarousalCardComponent,
    HomeSectionCarousalComponent,
    HomePageComponent,
    VideoDetailPageComponent,
    FooterComponent,
    VideoDetailCardComponent,
    ScoreCardCarouselComponent,
    LiveSectionCardComponent,
    MiniMatchesComponent,
    ForgotPasswordComponent,
    LoginPageComponent,
    SignUpComponent,
    LiveScoreCardCarousalComponent,
    ManhattanComponent,
    AppComponent,
    ScorecardComponent,
    ScorecardDetailsComponent,
    FullScorecardComponent,
    ScorecardCardComponent,
    BatsmanScorecardComponent,
    BowlerScorecardComponent,
    CommentaryComponent,
    CommentaryCardComponent,
    PlayingXiComponent,
    MatchInfoComponent,
    WagonWheelComponent,
    WormComponent,
    LoaderComponent,
    VideoLandingPageComponent,
    BadmintonScorecardComponent,
    BadmintonScorecardDetailComponent,
    BadmintonScorecardCardComponent,
    BadmintonMatchStatusComponent,
    BadmintonScorecardCardComponent,
    TennisScorecardComponent,
    TableTennisScorecardComponent,
    TennisCardComponent,
    TennisCardDetailComponent,
    BadmintonPlayerProfileComponent,
    PlayerInfoComponent,
    RecentMatchesComponent,
    TopPerformersComponent,
    PartnershipComponent,
    PlayervsPlayerComponent,
    BoundaryTrackerComponent,
    TournamentsComponent,
    PitchMapComponent,
    FactSheetComponent,
    PointsTableComponent,
    CommonAlertsComponent,
    HeaderContentsComponent,
    PlayerProfileComponent,
    PopModelComponent,
    LiveStreamCardComponent,
    CricketPlayerInfoComponent,
    OtpVerificationComponent,
    EditProfileComponent,
    StatsComponent,
    ChangePasswordComponent,
    UpdateProfilepicComponent,
    TopPerformersCardComponent,
    TeamSummaryComponent,
    HomeSearchComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatTabsModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTableModule,
    MatExpansionModule,
    MatTableModule,
    HttpClientModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    HighchartsChartModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSelectModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatAutocompleteModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [LoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
