
<div class="container pb-5" >
    <!-- <h3 class="card-title m-0 pt-2 title" (click)="backToMyTeams()"><i class="bi bi-chevron-left">
            &nbsp;</i>Player stats summary</h3> -->
    <div class="d-flex col-12 col-lg-6 align-items-center mt-3">
        <!-- <img src="" alt="" class="col-3"> -->
        <i class="bi bi-person-circle fs-1 col-2 text-center" style="color: #0c3b5a;"></i>
        <div class="col-10">
            <h2 class="m-0 playerNmae">{{playerName}}</h2>
            <p class="m-0 id">{{userId}}</p>
        </div>
    </div>
    <div class="card border-0 shadow mt-3">

        <!-- <select id="year" (click)="updateOptions()" class="p-2 rounded-2">
            <option selected disabled>Select a year</option>
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select> -->
        <select id="year" [(ngModel)]="selectedYear" (change)="updateOptions(selectedYear)" class="p-2 rounded-2">
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
        
        <div class="table-responsive my-2">
            <div *ngIf="playerStats?.length == 0" class="text-center noRecord">
                No records found
            </div>
            <table class="table table-striped" *ngIf="playerStats?.stats?.length>0">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Practice Match</th>
                        <th>Tournaments</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let stats of playerStats.stats">
                        <tr>
                            <th scope="row">{{stats.month}}</th>
                            <!-- Your corresponding data for Practice Match and Tournaments columns will go here -->
                            <td>{{ stats.practiceMatches?? '-'}}</td>
                            <td>{{stats.tournamentMatches?? '-'}}</td>

                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>