<div class="container mt-3" *ngIf="playerInfo">
    <div class="card border-0 shadow">
        <div class="p-2 row">
            <div class=" col-lg-3 col-12 profileImage d-flex justify-content-center align-items-center">
                <img src="{{playerInfo.profile_pic}}" alt="" class="rounded-4">
            </div>
            <div class="playerInfo mt-3 mt-lg-0 col-lg-5 col-12 d-flex flex-column align-items-start justify-content-center border-end">
                <h4 class="name fs-2">{{playerInfo.F_name}} {{playerInfo.l_name}} </h4>
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>Date of Birth</td>
                            <td>{{playerInfo.d_o_b}}</td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>{{playerInfo.state_name}}</td>
                        </tr>
                        <tr>
                            <td>Country</td>
                            <td>{{playerInfo.country_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-4 my-2 mt-lg-0 col-12 d-flex justify-content-evenly align-items-center">
                <button type="button" class="btn btn-outline-dark">Events</button>
                <button type="button" class="btn btn-outline-dark" (click)="personalData()">Personal</button>
                <button type="button" class="btn btn-outline-dark">Overall</button>

            </div>
        </div>
    </div>
</div>
