import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-section-carousal',
  templateUrl: './home-section-carousal.component.html',
  styleUrls: ['./home-section-carousal.component.css']
})
export class HomeSectionCarousalComponent {

  @Input() videoArrey: any;

  customOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      992: {
        items: 3
      }
    },
    nav: true,
    // navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>']
  };

}
