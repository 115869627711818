<!-- After Header -->

<ng-container *ngIf="data" >
    <div class="card border-0 mb-3 rounded-4 mx-2 shadow col-11 px-lg-3 mx-2 justify-content-evenly  body px-2" [ngStyle]="{ cursor: upcomingMatch==true ? 'unset !important' : 'pointer' }" (click)="goToScoreCardDetailPage(data.Match_id)">
       
                <div class="d-flex mb-0 event-name justify-content-between align-items-center">

                    <div class="d-flex  col-3 justify-content-between align-items-start">
                        <!-- <p class="col-8 text-nowrap m-0 venue"><i class="bi bi-geo-alt-fill text-warning me-1"></i>{{data.Venue}}</p> -->
                        <p class="col text-start m-0 text-nowrap date">{{data.Match_Date}}</p>
                    </div>
                    <p class="col text-uppercase event-name text-end mb-0" [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}">{{data.Event_name}}</p>
                </div>

               

                <div class="row mt-2">
                    <div class="col-6 d-flex align-items-center justify-content-center">
                        <div>
                            <!-- <img *ngIf="upcomingMatch!=true" src="assets/dummyLogoBlue1.png" class="image mx-1" alt="flag"> -->
                            <!-- <img *ngIf="upcomingMatch==true" src="assets/images/srmatchlogoup.png" class="image mx-1" alt="flag"> -->
                        </div>
                        <div class="score px-2">
                            <p class="text-start m-0 mb-lg-2 team" [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}" style="font-family: Assist-Light;">{{data.teamone}}</p>
                            <div *ngIf="data.isMultiday !='Y'">
                                <p *ngIf="upcomingMatch!=true" class=" text-start team" [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}" ><span class="me-1">{{data.innings_1_score}}/{{data.innings_1_wkts}}</span><span class="over">({{data.innings_1_overs}})</span></p>
                            </div>
                            
                            <div class="d-flex justify-content-center" *ngIf="data.isMultiday=='Y'">
                                <span >{{ data['innings_' + data.team_1_group?.[0] + '_score'] }}/{{ data['innings_' + data.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[1] + '_score'] > 0 || data['innings_' + data.team_1_group?.[1] + '_score'] === 0">
                                    & {{ data['innings_' + data.team_1_group?.[1] + '_score'] }}/{{ data['innings_' + data.team_1_group?.[1] + '_wkts'] }}
                                </span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                            
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-center">
                        <div class="score px-2">
                            <p class="text-start m-0 mb-lg-2  team" [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}" style="font-family: Assist-Light;">{{data.teamtwo}}</p>
                            <div *ngIf="data.isMultiday !='Y'">
                                <p *ngIf="upcomingMatch!=true" class=" text-start team" [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}"><span class="me-1 ">{{data.innings_2_score}}/{{data.innings_2_wkts}}</span><span class="over">({{data.innings_2_overs}})</span></p>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="data.isMultiday=='Y'">
                                <span>{{ data['innings_' + data.team_2_group?.[0] + '_score'] }}/{{ data['innings_' + data.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_score'] > 0 || data['innings_' + data.team_2_group?.[1] + '_score'] === 0">
                                    & {{ data['innings_' + data.team_2_group?.[1] + '_score'] }}/{{ data['innings_' + data.team_2_group?.[1] + '_wkts'] }}
                                </span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_status']?.split(' ')[1] !== 'D' && data['innings_' + data.team_2_group?.[0] + '_status']?.split(' ')[1] === 'L'">f/o</span>&nbsp;
                              </div>
                        </div>
                        <div class="text-end">
                            <!-- <img *ngIf="upcomingMatch!=true" src="assets/dummyLogoBlue1.png" class="image mx-1" alt="flag"> -->
                            <!-- <img *ngIf="upcomingMatch==true" src="assets/images/srmatchlogoup.png" class="image mx-1" alt="flag"> -->
                        </div>
                    </div>
                </div>
                <div class="row border border-light-subtle mx-auto pb-1 pt-3 rounded-3" style="background-color: rgb(237, 246, 255); width: 100%;" *ngIf="data.result">
                    <p class=" text-center" [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}" >{{data.result}}</p>
                </div>
                <div class="row border border-light-subtle mx-auto pb-1 pt-3 rounded-3" style="background-color: rgb(237, 246, 255); width: 100%;" *ngIf="upcomingMatch==true">
                    <p class=" text-center " [ngClass]="{'fs-7': isSmallScreen, '': !isSmallScreen}" >Yet To Start</p>
                </div>
    </div>
</ng-container>
