import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  // isLoading: boolean = false;

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    // this.loaderService.loaderState.subscribe((state: boolean) => {
    //   this.isLoading = state;
    // });
  }
}
