import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ScoreCardComponent implements OnInit, AfterViewInit {

  @Input() scoreCard: any;
  showTossWon = true;
  isfollowon: boolean=false;
  orgCode:any
  // @ViewChild('scrollingText') scrollingText!: ElementRef;
  constructor(private changeDetector : ChangeDetectorRef, private router : Router,private orgFilter:OrgcodeFilterService) {
    
   }

  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
    // this.checkScrollNeeded();
  }

  getTossWonTeam(data: any) {
    this.showTossWon = false;
    return data.teamone + ' Won the Toss';
  }

  // getCricketLiveMatch(data: any) {
  //   if (data.isMultiday == 'L') {

  //     let target = data.innings_1_score + 1;
  //     const neededScore = target - data.innings_2_score;


  //     let innings2Overs = Number(data.innings_2_overs).toFixed(1)
  //     console.log();

  //     let ballsRemaining = ((6 * (data.max_overs - Number(innings2Overs.toString().split('.')[0]))) - Number(innings2Overs.toString().split('.')[1]));
  //     if (data.innings_2_wkts === 10 || ballsRemaining === 0 || data.innings_2_score === target) {
  //       if ((data.innings_2_score > data.innings_1_score) || (data.innings_2_score === target)) {
  //         return data.teamtwo + ' Won the Match';
  //       } else if (data.innings_1_score === data.innings_2_score) {
  //         return ' Match Tied';
  //       } else {
  //         return data.teamone + ' Won the Match';
  //       }
  //     } else {
  //       return data.teamtwo + " needs " + neededScore + " runs in " + ballsRemaining + " balls.";
  //     }
  //   } else if (data.isMultiday == 'Y') {
  //     let target = data.innings_1_score + 1;
  //     const neededScore = target - data.innings_2_score;
  //     const teamonescore= data.innings_1_score+data.innings_3_score;
  //     const teamtwoscore= data.innings_2_score+data.innings_4_score;
  //     if (data.curr_innings == 1 && data.innings_1_overs != 0) {
  //       return data.teamone + 'lead by' + data.innings_1_score;
  //     }
  //     else if (data.curr_innings == 2 && data.innings_2_overs != 0) {
  //       if (data.innings_1_score > data.innings_2_score) {
  //         return data.teamtwo + 'trial by' + (teamonescore - teamtwoscore);
  //       } else {
  //         return data.teamtwo + 'lead by' + (teamtwoscore- teamonescore);
  //       }
  //     }
  //     else if(data.curr_innings == 3 && data.innings_3_overs!=0){
  //       if (teamonescore > teamtwoscore) {
  //         return data.teamtwo + 'trial by' + (teamonescore - teamtwoscore);
  //       } else {
  //         return data.teamtwo + 'lead by' + (teamtwoscore- teamonescore);
  //       }
  //     }
  //     else if(data.curr_innings == 4 && data.innings_4_overs!=0){
  //       return data.teamtwo + 'needs' +  ((teamonescore - teamtwoscore)+1);

  //     }

  //   }
  // }
  getCricketLiveMatch(data: any) {
    if (data.isMultiday == 'L') {

      let target = data.innings_1_score + 1;
      const neededScore = target - data.innings_2_score;


      let innings2Overs = Number(data.innings_2_overs).toFixed(1)
      console.log();

      let ballsRemaining = ((6 * (data.max_overs - Number(innings2Overs.toString().split('.')[0]))) - Number(innings2Overs.toString().split('.')[1]));
      if (data.innings_2_wkts === 10 || ballsRemaining === 0 || data.innings_2_score === target) {
        if ((data.innings_2_score > data.innings_1_score) || (data.innings_2_score === target)) {
          return data.teamtwo + ' Won the Match';
        } else if (data.innings_1_score === data.innings_2_score) {
          return ' Match Tied';
        } else {
          return data.teamone + ' Won the Match';
        }
      } else {
        return data.teamtwo + " needs " + neededScore + " runs in " + ballsRemaining + " balls.";
      }
    } else if (data.isMultiday == 'Y') {
      // let target = data.innings_1_score + 1;
      // const neededScore = target - data.innings_2_score;
      const teamonescore = data.innings_1_score + data.innings_3_score;
      const teamtwoscore = data.innings_2_score + data.innings_4_score;
      const teamonescoreiffollow = data.innings_1_score + data.innings_4_score;
      const teamtwoscoreiffollow = data.innings_2_score + data.innings_3_score;
      const innings2StatusParts = (data.innings_2_status || "").split(' ');
      if (innings2StatusParts.includes('L')) {
        this.isfollowon=true;
      }
        else{
          this.isfollowon=false;
        }
      if (data.curr_innings == 1 && data.innings_1_overs == 0) {
        return data.tossWon + ' elected to ' + data.elected_to;

      }
      else if (data.curr_innings == 1 && data.innings_1_overs > 0) {
        return data.teamone + ' lead by ' + data.innings_1_score;
      } else if (data.curr_innings == 2) {
        if (data.innings_2_overs == 0) {
          return data.teamone + ' lead by ' + data.innings_1_score;
        } else {
          if (data.innings_1_score > data.innings_2_score) {
            return data.teamtwo + ' trail by ' + (teamonescore - teamtwoscore);
          } else {
            return data.teamtwo + ' lead by ' + (teamtwoscore - teamonescore);
          }
        }
      } else if (data.curr_innings == 3) {
        if (data.innings_3_overs == 0) {
          if (data.innings_1_score > data.innings_2_score) {
            return data.teamtwo + ' trail by ' + (teamonescore - teamtwoscore);
          } else {
            return data.teamtwo + ' lead by ' + (teamtwoscore - teamonescore);
          }
        } else {
          if (this.isfollowon==true) {
            if (teamonescoreiffollow > teamtwoscoreiffollow) {
              return data.teamtwo + ' trail by ' + ((teamonescoreiffollow) - teamtwoscoreiffollow);
            } else {
              return data.teamtwo + ' lead by ' + (teamtwoscoreiffollow - teamonescoreiffollow);
            }
          } else {
            if (teamonescore > teamtwoscore) {
              return data.teamone + ' lead by ' + (teamonescore - teamtwoscore);
            } else {
              return data.teamone + ' trail by ' + (teamtwoscore - teamonescore);
            }
          }
        }
      } else if (data.curr_innings == 4) {
        if (data.innings_4_overs == 0) {
          if(this.isfollowon==true){
            if (teamonescoreiffollow > teamtwoscoreiffollow) {
              return data.teamtwo + ' trail by ' + ((teamonescoreiffollow) - teamtwoscoreiffollow);
            } else {
              return data.teamtwo + ' lead by ' + (teamtwoscoreiffollow - teamonescoreiffollow);
            }
          }else{
            if (teamonescore > teamtwoscore) {
              return data.teamone + ' lead by ' + (teamonescore - teamtwoscore);
            } else {
              return data.teamone + ' trail by ' + (teamtwoscore - teamonescore);
            }
          }
        }
        else {
          if(this.isfollowon==true){
            return data.teamone + ' needs ' + (((teamtwoscoreiffollow) -(teamonescoreiffollow) ) + 1) + ' to win ';  
          }else{
            return data.teamtwo + ' needs ' + ((teamonescore - teamtwoscore) + 1) + ' to win ';
          }
        }
      } else {
        return 'Match in progress';
      }
    } else {
      return '';
    }
  }


  getStatus(data:any) {
    return (data?.status === "C" ||  data?.status === "c" ) ? 'Result' : 'Live';
  }

  goToScoreCardDetailPage(matchId : any, Team1:any, Team2: any, teamone:any, teamtwo: any ) {
    this.router.navigate([`${this.orgCode}/scorecardDetails`, matchId]);
    }


  // checkScrollNeeded(): void {
  //   const containerWidth = this.scrollingText.nativeElement.parentElement.offsetWidth;
  //   const textWidth = this.scrollingText.nativeElement.offsetWidth;

  //   if (textWidth > containerWidth) {
  //     this.scrollingText.nativeElement.classList.add('active');
  //   } else {
  //     this.scrollingText.nativeElement.classList.remove('active');
  //   }
  // }

}
