import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-live-stream-card',
  templateUrl: './live-stream-card.component.html',
  styleUrls: ['./live-stream-card.component.css']
})
export class LiveStreamCardComponent {

  @ViewChild('sequence', { static: false })
  sequence!: ElementRef;
  liveScoringDetail: any;
  liveMatchStrem: any;
  overDetail: any;
  showCRR:boolean = false;
  showCRRandRRR:boolean = false;
  showProjectedScore:boolean = false;
  showLiveFeed:boolean = false;
  showTarget:boolean = false;
  showTossDetail:boolean = false;
  matchId: any;
  showFourOrSix:boolean = false;
  showBatsmanDetail:boolean = true;
  lastBallRun: any;
  codeExecuted: boolean = false;
  showBowlerDetail: boolean = true;
  showWicketEXtras:boolean = false;
  showTotalSCore:boolean = true;
  showBreakDetail:boolean = false;
  lastProcessedBall: any;
  thisOver: any;
  showImage: any;
  scoreCardImage:boolean = false;
  showWebcard:boolean = false;

  

  constructor(private elementRef: ElementRef,private http: HttpClient,private route :ActivatedRoute,private service:CricketScoreCardService) {}

  // getLiveScoring(matchId:any){
  //   return this.http.get('https://www.sportsruler.com/getLiveStreamingApi?MATCH_ID=matchId'.replace('matchId',matchId));
  // }
  // getOverSequence(matchId:any ,currInnings:any){
  //   return this.http.get('https://www.sportsruler.com/getcurrentoverballdetails?MATCHID=matchId&INNINGS=currInnings'.replace('currInnings',currInnings).replace('matchId',matchId))
  // }
  ngOnInit(){
    this.matchId= this.route.snapshot.paramMap.get('matchId');
    this.showImage = this.route.snapshot.paramMap.get('showImage');
    
    // setInterval(() => {
    //   this.liveMatchStremResponce();
    //   this.getOverBallsDetail()
    // }, 5000);
    if(this.showImage == 'Y' ){
      this.scoreCardImage = true;
    } else if(this.showImage == 'N'){
      this.showWebcard = true;
    }
  }
  liveMatchStremResponce(){
    this.service.getLiveScoring(this.matchId).subscribe((responce:any)=>{
      this.liveScoringDetail = responce.result[0];
    });

    if(this.liveScoringDetail?.match_break == 'Innings Break'){
      this.showBreakDetail = true;
      this.showTotalSCore = false;
      this.showBatsmanDetail = false;
      this.showBowlerDetail = false 
     } else {
      this.showBreakDetail = false;
      this.showTotalSCore = true;
      this.showBatsmanDetail = true;
      this.showBowlerDetail = true 
     }

  }
  
getOverBallsDetail() {
  this.service.getOverSequence(this.matchId, this.liveScoringDetail?.curr_innings).subscribe((overRespose: any) => {
    this.overDetail = overRespose.result.overDetails[0];
    this.thisOver = overRespose.result.overDetails;

    const balls = this.overDetail?.over_sequence.trim().split(' ');
    let lastBall = balls[balls?.length - 1];
    let lastBallCount = this.overDetail.last_ball_in_over;
    
    if (lastBallCount !== this.lastProcessedBall) {
      this.codeExecuted = false;
      this.lastProcessedBall = this.overDetail.last_ball_in_over;


      switch (lastBall) {
        case '4':
          this.lastBallRun = "FOUR";
          break;
        case '6':
          this.lastBallRun = "SIX";
          break;
        case 'W':
          this.lastBallRun = "WICKET";
          break;
        case 'Wd':
        case 'Wd1':
        case 'Wd2':
        case 'Wd3':
        case 'Wd4':
        case 'Wd5':
          this.lastBallRun = "WIDE";
          break;
        case 'Nb':
        case 'Nb1':
        case 'Nb2':
        case 'Nb3':
        case 'Nb4':
        case 'Nb5':
        case 'Nb6':
          this.lastBallRun = "NO BALL";
          break;
        default:
          this.lastBallRun = "";
      }

      if (this.lastBallRun === "FOUR" || this.lastBallRun === "SIX") {
        this.executeCode(true, false,'BOUNDARY');
      } else if (this.lastBallRun === "WICKET" || this.lastBallRun === "WIDE" || this.lastBallRun === "NO BALL") {
        if(this.lastBallRun === "NO BALL"){
          setTimeout(() => {
            this.lastBallRun = "FREE HIT"
          }, 1250);
        }
        this.executeCode(true, false,'EXTRAS');
      } else {
        this.codeExecuted = false;
      }

    }
    this.hideAndShowFOrMainScore();
    this.checkOverflow();
  });
}


  executeCode(showValue: boolean, hideValue: boolean,type:any) {
    if (!this.codeExecuted) {
      this.codeExecuted = true;
      if(type == 'EXTRAS'){
        this.showBowlerDetail = hideValue;
        this.showWicketEXtras = showValue;
        setTimeout(() => {
          this.showBowlerDetail = showValue;
          this.showWicketEXtras = hideValue;
        }, 3000);
      } else if(type == 'BOUNDARY'){
        this.showBatsmanDetail = hideValue;
        this.showFourOrSix = showValue;
        setTimeout(() => {
          this.showBatsmanDetail = showValue;
          this.showFourOrSix = hideValue;
        }, 3000);
      }
    }
  }


  hideAndShowFOrMainScore(){
    // let [over, balls] = this.liveScoringDetail?.currentBattingOvers.split('.');
 
    if(this.overDetail?.over_no == '0' && this.liveScoringDetail?.curr_innings == '1'){
      this.showTossDetail = true;
      this.showCRR = false;
    } else if(this.liveScoringDetail?.curr_innings == '1'){
      this.showCRR = true;
      setTimeout(() => {
        this.showCRR = false;
        this.showProjectedScore = true;
        setTimeout(() => {
          this.showProjectedScore = false;
          this.showCRR = true;
          
        }, 2500);

      }, 2500);

    } else if(this.liveScoringDetail?.curr_innings == '2'){   
      this.showCRRandRRR = true;
      this.showLiveFeed = false;
      setTimeout(() => {
        this.showCRRandRRR = false;
        this.showLiveFeed = true;
        setTimeout(() => {
          this.showLiveFeed =false;
          this.showCRRandRRR = true;
        }, 2500);
      }, 2500);
      
    }


  }


  checkOverflow() {
    const sequenceElement = this.sequence.nativeElement;
    const containerElement = sequenceElement.parentElement;

    if (sequenceElement.scrollWidth > containerElement.offsetWidth) {
      sequenceElement.classList.add('scrolling');
    } else {
      sequenceElement.classList.remove('scrolling');
    }
  }

}
