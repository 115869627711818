<div class="container p-3">
    <div class="row">
        <div class="nav-bar col-12 col-lg-8 p-3">
            <ul
                class="rhombus nav nav-tabs nav-pills border-0 fw-semibold text-white justify-content-center flex-nowrap">
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link text-decoration-none" (click)="getAllVideos('All')"
                        [ngClass]="{'active': activeTab === 'All'}">
                        <h3 [class]="activeTab === 'All' ? 'text-white m-0' : 'text-dark m-0'">ALL</h3>
                    </a>
                </li>
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link  text-decoration-none" (click)="getAllVideos('1')"
                        [ngClass]="{'active': activeTab === '1'}">
                        <h3 [class]="activeTab === '1' ? 'text-white m-0' : 'text-dark m-0'" >PRACTICE</h3>
                    </a>
                </li>
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link text-decoration-none " (click)="getAllVideos('2')"
                        [ngClass]="{'active': activeTab === '2'}">
                        <h3 [class]="activeTab === '2' ? 'text-white m-0' : 'text-dark m-0'">NEWS</h3>
                    </a>
                </li>
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link text-decoration-none " (click)="getAllVideos('3')"
                        [ngClass]="{'active': activeTab === '3'}">
                        <h3 [class]="activeTab === '3' ? 'text-white m-0' : 'text-dark m-0'">INTERVIEWS</h3>
                    </a>
                </li>
                <li class="nav-item w nav-item-spacing">
                    <a class="nav-link text-decoration-none " (click)="getAllVideos('4')"
                        [ngClass]="{'active': activeTab === '4'}">
                        <h3 [class]="activeTab === '4' ? 'text-white m-0 text-nowrap' : 'text-dark m-0 text-nowrap'">STREAMED VIDEOS</h3>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-lg-4 col-12 d-flex justify-content-end text-center p-lg-4">
            <div class="searchBtn">
                <input
                  class=""
                  type="search"
                  placeholder="Search"
                  [(ngModel)]="searchQuery"
                  (input)="search()"
                  aria-label="Search"
                /><i class="bi bi-search"></i>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="isloading" class="d-flex justify-content-center align-items-center" style="height: 20vh"></app-loader>
<div *ngIf="videos?.length === 0 && !isloading"
class="text-center fs-5 d-flex justify-content-center name" style="height: 50vh;align-items: center;font-family:'Assist-Bold';">
No record found
</div>
<div class="container videoLanding" *ngIf="videos.length>0 && !isloading " >
    <div class="row">
        <div class="col-lg-4 p-2 p-lg-2" *ngFor="let item of getDataForPage()">
            <div class="card border-0 shadow rounded-4 d-flex justify-content-center"  (click)="getVideoDetail(item.video_id,item.vedio_category_id)">
                <img src="{{item.video_thumbnail_image}}" onerror="this.src='/assets/images/World.webp';"  alt="" class="card-img-top rounded-top-4">
                <div class="card-body">
                    <p class="card-title fs-5">{{item.video_title}}</p>
                </div>
            </div>
        </div>
    </div>
    <mat-paginator [pageSize]="pageSize" [length]="videos.length" [pageIndex]="currentPage"
        (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
</div>
