<div class="container my-5 py-5">
    <div class="card my-4">
        <div class="row">
            <div class="col my-4">
                <div class="col-12 d-flex justify-content-center align-items-center ">
                    <div class="col-6 position-relative d-flex justify-content-center align-items-end">
                        <img [src]="previewImageUrl"  onerror="this.src='/assets/user-thumbnail.png';" alt="" class="img-fluid rounded-circle"
                            style="width:130px;background-color: white;height: 130px;object-fit: cover;">
                            <label for="fileInput" class="rounded-circle position-relative custom-button" style="right: 34px;">
                                <i class="bi bi-camera-fill" style="font-size: 22px;"></i>
                            </label>
                            <input id="fileInput" type="file" (change)="onFileSelected($event)">
                    </div>
                </div>
            </div>
           
            <div class="my-3 d-flex fw-medium fs-6 result text-center mb-3 justify-content-center">
                <button class="button col-5 btn btn-danger" (click)="cancel()">cancel</button>&nbsp;
                <button [disabled]="!imageFile" class="button col-5 btn btn-success" (click)="save()">save</button>
            </div>
        </div>
    </div>
</div>
