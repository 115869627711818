<div class="container my-2 d-flex justify-content-center" >
   <div *ngIf="statsCard && statsCard.length>0 ;else noRecords">
    <div class="d-flex flex-wrap gap-2 justify-content-lg-start justify-content-center">
        <div class="card border-0 shadow col-lg-2 col-3  statsCard align-items-center justify-content-center gap-2 p-2" *ngFor="let stats of statsCard">
            <div class="value">{{stats.value}}</div>
            <div class="statsName">{{stats.name}}</div>
        </div>
    </div>
    <div class="">
        <div *ngIf="battingToppers?.length>0">
        <h3 class="heading my-2">TOP RUN GETTERS</h3>
        <div class="d-flex gap-3 flex-wrap" *ngIf="battingToppers[0]">
            <div class="shadow d-flex col-lg-4 col-md-5 align-items-center p-2 col-12 rounded-2">
                <div class="col-3 text-center"><i class="bi bi-person-circle fs-1 profile"></i></div>
                <div class="col-9 d-flex flex-column">
                    <span class="fs-2 record my-2">{{battingToppers[0]?.record}}</span>
                   <span class="nameAndTeam">Name : {{battingToppers[0]?.name}}</span>
                   <span class="nameAndTeam">
                    Team : {{battingToppers[0]?.team_name}}
                   </span>

                </div>
            </div>
            <div class="shadow d-flex col-lg-4 col-md-5 col-12 align-items-center p-2 rounded-2" *ngIf="battingToppers[1]">
                <div class="col-3 text-center"><i class="bi bi-person-circle fs-1 profile"></i></div>
                <div class="col-9 d-flex flex-column">
                    <span class="fs-2 record my-2">{{battingToppers[1]?.record}}</span>
                    <span class="nameAndTeam">Name : {{battingToppers[1]?.name}}</span>
                    <span class="nameAndTeam">
                     Team : {{battingToppers[1]?.team_name}}
                    </span>
                </div>
            </div>

        </div>
        </div>

        <div *ngIf="battingToppers?.length>2">
        <h3 class="heading my-2">HIGHEST SR(MIN:25 RUNS)</h3>
        <div class="d-flex gap-3 flex-wrap" *ngIf="battingToppers[2]">
            <div class="shadow d-flex col-lg-4 col-md-5 align-items-center p-2 col-12 rounded-2">
                <div class="col-3 text-center"><i class="bi bi-person-circle fs-1 profile"></i></div>
                <div class="col-9 d-flex flex-column">
                    <span class="fs-2 record my-2">{{battingToppers[2]?.record}}</span>
                   <span class="nameAndTeam">Name : {{battingToppers[2]?.name}}</span>
                   <span class="nameAndTeam">
                    Team : {{battingToppers[2]?.team_name}}
                   </span>

                </div>
            </div>
            <div class="shadow d-flex col-lg-4 col-md-5 col-12 align-items-center p-2 rounded-2" *ngIf="battingToppers[3]">
                <div class="col-3 text-center"><i class="bi bi-person-circle fs-1 profile"></i></div>
                <div class="col-9 d-flex flex-column">
                    <span class="fs-2 record my-2">{{battingToppers[3]?.record}}</span>
                    <span class="nameAndTeam">Name : {{battingToppers[3]?.name}}</span>
                    <span class="nameAndTeam">
                     Team : {{battingToppers[3]?.team_name}}
                    </span>
                </div>
            </div>

        </div>
        </div>
        <div *ngIf="bowlingToppers?.length>0">
        <h3 class="heading my-2">LEADING WICKET TAKERS</h3>
        <div class="d-flex gap-3 flex-wrap" *ngIf="bowlingToppers[0]">
            <div class="shadow d-flex col-lg-4 col-md-5 align-items-center p-2 col-12 rounded-2">
                <div class="col-3 text-center"><i class="bi bi-person-circle fs-1 profile"></i></div>
                <div class="col-9 d-flex flex-column">
                    <span class="fs-2 record my-2">{{bowlingToppers[0]?.record}}</span>
                   <span class="nameAndTeam">Name : {{bowlingToppers[0]?.name}}</span>
                   <span class="nameAndTeam">
                    Team : {{bowlingToppers[0]?.team_name}}
                   </span>

                </div>
            </div>
            <div class="shadow d-flex col-lg-4 col-md-5 col-12 align-items-center p-2 rounded-2" *ngIf="bowlingToppers[1]">
                <div class="col-3 text-center"><i class="bi bi-person-circle fs-1 profile"></i></div>
                <div class="col-9 d-flex flex-column">
                    <span class="fs-2 record my-2">{{bowlingToppers[1]?.record}}</span>
                    <span class="nameAndTeam">Name : {{bowlingToppers[1]?.name}}</span>
                    <span class="nameAndTeam">
                     Team : {{bowlingToppers[1]?.team_name}}
                    </span>
                </div>
            </div>

        </div>
        </div>

    </div>
   </div>
</div>
<ng-template #noRecords>
  <div class="p-3 text-center not-found fw-bolder">No records found!</div>
</ng-template>
