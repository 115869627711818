import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent {
  userOtp:any;
  emailFormControl = new FormControl('', [Validators.required,Validators.pattern('^[0-9]{6}$')]);
  isValidOtp: boolean=false; 
  userId:any;
  sysGenOtp:any
  emailId: any;
  loginType: any;

  constructor(private route: ActivatedRoute,private service :CommonService,private router:Router){ }
  
  ngOnInit(){
    this.userId = history.state.userId;
    this.emailId = history.state.emailId;
  }

  onSubmit() {
    if (this.emailFormControl.valid) {
       this.userOtp = this.emailFormControl.value;
     this.service.getverificationCode(this.userId).subscribe((response:any) => {
      if(response.status =='TRUE'){
        this.sysGenOtp=response.result[0].email_verification
        this.updateLoginTypeforUser();

      }

      })
    }
}

onInputChange(event: any) {
  const newValue = event.target.value.replace(/\D/g, ''); 
  this.emailFormControl.setValue(newValue); 
}

updateLoginTypeforUser(){
  this.service.updateLoginType(this.emailId).subscribe((response:any) => {
    if(response.status === 'TRUE'){
      this.loginType = response.result;
      this.isValidOtp=false;
      this.router.navigateByUrl('');
      console.log("Logintype updated successfully");
    }else{
      this.isValidOtp=true;
      console.log("Failed to updated Logintype");
    }

  })
}

}