<div class="main-card">
    <mat-card class="background">
        <div class="container d-block d-lg-none ">
            <div class="row">
                <div class="col-12" >
                    <div>
                        <div class="live m-2 text-danger w-25 rounded-pill text-start fw-semibold d-flex align-items-center"
                            *ngIf="cricketMatchSumm?.status === 'P'">
                            <i class="bi bi-circle-fill me-1"></i> LIVE
                        </div>
                        <div class="row mt-2">
                            <p class="col m-0 fs-6 ms-1 fw-semibold venue"><i
                                    class="bi bi-geo-alt-fill text-warning-50 me-1"></i>{{cricketMatchSumm?.venue}}</p>
                            <p class="col m-0 fs-6 fw-light text-end date"><i
                                    class="bi bi-calendar2-minus text-white-50 me-1"></i>{{cricketMatchSumm?.match_date}}</p>
                        </div>
                        <hr>
                        <div class="d-flex">
                            <div class="col-6 fs-6 teams text-center">{{cricketMatchSumm?.teamOne}}</div>
                            <div class="col-6 fs-6 teams text-center">{{cricketMatchSumm?.teamTwo}}</div>
                        </div>
                        <div class="row my-2">
                            <div class="col-5 d-flex justify-content-evenly align-items-center p-0">
                                <div class="col-4 logo-container ms-1">
                                    <img src="assets/images/srmatchlogoup.png" class="img-fluid  d-block team" alt="">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-between align-items-center lh-base">

                                    <div class="col-md-1 d-flex justify-content-center align-items-center">
                                        <div class="" *ngIf="cricketMatchSumm?.isMultiDay !='Y'">
                                            <p class="fs-5 text-center team"><span class="me-1 score">{{cricketMatchSumm?.innings_1_score}}/{{cricketMatchSumm?.innings_1_wkts}}</span><span class="over">({{cricketMatchSumm?.innings_1_overs}})</span></p>
                                        </div>
                                            <div class="fs-6 fw-bolder" *ngIf="cricketMatchSumm?.isMultiDay == 'Y'">
                                                <div class="text-center col-12">
                                                <span>{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>
                                                </div>
                                                <div class="text-center col-12">
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_score'] > 0 || cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_score'] === 0">
                                                {{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_wkts'] }}
                                                </span>
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <span class="col-2 d-flex align-items-center justify-content-center fw-semibold fs-6">Vs</span>
                            <div class="col-5 d-flex flex-row-reverse justify-content-evenly align-items-center p-0">
                                <div class="col-4 logo-container me-1">
                                    <img src="assets/images/srmatchlogoup.png" class="img-fluid  d-block team" alt="">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-between align-items-center lh-base">

                                    <div class="col-md-1 d-flex justify-content-center align-items-center">
                                        <div class="" *ngIf="cricketMatchSumm?.isMultiDay !='Y'">
                                            <p class="fs-5 text-center team"><span class="me-1 score fw-bold">{{cricketMatchSumm?.innings_2_score}}/{{cricketMatchSumm?.innings_2_wkts}}</span><span class="over">({{cricketMatchSumm?.innings_2_overs}})</span></p>
                                        </div>
                                        <div class=" fs-6  fw-bolder" *ngIf="cricketMatchSumm?.isMultiDay =='Y'">
                                            <div class="text-center">
                                                <span>{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>
                                            </div>
                                            <div class="text-center ">
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_score'] > 0 || cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_score'] === 0">
                                                {{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_wkts'] }}
                                                </span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                                <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_status']?.split(' ')[1] !== 'D' && cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_status']?.split(' ')[1] === 'L'">f/o</span>&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-3 fw-semibold fs-6 result text-center">
                                {{cricketMatchSumm?.match_result}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-3 mb-3 d-lg-block d-none " >
            <div class="row">
                <div class="col-12">
                  <div class="live">
                  <div class="live m-2 text-danger w-25 rounded-pill text-start fw-semibold d-flex align-items-center "
                    *ngIf="cricketMatchSumm?.status === 'P'">
                    <i class="bi bi-circle-fill me-1"></i> LIVE
                  </div>
                  </div>
                    <div class="text-center">
                        <!-- Team and Venue -->
                        <div class="venue" style="font-size: 20px;">
                            {{cricketMatchSumm?.match_date}} | {{cricketMatchSumm?.venue}}
                        </div>

                        <!-- Centered Logos and Scores -->
                        <div class="row p-2 p-lg-0 justify-content-center align-items-center flex-wrap">
                            <div class="col-5 col-lg-5 d-flex justify-content-evenly">
                                <div
                                    class="col-9 col-md-9 d-flex flex-column justify-content-center align-items-center">
                                    <div class="logo-container">
                                      <img src="assets/images/srmatchlogoup.png" class="img-fluid  d-block team"
                                          alt="">
                                  </div>
                                    <div class="fs-3 teams fs-6 mb-3" style="line-height: 30px;">{{cricketMatchSumm?.teamOne}}</div>
                                </div>
                                <div class="col-3 col-md-3 d-flex justify-content-center align-items-center m-3" *ngIf="cricketMatchSumm?.isMultiDay !='Y'">
                                    <p class="fs-5 text-center team"><span class="me-1 score">{{cricketMatchSumm?.innings_1_score}}/{{cricketMatchSumm?.innings_1_wkts}}</span><span class="over">({{cricketMatchSumm?.innings_1_overs}})</span></p>
                                </div>
                                <div class="col-3 col-md-3 d-flex flex-column justify-content-center align-items-center m-3 gap-2 fs-6 fw-bolder" *ngIf="cricketMatchSumm?.isMultiDay == 'Y'">
                                    <div class="text-center col-12">
                                    <span>{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
                                    <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                    <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>
                                    </div>
                                    <div class="text-center col-12">
                                    <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_score'] > 0 || cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_score'] === 0">
                                    {{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_wkts'] }}
                                    </span>
                                    <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                    <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 col-md-2 d-flex justify-content-center align-items-center fs-3 vs">
                                <span class=" p-1 p-lg-3 p-md-4" style="font-size: 15px;">Vs</span>
                            </div>
                            <div class="col-lg-5 col-5 d-flex justify-content-evenly">
                                <div class="col-3 col-md-3 d-flex justify-content-center align-items-center m-3 fs-6" *ngIf="cricketMatchSumm?.isMultiDay !='Y'">
                                    <p class="fs-5 text-center team"><span class="me-1 score fw-bold">{{cricketMatchSumm?.innings_2_score}}/{{cricketMatchSumm?.innings_2_wkts}}</span><span class="over">({{cricketMatchSumm?.innings_2_overs}})</span></p>
                                </div>
                                <div class="col-3 col-md-3 d-flex flex-column justify-content-center align-items-center gap-2 m-3 fs-6  fw-bolder" *ngIf="cricketMatchSumm?.isMultiDay =='Y'">
                                    <div class="text-center">
                                        <span>{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
                                        <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                        <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>
                                    </div>
                                    <div class="text-center ">
                                        <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_score'] > 0 || cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_score'] === 0">
                                        {{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_score'] }}/{{ cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_wkts'] }}
                                        </span>&nbsp;
                                        <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                        <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                        <span *ngIf="cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[1] + '_status']?.split(' ')[1] !== 'D' && cricketMatchSumm['innings_' + cricketMatchSumm?.team_2_group?.[0] + '_status']?.split(' ')[1] === 'L'">f/o</span>&nbsp;
                                    </div>
                                </div>
                                <div
                                    class="col-9 col-md-9 d-flex flex-column justify-content-center align-items-center">
                                    <div class="logo-container logo-container-left">
                                      <img src="assets/images/srmatchlogoup.png" class="img-fluid  d-block team"
                                          alt="">
                                  </div>
                                    <div class="fs-3 fs-6 teams mb-3"style="line-height: 30px;">{{cricketMatchSumm?.teamTwo}}</div>
                                </div>
                            </div>
                        </div>

                        <!-- Match Status -->
                        <div class="mt-3 fw-bold fs-5 result">
                            {{cricketMatchSumm?.match_result}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>

<div *ngIf="isLoading; else dataTemplate"></div>
<ng-template #dataTemplate>
<div class="container mt-2 p-0">
    <div class="card-container">
        <mat-tab-group (selectedIndexChange)="tabChanged($event)">
            <mat-tab label="Commentary" *ngIf="matchDetail.match_status == 'P'">
                <ng-container *ngTemplateOutlet="commentory; else noRecord"></ng-container>
            </mat-tab>
            <mat-tab label="Scorecard" class="auto-scroll ">
                <div  *ngIf="matchDetail ==null && matchDetail=='undefined'; else fullScoreCard">
                </div>
         </mat-tab>
            <mat-tab label="Commentary" *ngIf="matchDetail.match_status == 'C'" >
                <ng-container *ngTemplateOutlet="commentory; else noRecord"></ng-container>
            </mat-tab>
            <mat-tab label="Top Performers" >
                <ng-template matTabContent>
                <app-top-performers [matchId]="matchId"></app-top-performers>
            </ng-template>
            </mat-tab>
            <mat-tab label="Stats">
              <ng-template matTabContent>

                  <div class="d-flex justify-content-evenly gap-lg-2 mt-3 flex-wrap">

                      <mat-card class="col-lg-5 col-12 col-12 mt-2 wagoncard">
                          <div class="ms-3"><h2 class="my-2 text-start" style="font-family: 'Assist-Bold';">Wagon Wheel</h2></div>
                          <app-wagon-wheel  [teamOneName]="teamOneName" [teamTwoName]="teamTwoName"  class="mt-2"></app-wagon-wheel>
                      </mat-card>
                      <mat-card class="col-lg-5 col-md-12 col-12 mt-2">
                          <div class="ms-3"><h2 class="my-2 text-start" style="font-family: 'Assist-Bold';">Pitch Map</h2></div>
                          <app-pitch-map  [teamOneName]="teamOneName" [teamTwoName]="teamTwoName" class="mt-2"></app-pitch-map>
                      </mat-card>
                  </div>
              <div class="ms-3">  <h2 class="my-2 text-start ms-2" style="font-family: 'Assist-Bold';">Man Hatten</h2></div>
              <app-manhattan [team1]="teamOneName" [team2]="teamTwoName" [matchId]="matchId" ></app-manhattan>
              <div class="ms-3"> <h2 class="my-2 text-start ms-2" style="font-family: 'Assist-Bold';">Worm Graph</h2></div>
              <app-worm [matchId]="matchId" [team1]="teamOneName" [team2]="teamTwoName" ></app-worm>
              <div class="ms-3"> <h2 class="my-2 text-start ms-2" style="font-family: 'Assist-Bold';">Partnership</h2></div>
              <app-partnership [matchId]="matchId" ></app-partnership>
              <div class="ms-3">  <h2 class="my-2 text-start ms-2" style="font-family: 'Assist-Bold';">Player vs Player</h2></div>
              <app-playervs-player [matchId]="matchId" ></app-playervs-player>
              <div class="ms-3"> <h2 class="my-2 text-start ms-2" style="font-family: 'Assist-Bold';">Boundary Tracker</h2></div>
              <app-boundary-tracker [matchId]="matchId" ></app-boundary-tracker>
          </ng-template>
          </mat-tab>
            <mat-tab label="Teams">
                <ng-template matTabContent>
                <app-playing-xi [matchId]="matchId" [team1Id]="team1Id" [team2Id]="team2Id"></app-playing-xi>
            </ng-template></mat-tab>

            <mat-tab label="Match Info"> <app-matchinfo [matchId]="matchId"></app-matchinfo> </mat-tab>


        </mat-tab-group>
    </div>
</div>
</ng-template>

<ng-template #fullScoreCard>
    <div class="p-1 mt-3 mb-5 fullscorecard mx-2">
        <mat-card>
            <mat-accordion>
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" class="scoreExpanding" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{teamOneName}}
                        </mat-panel-title>
                        <mat-panel-description style="justify-content: flex-end;">
                            {{inningsOneScore??'-'}}/{{inningsOneWkts??'-'}} in
                            ({{formatOversAndBalls(inningsOneOvers)??'-'}} ov)
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
        <app-batsman-scorecard [innStats]="firstInnStats" [cricketMatchSumm]="cricketMatchSumm" [innings]="1"
        [didNotBat]="didNotBatInTeamOne" [innStatsDNB]="firstInnStatsDNB"  [fallOfWickets]="firstFallOfWick" [matchId]="matchId"></app-batsman-scorecard>
        <app-bowler-scorecard [innings]="1" [innBowStats]="firstInnBow" [matchId]="matchId"></app-bowler-scorecard>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
        <br>
        <mat-card>
            <mat-accordion>
                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" class="scoreExpanding">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{teamTwoName}}
                        </mat-panel-title>
                        <mat-panel-description style="justify-content: flex-end;">
                            {{inningsTwoScore??'-'}}/{{inningsTwoWkts??'-'}} in
                            ({{formatOversAndBalls(inningsTwoOvers)??'-'}} ov)
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">

        <app-batsman-scorecard [innStats]="secondInnStats" [cricketMatchSumm]="cricketMatchSumm" [innings]="2"
        [didNotBat]="didNotBatInTeamTwo" [innStatsDNB]="secondInnStatsDNB"  [fallOfWickets]="secFallOfWick" [matchId]="matchId"></app-batsman-scorecard>
        <app-bowler-scorecard [innings]="2" [innBowStats]="secInnBow" [matchId]="matchId"></app-bowler-scorecard>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
        <br>
        <mat-card *ngIf="cricketMatchSumm?.isMultiDay =='Y' && cricketMatchSumm?.curr_innings>2">
            <mat-accordion>
                <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" class="scoreExpanding">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{thirdInnStats?.[0].Team_Name}}
                        </mat-panel-title>
                        <mat-panel-description style="justify-content: flex-end;">
                            {{cricketMatchSumm?.innings_3_score}}/{{cricketMatchSumm?.innings_3_wkts}} in
                            ({{formatOversAndBalls(cricketMatchSumm?.innings_3_overs)}} ov)
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
        <app-batsman-scorecard [innStats]="thirdInnStats" [cricketMatchSumm]="cricketMatchSumm" [innings]="3"
        [didNotBat]="didNotBatInTeamOne"  [innStatsDNB]="thirdInnStatsDNB"  [fallOfWickets]="firstFallOfWick" [matchId]="matchId"></app-batsman-scorecard>
        <app-bowler-scorecard [innings]="3" [innBowStats]="thirdInnBow" [matchId]="matchId"></app-bowler-scorecard>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
        <br>
        <mat-card *ngIf="cricketMatchSumm?.isMultiDay =='Y' && cricketMatchSumm?.curr_innings>3">
            <mat-accordion>
                <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" class="scoreExpanding">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{fourthInnStats?.[0].Team_Name}}
                        </mat-panel-title>
                        <mat-panel-description style="justify-content: flex-end;">
                            {{cricketMatchSumm?.innings_4_score}}/{{cricketMatchSumm?.innings_4_wkts}} in
                            ({{formatOversAndBalls(cricketMatchSumm?.innings_4_overs)}} ov)
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
        <app-batsman-scorecard [innStats]="fourthInnStats" [cricketMatchSumm]="cricketMatchSumm" [innings]="4"
        [didNotBat]="didNotBatInTeamOne" [innStatsDNB]="fourthInnStatsDNB" [fallOfWickets]="fourthFallOfWick" [matchId]="matchId"></app-batsman-scorecard>
        <app-bowler-scorecard [innings]="4" [innBowStats]="fourthInnBow" [matchId]="matchId"></app-bowler-scorecard>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
    </div>
</ng-template>

<ng-template #commentory >
    <ng-container *ngIf="getLiveCommentarystatus||getCompletedCommentarystatus=='TRUE' else loader" >
    
    <div class="container px-1 mt-3 mb-5" >
        <mat-card class="m-1" *ngIf="matchDetailstatus == 'C'">
          <mat-card-content>
            <mat-tab-group (selectedTabChange)="onChange($event)">
              <mat-tab label="{{teamOneName}}">
                <app-commentary-card *ngIf="overCommentaryData?.length>0" [showLoadMore]="showLoadMore" [completedData]="overCommentaryData" [batsmanSumm]="batsmanSumm" [bowlerSumm]="bowlerSumm" [extraSumm]="extraSumm"
            [tenBalls]="tenBalls"  [liveData]="liveCommentaryData" (limit)="getMoreResultCommentary($event,teamOneName)">
                </app-commentary-card>
                <h3 class="text-center m-0 py-2" *ngIf="overCommentaryData?.length==0">No Data Found</h3>
              </mat-tab>
              <mat-tab label="{{teamTwoName}}">
                <app-commentary-card *ngIf="overCommentaryData?.length>0" [showLoadMore]="showLoadMore" [completedData]="overCommentaryData" (limit)="getMoreResultCommentary($event,teamTwoName)" >
                </app-commentary-card>
                <h3 class="text-center m-0 py-2" *ngIf="overCommentaryData?.length==0">No Data Found</h3>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>

        <div class="container mt-3 mb-5">
        <mat-card *ngIf="matchDetailstatus == 'P'">
          <mat-card-content>
            <app-commentary-card  [batsmanSumm]="batsmanSumm" [bowlerSumm]="bowlerSumm" [extraSumm]="extraSumm" [liveScore]="liveScore"
            [tenBalls]="tenBalls" [liveData]="liveCommentaryData" (limit)="getMoreLiveCommentary($event)" ></app-commentary-card>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
</ng-template>

<ng-template #noRecord>
    <div class="my-5 py-5">
      <h4 class="m-0 text-center">
        No Record Found!
      </h4>
    </div>
  </ng-template>
<!--
<ng-template #wagonWheel>
    <div class="container">
        <app-wagon-wheel [matchId]="matchId"></app-wagon-wheel>

    </div>
</ng-template>
<ng-template #pitchMap>
    <div class="container">
        <app-pitch-map [matchId]="matchId"></app-pitch-map>
    </div>
</ng-template> -->
<ng-template #loader>
    <div class="d-flex justify-content-center" *ngIf="true" >
    <app-loader></app-loader>
    </div>
  </ng-template>