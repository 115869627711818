<div class="container-fluid px-1 py-2 mx-1 mt-2 playing_xi" >
    <mat-card class="d-none d-lg-block">
        <mat-card-header class="row text-center">
            <mat-card-title style="border-bottom: 2px solid #b3afaf83;">
                <div class="row my-2 ">
                    <div class="col-lg-6 d-flex justify-content-center fw-bold px-0 fs-6" >{{teams?.teamOne}}</div>
                    <div class="col-lg-6 d-flex justify-content-center fw-bold px-0 fs-6">{{teams?.teamTwo}}</div>
                </div>
            </mat-card-title>
            <!-- <mat-card-title>Players</mat-card-title> -->
        </mat-card-header>
        <mat-card-content>
            <div class="row ms-0 me-0" style="border-top:1px solid white;background-color: #00000008;">
                <div class="col-lg-6 col-sm-12 overflow-hidden" style="border-right:3px solid white"  *ngIf="team1Details && team1Details.length > 0; else noRecords">
                    <ng-container *ngFor="let data of team1Details">
                        <div class="row py-2 zoom-in"
                            style="border-bottom: 1px solid white; border-right: 1px solid white; cursor: pointer;"
                            *ngIf="data.is_substitute == 'N'">
                            <img class="col-3 rounder-circle" src="assets/user.png" style="width: 60px; height: auto;" alt="">
                            <span class="col-9 text-start fw-normal d-flex align-items-center ps-0"
                                (click)="navigateToPlayerInfo(data.User_id)">{{ data.l_name }} {{
                                data.F_name}}&nbsp;
                                <img *ngIf="team1cap == data.User_id" class="rounder-circle role-img" src="assets/captain.png" alt="">&nbsp;
                                <img *ngIf="team1keeper == data.User_id" class="rounder-circle role-img" src="assets/glove.png" alt="">
                            </span>
                        </div>
                    </ng-container>
                    <h5 class="my-1" *ngIf="team1subDetails?.length>0" >SUBSTITUTE</h5>
                    <ng-container *ngFor="let data of team1Details">
                        <div class="row py-2 zoom-in"
                            style="border-bottom: 1px solid white; border-right: 1px solid white; cursor: pointer;"
                            *ngIf="data.is_substitute == 'Y'">
                            <img class="col-3 rounder-circle" src="assets/user.png" style="width: 60px; height: auto;" alt="">
                            <span class="col-9 text-start fw-normal d-flex align-items-center ps-0"
                                (click)="navigateToPlayerInfo(data.User_id)">{{ data.l_name }} {{
                                data.F_name}}&nbsp;
                            </span>
                        </div>
                    </ng-container>
                </div>
                <ng-template #noRecords>
                  <div class="p-3 text-center not-found">No records found.</div>
              </ng-template>
                <div class="col-lg-6 col-sm-12 overflow-hidden">
                    <ng-container *ngFor="let data of team2Details">
                        <div class="row py-2 zoom-in"
                            style="border-bottom: 1px solid white; border-right: 1px solid white; cursor: pointer;"
                            *ngIf="data.is_substitute == 'N'">
                            <img class="col-3 rounder-circle" src="assets/user.png" style="width: 60px; height: auto;" alt="">
                            <span class="col-9 text-start fw-normal d-flex align-items-center ps-0"
                                (click)="navigateToPlayerInfo(data.User_id)">{{ data.l_name }} {{
                                data.F_name}}&nbsp;
                                <img *ngIf="team2cap == data.User_id" class="rounder-circle role-img" src="assets/captain.png" alt="">&nbsp;
                                <img *ngIf="team2keeper == data.User_id" class="rounder-circle role-img" src="assets/glove.png" alt="">
                            </span>
                        </div>
                    </ng-container>
                    <h5 class="my-1" *ngIf="team2subDetails?.length>0">SUBSTITUTE</h5>
                    <ng-container *ngFor="let data of team2Details">
                        <div class="row py-2 zoom-in"
                            style="border-bottom: 1px solid white; border-right: 1px solid white; cursor: pointer;"
                            *ngIf="data.is_substitute == 'Y'">
                            <img class="col-3 rounder-circle" src="assets/user.png" style="width: 60px; height: auto;" alt="">
                            <span class="col-9 text-start fw-normal d-flex align-items-center ps-0"
                                (click)="navigateToPlayerInfo(data.User_id)">{{ data.l_name }} {{
                                data.F_name}}&nbsp;
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="d-lg-none d--block d-sm-block">
        <mat-card-header class="p-2 mb-2 row text-center ">
            <!-- <mat-card-title>Players</mat-card-title> -->
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <mat-tab-group  style="margin-bottom: 10%;">
                    <mat-tab label="{{teams?.teamOne}}">
                        <ng-container *ngFor="let data of team1Details;let i = index">
                            <div class="row my-2" *ngIf="data.is_substitute == 'N'"
                                [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
                                <img class="col-3 rounder-circle" src="../../../../assets/user.png" style="width: 60px; height: auto; " alt="">
                                <span class="col-9 my-1 text-start fw-normal d-flex align-items-center" style="font-size: 16px;"
                                    (click)="navigateToPlayerInfo(data.User_id)"> {{ data.l_name }} {{
                                    data.F_name}}&nbsp;
                                    <img *ngIf="team1cap == data.User_id" class="rounder-circle role-img" src="../../../../assets/captain.png"
                                        alt="">&nbsp;
                                    <img *ngIf="team1keeper == data.User_id" class="rounder-circle role-img" src="../../../../assets/glove.png"
                                        alt="">
                                </span>
                            </div>
                        </ng-container>
                        <h6 class="my-1" *ngIf="team1subDetails?.length>0">SUBSTITUTE</h6>
                        <ng-container *ngFor="let data of team1Details;let i = index">
                            <div class="row my-2" *ngIf="data.is_substitute == 'Y'"
                                [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
                                <img class="col-3 rounder-circle" src="../../../../assets/user.png" style="width: 60px; height: auto; " alt="">
                                <span class="col-9 my-1 text-start fw-normal d-flex align-items-center" style="font-size: 16px;"
                                    (click)="navigateToPlayerInfo(data.User_id)"> {{ data.l_name }} {{
                                    data.F_name}} {{team1cap}} {{data.User_id}}&nbsp;
                                   
                                </span>
                            </div>
                        </ng-container>
                    </mat-tab>
                    <mat-tab label="{{teams?.teamTwo}}">
                        <ng-container *ngFor="let data of team2Details;let i = index">
                            <div class="row my-2" *ngIf="data.is_substitute == 'N'"
                                [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
                                <img class="col-3 rounder-circle" src="../../../../assets/user.png" style="width: 60px; height: auto; " alt="">
                                <span class="col-9 my-1 text-start fw-normal d-flex align-items-center" style="font-size: 16px;"
                                    (click)="navigateToPlayerInfo(data.User_id)"> {{ data.l_name }} {{
                                    data.F_name}}&nbsp;
                                    <img *ngIf="team2cap == data.User_id" class="rounder-circle role-img" src="../../../../assets/captain.png"
                                        alt="">&nbsp;
                                    <img *ngIf="team2keeper == data.User_id" class="rounder-circle role-img" src="../../../../assets/glove.png"
                                        alt="">
                                </span>
                            </div>
                        </ng-container>
                        <h6 class="my-1" *ngIf="team2subDetails?.length>0">SUBSTITUTE</h6>
                        <ng-container *ngFor="let data of team2Details;let i = index">
                            <div class="row my-2" *ngIf="data.is_substitute == 'Y'"
                                [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
                                <img class="col-3 rounder-circle" src="../../../../assets/user.png" style="width: 60px; height: auto; " alt="">
                                <span class="col-9 my-1 text-start fw-normal d-flex align-items-center" style="font-size: 16px;"
                                    (click)="navigateToPlayerInfo(data.User_id)"> {{ data.l_name }} {{
                                    data.F_name}} {{team1cap}} {{data.User_id}}&nbsp;
                                   
                                </span>
                            </div>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>
