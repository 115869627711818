<!-- Commentary for completed match starts -->

<div *ngIf="matchDetail == 'C'" class="p-2 pb-0">
  <div class="mt-" *ngFor="let item of completedData" >
    <mat-card>
      <mat-card-content class="upper-card">
        <div class=" column d-none d-sm-block p-2">
          <span>Score at {{ incrementOverNo(item.over_no)}} Over :</span>
          <span class="p-1 fs-5">{{ item.end_of_over_runs}}/{{ item.wickets_in_over }}</span>
          <span class="ps-5">Runs scored : </span>
          <span class="fs-5">{{ item.over_sequence}}</span>
        </div>
        <div class="column d-block d-sm-none ps-2">
          <span>Score at {{ incrementOverNo(item.over_no)}} Over :</span>
          <span class="p-1">{{ item.end_of_over_runs}}/{{ item.wickets_in_over }}</span>
          <div class="mt-1">
            Runs scored :
            <span>{{ item.over_sequence}}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content *ngFor="let row of item.overSumm" class="score-display">
        <div style="display: flex;" class="mt-1">

          <span class="p-2 font ms-2">{{ row.over }}.{{ row.ball_no}}</span>

          <span class="dimension p-2 text-center" *ngIf="row.runs_in_a_ball === '6' || row.runs_in_a_ball === '4'"
          [ngClass]="{'run-six': row.runs_in_a_ball === '6', 'run-four': row.runs_in_a_ball === '4'}">{{
            row.runs_in_a_ball }}</span>

          <span class="dimension wicket p-2 text-center" *ngIf="row.is_wicket === 'W'">W</span>

          <span *ngIf="row.runs_in_a_ball != '6' && row.runs_in_a_ball != '4' && row.is_wicket != 'W'"
            class="dimension runs text-center p-2">{{ row.runs_in_a_ball }}</span>

          <!-- For wicket type caught -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && (row.how_out == 'Caught')">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            It's <b>OUT!</b>{{row.how_out}} by {{row.fielder_name}}.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, through the {{row.position}} straight to the {{row.region}} fielder.</span>
          </span>

          <!-- For wicket type caught by wicket keeper -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Caught & Bowled' ">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            It's <b>OUT!</b> {{'c & b '+row.fielder_name}}.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, through the {{row.position}} stright to the bowler.</span>
          </span>

          <!-- For wicket type caught and bowled -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Caught & Wicket' ">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            It's <b>OUT!</b> {{'Caught By '+row.fielder_name}}.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, stright to the keeper.</span>
          </span>

          <!-- For normal wicket bowled -->
          <span class="font p-2"
            *ngIf="row.is_wicket == 'W' && (row.how_out != 'Caught' && row.how_out != 'Caught & Bowled' && row.how_out != 'Caught & Wicket' &&  row.how_out != 'Run Out') ">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            It's <b>OUT!</b>{{row.how_out}}.
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
          </span>

          <!-- For normal runout and displaying no of runs more than one -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Run Out' && row.runs > 1 ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} {{row.runs_in_a_ball}} runs.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, tried for another run but {{row.region}} fielder hitted the stumps.</span>
          </span>

          <!-- For normal runout and displaying no of runs equal to one -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Run Out' && row.runs == 1 ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} {{row.runs_in_a_ball}} run.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, tried for another run but {{row.region}} fielder hitted the stumps.</span>
          </span>

          <!-- For normal run out and displaying no of runs equal to zero  -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && (row.how_out == 'Run Out' && row.runs == 0) ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} No Run.
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
          </span>

          <!-- For byes and legbyes equal to 1 -->
          <span class="font p-2"
            *ngIf="row.is_wicket != 'W' && (row.runs >= 0 &&  (row.runs_in_a_ball == 'B' || row.runs_in_a_ball == 'Lb'))">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, {{row.runs_in_a_ball}}ye
          </span>

          <!-- For dot ball no run  -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs_in_a_ball == 0">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            <b>No run</b>.
          </span>

          <!-- For wides, no balls and runs greater than 1 -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs > 1 ">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            <b>{{row.runs_in_a_ball}}</b> runs<span *ngIf="row.commentry">,
              {{row.commentry}}</span><span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, batsman hits through the {{row.position}} and it went to {{row.region}}.</span>
          </span>

          <!-- For wides, no balls and runs equal to 1 -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs == 1 ">
            <span>{{row.bowler_name}}</span> to <span>{{row.batsman_name}}</span>,
            <b>{{row.runs_in_a_ball}}</b> run<span *ngIf="row.commentry">,
              {{row.commentry}}</span><span *ngIf="!row.position && row.runs_in_a_ball == 'Wd'">.</span>
            <span *ngIf="row.position">, batsman hits through the {{row.position}} and it went to {{row.region}}.</span>
          </span>

          <!-- For penalty -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs_in_a_ball == 'P' ">
            <span>{{row.bowler_name}}</span>
            to <span>{{row.batsman_name}}</span>,
            <b>Penalty</b><span>{{row.runs}}</span>runs.
          </span>

        </div> 
        <!-- <span *ngIf="row.ball_no != 1">
          <hr class="mb-3">
        </span> -->
        <hr class="mb-3">
      </mat-card-content>
    </mat-card>
  </div>  

  <div class="d-flex justify-content-center my-2" *ngIf="completedData.length > 0">
    <button type="button" class="btn btn-secondary" (click)="getMoreCommentary()" [disabled]="emitCount>completedData.length">LOAD MORE</button>
  </div>
</div>

<!-- Commentary for completed match ends -->

<!-- Commentary for live match starts -->

<div *ngIf="matchDetail == 'P'">
  <mat-card class="mb-3" *ngIf="batsmanSumm?.length > 0 || bowlerSumm?.length > 0 || extraSumm?.length > 0 || tenBalls?.length > 0">
    <mat-card-content>

      <!-- Batsman data -->
    <div class="scroll-container">  
      <table *ngIf="batsmanSumm?.length > 0" mat-table [dataSource]="batsmanSumm">
        <ng-container matColumnDef="batsmen">
          <th mat-header-cell *matHeaderCellDef> BATSMEN </th>
          <td class="player" mat-cell *matCellDef="let element"> {{element?.batsman_name}} <span *ngIf="element?.batsman_user_id==liveScore[0].striker_id"  style="color: green;">*</span> </td>
        </ng-container>

        <ng-container matColumnDef="runs">
          <th mat-header-cell *matHeaderCellDef> R </th>
          <td mat-cell *matCellDef="let element"> {{element?.runs}} </td>
        </ng-container>
      
        <ng-container matColumnDef="balls">
          <th mat-header-cell *matHeaderCellDef> B </th>
          <td mat-cell *matCellDef="let element"> {{element?.balls_faced}} </td>
        </ng-container>
      
        <ng-container matColumnDef="fours">
          <th mat-header-cell *matHeaderCellDef> 4s </th>
          <td mat-cell *matCellDef="let element"> {{element?.fours}} </td>
        </ng-container>
      
        <ng-container matColumnDef="sixes">
          <th mat-header-cell *matHeaderCellDef> 6s </th>
          <td mat-cell *matCellDef="let element"> {{element?.sixers}} </td>
        </ng-container>

        <ng-container matColumnDef="strikeRate">
          <th mat-header-cell *matHeaderCellDef> SR </th>
          <td mat-cell *matCellDef="let element"> {{element?.strike_rate}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedBatsmen"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedBatsmen;"></tr>
      </table>
    </div>    

      <!-- Bowler data -->
    <div class="scroll-container">  
      <table *ngIf="bowlerSumm?.length > 0" mat-table [dataSource]="bowlerSumm">
        <ng-container matColumnDef="bowler">
          <th mat-header-cell *matHeaderCellDef> BOWLERS </th>
          <td class="player" mat-cell *matCellDef="let element"> {{element?.bowler_name}} </td>
        </ng-container>

        <ng-container matColumnDef="overs">
          <th mat-header-cell *matHeaderCellDef> O </th>
          <td mat-cell *matCellDef="let element"> {{element?.overs}} </td>
        </ng-container>
      
        <ng-container matColumnDef="maidens">
          <th mat-header-cell *matHeaderCellDef> M </th>
          <td mat-cell *matCellDef="let element"> {{element?.maidens}} </td>
        </ng-container>
      
        <ng-container matColumnDef="runs">
          <th mat-header-cell *matHeaderCellDef> R </th>
          <td mat-cell *matCellDef="let element"> {{element?.runs}} </td>
        </ng-container>
      
        <ng-container matColumnDef="wickets">
          <th mat-header-cell *matHeaderCellDef> W </th>
          <td mat-cell *matCellDef="let element"> {{element?.wickets}} </td>
        </ng-container>

        <ng-container matColumnDef="economy">
          <th mat-header-cell *matHeaderCellDef> Eco </th>
          <td mat-cell *matCellDef="let element"> {{element?.economy}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedBowlers"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedBowlers;"></tr>
      </table>
    </div>    
      
      <div class="mt-3 ms-3" *ngFor="let data of extraSumm"><strong *ngIf="batsmanSumm?.length > 0">Extras: {{data?.inningsExtras}} (b {{data?.byes}}, lb {{data?.legbye}}, w {{data?.wides}},
         nb {{data?.noballs}}, p {{data?.penalty}})</strong>
      </div>
       <div class="mt-3 ms-3" *ngIf="!extraSumm"><strong>Extras:(b , lb , w ,
         nb , p )</strong>
      </div>

      <div class="mt-3 ms-3 scroll-container" *ngIf="tenBalls?.length > 0">Last 10 balls: 
        <span *ngFor="let data of tenBalls; let i = index">
          <span class="ms-2 fs-6" *ngIf="i > 0 && data?.over !== tenBalls[i - 1].over">|</span>
          
         <span class="ten-balls text-center ms-2 p-2"> <span *ngIf="data?.is_wicket == 'W' && data?.runs_in_ball == 0">{{data?.is_wicket}}</span> 
          <span *ngIf="data?.is_wicket == 'W' && data?.runs_in_ball > 0">{{data?.is_wicket}}{{data?.runs_in_ball}}</span>
					<span *ngIf="data?.is_wicket != 'W'">{{data?.runs_in_ball}}</span>
        </span> </span>
      </div>
    </mat-card-content>
  </mat-card>


  <div class="mt-2" *ngFor="let item of liveData">
    <mat-card>
      <mat-card-content class="upper-card">
        <div class=" column d-none d-sm-block p-2">
          <span>Score at {{ incrementOverNo(item.over_no)}} Over :</span>
          <span class="p-1 fs-5">{{ item.end_of_over_runs}}/{{ item.wickets_in_over }}</span>
          <span class="ps-5">Runs scored : </span>
          <span class="fs-5">{{ item.over_sequence}}</span>
        </div>
        <div class="column d-block d-sm-none">
          <span>Score at {{ incrementOverNo(item.over_no)}} Over :</span>
          <span class="p-1">{{ item.end_of_over_runs}}/{{ item.wickets_in_over }}</span>
          <div class="mt-1">
            Runs scored :
            <span>{{ item.over_sequence}}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content *ngFor="let row of item.overSumm" class="score-display">
        <div style="display: flex;">

          <span class="p-2 font ms-2">{{ row.over }}.{{ row.ball_no}}</span>

          <span class="dimension p-2 text-center" *ngIf="row.runs_in_a_ball === '6' || row.runs_in_a_ball === '4'"
          [ngClass]="{'run-six': row.runs_in_a_ball === '6', 'run-four': row.runs_in_a_ball === '4'}">{{
            row.runs_in_a_ball }}</span>

          <span class="dimension wicket p-2 text-center" *ngIf="row.is_wicket === 'W'">W</span>

          <span *ngIf="row.runs_in_a_ball != '6' && row.runs_in_a_ball != '4' && row.is_wicket != 'W'"
            class="dimension runs text-center p-2">{{ row.runs_in_a_ball }}</span>

          <!-- For wicket type caught -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && (row.how_out == 'Caught')">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} by {{row.fielder_name}}<span *ngIf="row.commentry">,
              {{row.commentry}}</span><span *ngIf="!row.position">.</span><span *ngIf="row.position">, to the {{row.region}}
              fielder.</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
          </span>

          <!-- For wicket type caught by wicket keeper -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Caught & Bowled' ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{'c & b '+row.fielder_name}}.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, through the {{row.position}} but landed to the bowler hands.</span>
          </span>

          <!-- For wicket type caught and bowled -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Caught & Wicket' ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{'Caught By'+row.fielder_name+' (WK)'}}.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, straight to the keeper.</span>
          </span>

          <!-- For normal wicket bowled -->
          <span class="font p-2"
            *ngIf="row.is_wicket == 'W' && (row.how_out != 'Caught' && row.how_out != 'Caught & Bowled' && row.how_out != 'Caught & Wicket' &&  row.how_out != 'Run Out') ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}}.
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
          </span>

          <!-- For normal runout and displaying no of runs more than one -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Run Out' && row.runs > 1 ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} {{row.runs_in_a_ball}} runs.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, tried for another run but {{row.region}} fielder hit the stumps.</span>
          </span>

          <!-- For normal runout and displaying no of runs equal to one -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && row.how_out == 'Run Out' && row.runs == 1 ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} {{row.runs_in_a_ball}} run.<span *ngIf="row.commentry">,
              {{row.commentry}}</span>
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, tried for another run but {{row.region}} fielder hit the stumps.</span>
          </span>

          <!-- For normal run out and displaying no of runs equal to zero  -->
          <span class="font p-2" *ngIf="row.is_wicket == 'W' && (row.how_out == 'Run Out' && row.runs == 0) ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, It's
            <b>OUT!</b> {{row.how_out}} No Run.
            <b>{{row.batsman_name}} {{row.batsmanScr}}({{row.balls_faced}}) <span *ngIf="row.fours != 0 || row.sixers != 0">[<span
                  *ngIf="row.fours != 0">4s-{{row.fours}} </span> <span
                  *ngIf="row.sixers != 0">6s-{{row.sixers}}</span>]</span></b>
            <span *ngIf="!row.position">.</span>
            <span *ngIf="row.position">, tried for a run but {{row.region}} fielder hit the stumps.</span>
          </span>

          <!-- For byes and legbyes equal to 1 -->
          <span class="font p-2"
            *ngIf="row.is_wicket != 'W' && (row.runs >= 0 &&  (row.runs_in_a_ball == 'B' || row.runs_in_a_ball == 'Lb'))">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, {{row.runs_in_a_ball}}ye
          </span>

          <!-- For dot ball no run  -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs_in_a_ball == 0 ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, No run.
          </span>

          <!-- For wides, no balls and runs greater than 1 -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs > 1 ">
            <span>{{row.bowler_name}}</span> to <span>{{row.batsman_name}}</span>,
            {{row.runs_in_a_ball}} runs<span *ngIf="row.commentry">,
              {{row.commentry}}</span><span *ngIf="!row.position">.</span><span *ngIf="row.position">,
              batsman hits through the {{row.position}} and it went to {{row.region}}.</span>
          </span>

          <!-- For wides, no balls and runs equal to 1 -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs == 1 ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, {{row.runs_in_a_ball}}
            run<span *ngIf="row.commentry">,
              {{row.commentry}}</span><span *ngIf="(!row.position) && row.runs_in_a_ball == 'Wd'">.</span>
            <span *ngIf="(row.position)">, batsman hits through the {{row.position}} and it went to {{row.region}}.</span>
          </span>

          <!-- For penalty -->
          <span class="font p-2" *ngIf="row.is_wicket != 'W' && row.runs_in_a_ball == 'P' ">
            <span>{{row.bowler_name}}</span> to
            <span>{{row.batsman_name}}</span>, Penalty <span>{{row.runs}}</span>runs.
          </span>

        </div> <hr class="mb-3">
      </mat-card-content>
    </mat-card> 
  </div>  

  <div class="d-flex justify-content-center mt-3" *ngIf="liveData?.length > 0">
    <button type="button" class="btn btn-secondary" (click)="getMoreCommentary()" [disabled]="emitCount>liveData?.length">LOAD MORE</button>
  </div>
</div>

<!-- Commentary for live match ends -->