<div class="container">
  <div class="card test  p-2 pt-0" style="height: 230px;" *ngIf="scoreCard.status=='P'"
    (click)="goToScoreCardDetailPage(scoreCard.Match_id, scoreCard.Team1, scoreCard.Team2,scoreCard.teamone,scoreCard.teamtwo)">
    <p class="live text-start text-danger ms-2 mb-0" *ngIf="scoreCard.status=='P' || scoreCard.status=='p'"><i
      class="bi bi-circle-fill me-1"></i> LIVE</p>
  <!-- <p class="live m-0 text-start text-uppercase ms-2 " *ngIf="scoreCard.status=='C' || scoreCard.status=='c'">
    Result</p> -->
    <div class="d-flex justify-content-around align-items-center">
      <div class="col-4">
       
        <p class="m-0 fs "><i class="bi bi-geo-alt-fill text-warning"></i>{{scoreCard.Venue}}</p>
      </div>

      <h4 class="venue fs m-0 text-end mb-1 col-8">{{scoreCard.Event_name}}</h4>
      <!-- <div class="scrolling-text-container col-8">
        <h4 class="scrolling-text venue m-0 text-end mb-1" #scrollingText>{{ scoreCard.Event_name }}</h4>
      </div> -->
    </div>
    <div class="card-body py-0 px-1 pt-1 d-flex justify-content-evenly gap-1 flex-column">
     

      <div class="m-0 text-center row justify-content-evenly align-items-center py-1">
        <div class="col-8 d-flex justify-content-start">
          <img src="assets/dummyLogoBlue1.png" alt="" class="logo p-0">
          <p class="teamName ms-1 m-0 fs">{{scoreCard.teamone}}</p>
        </div>
        <div class="col-4 d-flex fs flex-column justify-content-center">
          <div class="score d-flex" *ngIf="scoreCard.isMultiday != 'Y'">
            <span><b>{{scoreCard.team1_score}}</b></span><span style="font-family: 'Assist-Regular';" class="text-body-secondary">Over</span>
          </div>
          <div class="score d-flex" *ngIf="scoreCard.isMultiday == 'Y' && scoreCard.inningsStatus != 'L'">
            <span><b>{{scoreCard.test1_score}}</b></span>
          </div>
          <div class="score d-flex" *ngIf="scoreCard.inningsStatus == 'L'">
            <span><b>{{scoreCard.followOnScore2}}</b></span>
          </div>
        </div>
      </div>
      <div class="m-0 text-center row justify-content-evenly align-items-center py-1">
        <div class="col-8 d-flex justify-content-start">
          <img src="assets/dummyLogoBlue1.png" alt="" class="logo p-0">
          <p class="teamName ms-1 m-0 fs">{{scoreCard.teamtwo}}</p>
        </div>
        <div class="col-4 fs d-flex flex-column justify-content-center">
          <div class="score d-flex" *ngIf="scoreCard.isMultiday != 'Y'">
            <span><b>{{scoreCard.team2_score}}</b></span><span style="font-family: 'Assist-Regular';" class="text-body-secondary">Over</span>
          </div>
          <div class="score d-flex" *ngIf="scoreCard.isMultiday == 'Y' && scoreCard.inningsStatus != 'L'">
            <span><b>{{scoreCard.test2_score}}</b></span>
          </div>
          <div class="score d-flex" *ngIf="scoreCard.inningsStatus == 'L'">
            <span><b>{{scoreCard.followOnScore1}}</b></span>
          </div>
        </div>
      </div>

    </div>
    <div class="border border-light-subtle  pb-1  rounded-3 m-1 d-flex align-items-center col-12" style="height: 55px; background-color: rgb(237, 246, 255);">
      <p class="result text-center m-0 p-2 fs">{{scoreCard?.result ? scoreCard?.result :
        getCricketLiveMatch(scoreCard) }}
      </p>
    </div>

  </div>
  <div class="card test  p-2 pt-0" style="height: 230px;" *ngIf="scoreCard.status=='C'"
  (click)="goToScoreCardDetailPage(scoreCard.Match_id, scoreCard.Team1, scoreCard.Team2,scoreCard.teamone,scoreCard.teamtwo)">
  <!-- <p class="live text-start text-danger ms-2 mb-0" *ngIf="scoreCard.status=='P' || scoreCard.status=='p'"><i
    class="bi bi-circle-fill me-1"></i> LIVE</p> -->
<p class="live m-0 text-start text-uppercase ms-2 fs " *ngIf="scoreCard.status=='C' || scoreCard.status=='c'">
  Result</p>
  <div class="d-flex justify-content-around align-items-center">
    <div class="col-4">
     
      <p class="m-0 "><i class="bi bi-geo-alt-fill text-warning"></i>{{scoreCard.Venue}}</p>
    </div>

    <h4 class="venue m-0 text-end mb-1 col-8 fs">{{scoreCard.Event_name}}</h4>
  </div>
  <div class="card-body py-0 px-1 pt-1 d-flex justify-content-evenly gap-1 flex-column">
   

    <!-- <div class="d-flex flex-column justify-content-between"> -->
    <div class="m-0 text-center row justify-content-evenly align-items-center py-1">
      <div class="col-8 d-flex justify-content-start">
        <img src="assets/dummyLogoBlue1.png" alt="" class="logo p-0">
        <p class="teamName ms-1 m-0 fs">{{scoreCard.teamone}}</p>
      </div>
      <div class="col-4 d-flex fs flex-column justify-content-center">
        <div class="score d-flex"  *ngIf="scoreCard.isMultiday !='Y'">
          <span >{{scoreCard.innings_1_score}}/{{scoreCard.innings_1_wkts}}</span><span class="over">({{scoreCard.innings_1_overs}})</span><span style="font-family: 'Assist-Regular';" class="text-body-secondary">Over</span>
      </div>
      
      <div class=" score d-flex justify-content-center text-nowrap fs" *ngIf="scoreCard.isMultiday=='Y'">
          <span>{{ scoreCard['innings_' + scoreCard.team_1_group?.[0] + '_score'] }}/{{ scoreCard['innings_' + scoreCard.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>
          <span *ngIf="scoreCard['innings_' + scoreCard.team_1_group?.[1] + '_score'] > 0 || scoreCard['innings_' + scoreCard.team_1_group?.[1] + '_score'] === 0">
              & {{ scoreCard['innings_' + scoreCard.team_1_group?.[1] + '_score'] }}/{{ scoreCard['innings_' + scoreCard.team_1_group?.[1] + '_wkts'] }}
          </span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
      
      </div>
      </div>
    </div>
    <!-- <div class="row">
      <p class="text-center fw-normal m-0" style="font-family: 'Assist-Regular';">Vs</p>
      </div> -->
    <div class="m-0 text-center row justify-content-evenly align-items-center py-1">
      <div class="col-8 d-flex justify-content-start">
        <img src="assets/dummyLogoBlue1.png" alt="" class="logo p-0">
        <p class="teamName ms-1 m-0 fs">{{scoreCard.teamtwo}}</p>
        <!-- <p class="teamName ms-1 m-0">Young gun cricket team under 16</p> -->
      </div>
      <div class="col-4 d-flex fs flex-column justify-content-center">
        <div class="score d-flex"  *ngIf="scoreCard.isMultiday !='Y'">
        <span>{{scoreCard.innings_2_score}}/{{scoreCard.innings_2_wkts}}</span><span class="over">({{scoreCard.innings_2_overs}})</span><span style="font-family: 'Assist-Regular';" class="text-body-secondary">Over</span>
      </div>
      <div class=" score d-flex justify-content-center text-nowrap fs" *ngIf="scoreCard.isMultiday=='Y'">
          <span>{{ scoreCard['innings_' + scoreCard.team_2_group?.[0] + '_score'] }}/{{ scoreCard['innings_' + scoreCard.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_2_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_2_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_score'] > 0 || scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_score'] === 0">
              & {{ scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_score'] }}/{{ scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_wkts'] }}
          </span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
          <span *ngIf="scoreCard['innings_' + scoreCard.team_2_group?.[1] + '_status']?.split(' ')[1] !== 'D' && scoreCard['innings_' + scoreCard.team_2_group?.[0] + '_status']?.split(' ')[1] === 'L'">f/o</span>&nbsp;
        </div>
      </div>
    </div>
    <!-- </div> -->

  </div>
  <!-- <div class="card-footer text-center border-0 p-1 d-flex justify-content-center align-items-center"
    style="height: 45px;">
    <div *ngIf="showTossWon">
      <div class="text-center result">{{scoreCard?.result ? scoreCard?.result : getCricketLiveMatch(scoreCard) }}
      </div>
    </div>
  </div> -->
  <div class="border border-light-subtle  pb-1  rounded-3 m-1 d-flex align-items-center" *ngIf="scoreCard.isMultiday=='L' || scoreCard.isMultiday==null" style="height: 55px; background-color: rgb(237, 246, 255);">
    <p class="result text-center m-0 p-2 fs">{{scoreCard?.result ? scoreCard?.result :
      getCricketLiveMatch(scoreCard) }}
    </p>
  </div>
  <!-- <div class="border border-light-subtle  pb-1  rounded-3 m-1 d-flex align-items-center" *ngIf="scoreCard.isMultiday=='Y'" style="height: 55px; background-color: rgb(237, 246, 255);">
    <p class="result text-center m-0 p-2 fs">{{scoreCard?.result ? scoreCard?.result :
      getCricketLiveMatchformulti(scoreCard) }}
    </p>
  </div> -->
</div>
</div>