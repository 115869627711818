import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { checkPasswordMatch } from '../customValidators/passwordValidation';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/service/common.service';
import * as moment from 'moment';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CommonAlertsComponent } from '../common-alerts/common-alerts.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrgcodeFilterService } from '../orgcode-filter.service';

interface UserData {
  type: string;
  lastLogin: string;
  lastLoginMode: string;
  regnMode: string;
  regnCompleted: string;
  loginType: string;
  email: string;
  mobileNo: string;
  password: string;
  name: string;
  // verify: string;
}
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit{
  maxDate: NgbDateStruct = { year: new Date().getFullYear() - 18, month: 12, day: 31 };
  isUserExist : boolean = false;
  isEmailExist : boolean = false;
  isMobileExist : boolean = false;
  userId:any;
  isPasswordVisible: boolean = false;
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  orgCode:any;

  constructor(private formBuilder: FormBuilder, private http : HttpClient,private dialog:MatDialog, private commonService : CommonService,private ngbDateParserFormatter: NgbDateParserFormatter,private router: Router,private orgFilter:OrgcodeFilterService) {
    // const currentDate = new Date();
    // this.maxDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
   }
  ngOnInit(){
    this.commonService.pathName.next(false);
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  signUpForm: FormGroup = this.formBuilder.group(
    {
      firstName: new FormControl('', [Validators.required, Validators.pattern('^(?! )[a-zA-Z]+( [a-zA-Z]+)*$')]),
      lastName: new FormControl('', [Validators.pattern('^(?! )[a-zA-Z]+( [a-zA-Z]+)*$')]),
      userName: new FormControl('', Validators.required,),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobileNo: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?) |0)?[0-9]{10}$')]),
      dob: new FormControl('', [Validators.required, minimumAge]),
      pwd: new FormControl('', [Validators.required,Validators.minLength(6)]),
      confirmPwd: new FormControl('', [Validators.required])
    },
    // {
    //   firstName: new FormControl('', [Validators.required, Validators.pattern('^(?! )[a-zA-Z]+( [a-zA-Z]+)*$')]),
    //   lastName: new FormControl('', [Validators.pattern('^(?! )[a-zA-Z]+( [a-zA-Z]+)*$')]),
    //   userName: new FormControl('', [Validators.required, Validators.pattern('^(?!\s)(?!.*\s).*')]),
    //   email: new FormControl('', [Validators.required, Validators.email]),
    //   mobileNo: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?) |0)?[0-9]{10}$')]),
    //   dob: new FormControl('', Validators.required),
    //   pwd: new FormControl('', [Validators.required, Validators.minLength(6)]),
    //   confirmPwd: new FormControl('', [Validators.required])
    // },
    {
      validator: checkPasswordMatch
    }
  )

  get f() {
    return this.signUpForm.controls;
  }
  ispasswordvalid(){
   if( this.signUpForm.value.pwd !==this.signUpForm.value.confirmPwd){
     return {mismatch:true}
   }else{
    return {mismatch:false}
   }
  }

  checkUserName(event : any) {
    this.commonService.checkUserNameExist(event.target.value).subscribe((response : any) => {
      if(response.result?.length > 0  ){
        this.isUserExist = true;
      }else {
        this.isUserExist = false;
      }
    });
  }

  checkEmail(event : any) {
    this.commonService.checkEmailExist(event.target.value).subscribe((response : any) => {
      if(response.result?.length > 0){
        this.isEmailExist = true;
      }else {
        this.isEmailExist = false;
      }
    });
  }
  
  checkMobileNo(event : any) {
    this.commonService.checkMobileExist(event.target.value).subscribe((response: any) => {
      if(response.result?.length > 0){
        this.isMobileExist = true;
      }else {
        this.isMobileExist = false;
      }
    });
  }

  registerUser() {
    this.dialogRef= this.dialog.open(CommonAlertsComponent, {
      data: { identifier: 'loader' }
    });
    const email = this.signUpForm.value['email'];
    const mobileNo = this.signUpForm.value['mobileNo'];
    const password = this.signUpForm.value['pwd'];
    const name = this.signUpForm.value['firstName'] + ' ' + this.signUpForm.value['lastName'];
    const date = new Date(this.signUpForm.value['dob']);


    const dobFormatted = date.getFullYear() + '-' + 
                      ('0' + (date.getMonth() + 1)).slice(-2) + '-' + 
                      ('0' + date.getDate()).slice(-2);

    function formatData(obj: any): string {
      const formattedData: string[] = [];
    
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(obj[key]);
          formattedData.push(`${encodedKey}=${encodedValue}`);
        }
      }
    
      return formattedData.join('&');
    }
    const userData = {
      type: 'User_Master',
      F_name: this.signUpForm.value['firstName'],
      l_name: this.signUpForm.value['lastName'],
      user_name: this.signUpForm.value['userName'],
      mobile_no: mobileNo,
      d_o_b: dobFormatted,
      email_id: email,
      passwd: this.signUpForm.value['pwd'],
      last_login: moment.now().toString(),
      last_login_mode: 'W',
      regn_mode: 'W',
      regn_completed: 'Y',
      Login_Type: 'V'
  };
    this.commonService.registerUser(email, mobileNo, password, name, userData).subscribe(
      (response) => {
        this.getUserId(email)
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
      
      
  }  
  get today():Date{
    return new Date();
  }
  getUserId(emailId:any){
    this.commonService.getUserid(emailId).subscribe((response : any) => {
      this.userId=response.result[0]?.User_id;
      this.dialogRef?.close();
      this.router.navigate([`${this.orgCode}/verfication`],{state:{userId:this.userId,emailId:this.signUpForm.value['email']}})
    })
  } 

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
function minimumAge(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value) {
    const today = new Date();
    const birthDate = new Date(control.value);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 6 || birthDate > today) { 
      return { 'invalidDOB': true }; 
    }
  }
  return null;

  
}