<div *ngIf="data.identifier === 'uploadimg'">
    <h2 mat-dialog-title>Upload Image / Paper cutting</h2>
    <form name="fileform" id="fileformSR" method="POST" enctype="multipart/form-data" (ngSubmit)="onSubmit()"
        #fileformSR="ngForm">
        <div mat-dialog-content>
            <div class="form-group">
                <label for="imageFile">Select picture</label>
                <input type="file" name="imageFile" id="imageFile" class="form-control" (change)="showFileSize($event)"
                    required>
                <input type="hidden" name="saveAs" id="saveAs">
            </div>
            <div *ngIf="imageError">
                <p><strong>Note: Image size should be less than <b>2MB</b> and the format is png, jpg</strong></p>
                <span class="font_color_red">Note: Max size 2 MB</span>
            </div>
        </div>
        <div mat-dialog-actions style="display: flex;justify-content: space-evenly;"> 
            <button type="button" mat-button (click)="dialogRef.close()">Close</button>
            <button type="submit" mat-button color="primary">Update Photo</button>
        </div>
    </form>
</div>
<div *ngIf="data.identifier === 'AboutMe'">
    <h2 mat-dialog-title>Enter Details</h2>
    <form  class="modal-content" (ngSubmit)="updateAboutMe()">
        <div mat-dialog-content>
            <div class="modal-body">
                <div class="form-group">
                    <label for="comment">Details:</label>
                    <textarea class="form-control mb-2" type="text" rows="8" ng-trim="false" maxlength="500"
                        [(ngModel)]="userDetails.about_me" name="about_me"></textarea>
                    <span>Max 500 letters. {{ 500 - userDetails.about_me.length }} left </span>
                </div>
            </div>
            <div mat-dialog-actions style="display: flex;justify-content: space-evenly;">
                <button type="button" mat-button (click)="dialogRef.close()">Close</button>
                <button type="submit" mat-button color="primary">Save</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="data.identifier === 'Strengths'">
    <h2 mat-dialog-title>Enter Strengths </h2>
    <form  class="modal-content" (ngSubmit)="updateStrengths()">
        <div mat-dialog-content>
            <div class="modal-body">
                <div class="form-group">
                    <label for="comment">My Strengths:</label>
                    <textarea class="form-control mb-2" type="text" rows="8" ng-trim="false" maxlength="500"
                        [(ngModel)]="userStringths.Stringths" name="about_me"></textarea>
                    <span>Max 500 letters. {{ 500 - userStringths.Stringths.length }} left </span>
                </div>
            </div>
            <div mat-dialog-actions style="display: flex;justify-content: space-evenly;">
                <button type="button" mat-button (click)="dialogRef.close()">Close</button>
                <button type="submit" mat-button color="primary">Save</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="data.identifier === 'Weakness'">
    <h2 mat-dialog-title>Enter Weakness </h2>
    <form  class="modal-content" (ngSubmit)="updateWeakness()">
        <div mat-dialog-content>
            <div class="modal-body">
                <div class="form-group">
                    <label for="comment">Weakness:</label>
                    <textarea class="form-control mb-2" type="text" rows="8" ng-trim="false" maxlength="500"
                        [(ngModel)]="userWeakness.Weakness" name="about_me"></textarea>
                    <span>Max 500 letters. {{ 500 - userWeakness.Weakness.length }} left </span>
                </div>
            </div>
            <div mat-dialog-actions style="display: flex;justify-content: space-evenly;">
                <button type="button" mat-button (click)="dialogRef.close()">Close</button>
                <button type="submit" mat-button color="primary">Save</button>
            </div>
        </div>
    </form>
</div>

