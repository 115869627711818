<div class="px-3 py-2 mb-1 bg-body-tertiary border-0 card-design" *ngIf="innStats?.length > 0 ">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col" class="batsmen-header " style="padding: 15px!important; width: 50%;">
                    <!-- <img src="assets/batting.webp" alt="Bat Icon" class="bat-icon"> -->
                    Batsmen
                </th>
                <th scope="col" style="padding-bottom: 15px!important;">R</th>
                <th scope="col" style="padding-bottom: 15px!important;">B</th>
                <th scope="col" style="padding-bottom: 15px!important;">4s</th>
                <th scope="col" style="padding-bottom: 15px!important;">6s</th>
                <th scope="col" style="padding-bottom: 15px!important;">SR</th>
                <th scope="col" style="padding-bottom: 15px!important;"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let data of innStats; let i = index">
            <tr (click)="openWagon(data.batsman_user_id , i)" class="batsman-name-fs" >
                <td class="row mx-0" ><span class="ms-lg-2 col-lg-5 col-md-12 col-sm-12 px-0 sm-screen-fs">{{data.batsman_name}} </span>
                    <p class="mt-1 col-lg-6 col-md-12 col-sm-12 px-0 howOut" style="color: #747171;">
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'not out'">
                            <span class="ms-lg-2" style="color: #00DA9D;">not out</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'retired out'">
                            <span class="ms-lg-2">retired</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'lbw'">
                            <span  class="ms-lg-2">lbw {{ data.wicket_bowler_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'caught'" >
                           <span class="ms-lg-2">c {{ data.fielder_name }} </span> &nbsp; <br class="d-block d-lg-none d-md-none"><span> b {{ data.wicket_bowler_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'hit wicket'">
                            <span  class="ms-lg-2">hit wicket &nbsp;  b {{ data.wicket_bowler_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'stumped'">
                          <span  class="ms-lg-2">st {{ data.fielder_name }}</span> &nbsp;<br class="d-block d-lg-none d-md-none"><span> b {{ data.wicket_bowler_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'bowled'">
                          <span class="ms-lg-2"> b {{ data.wicket_bowler_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'run out'">
                          <span class="ms-lg-2">run out {{ data.fielder_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'caught & bowled'">
                            <span class="ms-lg-2">c & b {{ data.fielder_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'caught & wicket'">
                            <span class="ms-lg-2">  c {{ data.fielder_name }} </span> &nbsp;<br class="d-block d-lg-none d-md-none"><span class="ms-lg-2"> b {{ data.wicket_bowler_name }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'retired hurt'">
                            <span class="ms-lg-2"> retired hurt </span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'obstructing'">
                            <span class="ms-lg-2"> obstructing </span>
                        </ng-container>
                        <ng-container *ngIf="data.how_out.toLowerCase() === 'time out'">
                            <span class="ms-lg-2"> time out </span>  
                        </ng-container>
                    </p>
                </td>
                <td class="sm-screen-fs">{{data.runs}}</td>
                <td class="sm-screen-fs">{{data.balls_faced}}</td>
                <td class="sm-screen-fs" (click)="openVideoModal(data.batsman_user_id, 'fours', i)" style="cursor: pointer;">{{data.fours}}</td>
                <td class="sm-screen-fs" (click)="openVideoModal(data.batsman_user_id, 'sixs', i)" style="cursor: pointer;">{{data.sixers}}</td>
                <td class="sm-screen-fs">{{data.strike_rate}}</td>
                <td class="sm-screen-fs" >
                    <div *ngIf="!showWagon[i]"><mat-icon>keyboard_arrow_down</mat-icon></div>
                    <div *ngIf="showWagon[i]"><mat-icon>keyboard_arrow_up</mat-icon></div>
                </td>
            </tr>
            <tr *ngIf="showWagon[i]">
                <td colspan="7">
                    <app-wagon-wheel [showTeams]="false" [wagonFor]="wagonFor"
                        [wagonCoordinatesForPlayer]="ballHit"></app-wagon-wheel>

                    <div class="d-flex justify-content-center">
                        <table class="table rounded-3 overflow-hidden shadow table-borderless text-center battertable" *ngIf="batsmansumm[i]?.length>0">
                            <thead>
                                <tr>
                                    <th scope="col">Ball By Ball</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="batsmansumm" >
                                <tr class="border-bottom d-flex justify-content-center bg-white">
                                    <ng-container >
                                        <td class="mx-2 d-flex justify-content-center" >
                                            <div class="d-flex gap-lg-3 gap-md-2 gap-sm-1 spacegap" style="flex-wrap: wrap !important;">
                                                <div *ngFor="let run of batsmansumm[i];let i=index"
                                                    class=" d-flex justify-content-center align-items-center">
                                                    <div class="run text-center justify-content-center align-items-center d-flex" *ngIf="run.videoURL && run.videoURL !== 'Y' && run.videoURL !== 'N'"
                                                     [ngClass]="{'runWicket': isWicket(run.runs)}"  (click)="videoScorecard(run.videoURL)">
                                                        <span class="m-auto">{{ run.runs }}</span>
                                                        <i class="bi bi-play-fill videoicon"  
                                                            [ngClass]="{'runWicketicon': isWicket(run.runs)}"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            <!-- <tr *ngIf="showNoDataFound[i] && !showWagon[i]">
                <td colspan="9" >
                    <h3 class="text-center m-0 p-0">No Record Found </h3>
                </td>
            </tr>         -->
            </ng-container>
        </tbody>
    </table>
</div>

<div class="batsman-data">
    <mat-card class="p-3 sm-screen-fs">
        <mat-card-content class="py-1">
            <div *ngIf="innings=='1'"><span class="text">EXTRAS </span>: &nbsp;{{extrasFirstTotal}}&nbsp; ( B {{cricketMatchSumm?.innings_1_byes}}, LB
                {{cricketMatchSumm?.innings_1_legbye}}, W {{cricketMatchSumm?.innings_1_wides
                }}, NB {{cricketMatchSumm?.innings_1_noballs}}, P {{cricketMatchSumm?.innings_1_penalty}} )
            </div>
            <div *ngIf="innings=='2'"><span class="text">EXTRAS </span>: &nbsp;{{extrasSecondTotal}}&nbsp; ( B {{cricketMatchSumm?.innings_2_byes}}, LB
                {{cricketMatchSumm?.innings_2_legbye}}, W {{cricketMatchSumm?.innings_2_wides
                }}, NB {{cricketMatchSumm?.innings_2_noballs}}, P {{cricketMatchSumm?.innings_2_penalty}}  )
            </div>
            <div *ngIf="innings=='3'"><span class="text">EXTRAS </span>: &nbsp;{{extrasthirdTotal}}&nbsp; ( B {{cricketMatchSumm?.innings_3_byes}}, LB
                {{cricketMatchSumm?.innings_3_legbye}}, W {{cricketMatchSumm?.innings_3_wides
                }}, NB {{cricketMatchSumm?.innings_3_noballs}}, P {{cricketMatchSumm?.innings_3_penalty}}  )
            </div>
            <div *ngIf="innings=='4'"><span class="text">EXTRAS </span>: &nbsp;{{extrasfourthTotal}}&nbsp; ( B {{cricketMatchSumm?.innings_4_byes}}, LB
                {{cricketMatchSumm?.innings_4_legbye}}, W {{cricketMatchSumm?.innings_4_wides
                }}, NB {{cricketMatchSumm?.innings_4_noballs}}, P {{cricketMatchSumm?.innings_4_penalty}}  )
            </div>
            <hr class="my-2">
            <span class="text">DID NOT BAT</span>
            <!-- <div style="margin-top: 12px;" *ngIf="didNotBat && didNotBat.length>0; else noRecord">
                <p *ngFor="let data of didNotBat;let last = last" style="display: inline-block;">{{ data.batsman_name }}
                    <span *ngIf="!last">, &nbsp;</span>
                </p>
            </div> -->
            <div style="margin-top: 12px;" *ngIf="innStatsDNB && innStatsDNB.length>0; else noRecord">
                <p class="mb-1" *ngFor="let data of innStatsDNB;let last = last" style="display: inline-block;">{{ data.batsman_name }}
                    <span *ngIf="!last">, &nbsp;</span>
                </p>
            </div>

            <div *ngIf="fallOfWickets && fallOfWickets.length>0;">
                <hr class="my-2">
                <span class="mt-1 text">FALL OF WICKETS</span>
              <div style="margin-top: 12px; line-height: 1.8;">
                <span *ngFor="let data of fallOfWickets; let last = last;">
                    {{data.fow_runs}} - {{data.wicket_no}} ({{data.who_out_name}}, {{data.fow_over}} ov)
                    <span *ngIf="!last">, &nbsp;</span>
                </span>
            </div>
        </div>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #noRecord>
    <div>
      <h4 class="m-0 text-center">
        No Record Found!
      </h4>
    </div>
  </ng-template>
