import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpoint } from '../common/constants/endpoint.def';

@Injectable({
  providedIn: 'root'
})
export class TennisService {

constructor(private http:HttpClient) { }

// getTennisMatchList():Observable<any>{
//   return this.http.get(environment.serviceUrl + ApiEndpoint.TENNIS_SCORE_CARD.GET_TENNIS_MATCH_LIST);
// }
getTennisPlayerScoreDetail():Observable<any>{
  return this.http.get(environment.serviceUrl+ApiEndpoint.TENNIS_SCORE_CARD.GET_TENNIS_PLAYER_SCOREDETAIL_FOR_MATCH_FOREHAND)
}

}
