import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-video-detail-card',
  templateUrl: './video-detail-card.component.html',
  styleUrls: ['./video-detail-card.component.css']
})
export class VideoDetailCardComponent {

  @Input() videoDetail:any;

  constructor(private sanitizer: DomSanitizer){}

  trustSrc(src:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

}
