import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-home-section-carousal-card',
  templateUrl: './home-section-carousal-card.component.html',
  styleUrls: ['./home-section-carousal-card.component.css']
})
export class HomeSectionCarousalCardComponent {
  @Input() video: any;
  orgCode:any

  constructor(private route:Router, private orgFilter:OrgcodeFilterService){}
ngOnInit(): void {
  this.orgCode=this.orgFilter.storageItems.value[0].orgPath

}
  getVideoDetail(videoID:any,catID:any){
    this.route.navigate([`${this.orgCode}/videodetail`, videoID,catID]); 
  }
}
