<div class="p-3 mb-2 bg-body-tertiary border-0 card-design " *ngIf="innBowStats?.length > 0 ">
    <table class="table ">
        <thead>
            <tr>
                <th scope="col">Bowlers</th>
                <th scope="col">O</th>
                <th scope="col">M</th>
                <th scope="col">R</th>
                <th scope="col">W</th>
                <th scope="col">WD</th>
                <th scope="col">NB</th>
                <th scope="col">Eco</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let firstBow of innBowStats; let i =index ">            
            <tr  style="padding: 5px!important;" (click)="openWagon(firstBow.bowler_user_id , i) ;bowlerInningsSumm(firstBow.bowler_user_id,i)" *ngIf="firstBow.over > 0 || firstBow.overBalls > 0"  >
                <td>{{firstBow.bowler_name}}</td>
                <td>{{firstBow.overs}}</td>
                <td>{{firstBow.maidens}}</td>
                <td>{{firstBow.runs}}</td>
                <td>{{firstBow.wickets}}</td>
                <td>{{firstBow.wides}}</td>
                <td>{{firstBow.noBalls}}</td>
                <td>{{firstBow.economy}}</td>
                <td >                    
                    <div *ngIf="!showWagon[i] && !showNoDataFound[i]" ><mat-icon>keyboard_arrow_down</mat-icon></div>  
                    <div *ngIf="showWagon[i] || showNoDataFound[i]"><mat-icon>keyboard_arrow_up</mat-icon></div>         
                </td>
            </tr>
            <tr *ngIf="showWagon[i]">
                <td colspan="12">
                    <app-wagon-wheel [showTeams]="false" [wagonFor]="wagonFor"  [wagonCoordinatesForPlayer]="ballHit" ></app-wagon-wheel>
                    <app-pitch-map [showTeams]="false" [pitchMapFor]="wagonFor" [pitchMapResponseForPlayer]="pitchMapResponse" ></app-pitch-map>
                    <div class="d-flex justify-content-center">
                        <table class="table rounded-3 overflow-hidden shadow table-borderless text-start  w-50">
                            <thead>
                                <tr>
                                    <th scope="col">Ovr No</th>
                                    <th scope="col">Ball By Ball</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="bowlersumm[i]">
                                <tr *ngFor="let item of bowlersumm[i]; let ind=index" class=" border-bottom" >
                                    <ng-container *ngIf="item.innings==innings">
                                        <td class="d-flex justify-content-center align-items-center" style="height: 39px; font-size: smaller;" >
                                            {{ item?.overs }}
                                        </td>
                                        <td>
                                            <div class="d-flex gap-3">
                                                <div *ngFor="let run of item?.runs_in_over.split(',')" [ngClass]="{'runWicket': isWicket(run)}" class=" run text-center justify-content-center align-items-center"><span class="m-auto">{{ run }}</span></div>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-center align-items-center" style="height:39px; font-size: smaller;" >
                                            {{ item?.runs }}
                                        </td>
                                    </ng-container>
                                    
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                </td>
            </tr>  
            <!-- <tr *ngIf="showNoDataFound[i] && !showWagon[i]">
                <td colspan="9" >
                    <h3 class="text-center m-0 p-0">No Record Found </h3>
                </td>
            </tr>          -->
        </ng-container>
        </tbody>
    </table>
</div>