import { Component, Input, } from '@angular/core';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.css']
})
export class PartnershipComponent {

  innings: any;
  PartnerShip: any;
  @Input()
  matchId: any;
  bothTeam: any;
  teams: any;
  team1Active: boolean = false;
  team2Active: boolean = false;
  ispartnership: boolean=false;
  intervel:any;

  constructor(private scoreService: CricketScoreCardService) {
  }
  ngOnInit(): void {
    this.getBothTeamData();
    this.innings1();
    // this.intervel = setInterval(() => {
    //   this.getBothTeamData();
    //   if(this.innings == 1){
    //     this.innings1();
    //   } else {
    //     this.innings2();
    //   }
    // }, 5000);
    // if(this.matchStatus == 'C'){
    //   clearInterval(this.intervel);
    // }
  }

    // ngOnDestroy(): void {
    //   if (this.intervel) {
    //     clearInterval(this.intervel);
    //   }
    // }
  

  getpartner() {
    this.scoreService.getCricketPartnershipAnalytics(this.matchId, this.innings).subscribe((res: any) => {
      this.PartnerShip = res.result;
      this.PartnerShip=res.result.filter((data:any)=> data.balls!==('0'||null) && data.runs!=='0');
      if(this.PartnerShip.length>0){
        this.ispartnership=true;
      }else{
        this.ispartnership=false;
      }
    });
  }
  innings1() {
    this.innings = 1;
    this.getpartner();
    this.team1Active = true;
    this.team2Active = false;
  }
  innings2() {
    this.innings = 2;
    this.getpartner();
    this.team1Active = false;
    this.team2Active = true;
  }

  getBothTeamData() {
    this.scoreService.getCricketMatchScoresAndPlayingEleven(this.matchId).subscribe((response: any) => {
      if (Array.isArray(response.result)) {
        this.bothTeam = response.result[0];
        this.teams = this.bothTeam;
      } else {
        this.bothTeam = response.result.matchDetails;
        this.teams = this.bothTeam;
      }
    });
  }

}
