import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { EventService } from 'src/app/service/event.service';
import { HeaderParameterService } from 'src/app/service/header-parameter.service';
import { environment } from 'src/environments/environment';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-update-profilepic',
  templateUrl: './update-profilepic.component.html',
  styleUrls: ['./update-profilepic.component.css']
})
export class UpdateProfilepicComponent {

  previewImageUrl: string | ArrayBuffer | null = '/assets/user-thumbnail.png';
  userId: any;
  imageFile: File | null = null;
  fileSizeError: boolean = false;
  fileTypeError: boolean = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>; // Reference to the file input element
  orgCode:any

  constructor(private commonService: CommonService, private router: Router, private snackBar: MatSnackBar,private headerparam: HeaderParameterService,private eventService :EventService,private orgFilter:OrgcodeFilterService) { }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    if(this.userId== null){
      this.router.navigate([`${this.orgCode}/home`])
    }else{
      this.getUserDetails();
    }
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.fileSizeError = false;
    this.fileTypeError = false;
    if (file) {
      if (this.isImageFileType(file)) {
        if (this.isFileSizeValid(file) ) {
          const reader = new FileReader();
          reader.onload = () => {
            this.previewImageUrl = reader.result;
            this.imageFile = file;
            this.fileSizeError = false;
            this.fileTypeError = false;
          };
          reader.readAsDataURL(file);
        } else {

          this.snackBar.open('File size should be less than 1MB', '', {
            duration: 3000,
            verticalPosition: 'bottom',
          });
          this.fileSizeError = true;
          this.clearInput();
        }
      } else {
        this.snackBar.open('File type should be JPG, JPEG, or PNG', '', {
          duration: 3000,
          verticalPosition: 'bottom',
        });
        this.fileTypeError = true;
        this.clearInput();

      }
    }
  }
  getUserDetails() {
    const timestamp = new Date().getTime();
    this.commonService.getUserDetails(this.userId).subscribe((response: any) => {
      this.previewImageUrl = environment.serviceUrl + response.result[0].path_to_pic + "?" + timestamp;
      this.headerparam.setuserImg(this.previewImageUrl);
    })
  }

  isFileSizeValid(file: File): boolean {
    return file.size <= 1024 * 1024;
  }
  isImageFileType(file: File): boolean {
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension !== undefined && ['jpg', 'jpeg', 'png'].includes(extension);
  }

  save() {
    if (this.imageFile) {
      this.eventService.updateProfilePic(this.userId, this.imageFile).subscribe(response => {
        const form = {
          "pathToPic": response.pathToPic,
          "uid": this.userId,
          "flag": "profilePicurl"
        };
        this.eventService.updateEvenMaster(form).subscribe((res: any) => {
          this.getUserDetails();
          this.snackBar.open('Image Updated Successfully', '', {
            duration: 3000,
            verticalPosition: 'bottom',
          });
          this.clearInput();
          this.router.navigate([`${this.orgCode}/edit-profile`]);
        })
      });
    }
  }
  clearInput() {
    this.imageFile = null;
    // this.fileInput.nativeElement.value = '';
  }

  cancel() {
    window.history.back();
  }
}
