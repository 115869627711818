import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';
import { UpcmingCricketMatchResult, UpcomingBadmintonResult, UpcomingMatchResult, UpcomingTableTennisResult, UpcomingTennisResult } from 'src/app/utils/cricketInterface';
@Component({
  selector: 'app-upcoming-match',
  templateUrl: './upcoming-match.component.html',
  styleUrls: ['./upcoming-match.component.css']
})
export class UpcomingMatchComponent {

  results: any;
  matchResult: UpcomingMatchResult[] = [];
  matchResultLength :number = 0 ;
  status1: string = 'C';
  dataLimit : number = 10;
  selectedType: any;
  limit = 4;
  orgCode:any
  SportsList = [
    {active: true, type:'cricket',game:'ball', backgroundColor:'red'}, {active : false,type:'tennis' ,game:'tennisball',backgroundColor:'yellow'}, {active : false,type: 'badminton',game:'batmiton ball',backgroundColor:'white'}, {active : false,type:'tableTennis', game:'tableTennis',backgroundColor:'maroon'}]
  upcomingmatchdetails: any;

  constructor(private commonService: CommonService,private route:Router,private orgFilter:OrgcodeFilterService) {
  }

  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
    console.log("🚀 ~ UpcomingMatchComponent ~ ngOnInit ~ this.orgCode:", this.orgCode)

    this.sportDetail('cricket');
    this.selectedType = 'cricket'
  }

  shouldDisplayCard(index: number): boolean {
    if (this.orgCode === 'SKA') {
      if  (window.innerWidth >= 1025) { // laptop screen with more than 1024px
        return index < 3;
      } else if(window.innerWidth >= 992) { // Large screens
        return index < 2;
      } else if (window.innerWidth >= 768) { // Medium screens
        return index < 1;
      } else { // Small screens
        return index < 1;
      }
    } else {
      return index < 5; // Default for other orgCodes
    }
  }
  changeTab(selectedType: string) {
    this.SportsList.forEach(sport => {
      sport.active = sport.type === selectedType;
      this.selectedType = selectedType
    });
    this.sportDetail(selectedType);
  }

  sportDetail(data: any) {
    console.log("🚀 ~ UpcomingMatchComponent ~ sportDetail ~ data:", data)
    if (data == 'cricket') {
      this.getAllCricketMatches();
    } else if (data == 'tennis') {
      this.getUpcomingTennisMatches();
    } else if (data == 'badminton') {
      this.getUpcomingBadmintonMatches();
    } else {
      this.getUpcomingTableTennisMatches();
    }
  }

  getAllCricketMatches() {
    this.matchResult = [];
    this.matchResultLength = 0;
    this.commonService.getAllUpcomingMatches(this.dataLimit).subscribe((response) => {
      this.results = response;
      if (this.results?.result?.length > 0) {
        let upcmingCricketMatchResult:UpcmingCricketMatchResult[] = this.results.result;
        this.upcomingmatchdetails=this.results.result;
        upcmingCricketMatchResult.forEach((item) => {
          item.Match_Date = moment(item.Match_Date).format("ll");
          item.Match_Time = moment(item.Match_Time).format('h:mm');
          this.matchResult.push(this.mapToUpcomingMatchResult(item));
        })
        
        this.matchResultLength = this.matchResult.length;

      }else{
        console.log("there is no upcoming cricket matches.");
      }

    });
  }

  getUpcomingTennisMatches() {
    this.matchResult = [];
    this.matchResultLength=0;
  }

  getUpcomingBadmintonMatches() {
    this.matchResult = [];
    this.matchResultLength=0;
  }

  getUpcomingTableTennisMatches() {
    this.matchResult = [];
    this.matchResultLength=0;
  }

  mapToUpcomingMatchResult(source: UpcmingCricketMatchResult): UpcomingMatchResult {
    const destination: UpcomingMatchResult = {
      teamone: source.teamone,
      teamtwo: source.teamtwo,
      Venue: source.Venue,
      Match_Date: source.Match_Date,
      Match_Time: source.Match_Time,
      Event_name: source.Event_name,
    };
    return destination;
  }

  mapToUpcomingMatchResult1(source: UpcomingTennisResult): UpcomingMatchResult{
    const destination: UpcomingMatchResult = {
      teamone: source.team1_detail[0].player_name,
      teamtwo: source.team2_detail[0].player_name,
      Venue: source.match_venue,
      Match_Date: source.match_date,
      Match_Time: source.match_time,
      Event_name: source.Event_name,
    };
    return destination;
  }

  mapToUpcomingMatchResult2(source: UpcomingBadmintonResult): UpcomingMatchResult{
    const destination: UpcomingMatchResult = {
      teamone: source.team1_detail[0].player_name,
      teamtwo: source.team2_detail[0].player_name,
      Venue: source.match_venue,
      Match_Date: source.match_date,
      Match_Time: source.match_time,
      Event_name: source.Event_name,
    };
    return destination;
  }

  mapToUpcomingMatchResult3(source: UpcomingTableTennisResult): UpcomingMatchResult{
    const destination: UpcomingMatchResult = {
      teamone: source.team1_detail[0].player_name,
      teamtwo: source.team2_detail[0].player_name,
      Venue: source.match_venue,
      Match_Date: source.match_date,
      Match_Time: source.match_time,
      Event_name: source.Event_name,
    };
    return destination;
  }

  navigateToMatches( section: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        section: section
      }
    };
    this.route.navigate(['/matches'], navigationExtras);
  }
}
