<div class="videoHighlights container-fluid mt-3 mb-3">
    <mat-card>
        <h2 class="d-flex justify-content-center fw-semibold my-2">Highlights</h2>
        <mat-card-title style="border-bottom: 2px solid #b3afaf83;" class="pb-1">
            <div class="d-flex justify-content-evenly">
                <div class="col-lg-2 col-6 d-flex justify-content-center align-items-center px-0" (click)="innings1()"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team1Active" style="border: none;">Innings1</span></div>
                <div class="col-lg-2 col-6 d-flex justify-content-center align-items-center px-0" (click)="innings2()"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team2Active"   style="border: none;">Innings2</span></div>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="d-flex flex-lg-row flex-wrap gap-lg-3 gap-md-2 justify-content-lg-end justify-content-md-end justify-content-evenly  mt-2 mx-1">
                <mat-form-field appearance="outline" class="col-5 col-md-3 my-1 col-lg-2">
                    <mat-label>Batter</mat-label>
                    <mat-select (selectionChange)="filter($event.value,this.selectedbowlerdetail,this.selectedFilter)" placeholder="Batter">
                        <mat-option value="All">
                            All
                        </mat-option>
                        <mat-option *ngFor="let batters of BattingPlayers" [value]="batters">
                            {{ batters.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-5 col-md-3 my-1 col-lg-2">
                    <mat-label>Bowler</mat-label>
                    <mat-select (selectionChange)="filter(this.selectedbatsmandetail,$event.value,this.selectedFilter)" placeholder="Bowler">
                        <mat-option value="All">
                            All
                        </mat-option>
                        <mat-option *ngFor="let bowers of BowlingPlayers" [value]="bowers">
                            {{ bowers.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-5 col-md-3 my-1 col-lg-2">
                    <mat-label>Events</mat-label>
                    <mat-select  (selectionChange)="filter(this.selectedbatsmandetail,this.selectedbowlerdetail,$event.value)" placeholder="Events">
                        <mat-option *ngFor="let selectlist of filterlist" [value]="selectlist.value">
                            {{ selectlist.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>
            <div class="d-flex justify-content-center" *ngIf="videoList.length==0" style="align-items:center;height: 16.5vh;">No Video Highlights Found</div>
            <div class="d-flex justify-content-center">
                <video #videoPlayer class=" shadow w-100 h-100 my-2" controlslist="nodownload"  autoplay="autoplay" controls="controls" preload="auto" *ngIf="videoList.length>0"
                    playsinline (ended)="onVideoEnded()">
                    <source [src]="currentVideo" type="video/mp4" />
                </video>
            </div>
        </mat-card-content>
    </mat-card>
</div>
