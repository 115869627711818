import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiEndpoint } from '../common/constants/endpoint.def';
import { OrgcodeFilterService } from '../common/orgcode-filter.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {


  public pathName = new BehaviorSubject<boolean>(true);


  matchesdata: any;
  headers:any;
  orgCode=this.commonStorage.storageItems.value[0].orgPath

  constructor(private http: HttpClient,private commonStorage:OrgcodeFilterService) {}
   private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Orgcode': `${this.commonStorage.storageItems.value[0].orgPath}`
    });
  }

  login(data: any,) {
    const  headers = new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin':'*' });
    return this.http.post(environment.serviceUrl+ApiEndpoint.USER.LOGIN,  JSON.stringify(data), { headers : headers } );
  }
  // login(email: any, password: any) {
  //   return this.http.get(environment.serviceUrl + ApiEndpoint.USER.LOGIN.replace('{email}',email).replace('{password}',password));
  // }
  getAllUpcomingMatches(data: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.UPCOMING_MATCH.GET_ALL_UPCOMING_MATCH.replace('{limit}', data), { headers : headers });
  }

  getTennisMatches(status: any, limit: any) {
        const headers = this.getHeaders();

    return this.http.get(
      environment.serviceUrl +
      ApiEndpoint.UPCOMING_MATCH.GET_TENNIS_UPCOMING_MATCH.replace(
        '{status}',
        status
      ).replace('{limit}', limit), { headers : headers }
    );
  }

  getBadmintonMatches(status: any, limit: any) : Observable<any>{
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl +ApiEndpoint.UPCOMING_MATCH.GET_BADMINTON_UPCOMING_MATCH
      .replace('{status}',status).replace('{limit}', limit), { headers : headers }
    );
  }

  getTableTennisMatches(status: any, limit: any) {
        const headers = this.getHeaders();

    return this.http.get(
      environment.serviceUrl +
      ApiEndpoint.UPCOMING_MATCH.GET_TABLE_TENNIS_UPCOMING_MATCH.replace(
        '{status}',
        status
      ).replace('{limit}', limit), { headers : headers }
    );
  }

  getAllCompletedMatches() {
        const headers = this.getHeaders();

    return this.http.get(
      environment.serviceUrl +
      ApiEndpoint.CRICKET_SCORE.GET_ALL_COMPLETED_MATCH, { headers : headers }
    );
  }

  getAllCompletedAndLiveMatchesDataUser(data: any) {
    this.matchesdata = data;
  }
  get1() {
    return this.matchesdata;
  }
  getCommentaryForCompletedMatches(data: any) {
        const headers = this.getHeaders();

    let params = new HttpParams();
    params = params.append('TEAMID', data.teamId);
    params = params.append('MATCHID', data.matchId);
    params = params.append('MINLIMIT', data.minLimit);
    params = params.append('MAXLIMIT', data.maxLimit);
    return this.http.get(
      environment.serviceUrl +
      ApiEndpoint.COMMENTARY.GET_COMMMENTARY_DETAILS,
      { params: params,headers:headers }
    );
  }

  sendEmailForForgotPassword(email: string) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FORGOT_PASSWORD.CHECK_EMAIL.replace('{email}', email), { headers : headers });
  }

  getMemberByMailId(email: string) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FORGOT_PASSWORD.CHECK_MEMBER_TEAM.replace('{email}', email), { headers : headers });
  }
  checkUserNameExist(data: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.USER.CHECK_USER_EXIST + data, { headers : headers });
  }

  checkEmailExist(data: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.USER.CHECK_USER_EMAIL_EXIST + data, { headers : headers });
  }

  checkMobileExist(data: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.USER.CHECK_MOBILE_NO_EXIST + data, { headers : headers });
  }

  registerUser(email: any, mobileNo: any, password: any, name: any, jsonData: any) {
        const headers = this.getHeaders();

    const body = {
      EMAIL_ID: email,
      mobile_no: mobileNo,
      PASSWORD: password,
      NAME: name,
      jsondata: jsonData
    };

    return this.http.post(environment.serviceUrl + ApiEndpoint.SIGN_UP.REGISTER_USER, body, { headers : headers });
  }
  getBattingSumm(matchId:any,innings:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.TOP_PERFORMERS.INNINGS_BATTING_SUM.replace('{matchId}',matchId).replace('{innings}',innings), { headers : headers })
  }
  getBowlingSumm(matchId:any,innings:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.TOP_PERFORMERS.INNINGS_BOWLING_SUMM.replace('{matchId}',matchId).replace('{innings}',innings), { headers : headers })
  }
  getTournaments(){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.TOURNAMENTS.EVENTS_HAPPENING, { headers : headers })
  }
  getUserEvents(SEARCHSTR:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.TOURNAMENTS.GET_USER_EVENTS.replace('{SEARCHSTR}',SEARCHSTR), { headers : headers })
  }
  currentEventLiveMatch(eventId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.TOURNAMENTS.GET_CURRENT_EVENT_LIVE_MATCHES.replace('{eventId}',eventId), { headers : headers })
  }
  getPointsTable(eventId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl +ApiEndpoint.TOURNAMENTS.GET_POINTSTABLE.replace('{eventId}',eventId), { headers : headers });
  }
  getEventSubCat(eventId:any,sportId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ApiEndpoint.TOURNAMENTS.GET_EVENT_SUBCAT.replace('{eventId}',eventId).replace('{sportId}',sportId), { headers : headers })
  }
  getLeaderUi(type:any,sportId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ApiEndpoint.TOURNAMENTS.GET_LEADER_UI.replace('{type}',type).replace('{sportId}',sportId), { headers : headers })
  }
  getLeaderTabUi(tabId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ApiEndpoint.TOURNAMENTS.GET_LEADER_TAB_UI.replace('{tabId}',tabId), { headers : headers })
  }
  getBattingAggregate(teamId:any,type:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ApiEndpoint.TOURNAMENTS.GET_BATTING_AGGREGATING.replace('{teamId}',teamId).replace('{type}',type), { headers : headers })
  }
  getFactSheets(eventId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.TOURNAMENTS.GET_FACTSHEET.replace('{eventId}',eventId), { headers : headers });
  }
  getoldPassword(userId:any,passwd:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ApiEndpoint.FORGOT_PASSWORD.GET_OLD_PASSWORD.replace('{userId}',userId).replace('{passwd}',passwd), { headers : headers });
  }
  getencp(passwd:any,email:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ApiEndpoint.FORGOT_PASSWORD.GET_ENCP.replace('{passwd}',passwd).replace('{email}',email), { headers : headers })
  }
  updateuserpassword(data:any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(`${environment.serviceUrl}${ApiEndpoint.FORGOT_PASSWORD.UPDATE_PASSWORD}`, data);
  }
  getAllResults(eventId:any,status:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.TOURNAMENTS.GET_ALL_RESULTS.replace('{eventId}',eventId).replace('{status}',status), { headers : headers })
  }
  getUserid(emailId: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.SIGN_UP.GET_USER_ID.replace('{emailId}', emailId), { headers : headers });
  }
  getverificationCode(userId: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.OTP_VERIFICATION.GET_VERFICATION_CODE.replace('{userId}', userId), { headers : headers });
  }



getAllEventFixtures(eventId:any, status:any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.TOURNAMENTS.GET_ALL_EVENT_FXITURES.replace('{eventId}',eventId).replace('{status}',status), { headers : headers })
}

getUserDetails(User_id: any) {
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.EDIT_PROFILE.GET_USER_DETAILS + '?SEARCHSTR=' + User_id, { headers : headers });
}

getOldPassword(userId: number, passwd: String) {
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.get<any>(`${environment.serviceUrl}/${ApiEndpoint.LOGIN.GET_OLD_PASSWORD}?USER_ID=${userId}&PASS=${passwd}`, { headers: headers });
}

getEncp(password: string, email: string) {
  const headers = this.getHeaders();
  const params = { password: password, email: email };
  return this.http.get(`${environment.serviceUrl}/${ApiEndpoint.LOGIN.GET_ENCP}`, { params: params ,headers:headers});
}

updateUserPassword(updateUserRequest: any) {
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.post<any>(`${environment.serviceUrl}/${ApiEndpoint.LOGIN.UPDATE_PASSWORD}`, updateUserRequest);
}
displayTeamName(teamId:any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.DISPLAY_TEAM_NAME.replace('{teamId}',teamId), { headers : headers })
}
cricketTeamUserRecord(teamId:any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.CRICKET_TEAM_USER_RECORD.replace('{teamId}',teamId), { headers : headers })

}

allTeamRecent(teamId: any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.ALL_TEAM_RECENT.replace('{teamId}',teamId), { headers : headers })
}

cricketTeamUpcomingMatches(teamId: any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.CRICKET_TEAM_UPCOMING_MATCHES.replace('{teamId}',teamId), { headers : headers })
}

teamLeaderBoard(teamId: any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.TEAM_LEADER_BOARD.replace('{teamId}',teamId), { headers : headers })
}

eventLeaderBoard(eventId: any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.EVENT_LEADER_BOARD.replace('{eventId}',eventId), { headers : headers })
}

teamEventLeaderboard(teamId: any){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SUMMERY.TEAM_EVENT_LEADER_BOARD.replace('{teamId}',teamId), { headers : headers })
}

getAllFactSheet(){
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl+ApiEndpoint.TOURNAMENTS.GET_ALL_FACTSHEET, { headers : headers });
}
getDynamicHomeContent(){
      const headers = this.getHeaders();
  return this.http.get(environment.serviceUrl + ApiEndpoint.DYNAMIC_CONTENTS.GET_HOME_DYNAMIC_CONTENT, { headers : headers })
}
getDynamicContent() {
      const headers = this.getHeaders();

  return this.http.get(environment.serviceUrl + ApiEndpoint.DYNAMIC_CONTENTS.GET_DYNAMIC_DATA, { headers : headers })
}

updateLoginType(emailId: any) {
      const headers = this.getHeaders();

  return this.http.post(environment.serviceUrl + ApiEndpoint.OTP_VERIFICATION.UPDATE_LOGIN_TYPE.replace('{emailId}', emailId),emailId, { headers : headers });
}
getSearchResult(searchString:any, academiesFilter:any,communityFilter:any,eventFilter:any,peopleFilter:any,userId:any,teamFilter:any ,sportFilter:any){
  const headers = this.getHeaders();
  return this.http.get(environment.serviceUrl+ApiEndpoint.COMMON_SEARCH.GET_SEARCH_RESULT
    .replace('{searchString}',searchString)
    .replace('{academiesFilter}',academiesFilter)
    .replace('{communityFilter},',communityFilter)
    .replace('{eventFilter}',eventFilter)
    .replace('{peopleFilter}',peopleFilter)
    .replace('{userId}',userId)
    .replace('{teamFilter}',teamFilter)
    .replace('{sportFilter}',sportFilter)
    , { headers : headers }
    // .replace('{sportFilter}',sportFilter)
    // .replace('{userId}',userId)
    )
}
bowlerInningsSumm(matchId:any,userId:any){
  const headers = this.getHeaders();
  return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.BOWLER_INNINGS_SUMM.replace('{matchId}',matchId).replace('{userId}',userId),{ headers : headers });
}

}
