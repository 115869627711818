import { Component, Input, OnInit } from '@angular/core';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-batsman-scorecard',
  templateUrl: './batsman-scorecard.component.html',
  styleUrls: ['./batsman-scorecard.component.css']
})
export class BatsmanScorecardComponent {

  @Input() innStats: any;
  @Input() cricketMatchSumm: any;
  @Input() fallOfWickets: any;
  wagonCoordinateForplayers: any;
  showWagon : boolean[] = [];
  @Input() matchId: any;
  @Input() innings:any;
  batsmanUserId: any;
  wagonStatus: any;
  showNoDataFound: boolean[] = [];
  ballHit: any;
  wagonFor = 'player';
  batsmanId: any;
  extrasFirstTotal:any;
  extrasSecondTotal:any;
  extrasthirdTotal: any;
  extrasfourthTotal: any;
  didNotBatInTeamOne: any;
  didNotBatInTeamTwo: any;
  @Input() innStatsDNB: any;
  didNotBat: any;
  constructor(private scorecardService: CricketScoreCardService) {
  }
  ngOnInit() {   
    this.extrasFirstTotal=this.cricketMatchSumm?.innings_1_byes+this.cricketMatchSumm?.innings_1_wides+this.cricketMatchSumm?.innings_1_legbye+this.cricketMatchSumm?.innings_1_noballs+this.cricketMatchSumm?.innings_1_penalty;
    this.extrasSecondTotal=this.cricketMatchSumm?.innings_2_byes+this.cricketMatchSumm?.innings_2_wides+this.cricketMatchSumm?.innings_2_legbye+this.cricketMatchSumm?.innings_2_noballs+this.cricketMatchSumm?.innings_2_penalty;
    this.extrasthirdTotal=this.cricketMatchSumm?.innings_3_byes+this.cricketMatchSumm?.innings_3_wides+this.cricketMatchSumm?.innings_3_legbye+this.cricketMatchSumm?.innings_3_noballs+this.cricketMatchSumm?.innings_3_penalty;
    this.extrasfourthTotal=this.cricketMatchSumm?.innings_4_byes+this.cricketMatchSumm?.innings_4_wides+this.cricketMatchSumm?.innings_4_legbye+this.cricketMatchSumm?.innings_4_noballs+this.cricketMatchSumm?.innings_4_penalty;
    

  }
  openWagon(data: any, index: number) {
    this.batsmanId = data;
    this.scorecardService.getWagonCoordinateForPlayer(this.matchId, data).subscribe((data: any) => {
      if(data.status =="TRUE"){
      this.wagonCoordinateForplayers = data.result;
      this.ballHit=this.wagonCoordinateForplayers.filter((data:any)=>data.where_hit_x !='0' && data.where_hit_y !='0')
      this.showWagon[index] = !this.showWagon[index] 
      } else {
        this.ballHit= [];
        this.showWagon[index] = !this.showWagon[index] 
      }
    });
  }
}
