<div class="table-responsive">
    <table class="table table-light" style="table-layout: auto;">
        <thead>
            <tr class="text-center">
                <th scope="col" class="bg-secondary text-white fw-medium">
                    {{data.team1_detail[0].player_name}}
                </th>
                <th scope="col" class="bg-secondary text-white fw-medium">
                    {{data.team1_detail[1].player_name}}
                </th>
                <th scope="col" class="bg-secondary text-white fw-medium">T1
                    {{data.setDetail.team1_score}}</th>
                <th scope="col" class="bg-secondary text-white fw-medium">T1%</th>
                <th scope="col" class="bg-secondary text-white fw-medium" style="width: 30%;"> </th>
                <th scope="col" class="bg-secondary text-white fw-medium">T2%</th>
                <th scope="col" class="bg-secondary text-white fw-medium">T2
                    {{data.setDetail.team2_score}}</th>
                <th scope="col" class="bg-secondary text-white fw-medium">
                    {{data.team2_detail[0].player_name}}
                </th>
                <th scope="col" class="bg-secondary text-white fw-medium">
                    {{data.team2_detail[1].player_name}}
                </th>
                <th scope="col" class="bg-secondary text-white"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center">
                <td scope="col" class="fw-medium bg-success text-white">{{data.player1_winningPoints}}</td>
                <td scope="col" class="fw-medium bg-success text-white">{{data.player2_winningPoints}}</td>
                <td scope="col" class="fw-medium bg-success text-white">{{data.team1_winningPoints}}</td>
                <td scope="col" class="fw-medium bg-success text-white">T1%</td>
                <td scope="col" class="fw-medium bg-success text-white">Winners</td>
                <td scope="col" class="fw-medium bg-success text-white">T2%</td>
                <td scope="col" class="fw-medium bg-success text-white">{{data.team2_winningPoints}}</td>
                <td scope="col" class="fw-medium bg-success text-white">{{data.player3_winningPoints}}</td>
                <td scope="col" class="fw-medium bg-success text-white">{{data.player4_winningPoints}}</td>
                <td (click)="openData()" class=" bg-success text-white">
                    <div *ngIf="!showData[0]"><mat-icon>keyboard_arrow_down</mat-icon></div>
                    <div *ngIf="showData[0]"><mat-icon>keyboard_arrow_up</mat-icon></div>
                </td>
            </tr>
            <ng-container *ngFor="let item of winner">
                <tr *ngIf="showData[0]" class="text-center">
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team1_playerDetails['player1_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team1_playerDetails['player2_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium">
                        {{data.setDetail['team1_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium">
                        {{ ((data.setDetail['team1_' + item.id] /
                        data.team1_winningPoints) * 100) | number: '1.0-2' }}%
                    </td>
                    <td scope="col" class="fw-medium">
                        {{item.value}}
                    </td>
                    <td scope="col" class="fw-medium">
                        {{ ((data.setDetail['team2_' + item.id] /
                        data.team2_winningPoints) * 100) | number: '1.0-2' }}%
                    </td>
                    <td scope="col" class="fw-medium">
                        {{data.setDetail['team2_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team2_playerDetails['player1_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team2_playerDetails['player2_'+item.id]}}
                    </td>
                    <td scope="col"></td>
                </tr>
            </ng-container>

            <tr class="text-center">
                <td scope="col" class="fw-medium bg-danger text-white">{{data.player1_errorPoints}}</td>
                <td scope="col" class="fw-medium bg-danger text-white">{{data.player2_errorPoints}}</td>
                <td scope="col" class="fw-medium bg-danger text-white">{{data.team1_errorPoints}}</td>
                <td scope="col" class="fw-medium bg-danger text-white">T1%</td>
                <td scope="col" class="fw-medium bg-danger text-white">Error</td>
                <td scope="col" class="fw-medium bg-danger text-white">T2%</td>
                <td scope="col" class="fw-medium bg-danger text-white">{{data.team2_errorPoints}}</td>
                <td scope="col" class="fw-medium bg-danger text-white">{{data.player3_errorPoints}}</td>
                <td scope="col" class="fw-medium bg-danger text-white">{{data.player4_errorPoints}}</td>
                <td (click)="openData1()" class="bg-danger text-white">
                    <div *ngIf="!showData[1]"><mat-icon>keyboard_arrow_down</mat-icon></div>
                    <div *ngIf="showData[1]"><mat-icon>keyboard_arrow_up</mat-icon></div>
                </td>
            </tr>
            <ng-container *ngFor="let item of error">
                <tr *ngIf="showData[1]" class="text-center">
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team1_playerDetails['player1_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team1_playerDetails['player2_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium">
                        {{data.setDetail['team1_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium">
                        {{ ((data.setDetail['team1_' + item.id] / data.team1_errorPoints)
                        * 100) | number: '1.0-2' }}%
                    </td>
                    <td scope="col" class="fw-medium">
                        {{item.value}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{ ((data.setDetail['team2_' + item.id] / data.team2_errorPoints)
                        * 100) | number: '1.0-2' }}%
                    </td>
                    <td scope="col" class="fw-medium">
                        {{data.setDetail['team2_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team2_playerDetails['player1_'+item.id]}}
                    </td>
                    <td scope="col" class="fw-medium text-uppercase">
                        {{data.team2_playerDetails['player2_'+item.id]}}
                    </td>
                    <td scope="col" class=""></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>