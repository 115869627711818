import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-recent-matches',
  templateUrl: './recent-matches.component.html',
  styleUrls: ['./recent-matches.component.css']
})
export class RecentMatchesComponent {

  @Input() matchlist: any
  @Input() playerResult: any
  @Input() teamMatches: any

  Highcharts = Highcharts;
  wonChart!: {};
  lostChart!: {};

  constructor() {
    setTimeout(() => {
      this.wonChart = {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
          },
          width:300,
          height:300,
        },
        title: {
          text: 'Points Won',
        },
        tooltip: {
          pointFormat: '<b>Points:</b> {point.y}',
        },
        plotOptions: {
          pie: {
            colors: ['#7cb5ec', '#90ed7d'], // Define custom colors for the chart
          },
        },
        series: [
          {
            type: 'pie',
            innerSize: '65%',
            name: 'Data',
            data: [
              {
                name: 'Points Won by You',
                y: +this.playerResult?.Points_won_player,
              },
              {
                name: 'Points Lost Opp',
                y: +this.playerResult?.Points_lost_opp,
              },
            ],
          },
        ],
      };
      
      this.lostChart = {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45
          },
          width:300,
          height:300,
        },
        title: {
          text: 'Points Lost'
        },
        tooltip: {
          pointFormat: '<b>Points:</b> {point.y}',
        },
        plotOptions: {
          pie: {
            colors: ['#f15c80', '#e4d354'], // Define custom colors for the chart
          },
        },
        series: [{
          type: 'pie',
          innerSize: '65%',
          name: 'Data',
          data: [
            {
              name: 'Scored By Opp',
              y: +this.playerResult?.Points_won_opp,
            },
            {
              name: 'Lost By You',
              y: +this.playerResult?.Points_lost_player,
            },
          ]
        }]
      };
    }, 1000);
  }


}
