<div id="main" class="container">
  <div class="card border-0 px-2 overflow-hidden bg-body-tertiary rounded cursor-pointer">
    <nav class="navbar py-0  position-relative justify-content-center" style="margin-bottom: 0px !important;">
      <div class="navbar-header">
        <ul class="d-flex justify-content-center align-items-center match-nav gap-4">
          <li style="" *ngFor="let sport of SportsList">
            <ng-container *ngIf="orgCode=='SR' else SKA">
            <span class="logo active" (click)="changeTab(sport.type)">
              <span
                matTooltipPosition="above"
                matTooltip="{{ sport.type | uppercase }}"
                aria-label="Button that displays a tooltip when focused or hovered over"
                class="circle-bg"
              >
                <img
                  src="/assets/{{ sport.game }}.png"
                  alt=""
                  class="circle-img border-bottom img-fluid pb-1"
                  [class.active-tab]="sport.active === true"
                />
              </span>
            </span>
          </ng-container>
          <ng-template #SKA>
            <ng-container *ngIf="sport.type=='cricket'">
              <span class="logo active" (click)="changeTab(sport.type)">
                <span
                  matTooltipPosition="above"
                  matTooltip="{{ sport.type | uppercase }}"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                  class="circle-bg"
                >
                  <img
                    src="/assets/{{ sport.game }}.png"
                    alt=""
                    class="circle-img border-bottom img-fluid pb-1"
                    [class.active-tab]="sport.active === true"
                  />
                </span>
              </span>
            </ng-container>

          </ng-template>

          </li>
        </ul>
      </div>
    </nav>
    <div class="tab-content" style="background-color: white;">
      <div id="series" class="tab-pane fade in show active">
        <ng-container>
          <div *ngIf="matchResult.length > 0; else noResults">
            <ng-container *ngFor="let data of matchResult; let i = index">
              <div class="score-card"  *ngIf="i < 5">
                <app-mini-matches [data]="data" [length]="matchResultLength" [selectedType]="selectedType" ></app-mini-matches>
              </div>
            </ng-container>
            <div class="d-flex justify-content-end">
              <h5 *ngIf="matchResultLength >= 5" class="text-end fw-bolder more " (click)="navigateToMatches('UPCOMING')">MORE<i class="bi bi-chevron-right"></i></h5>
            </div>
          </div>
          <ng-template #noResults>
            <app-mini-matches [length]="matchResultLength"></app-mini-matches>
          </ng-template>
        </ng-container>

      </div>
    </div>
  </div>

</div>
