import { Component, OnInit } from '@angular/core';
import { TennisService } from 'src/app/service/tennis.service';
import { PlayersScoreDetail } from 'src/app/utils/tennisInterface';
@Component({
  selector: 'app-tennis-card-detail',
  templateUrl: './tennis-card-detail.component.html',
  styleUrls: ['./tennis-card-detail.component.css']
})
export class TennisCardDetailComponent implements OnInit {

  team1: any;
  team2: any
  team1playerDetails: any;
  team2playerDetails: any;
  tennisMatchList: any;
  index: number = 0;
  aces = 0;
  accumulatedData: any;
  team1SetDetails: any;
  team2SetDetails: any;
  selectedSet: any = 'all';
  scoreDetail: any;
  team1Data: any;
  team2Data: any;
  createAccumulateObj(): PlayersScoreDetail {
    return {
      player1: '',
      player1_name: '',
      player2: '',
      player2_name: '',
      team_id: '',
      team_name: '',
      set1: 0,
      set2: 0,
      set3: 0,
      set4: 0,
      set5: 0,
      sets_won: 0,
      player1_score: 0,
      player2_score: 0,
      player1_aces: 0,
      player2_aces: 0,
      player1_winner: 0,
      player2_winner: 0,
      player1_double_fault: 0,
      player2_double_fault: 0,
      player1_tie_breaker_points: 0,
      player2_tie_breaker_points: 0,
      player1_first_serve: 0,
      player2_first_serve: 0,
      player1_total_serve: 0,
      player2_total_serve: 0,
      player1_fore_hand_positive: 0,
      player1_fore_hand_negative: 0,
      player2_fore_hand_positive: 0,
      player2_fore_hand_negative: 0,
      player1_back_hand_positive: 0,
      player1_back_hand_negative: 0,
      player2_back_hand_positive: 0,
      player2_back_hand_negative: 0,
      player1_return_point_positive: 0,
      player1_return_point_negative: 0,
      player2_return_point_positive: 0,
      player2_return_point_negative: 0,
      player1_overhead_shot_positive: 0,
      player2_overhead_shot_positive: 0,
      player1_overhead_shot_negative: 0,
      player2_overhead_shot_negative: 0,
      player1_over_head_smash_positive: 0,
      player2_over_head_smash_positive: 0,
      player1_over_head_smash_negative: 0,
      player2_over_head_smash_negative: 0,
      player1_passing_shot_positive: 0,
      player2_passing_shot_positive: 0,
      player1_passing_shot_negative: 0,
      player2_passing_shot_negative: 0,
      player1_lob_shot_positive: 0,
      player2_lob_shot_positive: 0,
      player1_lob_shot_negative: 0,
      player2_lob_shot_negative: 0,
      player1_drop_shot_positive: 0,
      player2_drop_shot_positive: 0,
      player1_drop_shot_negative: 0,
      player2_drop_shot_negative: 0,
      player1_volley_positive: 0,
      player2_volley_positive: 0,
      player1_volley_negative: 0,
      player2_volley_negative: 0,
      player1_half_volley_positive: 0,
      player2_half_volley_positive: 0,
      player1_half_volley_negative: 0,
      player2_half_volley_negative: 0,
      player1_net_cross: 0,
      player2_net_cross: 0,
      player1_over_the_net_cross: 0,
      player2_over_the_net_cross: 0
    };
  }
  constructor(private tennisService: TennisService) { }

  ngOnInit() {
    // this.getTennisPlayerScoreDetail();
  }

  setData(data: any) {
    this.selectedSet = data;
    if (this.selectedSet != 'all') {
      this.index = data - 1;
    }
    this.validateAccumulate();
  }

  validateAccumulate() {
    if (this.team1 != undefined && this.team2 != undefined) {
      this.accumulateTeam1player1Details();
     }
  }

  getTennisPlayerScoreDetail() {
    // this.getTennisMatchList();
    this.tennisService.getTennisPlayerScoreDetail().subscribe(data => {
      this.team1 = Array.from(data.result.reduce((result: any, record: any) => {
        const currentTeamDetail = JSON.parse(decodeURIComponent(record.team1_detail));

        if (result.has(record.set_no)) {
          let teamDetail = result.get(record.set_no);

          for (const key in teamDetail) {
            if (teamDetail.hasOwnProperty(key) && currentTeamDetail.hasOwnProperty(key) && teamDetail[key] < currentTeamDetail[key]) {
              teamDetail[key] = currentTeamDetail[key];
            }
            result.set(record.set_no, teamDetail);
          }
        } else {
          result.set(record.set_no, currentTeamDetail);
        }
        return result;
      }, new Map()));


      this.team2 = Array.from(data.result.reduce((result: any, record: any) => {
        const currentTeamDetail = JSON.parse(decodeURIComponent(record.team2_detail));

        if (result.has(record.set_no)) {
          let teamDetail = result.get(record.set_no);

          for (const key in teamDetail) {
            if (teamDetail.hasOwnProperty(key) && currentTeamDetail.hasOwnProperty(key) && teamDetail[key] < currentTeamDetail[key]) {
              teamDetail[key] = currentTeamDetail[key];
            }
            result.set(record.set_no, teamDetail);
          }
        } else {
          result.set(record.set_no, currentTeamDetail);
        }
        return result;
      }, new Map()));

      this.validateAccumulate();
    })
  }

  // getTennisMatchList() {
  //   this.tennisService.getTennisMatchList().subscribe(data => {
  //     this.tennisMatchList = data.result;
  //   })
  // }

  accumulateTeam1player1Details() {
    if (this.selectedSet == 'all') {
      let accumulatedData1 = this.createAccumulateObj();
      let accumulatedData2 = this.createAccumulateObj();
      const keys = ['aces', 'overhead_shot_positive', 'half_volley_positive', 'drop_shot_positive',
        'volley_positive', 'lob_shot_positive', 'return_point_positive', 'over_head_smash_positive',
        'passing_shot_positive', 'winner', 'overhead_shot_negative', 'half_volley_negative', 'drop_shot_negative',
        'volley_negative', 'lob_shot_negative', 'return_point_negative', 'over_head_smash_negative',
        'passing_shot_negative', 'double_fault'];

      this.team1.forEach((data: any) => {
        const team1player1 = data[1];
        keys.forEach((key: any) => {
          (accumulatedData1 as any)['player1_' + key] += team1player1['player1_' + key];
        });
      });
      this.team2.forEach((data: any) => {
        const team2player1 = data[1];
        keys.forEach((key: any) => {
          (accumulatedData2 as any)['player1_' + key] += team2player1['player1_' + key];
        });
      });
      this.team1SetDetails = accumulatedData1;
      this.team2SetDetails = accumulatedData2;
    }

    if (this.selectedSet != 'all') {
      this.team1SetDetails = this.team1[this.index][1];
      this.team2SetDetails = this.team2[this.index][1];
    }
  }
}
