import { Component, Input } from '@angular/core';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-boundary-tracker',
  templateUrl: './boundary-tracker.component.html',
  styleUrls: ['./boundary-tracker.component.css']
})
export class BoundaryTrackerComponent {
  boundary: any;
  team1Id: any;
  team2Id: any;
  @Input() matchId: any;
  innings :any = 'All';
  team: any;
  team1: any;
  team2: any;
  team1innings: any;
  team2innings: any;
  teamID:any;
  tempFours: any;
  Fours: any;
  tempSixes: any;
  Sixes: any;
  boundaryFours: any;
  boundarySixers: any;
  team1Active: boolean = false;
  team2Active: boolean = false;
  isboundary: boolean=false;
  isBoundarySixers:boolean = false;
  intervel:any;  
  allBoundarys:boolean= false;
  constructor(private scoreService: CricketScoreCardService) {
  }


  
  ngOnInit(): void {
    this.getteamsdetail();
    // this.intervel = setInterval(() => {
    //   this.getteamsdetail();
    // }, 5000);
    // if(this.matchStatus == 'C'){
    //   clearInterval(this.intervel);
    // }
  }

  // ngOnDestroy(): void {
  //   if (this.intervel) {
  //     clearInterval(this.intervel);
  //   }
  // }

  getteamsdetail() {
    this.scoreService.getTeamNamesForMultiDay(this.matchId).subscribe((resp: any) => {
      this.team=resp.result
      this.team1 = resp.result[0].Team_Name;
      this.team2 = resp.result[1].Team_Name;
      this.team1Id=resp.result[0].team_id;
      this.team2Id=resp.result[1].team_id;
      this.team1innings = resp.result[0].innings;
      this.team2innings = resp.result[1].innings;
      this.changeTab(this.innings);
    });
  }

  changeTab(event: any) {
    this.innings=event;
    if(this.innings==1){
    this.teamID=this.team1Id;
    this.team1Active = true;
    this.team2Active = false;
    this.allBoundarys = false;
    }else if(this.innings==2){
    this.teamID=this.team2Id;
    this.team1Active = false;
    this.team2Active = true;
    this.allBoundarys = false;
    }else if("ALL") {
      this.teamID="ALL"
      this.team1Active = false;
      this.team2Active = false;
      this.allBoundarys = true;
    }
    this.getboundarydetails(this.teamID,this.innings);
  }

  getboundarydetails(data:string,innings:any){
    this.teamID=data;
    this.scoreService.getBoundaryDetails(this.matchId,this.teamID,innings).subscribe((data:any)=>{
      this.boundary=data.result;
      this.boundaryFours=this.boundary.filter((data:any)=>data.fours !='0')
      if (this.boundaryFours.length>0){
        this.isboundary=true;
      }else{
        this.isboundary=false;
      }
      this.boundarySixers=this.boundary.filter((data:any)=>data.sixers !='0')
      if (this.boundarySixers.length>0){
        this.isBoundarySixers=true;
      }else{
        this.isBoundarySixers=false;
      }
      this.tempFours=0;
      this.tempSixes=0;
       for (let i = 0; i < this.boundary.length; i++) {
        this.tempFours = this.tempFours + parseInt(this.boundary[i].fours);
        this.tempSixes = this.tempSixes + parseInt(this.boundary[i].sixers);
      }
      this.Fours=this.tempFours;
      this.Sixes=this.tempSixes;
    });
  }
 


}
