<footer class="footer" style="background-color: #1f5d74;" *ngIf="orgCode==='SR'">
    <div class="footer-top py-5 py-lg-4 ">
        <div class="container">
            <div class="row">
                <div class=" col-6 col-md-3 col-lg-3 mb-2 mobile-view-hide">
                    <div class="foo-widget foo-navigation">                        <h3 class="widget-title">Features</h3>
                            <div class="widgets" style="margin-bottom: 10px;">
                                <ul>
                                    <li><a>Players</a></li>
                                    <li><a>Teams</a></li>
                                    <li><a>Community</a></li>
                                    <li><a>Corporates</a></li>
                                    <li><a>Sports Academy</a></li>
                                    <li><a>Tournaments & Events</a></li>
                                </ul>
                            </div>
                    </div>
                </div>
                <div class=" col-6 col-md-3 col-lg-3 mb-2 mobile-view-hide">
                    <div class="foo-widget foo-navigation">
                        <h3 class="widget-title">Support</h3>
                        <div class="widgets" style="margin-bottom: 30px;" >
                            <ul>
                                <li><a class="links" href="mailto:support@sportsruler.com">Email: support@sportsruler.com</a></li>
                                <li><a class="links" [href]="getDynamicUrl('/HeaderContent/ProductServices')">Product / Services</a></li>
                                <li><a class="links" [href]="getDynamicUrl('/HeaderContent/Terms&Condition')">Terms &amp; Conditions</a></li>
                                <li><a class="links" [href]="getDynamicUrl('/HeaderContent/PrivacyPolicy')">Privacy Policy</a></li>
                                <li><a class="links" [href]="getDynamicUrl('/HeaderContent/Features')">Features</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-3 mb-2 mobile-view-hide">
                    <div class="foo-widget foo-navigation">
                        <h3 class="widget-title" >Sports Ruler</h3>
                        <div class="widgets" style="margin-bottom: 30px;" >
                        <ul >
                            <li><a >Advertise with us</a></li>
                            <li><a class="links" href="/videos">SportsRuler Tv</a></li>
                            <li><a >Live scores and Streaming</a></li>
                            <li><a class="links" href="/HeaderContent/ContactUs">Contact us</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-3 mb-2 foo-widget foo-social text-center text-lg-start  mobile-view-hide">
                    <h3 class="widget-title" >Follow Us</h3>
                    <div  class="ps-0">
                        <a href="https://www.facebook.com/SportsRulerOfficial" target="_blank"><i
                            class="bi bi-facebook"></i></a>
                            <a href="https://twitter.com/SportRuler" target="_blank">
                              <img src="/assets/images/twitter.png" alt="SportsRuler" class="twitter">
                            </a>
                          <a href="https://www.instagram.com/sportsruler" target="_blank"><i
                            class="bi bi-instagram"></i></a>
                    <a href="https://www.youtube.com/channel/UCs5exP4BcgkcVpraVgMMvOQ" target="_blank"><i
                            class="bi bi-youtube"></i></a>
                    </div>
              </div>
                <div class=" col-6 col-md-3 col-lg-3 web-view-hide" >
                  <div class="foo-widget foo-navigation text-center">
                      <h3 class="widget-title">Support</h3>
                      <div class="widgets">
                          <ul>
                              <li><a class="links"  href="mailto:support@sportsruler.com">Email : support@sportsruler.com</a></li>
                              <li><a class="links" href="/HeaderContent/Contact Us">Contact us</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class=" col-6 col-md-3 col-lg-3 web-view-hide" >
                <div class="foo-widget foo-navigation text-center">
                <h3 class="widget-title">Features</h3>
                <div class="widgets" style="margin-bottom: 10px;">
                    <ul>
                        <li><a>Players</a></li>
                        <li><a>Teams</a></li>
                        <li><a>Community</a></li>
                        <li><a>corporates</a></li>
                        <li><a>sports Academy</a></li>
                        <li><a >Tournaments & Events</a></li>
                    </ul>
                </div>
            </div>
            </div>
              <div class="foo-widget foo-social text-center text-lg-start my-3 web-view-hide">
                <a href="https://www.facebook.com/SportsRulerOfficial" target="_blank"><i
                        class="bi bi-facebook"></i></a>
                <a href="https://twitter.com/SportRuler" target="_blank">
                    <img src="/assets/images/twitter.png" alt="SportsRuler" class="twitter">
                </a>
                <a href="https://www.instagram.com/sportsruler" target="_blank"><i
                class="bi bi-instagram"></i></a>
                <a href="https://www.youtube.com/channel/UCs5exP4BcgkcVpraVgMMvOQ" target="_blank"><i
                        class="bi bi-youtube"></i></a>
            </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="footer-copyright">
            <div class="row">
                <p class="color_white text-center">
                    Copyright © {{currentYear}} <a class="neon-text links" href="http://prodian.co.in" target="_blank" style="color: #f6e824 !important;">Prodian infotech
                        pvt.Ltd </a> All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
    <div class="container-fluid newFooter rounded-top-4 d-lg-none" style="background:var(--secondary-color);">
        <ul class="d-flex justify-content-evenly ps-0 m-0">
            <li class="footerItem list-group-item p-2" [class.active-tab]="item.active === true"
                *ngFor="let item of FooterArray">
                <a *ngIf="item.tab !== 'More'" href="{{ item.routerLink }}" class="footerItem links text-decoration-none">
                    <i [class]="item.icon" class="d-block text-center fs-6"></i>
                    {{item.tab}}
                </a>
                <a *ngIf="item.tab === 'More'" class="footerItem footerItem text-decoration-none" (click)="openNav()">
                    <i *ngIf="showNav == false" [class]="item.icon" class="d-block text-center fs-6"></i>
                    <span *ngIf="showNav == false">{{ item.tab }}</span>
                    <i *ngIf="showNav == true" class="bi bi-x-circle-fill d-block text-center fs-6"></i>
                    <span *ngIf="showNav == true">{{item.tab}}</span>
                </a>
            </li>
        </ul>
    </div>


</footer>

