import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-batsman-scorecard',
  templateUrl: './batsman-scorecard.component.html',
  styleUrls: ['./batsman-scorecard.component.css']
})
export class BatsmanScorecardComponent {

  @Input() innStats: any;
  @Input() cricketMatchSumm: any;
  @Input() fallOfWickets: any;
  wagonCoordinateForplayers: any;
  showWagon : boolean[] = [];
  @Input() matchId: any;
  @Input() innings:any;
  batsmanUserId: any;
  wagonStatus: any;
  showNoDataFound: boolean[] = [];
  ballHit: any;
  wagonFor = 'player';
  batsmanId: any;
  extrasFirstTotal:any;
  extrasSecondTotal:any;
  extrasthirdTotal: any;
  extrasfourthTotal: any;
  didNotBatInTeamOne: any;
  didNotBatInTeamTwo: any;
  @Input() innStatsDNB: any;
  didNotBat: any;
  batsmansumm: any[] = [];
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  constructor(private scorecardService: CricketScoreCardService,private services:CommonService,private dialog:MatDialog) {
  }
  ngOnInit() {
    setTimeout(() => {
      this.extrasFirstTotal=this.cricketMatchSumm?.innings_1_byes+this.cricketMatchSumm?.innings_1_wides+this.cricketMatchSumm?.innings_1_legbye+this.cricketMatchSumm?.innings_1_noballs+this.cricketMatchSumm?.innings_1_penalty;
      this.extrasSecondTotal=this.cricketMatchSumm?.innings_2_byes+this.cricketMatchSumm?.innings_2_wides+this.cricketMatchSumm?.innings_2_legbye+this.cricketMatchSumm?.innings_2_noballs+this.cricketMatchSumm?.innings_2_penalty;
      this.extrasthirdTotal=this.cricketMatchSumm?.innings_3_byes+this.cricketMatchSumm?.innings_3_wides+this.cricketMatchSumm?.innings_3_legbye+this.cricketMatchSumm?.innings_3_noballs+this.cricketMatchSumm?.innings_3_penalty;
      this.extrasfourthTotal=this.cricketMatchSumm?.innings_4_byes+this.cricketMatchSumm?.innings_4_wides+this.cricketMatchSumm?.innings_4_legbye+this.cricketMatchSumm?.innings_4_noballs+this.cricketMatchSumm?.innings_4_penalty;
    }, 10);


  }
  openWagon(data: any, index: number) {
    this.batsmanId = data;
    this.scorecardService.getWagonCoordinateForPlayer(this.matchId, data).subscribe((data: any) => {
      if(data.status =="TRUE"){
      this.wagonCoordinateForplayers = data.result;
      this.ballHit=this.wagonCoordinateForplayers.filter((data:any)=>data.where_hit_x !='0' && data.where_hit_y !='0')
      this.showWagon[index] = !this.showWagon[index]
      } else {
        this.ballHit= [];
        this.showWagon[index] = !this.showWagon[index]
      }
    });
    this.batsmanInningsSumm(this.batsmanId,index );
  }
  batsmanInningsSumm(userId: any, index: number) {
    this.services.batsmanInningsSumm(this.matchId, userId).subscribe((data: any) => {
      if (data.status === "TRUE") {
        // this.batsmansumm[index] = data.result; // Store data in the index
        this.batsmansumm[index]=data.result.filter(
          (item: any) => item.videoURL && item.videoURL.trim() !== ''
        );
      } else {
        this.showNoDataFound[index] = true;
      }
    });
  }
  videoScorecard(data:any){
    if(data!="Y"|| data!="N"){
      this.dialogRef= this.dialog.open(CommonAlertsComponent, {
        data: { identifier: 'videoScorecard',url:data },
        disableClose:true
      });
    }
  }

  isWicket(run: string): boolean {
    return isNaN(Number(run)) && (run.includes('Wk') || run.includes('B')|| run.includes('Lb'));
  }

openVideoModal(userId: number, type: string, index: number): void {
  const ballType = type === 'fours' ? '4' : '6';

  this.services.batsmanInningsSumm(this.matchId, userId).subscribe((data: any) => {
    if (data.status === "TRUE") {
      const relevantVideos = data.result
        .filter((ball: any) => ball.runs === ballType && ball.videoURL.startsWith('http'))
        .map((ball: any) => ({
          ...ball,
          overBall: `${ball.overs}.${ball.ball_no}`,
        }));

      if (relevantVideos.length > 0) {
        this.dialogRef = this.dialog.open(CommonAlertsComponent, {
          data: { identifier: 'RunVideo', videos: relevantVideos },
          disableClose: true
        });
      } else {
        console.log(`No videos available for ${type} at index ${index}`);
      }
    } else {
      console.error('Failed to fetch innings summary');
    }
  });
}




}
