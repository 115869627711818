<div class="container" *ngIf="data">
    <div class="card">
        <div class="card-body py-0 px-1 pt-1">
            <p class="live text-start text-danger ms-2 mb-0" *ngIf="data.match_status=='P' || data.match_status=='p'"><i class="bi bi-circle-fill me-1"></i> LIVE</p>
            <p class="live m-0 text-start text-uppercase ms-2" *ngIf="data.match_status=='C' || data.match_status=='c'">Result</p>
            <div class="d-flex justify-content-between px-2">
                <h5 class="m-0">{{data.match_date}}</h5>
                <h5 class="m-0">{{data.match_time}}</h5>
            </div>
            <h4 class="venue m-0 text-center mb-1">{{data.match_venue}}</h4>
            <div class="m-0 text-center d-flex justify-content-center align-items-center">
                <img src="assets/img/tableTennis.png" alt="" class="logo p-0">
               
                    <ng-container *ngIf="data.match_type == 'D';">
                        <div class="col-5 p-0 playerName ms-1">{{ data.team1_detail[0]}}</div>
                        <div class="col-1"> & </div>
                        <div class="col-5 p-0 playerName">{{ data.team1_detail[1]}}</div>
                    </ng-container>
                        <div class="playerName ms-1" *ngIf="data.match_type === 'S'">{{ data.team1_detail}}</div>
            </div>
            <div class="text-center fw-normal" style="font-family: 'Assist-Regular';">Vs</div>
            <div class="m-0 text-center d-flex justify-content-center align-items-center">
                <img src="assets/img/tableTennis.png" alt="" class="logo p-0">
               
                    <ng-container *ngIf="data.match_type == 'D';">
                        <div class="col-5 p-0 playerName ms-1">{{ data.team2_detail[0]}}</div>
                        <div class="col-1"> & </div>
                        <div class="col-5 p-0 playerName">{{ data.team2_detail[1]}}</div>
                    </ng-container>
                        <div class="playerName ms-1" *ngIf="data.match_type === 'S'">{{ data.team2_detail}}</div>
            </div>
            <div class="points text-center text-nowrap fs-5 p-1">
                <p class="m-0">{{data.teamOnePoint}}&nbsp; - &nbsp;{{data.teamTwoPoint}}</p>
            </div>

        </div>
        <div class="card-footer text-center border-0 p-1 d-flex justify-content-center align-items-center"
            style="height: 45px;">
            <h4 class="result m-0 col-12 text-uppercase">{{data.matchResult}} won the match
            </h4>
        </div>
    </div>
</div>