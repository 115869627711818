<div *ngIf="data.identifier === 'forgotPassword'">
    <div mat-dialog-content>A Temporary Password has been send to your register E-mail </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="matDialogConfromation('forgotPassword')" >Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'CheckPassword'">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;">Old Password is incorrect</div>
    <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-button mat-dialog-close (click)="matDialogConfromation('CheckPasswordSuccess')" class="g-fold " style="font-size: 80%;" class="g-fold">Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'oldAndNewPassword'">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;">New password & old password can't be the same</div>
    <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-button mat-dialog-close (click)="matDialogConfromation('oldAndNewPasswordSuccess')" class="g-fold " style="font-size: 80%;" class="g-fold">Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'loader'">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;">
        <div class="loader">
            <app-loader class="d-flex justify-content-center  mx-auto"></app-loader>
        </div>
    </div>
</div>
<div *ngIf="data.identifier === 'Password Updated Success'">
    <div mat-dialog-content>Password updated successfully </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="matDialogConfromation('Password Updated Success')" >Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'Password Updated Faild'">
    <div mat-dialog-content>unable to update the Password</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="matDialogConfromation('')" >Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'search'" class="search">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;" class="w-100">
        <div mat-dialog-actions class="p-0 position-absolute" style="z-index: 1;right: 14px;top: 3px;">
            <div mat-button  (click)="matDialogConfromation('')" >    
                <i class="bi bi-x-lg text-end searchX"></i>
            </div>
        </div>
        <div >
            <app-home-search></app-home-search>
        </div>
    </div>
</div>