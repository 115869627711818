<div class="container col-sm-12">
  <div class="row">
    <div class="">
      <app-article-card-one [videoList]="videoList"></app-article-card-one>
    </div>
  </div>
  <div class="row">
    <div class="">
      <app-article-card-two [videoList]="videoList"></app-article-card-two>
    </div>
  </div>
</div>  