import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-home-section-live-score-card',
  templateUrl: './home-section-live-score-card.component.html',
  styleUrls: ['./home-section-live-score-card.component.css'],
})
export class HomeSectionLiveScoreCardComponent {
  @Input() data:any;
  orgCode:any

  constructor(private commonService: CommonService, private router : Router,private orgFilter:OrgcodeFilterService) {}

  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath

    
  }

  goToScoreCardPage() {
    this.router.navigateByUrl('/scorecard')
  }


  goToScoreCardDetailPage(matchId : any) {
    this.router.navigate([`${this.orgCode}/scorecardDetails`,matchId]);
  }
}
