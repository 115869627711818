import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-pitch-map',
  templateUrl: './pitch-map.component.html',
  styleUrls: ['./pitch-map.component.css']
})
export class PitchMapComponent {
  pitchMapResponse: any[] = [];
  matchId:any;
  pitchMapArr: any[] = [];
  pitchX: number[] = [];
  pitchY: number[] = [];
  currentCoordinates: any[] = [];
  pitchNotAvailable:boolean= false;
  ballHit:any
  intervel:any;
  selectedTabIndex = 0;
  @Input() teamOneName:any;
  @Input() teamTwoName:any;
  @Input() pitchMapFor:any;
  @Input() pitchMapResponseForPlayer:any;
  @Input() showTeams=true
  constructor(private scoreCardService: CricketScoreCardService,private route :ActivatedRoute) {}
  ngOnInit(){
    this.matchId = this.route.snapshot.paramMap.get('matchId');
    if(this.pitchMapFor =='player'){
      this.getPitchMapForPlayer();
    } else {
      this.getWagonData(1);
      // this.intervel = setInterval(() => {
      //   this.getWagonData(this.selectedTabIndex == 2 ? 2 : 1);
      // }, 5000);
   }
    // if(this.matchStatus == 'C'){
    //   clearInterval(this.intervel);
    // }
  }
  // ngOnDestroy(): void {
  //   if (this.intervel) {
  //     clearInterval(this.intervel);
  //   }
  // }

  getWagonData(innings: any) { 
    this.pitchMapResponse= [];
    this.scoreCardService.getWagOnWheelById(this.matchId,innings).pipe(
      catchError((err, caught) => caught)
    ).subscribe((response: any) => {   
      this.ballHit = response.result;
      this.pitchMapResponse = this.ballHit.filter((data:any)=> data.where_pitch_x != '0.0' && data.where_pitch_x != '0.0' && data.where_pitch_x != null && data.where_pitch_y != null );
      if(this.pitchMapResponse?.length>0){        
        this.pitchPlot(this.pitchMapResponse);
        this.pitchNotAvailable = false;
        } else {
          this.pitchNotAvailable = true;
        } 
    });
  
  }
  changePitchMapTab(event: any) {    
    this.pitchMapResponse = [];
    this.selectedTabIndex = event.index + 1;
    this.getWagonData(event.index + 1);
  }
  coordinates = [
    { x: 147, y: 106, text: 'YORKER' },
    { x: 147, y: 155, text: 'FULL' },
    { x: 147, y: 214, text: 'GOOD' },
    { x: 147, y: 312, text: 'SHORT' },
  ];

  lines = [
    { startX: 55, startY: 120, endX: 241, endY: 120, stroke: 'white' },
    { startX: 55, startY: 186, endX: 241, endY: 186, stroke: 'white' },
    { startX: 55, startY: 232, endX: 241, endY: 232, stroke: 'white' },
  ];

  pitchPlot(response: any) {  
    // if(response.length == 0){
    //   this.pitchNotAvailable = true;
    // }  
    this.currentCoordinates = [];
    this.pitchX =[];
    this.pitchY =[];
    
    this.pitchMapArr = response;
    this.pitchMapArr.forEach(res => {
      this.pitchX.push(res.where_pitch_x);
      this.pitchY.push(res.where_pitch_y);
    });

    for (let i = 0; i < this.pitchX.length; i++) {
      const currentPitchX = this.pitchX[i];
      const currentPitchY = this.pitchY[i];
      const pitchValue = this.isWithinAllowedRange(currentPitchX, currentPitchY);
      if (pitchValue) {
        this.currentCoordinates.push({ x: currentPitchX, y: currentPitchY });
      }
    }
  }

  isWithinAllowedRange(x: any, y: any) {
    return x >= 73 && x <= 220 && y >= 30 && y <= 385;
  }

  getPitchMapForPlayer(){
    this.pitchMapResponse = this.pitchMapResponseForPlayer;
    if(this.pitchMapResponse?.length>0){        
      this.pitchPlot(this.pitchMapResponse);
      this.pitchNotAvailable = false;
      } else {
        this.pitchNotAvailable = true;
      } 
  }

}
