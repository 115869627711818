import { Component, Input } from '@angular/core';
import { BadmintonService } from 'src/app/service/badminton.service';
import { BadmintonMatchDetails, PlayerScoreDetails, TeamSetDetails } from 'src/app/utils/badminton-interface';

@Component({
  selector: 'app-badminton-scorecard-detail',
  templateUrl: './badminton-scorecard-detail.component.html',
  styleUrls: ['./badminton-scorecard-detail.component.css']
})
export class BadmintonScorecardDetailComponent {
  badmintonMatchList: any;
  playerScoreList: any;
  setDetails: any[] = [];
  badmintonMatchDetails: BadmintonMatchDetails | undefined;
  team1_playerSetDetails: any;
  team2_playerSetDetails: any;
  activeTab: string = 'All';


  constructor(private badmintonService: BadmintonService) { }
  ngOnInit() {
    this.getBadmintonMatchList('1049', '3');
    this.getBadmintonPlayerScoreDetailForMatchForehand('1049', '3');
    this.displayStatus('All');
  }
  getBadmintonMatchList(matchId: string, sportId: string) {

    this.badmintonService.getBadmintonMatchList(matchId, sportId).subscribe((data: any) => {
      this.badmintonMatchList = data.result[0];
      this.setDetails = this.badmintonMatchList.setDetail;
      console.log("assign detail", this.setDetails);

    });
  }
  getBadmintonPlayerScoreDetailForMatchForehand(matchId: string, sportId: string) {
    this.badmintonService.getBadmintonPlayerScoreDetailForMatchForehand(matchId, sportId).subscribe((data: any) => {
      this.playerScoreList = data.result;
      this.team1_playerSetDetails = this.playerScoreList.reduce((total: Map<number, PlayerScoreDetails>, currentSet: any) => {
        const curTeamDetails: any = JSON.parse(decodeURIComponent(currentSet.team1_detail)) as PlayerScoreDetails;
        // console.log(curTeamDetails.player1_aces + "-----------" + curTeamDetails.player2_aces);
        if (total.has(currentSet.set_no)) {
          let teamDetails: any = total.get(currentSet.set_no);
          for (const key in teamDetails) {
            if (curTeamDetails.hasOwnProperty(key) && teamDetails[key] < curTeamDetails[key]) {
              teamDetails[key] = curTeamDetails[key];
            }
          }
          total.set(currentSet.set_no, teamDetails);
        } else {
          // console.log(currentSet.set_no, "+++++");
          total.set(currentSet.set_no, curTeamDetails);
        }
        // console.log(total);
        return total;
      }, new Map<number, PlayerScoreDetails>());

      this.team2_playerSetDetails = this.playerScoreList.reduce((total: Map<number, PlayerScoreDetails>, currentSet: any) => {
        const curTeamDetails: any = JSON.parse(decodeURIComponent(currentSet.team2_detail)) as PlayerScoreDetails;
        if (total.has(currentSet.set_no)) {
          let teamDetails: any = total.get(currentSet.set_no);
          for (const key in teamDetails) {
            if (curTeamDetails.hasOwnProperty(key) && teamDetails[key] < curTeamDetails[key]) {
              teamDetails[key] = curTeamDetails[key];
            }
          }
          total.set(currentSet.set_no, teamDetails);
        } else {
          // console.log(currentSet.set_no, "+++++");
          total.set(currentSet.set_no, curTeamDetails);
        }
        // console.log(total);
        return total;
      }, new Map<number, PlayerScoreDetails>());
      // console.log('Map content:', JSON.stringify(Array.from(this.team1_playerSetDetails.entries())));
      // console.log('Map content:', JSON.stringify(Array.from(this.team2_playerSetDetails.entries())));

    });
  }

  displayStatus(data: string) {
    console.log(data, "-------");

    this.activeTab = data;
    if (data == 'All') {
      setTimeout(() => {
        const { team1_WP, team2_WP, team1_EP, team2_EP } = this.calculatePoints();
        const totalSetDetail: TeamSetDetails = this.setDetails.reduce((total: any, currentSet: any) => {
          for (const key in total) {
            if (total.hasOwnProperty(key) && currentSet.hasOwnProperty(key)) {
              total[key] += Number(currentSet[key]);
            }
          }
          return total;
        }, {
          team1_aces: 0,
          team2_aces: 0,
          team1_net_cross: 0,
          team2_net_cross: 0,
          team1_service_fault: 0,
          team2_service_fault: 0,
          team1_drop_shot_negative: 0,
          team2_drop_shot_negative: 0,
          team1_smash_shot_negative: 0,
          team2_smash_shot_negative: 0,
          team1_smash_shot_positive: 0,
          team2_smash_shot_positive: 0,
          team1_serve_above_waist: 0,
          team2_serve_above_waist: 0,
          team1_unforced_error: 0,
          team2_unforced_error: 0,
          team1_clear_shot_negative: 0,
          team2_clear_shot_negative: 0,
          team1_drive_shot_positive: 0,
          team2_drive_shot_positive: 0,
          team1_delay: 0,
          team2_delay: 0,
          team1_smash: 0,
          team2_smash: 0,
          team1_drop_shot_positive: 0,
          team2_drop_shot_positive: 0,
          team1_double_hit: 0,
          team2_double_hit: 0,
          team1_score: 0,
          team2_score: 0,
          team1_drop_error: 0,
          team2_drop_error: 0,
          team1_drive_shot_negative: 0,
          team2_drive_shot_negative: 0,
          team1_drop: 0,
          team2_drop: 0,
          team1_clear_shot_positive: 0,
          team2_clear_shot_positive: 0,
          team1_over_the_net_cross: 0,
          team2_over_the_net_cross: 0,
          team1_stop_start: 0,
          team2_stop_start: 0,
          team1_distraction: 0,
          team2_distraction: 0,
        });
        const set1 = this.accumulatePlayerDetails(this.team1_playerSetDetails);
        const set2 = this.accumulatePlayerDetails(this.team2_playerSetDetails);
        this.badmintonMatchDetails = {
          team1_winningPoints: team1_WP,
          team2_winningPoints: team2_WP,
          team1_errorPoints: team1_EP,
          team2_errorPoints: team2_EP,
          team1_detail: this.badmintonMatchList.team1_detail,
          team2_detail: this.badmintonMatchList.team2_detail,
          setDetail: totalSetDetail,
          team1_playerDetails: set1,
          team2_playerDetails: set2,
          player1_winningPoints: this.calculatePlayerPoints(set1, 'player1'),
          player1_errorPoints: this.calculatePlayerErrorPoints(set1, 'player1'),
          player2_winningPoints: this.calculatePlayerPoints(set1, 'player2'),
          player2_errorPoints: this.calculatePlayerErrorPoints(set1, 'player2'),
          player3_winningPoints: this.calculatePlayerPoints(set2, 'player1'),
          player3_errorPoints: this.calculatePlayerErrorPoints(set2, 'player1'),
          player4_winningPoints: this.calculatePlayerPoints(set2, 'player2'),
          player4_errorPoints: this.calculatePlayerErrorPoints(set2, 'player2')
        }
        // console.log(this.badmintonMatchDetails, "----yyyy");

      }, 500);

    } else {
      const setIndex = parseInt(data, 10) - 1;
      const set = this.setDetails[setIndex];
      const set1 = this.getDetailsByKey(this.team1_playerSetDetails, data);
      const set2 = this.getDetailsByKey(this.team2_playerSetDetails, data);

      console.log(this.getDetailsByKey(this.team1_playerSetDetails, '1'));

      this.badmintonMatchDetails = {
        team1_winningPoints: this.calculateTeamPoints(set, 'team1'),
        team2_winningPoints: this.calculateTeamPoints(set, 'team2'),
        team1_errorPoints: this.calculateErrorPoints(set, 'team1'),
        team2_errorPoints: this.calculateErrorPoints(set, 'team2'),
        team1_detail: this.badmintonMatchList.team1_detail,
        team2_detail: this.badmintonMatchList.team2_detail,
        setDetail: set,
        team1_playerDetails: set1,
        team2_playerDetails: set2,
        player1_winningPoints: this.calculatePlayerPoints(set1, 'player1'),
        player1_errorPoints: this.calculatePlayerErrorPoints(set1, 'player1'),
        player2_winningPoints: this.calculatePlayerPoints(set1, 'player2'),
        player2_errorPoints: this.calculatePlayerErrorPoints(set1, 'player2'),
        player3_winningPoints: this.calculatePlayerPoints(set2, 'player1'),
        player3_errorPoints: this.calculatePlayerErrorPoints(set2, 'player1'),
        player4_winningPoints: this.calculatePlayerPoints(set2, 'player2'),
        player4_errorPoints: this.calculatePlayerErrorPoints(set2, 'player2')
      }
      // console.log(this.team1_playerSetDetails);
      // console.log(this.badmintonMatchDetails, "----------");
    }
  }
  getDetailsByKey(map: Map<string, PlayerScoreDetails>, keyToFind: string): PlayerScoreDetails | undefined {
    const details = map.get(keyToFind);
    if (details) {
      return details;
    }
    return undefined;
  }
  accumulatePlayerDetails(detailsMap: Map<string, PlayerScoreDetails>): PlayerScoreDetails {
    const accumulator: PlayerScoreDetails = {
      player1_aces: 0,
      player2_aces: 0,
      player1_winner: 0,
      player2_winner: 0,
      player1_smash: 0,
      player2_smash: 0,
      player1_drop: 0,
      player2_drop: 0,
      player1_service_fault: 0,
      player2_service_fault: 0,
      player1_net_cross: 0,
      player2_net_cross: 0,
      player1_over_the_net_cross: 0,
      player2_over_the_net_cross: 0,
      player1_unforced_error: 0,
      player2_unforced_error: 0,
      player1_drop_error: 0,
      player2_drop_error: 0,
      player1_smash_shot_positive: 0,
      player2_smash_shot_positive: 0,
      player1_smash_shot_negative: 0,
      player2_smash_shot_negative: 0,
      player1_drop_shot_positive: 0,
      player2_drop_shot_positive: 0,
      player1_drop_shot_negative: 0,
      player2_drop_shot_negative: 0,
      player1_clear_shot_positive: 0,
      player2_clear_shot_positive: 0,
      player1_clear_shot_negative: 0,
      player2_clear_shot_negative: 0,
      player1_drive_shot_positive: 0,
      player2_drive_shot_positive: 0,
      player1_drive_shot_negative: 0,
      player2_drive_shot_negative: 0,
      player1_double_hit: 0,
      player2_double_hit: 0,
      player1_distraction: 0,
      player2_distraction: 0,
      player1_delay: 0,
      player2_delay: 0,
      player1_stop_start: 0,
      player2_stop_start: 0,
      player1_serve_above_waist: 0,
      player2_serve_above_waist: 0,
      player1_high_serve: 0,
      player2_high_serve: 0,
      player1_low_serve: 0,
      player2_low_serve: 0
    };
    const keys = [
      'service_fault', 'net_cross', 'over_the_net_cross',
      'drive_shot_negative', 'double_hit', 'stop_start',
      'distraction', 'serve_above_waist', 'delay',
      'clear_shot_negative', 'drop_shot_negative',
      'smash_shot_negative', 'aces', 'clear_shot_positive',
      'drop_shot_positive', 'smash_shot_positive', 'drive_shot_positive', 'winner'];
    for (const [, details] of detailsMap.entries()) {
      for (const key of keys) {
        (accumulator as any)[`player1_${key}`] += (details as any)[`player1_${key}`];
        (accumulator as any)[`player2_${key}`] += (details as any)[`player2_${key}`];
      }

    }

    return accumulator;
  }

  private calculatePoints(): { team1_WP: number, team2_WP: number, team1_EP: number, team2_EP: number } {
    let team1_WP = 0, team2_WP = 0, team1_EP = 0, team2_EP = 0;

    for (const set of this.setDetails) {
      team1_WP += this.calculateTeamPoints(set, 'team1');
      team2_WP += this.calculateTeamPoints(set, 'team2');
      team1_EP += this.calculateErrorPoints(set, 'team1');
      team2_EP += this.calculateErrorPoints(set, 'team2');
    }

    return { team1_WP, team2_WP, team1_EP, team2_EP };
  }

  private calculateTeamPoints(set: any, team: 'team1' | 'team2'): number {
    const pointKeys = ['aces', 'clear_shot_positive', 'drop_shot_positive', 'smash_shot_positive', 'drive_shot_positive', 'winner'];
    return pointKeys.reduce((total, key) => total + Number(set[`${team}_${key}`]), 0);
  }

  private calculateErrorPoints(set: any, team: 'team1' | 'team2'): number {
    const errorKeys = ['service_fault', 'net_cross', 'over_the_net_cross', 'drive_shot_negative', 'double_hit', 'stop_start', 'distraction', 'serve_above_waist', 'delay', 'clear_shot_negative', 'drop_shot_negative', 'smash_shot_negative'];
    return errorKeys.reduce((total, key) => total + Number(set[`${team}_${key}`]), 0);
  }

  private calculatePlayerPoints(set: any, player: 'player1' | 'player2'): number {
    const pointKeys = ['aces', 'clear_shot_positive', 'drop_shot_positive', 'smash_shot_positive', 'drive_shot_positive', 'winner'];
    return pointKeys.reduce((total, key) => total + set[`${player}_${key}`], 0);
  }

  private calculatePlayerErrorPoints(set: any, player: 'player1' | 'player2'): number {
    const errorKeys = ['service_fault', 'net_cross', 'over_the_net_cross', 'drive_shot_negative', 'double_hit', 'stop_start', 'distraction', 'serve_above_waist', 'delay', 'clear_shot_negative', 'drop_shot_negative', 'smash_shot_negative'];
    return errorKeys.reduce((total, key) => total + set[`${player}_${key}`], 0);
  }
}
