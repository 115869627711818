import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TennisService } from 'src/app/service/tennis.service';

@Component({
  selector: 'app-tennis-scorecard',
  templateUrl: './tennis-scorecard.component.html',
  styleUrls: ['./tennis-scorecard.component.css']
})
export class TennisScorecardComponent {

  @Input() data : any;

  constructor(private router: Router) {}

  navigateToDetailPage() {
    this.router.navigateByUrl('/tennisDetail');
  }
}
