import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-playing-xi',
  templateUrl: './playing-xi.component.html',
  styleUrls: ['./playing-xi.component.css']
})
export class PlayingXiComponent {

  team1: any = [];
  team2: any = [];
  bothTeam: any = [];
  team1Details: any = [];
  team2Details: any = [];
  @Input()team1Id: any;
  @Input()team2Id: any;
  @Input() matchId: any;
  teams: any;
  team1cap: any;
  team2cap: any;
  team2keeper: any;
  team1keeper: any;
  playingXIimg:  string | ArrayBuffer | null = '/assets/user.png';
  team1subDetails: any=[];
  team2subDetails: any=[];
  orgCode:any;

  constructor(private scorecardService: CricketScoreCardService,private router:Router,private orgFilter:OrgcodeFilterService) {
  }

  ngOnInit(): void {
    this.getBothTeamData();
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  getBothTeamData() {
    this.scorecardService.getCricketMatchScoresAndPlayingEleven(this.matchId).subscribe((response : any) => {
      if (Array.isArray(response.result)) {
        this.bothTeam = response.result[0];
      }else{
      this.bothTeam = response.result.matchDetails;
      }
      this.teams=this.bothTeam;
      if (this.bothTeam.status === 'SUCCESS'||'TRUE') {
        // if (response.status === 'TRUE') {
        this.team1cap=this.bothTeam.team1_captain_id;
        this.team2cap=this.bothTeam.team2_captain_id;
        this.team1keeper=this.bothTeam.team1_wicket_keeper_id
        this.team2keeper=this.bothTeam.team2_wicket_keeper_id
        this.team1Id = this.bothTeam.team_1;
        this.team2Id = this.bothTeam.team_2;
      }
      
      this.getCricketPlayingEleven();
    })
  }
  getCricketPlayingEleven() {
    this.scorecardService.getCricketPlayingEleven(this.matchId, this.team1Id).subscribe((response: any) => {
      if (response.result.length > 0) {
        let isCaptainFound = false;
        let isKeeperFound = false;
        this.team1Details = response.result;
        this.team1Details.forEach((x: any) => {
          if (x.User_id == this.bothTeam.team1_captain_id) {
            this.team1cap = this.bothTeam.team1_captain_id;
            isCaptainFound = true;
          }
          if (x.User_id == this.bothTeam.team1_wicket_keeper_id) {
            this.team1keeper = this.bothTeam.team1_wicket_keeper_id;
            isKeeperFound = true;
          }
          // this.team1cap = x.User_id == this.bothTeam.team1_captain_id ? this.bothTeam.team1_captain_id : this.bothTeam.team2_captain_id;
          // this.team1keeper = x.User_id == this.bothTeam.team1_wicket_keeper_id ? this.bothTeam.team1_wicket_keeper_id : this.bothTeam.team2_wicket_keeper_id;
          if(x.is_substitute=="Y"){
            this.team1subDetails.push(x);
          }
        });
        if (!isCaptainFound) {
          this.team1cap = this.bothTeam.team2_captain_id;
        }
        
        if (!isKeeperFound) {
          this.team1keeper = this.bothTeam.team2_wicket_keeper_id;
        }
      }
    })

    this.scorecardService.getCricketPlayingEleven(this.matchId, this.team2Id).subscribe((response:any) => {
      if (response.result.length >0) {
        let isCaptainFound1 = false;
        let isKeeperFound1 = false;
        this.team2Details = response.result;
        this.team2Details.forEach((x: any) => {
          if (x.User_id == this.bothTeam.team2_captain_id) {
            this.team2cap = this.bothTeam.team2_captain_id;
            isCaptainFound1 = true;
          }
          if (x.User_id == this.bothTeam.team2_wicket_keeper_id) {
            this.team2keeper = this.bothTeam.team2_wicket_keeper_id;
            isKeeperFound1 = true;
          }
          // this.team2cap = x.User_id == this.bothTeam.team2_captain_id ? this.bothTeam.team2_captain_id : this.bothTeam.team1_captain_id;
          // this.team2keeper = x.User_id == this.bothTeam.team2_wicket_keeper_id ? this.bothTeam.team2_wicket_keeper_id : this.bothTeam.team1_wicket_keeper_id;
          if(x.is_substitute=="Y"){
            this.team2subDetails.push(x);
          }
        });
        if (!isCaptainFound1) {
          this.team2cap = this.bothTeam.team1_captain_id;
        }
        
        if (!isKeeperFound1) {
          this.team2keeper = this.bothTeam.team1_wicket_keeper_id;
        }
      }
    })
  }
  navigateToPlayerInfo(userId:any) {
    this.router.navigate([`${this.orgCode}/playerInfo`], { queryParams: { Id: userId } });

  }
  
}
