import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { VideoService } from 'src/app/service/video.service';

@Component({
  selector: 'app-video-landing-page',
  templateUrl: './video-landing-page.component.html',
  styleUrls: ['./video-landing-page.component.css']
})
export class VideoLandingPageComponent {

  selectCategory:String='All';
  activeTab: string = 'All';
  videos: any[]=[];
  videosData: any;
  pageSize = 9;
  currentPage = 0;
  searchQuery: any;
  filteredVideo: any;
  section: any = null;
  isloading: boolean=false;
  orgCode:any

  constructor(private videoService:VideoService,private route:Router,private router: ActivatedRoute,private orgFilter:OrgcodeFilterService){}

  ngOnInit(): void {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
    const navigationState = window.history.state;
    if (navigationState && navigationState.section) {
      this.section = navigationState.section;
    }
    this.getAllVideos(this.selectCategory)
    if(this.section==='practice'){
      this.activeTab='1'
    }
    else if(this.section==='news'){
      this.activeTab='2'
    }
  }

  
  getAllVideos(data:any){
    this.isloading=true;
    this.videoService.getVideoListAll(data).subscribe((response)=>{
      this.videosData=response
      if(this.videosData.status === "TRUE"){
        this.videos=this.videosData.result
        
        this.videos.forEach((videosData:any)=>{
          videosData.video_thumbnail_image = window.atob(videosData.video_thumbnail_image);
        })
        this.filteredVideo = this.videosData.result;
        this.isloading=false;
      }else{
        this.videos=[];
        this.isloading=false;
      }
    })
    this.activeTab = data;
    this.currentPage = 0;
  } 
  
  getDataForPage() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.videos.slice(startIndex, endIndex);
  }
  
  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  getVideoDetail(videoID:any,catID:any){
    this.route.navigate([`${this.orgCode}/videodetail`, videoID,catID]); 
  }

  search() {
   if(this.searchQuery!=''){
    const searchQueryLower = this.searchQuery.toLowerCase();

    this.videos = this.videosData.result.filter((item: any) =>(
      item.tags.toLowerCase().includes(searchQueryLower) || item.video_desc.toLowerCase().includes(searchQueryLower) || item.video_title.toLowerCase().includes(searchQueryLower))
    );
    this.currentPage = 0
    this.videos =this.getDataForPage();
   }
   else
   this.videos=this.filteredVideo
  }


}
