<div class="container">
    <h4 class="TopHeading  my-3 fs-4"*ngIf="isTopPerformersExist()" >TOP PERFORMERS</h4>
    <div class="carousel">
        <owl-carousel-o [options]="toperOptions">
            <ng-container class="carousel-container">
                <ng-template carouselSlide *ngFor="let data of factSheets;let i=index" class="col-5">
                  <div class="d-flex justify-content-center">
                    <div class="card col-lg-11 col-11 d-flex sc-height rounded-4"
                        style="box-shadow: 11px 12px 20px 0px lightgray;background-color:var(--secondary-color);color: white;"
                        *ngIf="factSheets[i].battingToppers.slice(0,2).length>0 || factSheets[i].battingToppers.slice(2,4).length>0 || factSheets[i].bowlingToppers.length>0">
                        <div class="container">
                            <h3 class="heading my-1 text-center">{{data.EventDeatil.Event_name}}</h3>
                            <h4 class="mb-1 text-center">{{data.EventDeatil?.Start_date}}&nbsp;-&nbsp;{{data.EventDeatil?.End_date}}</h4>
                            <div class="container justify-content-center d-flex mb-1">
                                <div class="col-12 d-lg-block d-md-block d-none">
                                    <div class="card mb-1" style="color: white; background-color:var(--primary-color);"
                                        *ngIf="factSheets[i].battingToppers.slice(0,2).length>0"  (click)="navigateto(factSheets[i].EventDeatil.Event_id,factSheets[i].EventDeatil.Sport_id)">
                                        <h3 class="heading my-2 text-center">TOP RUN GETTERS</h3>
                                        <div class="row justify-content-center">
                                            <div class="d-flex col-lg-6 col-sm-11 gap-3 flex-wrap justify-content-evenly"
                                                *ngFor="let data of factSheets[i].battingToppers.slice(0,2)">
                                                <div class="shadow d-flex  align-items-center mb-3 p-2  col-11 rounded-2"
                                                    style="background-color: #087099; color: white;height:15vh;">
                                                    <div class="col-12 d-flex flex-column text-center">
                                                        <i class="bi bi-person-circle fs-2 profile"
                                                            style="color: white;"></i>
                                                        <span class="fs-6 record my-2">Runs : {{data.record}}</span>
                                                        <span class="nameAndTeam">Name : {{data.name}}</span>
                                                        <span class="nameAndTeam">
                                                            Team : {{data.team_name}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mb-1" style="color: white; background-color: var(--primary-color);"
                                        *ngIf="factSheets[i].battingToppers.slice(2,4).length>0"  (click)="navigateto(factSheets[i].EventDeatil.Event_id,factSheets[i].EventDeatil.Sport_id)">
                                        <h3 class="heading my-2 text-center">HIGHEST SR(MIN:25 RUNS)</h3>
                                        <div class="row justify-content-center">
                                            <div class="d-flex gap-3 col-lg-6 col-sm-11 flex-wrap justify-content-evenly"
                                                *ngFor="let data of factSheets[i].battingToppers.slice(2,4)">
                                                <div class="shadow d-flex  align-items-center mb-3 p-2  col-11 rounded-2"
                                                    style="background-color: #087099; color: white;height:15vh;">
                                                    <div class="col-12 d-flex flex-column text-center">
                                                        <i class="bi bi-person-circle fs-2 profile"
                                                            style="color: white;"></i>
                                                        <span class="fs-6 record my-2">RR : {{data.record}}</span>
                                                        <span class="nameAndTeam">Name : {{data.name}}</span>
                                                        <span class="nameAndTeam">
                                                            Team : {{data.team_name}}
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mb-1" style="color: white; background-color: var(--primary-color);"
                                        *ngIf="factSheets[i].bowlingToppers.length>0"  (click)="navigateto(factSheets[i].EventDeatil.Event_id,factSheets[i].EventDeatil.Sport_id)">
                                        <h3 class="heading my-2 text-center">LEADING WICKET TAKERS</h3>
                                        <div class="row justify-content-center">
                                            <div class="d-flex col-lg-6 col-sm-11 gap-3 flex-wrap justify-content-evenly"
                                                *ngFor="let item of factSheets[i].bowlingToppers">
                                                <div class="shadow d-flex align-items-center mb-3 p-2  col-11 rounded-2"
                                                    style="background-color:#087099; color: white;height:15vh;">
                                                    <div class="col-12 d-flex flex-column text-center">
                                                        <i class="bi bi-person-circle fs-2 profile"
                                                            style="color: white;"></i>
                                                        <span class="fs-6 record my-2">Wickets : {{item.record}}</span>
                                                        <span class="nameAndTeam">Name : {{item.name}}</span>
                                                        <span class="nameAndTeam">
                                                            Team : {{item.team_name}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-sm-block d-lg-none d-md-none">
                                    <owl-carousel-o [options]="customOptions">
                                      <!-- Top Run Getters Card -->
                                      <ng-container *ngIf="factSheets[i].battingToppers.slice(0,2).length>0">
                                        <ng-template carouselSlide>
                                          <div class="card mb-1" style="color: white; background-color:var(--primary-color);" (click)="navigateto(factSheets[i].EventDeatil.Event_id,factSheets[i].EventDeatil.Sport_id)">
                                            <h3 class="heading my-2 text-center">TOP RUN GETTERS</h3>
                                            <div class="row justify-content-center">
                                              <div class="d-flex col-12 gap-3 flex-wrap justify-content-evenly" *ngFor="let data of factSheets[i].battingToppers.slice(0,2)">
                                                <div class="shadow d-flex align-items-center mb-3 p-2 col-11 rounded-2" style="background-color: #087099; color: white;height:15vh;">
                                                  <div class="col-12 d-flex flex-column text-center">
                                                    <i class="bi bi-person-circle fs-2 profile" style="color: white;"></i>
                                                    <span class="fs-6 record my-2">Runs : {{data.record}}</span>
                                                    <span class="nameAndTeam">Name : {{data.name}}</span>
                                                    <span class="nameAndTeam">Team : {{data.team_name}}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </ng-container>
                                  
                                      <!-- Highest SR Card -->
                                      <ng-container *ngIf="factSheets[i].battingToppers.slice(2,4).length>0">
                                        <ng-template carouselSlide>
                                          <div class="card mb-1" style="color: white; background-color: var(--primary-color);" (click)="navigateto(factSheets[i].EventDeatil.Event_id,factSheets[i].EventDeatil.Sport_id)">
                                            <h3 class="heading my-2 text-center">HIGHEST SR (MIN: 25 RUNS)</h3>
                                            <div class="row justify-content-center">
                                              <div class="d-flex col-12 gap-3 flex-wrap justify-content-evenly" *ngFor="let data of factSheets[i].battingToppers.slice(2,4)">
                                                <div class="shadow d-flex align-items-center mb-3 p-2 col-11 rounded-2" style="background-color: #087099; color: white;height:15vh;">
                                                  <div class="col-12 d-flex flex-column text-center">
                                                    <i class="bi bi-person-circle fs-2 profile" style="color: white;"></i>
                                                    <span class="fs-6 record my-2">RR : {{data.record}}</span>
                                                    <span class="nameAndTeam">Name : {{data.name}}</span>
                                                    <span class="nameAndTeam">Team : {{data.team_name}}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </ng-container>
                                  
                                      <!-- Leading Wicket Takers Card -->
                                      <ng-container *ngIf="factSheets[i].bowlingToppers.length>0">
                                        <ng-template carouselSlide>
                                          <div class="card mb-1" style="color: white; background-color: var(--primary-color);" (click)="navigateto(factSheets[i].EventDeatil.Event_id,factSheets[i].EventDeatil.Sport_id)">
                                            <h3 class="heading my-2 text-center">LEADING WICKET TAKERS</h3>
                                            <div class="row justify-content-center">
                                              <div class="d-flex col-12 gap-3 flex-wrap justify-content-evenly" *ngFor="let item of factSheets[i].bowlingToppers">
                                                <div class="shadow d-flex align-items-center mb-3 p-2 col-11 rounded-2" style="background-color:#087099; color: white;height:15vh;">
                                                  <div class="col-12 d-flex flex-column text-center">
                                                    <i class="bi bi-person-circle fs-2 profile" style="color: white;"></i>
                                                    <span class="fs-6 record my-2">Wickets : {{item.record}}</span>
                                                    <span class="nameAndTeam">Name : {{item.name}}</span>
                                                    <span class="nameAndTeam">Team : {{item.team_name}}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </ng-container>
                                    </owl-carousel-o>
                                  </div>
                                  
                            </div>
                        </div>
                    </div>
                  </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>