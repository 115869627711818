import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badminton-scorecard',
  templateUrl: './badminton-scorecard.component.html',
  styleUrls: ['./badminton-scorecard.component.css']
})
export class BadmintonScorecardComponent {
  @Input() data : any;
}
