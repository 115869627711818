<div class="container">
    <div class="card border-0 shadow my-2">
        <h4 class="heading text-uppercase p-2 m-0">Recent Matches</h4>
        <div class="card-body" *ngIf="matchlist?.length>0">
            <div class="row bg-body-tertiary my-1 rounded-4" *ngFor="let data of matchlist">
                <div class="top d-flex justify-content-between align-items-center rounded-top-4 text-white p-2">
                    <p class="date"><i class="bi bi-calendar2-week me-1"></i>{{data.match_date}}</p>
                    <p class="date">{{data.match_time}}</p>
                </div>
                <div class="playerName col-lg-3 col-4 d-flex flex-column mt-2 justify-content-center align-items-end">
                    <div class="text-end" *ngIf="data.match_type == 'D';">
                        <p>{{ data.team1_detail[0] }}</p>
                        <p>{{ data.team1_detail[1] }}</p>
                    </div>
                    <div class="text-end" *ngIf="data.match_type == 'S';">
                        <p>{{ data.team1_detail}}</p>
                    </div>
                </div>
                <div class="col-lg-6 col-4 mt-2 d-flex align-items-center justify-content-center">
                    <p class="points">
                        <span
                            [ngStyle]="{'color': data.teamOnePoint > data.teamTwoPoint ? 'green' : (data.teamOnePoint < data.teamTwoPoint ? 'black' : 'black')}">{{data.teamOnePoint}}</span>
                        -
                        <span
                            [ngStyle]="{'color': data.teamOnePoint > data.teamTwoPoint ? 'black' : (data.teamOnePoint < data.teamTwoPoint ? 'green' : 'black')}">{{data.teamTwoPoint}}</span>
                    </p>
                </div>
                <div class="playerName col-lg-3 col-4 mt-2 d-flex flex-column justify-content-center align-items-start">
                    <div *ngIf="data.match_type == 'D';">
                        <p>{{ data.team2_detail[0] }}</p>
                        <p>{{ data.team2_detail[1] }}</p>
                    </div>
                    <div *ngIf="data.match_type == 'S';">
                        <p>{{ data.team2_detail}}</p>
                    </div>
                </div>
                <div class="fade_rule"></div>
                <h4 class="result text-center mt-2"> <span class="fw-medium">Game won by
                        &nbsp;</span>{{data.matchResult}}</h4>
            </div>
        </div>
    </div>
    <div class="card border-0 shadow my-2">
        <h4 class="heading text-uppercase p-2 m-0">Recent Performance Breakdown For Singles</h4>
        <div class="card-body" *ngIf="playerResult">
            <div class="row">
                <div class="col-lg-6 col-12 d-flex justify-content-center">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="wonChart"></highcharts-chart>
                </div>
                <div class="col-lg-6 col-12 d-flex justify-content-center">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="lostChart"></highcharts-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-0 shadow my-2">
        <h4 class="heading text-uppercase p-2 m-0">Teams</h4>
        <div class="card-body row gap-2" *ngIf="teamMatches?.length>0">
            <div class="card border-0 shadow col-lg-4 col-12" *ngFor="let data of teamMatches">
                <div class="card-body">
                    <div class="row align-items-center">
                        <img src="assets/img/team.png" alt="" class="col-2">
                        <h4 class="m-0 col-10 teamName">{{data.Team_Name}}</h4>
                    </div>
                    <div class="scoreDetail row justify-content-evenly text-white rounded-2 p-2 gap-2">
                        <div class="col-6 row border-end border-white">
                            <p class="text-center text-uppercase border-bottom border-white p-2 team fs-6">Matches</p>
                            <div class="col-6 text-center">
                                <p class="text-uppercase plays text-nowrap">Played</p>
                                <p class="numbers">{{data.matches_played}}</p>
                            </div>
                            <div class="col-6 text-center">
                                <p class="text-uppercase plays">Won</p>
                                <p class="numbers">{{data.matches_won}}</p>
                            </div>
                        </div>
                        <div class="col-6 row ">
                            <p class="text-center text-uppercase border-bottom border-white p-2 team fs-6">Sets</p>
                            <div class="col-6 text-center">
                                <p class="text-uppercase plays text-nowrap">Played</p>
                                <p class="numbers">{{data.sets_played}}</p>
                            </div>
                            <div class="col-6 text-center">
                                <p class="text-uppercase plays">Won</p>
                                <p class="numbers">{{data.sets_won}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>