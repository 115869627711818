<div class="card mb-3 d-none d-lg-block d-md-block" *ngIf="matchData.status != 'S'">
  <div class="card-body rounded-4 shadow"
  NG
       [ngStyle]="{ background: orgCode === 'SKA' ? 'linear-gradient(to right, #f5c400, white, lightblue)' : 'linear-gradient(to right, rgb(48 188 229), white, lightblue)' }">
    <div class="row m-1">
      <div class="col-10 pe-0">
        <div class="d-flex justify-content-center event text-uppercase fw-bolder fs-5">
          {{ matchData.Event_name }}
        </div>
        <div class="row m-2 align-items-center">
          <div class="col-3 justify-content-center team position-relative">
            <div class="venueDate">
              <p class="text-center fw-normal">
                 {{ matchData.Match_Date }}
              </p>
              <p class="text-center fw-medium mb-1">
                <i class="bi bi-geo-alt-fill text-body-secondary" style="color: #444444;"></i> {{ matchData.Venue }}
              </p>
            </div>
            <div class="vertical-line"></div>
          </div>


          <div class="col-4 justify-content-center text-center logo ps-0">
            <h2 class="text-uppercase text-center mb-1 teamName">
              <span [ngClass]="{'d-none d-md-block': true, 'd-md-none d-lg-block': true}">{{ matchData.teamone }}</span>
              <span [ngClass]="{'d-none d-lg-block': true, 'd-md-block d-lg-none': true}">{{ matchData.teamOne }}</span>
            </h2>
          </div>

          <div class="col-4 justify-content-center text-center logo pe-0 position-relative">
            <h2 class="text-uppercase text-center mb-1 teamName">
              <span [ngClass]="{'d-none d-md-block': true, 'd-md-none d-lg-block': true}">{{ matchData.teamtwo }}</span>
              <span [ngClass]="{'d-none d-lg-block': true, 'd-md-block d-lg-none': true}">{{ matchData.teamTwo }}</span>
            </h2>
            <!-- Vertical line before the button -->
            <div class="vertical-line"></div>
          </div>

          <div class="row p-2" *ngIf="matchData.status == 'P'">
            <div class="d-flex MatchStatus justify-content-center text-uppercase status">
              {{ matchData.tossWon + "  WON THE TOSS AND ELECTED  " + matchData.elected_to }}
            </div>
          </div>
          <div class="row p-2" *ngIf="matchData.status=='C'">
            <div class="d-flex MatchStatus justify-content-center text-uppercase status">
              {{ matchData.result}}
            </div>
          </div>
          <div class="row teamName">
            <!-- Team 1 Score -->
            <div class="col-9 d-flex flex-column align-items-center">
              <div *ngIf="matchData.status == 'P'">
                <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday != 'Y'">
                  <span class="text-nowrap">{{ matchData.team1_score }}</span>
                </div>
                <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday == 'Y' && matchData.inningsStatus != 'L'">
                  <span>{{ matchData.test1_score }}</span>
                </div>
                <div class="d-flex justify-content-center" *ngIf="matchData.inningsStatus == 'L'">
                  <span>{{ matchData.followOnScore2 }}</span>
                </div>
              </div>
              <div *ngIf="matchData.isMultiday != 'Y' && matchData.status == 'C'">
                <p class="fs-6 text-center">
                  <span class="me-1">{{ matchData.innings_1_score }}/{{ matchData.innings_1_wkts }}</span>
                  <span class="over">({{ matchData.innings_1_overs }})</span>
                </p>
              </div>
              <div *ngIf="matchData.isMultiday == 'Y' && matchData.status == 'C'" class="text-center">
                <span>{{ matchData['innings_' + matchData.team_1_group?.[0] + '_score'] }}/{{ matchData['innings_' + matchData.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[1] + '_score'] > 0 || matchData['innings_' + matchData.team_1_group?.[1] + '_score'] === 0">
                  & {{ matchData['innings_' + matchData.team_1_group?.[1] + '_score'] }}/{{ matchData['innings_' + matchData.team_1_group?.[1] + '_wkts'] }}
                </span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
              </div>
            </div>

            <!-- Team 2 Score -->
            <div class="col-3 d-flex flex-column align-items-center">
              <div *ngIf="matchData.status == 'P'">
                <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday != 'Y'">
                  <span class="text-nowrap">{{ matchData.team2_score }}</span>
                </div>
                <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday == 'Y' && matchData.inningsStatus != 'L'">
                  <span>{{ matchData.test2_score }}</span>
                </div>
                <div class="d-flex justify-content-center" *ngIf="matchData.inningsStatus == 'L'">
                  <span>{{ matchData.followOnScore1 }}</span>
                </div>
              </div>
              <div *ngIf="matchData.isMultiday != 'Y' && matchData.status == 'C'">
                <p class="fs-6 text-center">
                  <span class="me-1">{{ matchData.innings_2_score }}/{{ matchData.innings_2_wkts }}</span>
                  <span class="over">({{ matchData.innings_2_overs }})</span>
                </p>
              </div>
              <div *ngIf="matchData.isMultiday == 'Y' && matchData.status == 'C'" class="text-center">
                <span>{{ matchData['innings_' + matchData.team_2_group?.[0] + '_score'] }}/{{ matchData['innings_' + matchData.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[1] + '_score'] > 0 || matchData['innings_' + matchData.team_1_group?.[1] + '_score'] === 0">
                  & {{ matchData['innings_' + matchData.team_2_group?.[1] + '_score'] }}/{{ matchData['innings_' + matchData.team_2_group?.[1] + '_wkts'] }}
                </span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                <span *ngIf="matchData['innings_' + matchData.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row">
          <div class="d-flex justify-content-center text-uppercase status">{{ matchData.result }}</div>
        </div> -->

      </div>
      <div class="col-2 d-flex flex-column align-items-center justify-content-between border-start ps-4 m-0">
        <!-- <div class="row mb-2 m-0 w-100">
          <div class="text-uppercase status text-center w-100">{{ matchData.result }}</div>
        </div> -->
        <div class="row m-5 w-100">
          <button type="button" class="btn webButtonOne w-100"
                  [ngStyle]="{ background: index % 2 === 0 ? 'var(--primary-color)' : 'rgba(55, 129, 157, 0.9)', color: 'white' }"
                  (click)="showScorecard(matchData.Match_id, matchData.Team1, matchData.Team2, matchData.teamone, matchData.teamtwo)">
            SCORECARD
          </button>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- mobile view -->
<!-- <div class="card shadow mb-2 bg-body-tertiary rounded d-block d-sm-none">
    <div class="card-body mobile" [ngStyle]="{ background: index % 2 ===0 ? 'linear-gradient(63deg, var(--primary-color) 0%, rgba(255,255,255,1) 100%)'  : 'linear-gradient(63deg, rgba(255,255,255,1) 0%, var(--primary-color) 100%)' }">
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col">
                        <div class="d-flex justify-content-center ">
                            <p>{{matchData.Match_Date}}</p>
                        </div>
                        <div class="d-flex justify-content-center text-center ">
                            <div></div>
                            <p>{{matchData.Venue}}</p>
                        </div>
                        <div class="d-flex justify-content-center text-center">
                            <p><b>{{matchData.Event_name}}</b></p>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-4 d-flex justify-content-center">
                        <img src="assets/cricketLogo.png" width="50%">
                    </div>
                    <div class="col-4 d-flex justify-content-center">
                    </div>
                    <div class="col-4 d-flex justify-content-center">
                        <img src="assets/cricketLogo.png" width="50%">
                    </div>
                </div>
                <div class="row mt-2 d-flex justify-content-center">
                    <div class="col-4 d-flex justify-content-center text-center">
                        <p><b>{{matchData.teamone}}</b></p>
                    </div>
                    <div class="col-4 d-flex justify-content-center">
                        <p>Vs</p>
                    </div>
                    <div class="col-4 d-flex justify-content-start text-center">
                        <p><b>{{matchData.teamtwo}}</b></p>
                    </div>
                </div>

                <div class="row">
                    <div class="col d-flex justify-content-end">
                        <div class="row">
                            <div class="col">
                                <div style="display: flex; justify-content: space-around;">
                                    <b>{{matchData.innings_1_score}}/{{matchData.innings_1_wkts}}</b>
                                </div>
                                <div>
                                    ({{matchData.innings_1_overs}}.ov)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col"></div>
                    <div class="col d-flex justify-content-start">
                        <div class="row">
                            <div class="col">
                                <div style="display: flex; justify-content: space-around;">
                                    <b>{{matchData.innings_2_score}}/{{matchData.innings_2_wkts}}</b>
                                </div>
                                <div>
                                    ({{matchData.innings_2_overs}}.ov)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col d-flex justify-content-center text-center">
                        <p style="font-weight: 500;"><b>{{matchData.tossWon}} Won the Toss
                                and
                                Elected to {{matchData.elected_to}}</b></p>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'col-12 border-top mt-2 mb-4 border-dark': windowWidth <= 768}">
            </div>
            <div class="col-sm-12">
                <div class="row d-flex align-items-center justify-content-center">
                    <button type=" button" class="btn w-auto mobileWebButton" [ngStyle]="{ background: index % 2 === 0 ? 'var(--primary-color)'  : 'white' , color: index % 2 === 0 ? 'white' : 'var(--secondary-color)'}"
                        (click)="showScorecard(matchData.Match_id,matchData.team1, matchData.team2, matchData.teamone, matchData.teamtwo)">
                        scorecard</button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="container d-block d-lg-none d-md-none score" *ngIf="matchData.status != 'S'">
    <div class="card rounded-4 my-2"
    [ngStyle]="{ background: orgCode === 'SKA' ? 'linear-gradient(to right, #f5c400, white, lightblue)' : 'linear-gradient(to right, rgb(48 188 229), white, lightblue)' }">
        <div class="row m-2 p-1">
            <div class="col-12 m-1">
                <div class="lives fw-semibold">
                    <p class="text-start text-danger mb-0" *ngIf="matchData.status === 'P'"><i
                            class="bi bi-circle-fill me-1"></i> LIVE</p>
                    <p class="text-center mt-0 event m-0 fs-6 text-uppercase">{{matchData.Event_name}}</p>
                </div>
                <div class="justify-content-around row">
                    <div class="text-start col-6">
                        <p class="venue m-0 fw-semibold text-uppercase text-start my-2" style="font-size: 14px;">
                            <i class="bi bi-geo-alt-fill text-body-secondary" style="color: #444444;"></i> {{matchData.Venue}}
                        </p>
                        <p class="date event m-0 fw-noraml"><i class="bi bi-calendar2-minus"></i>
                            {{matchData.Match_Date}}</p>
                    </div>
                    <div class="text-end col-6 mt-2">
                        <button type=" button" class="btn m-0 mobileWebButton text-end"
                            [ngStyle]="{ background: index % 2 === 0 ? 'var(--primary-color)'  : 'rgba(55, 129, 157, 0.9)' , color: 'white' }"
                            (click)="showScorecard(matchData.Match_id,matchData.team1, matchData.team2, matchData.teamone, matchData.teamtwo)">
                            scorecard</button>
                    </div>
                </div>
                <hr>
                <div class="d-flex justify-content-between">
                  <div class="fs-6 text-center col-3" style="font-weight: 500;">{{matchData.teamOne}}</div>
                  <div class="fs-6 text-center col-3" style="font-weight: 500;">{{matchData.teamTwo}}</div>
                </div>
                <div class="row my-2">
                    <div class="col-5 d-flex  flex-row-reverse justify-content-evenly align-items-center">
                        <div class="col-4 logo-container d-flex text-start justify-content-center ms-2">
                            <img src="assets/images/srmatchlogoup.png" class="img-fluid  d-block " width="101%"
                                alt="">
                        </div>
                        <div
                            class="col-8 d-flex flex-column justify-content-between justify-content-md-evenly align-items-center">
                            <!-- <div class="fs-6 text-center" style="font-weight: 500;">{{matchData.teamOne}}</div> -->


                            <div class="col-6 col-md-1 teams d-flex justify-content-center align-items-center">
                              <div *ngIf="matchData.status == 'P'">
                                <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday != 'Y'">
                                  <span class="text-center text-nowrap">{{matchData.team1_score}}</span>
                                </div>
                                <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday == 'Y' && matchData.inningsStatus != 'L'">
                                  <span class="text-center">{{matchData.test1_score}}</span>
                                </div>
                                <div class="d-flex justify-content-center" *ngIf="matchData.inningsStatus == 'L'">
                                  <span class="text-center">{{matchData.followOnScore2}}</span>
                                </div>
                              </div>
                              <div *ngIf="matchData.isMultiday !='Y' && matchData.status == 'C'">
                                <p class="fs-6 text-center "><span class="me-1">{{matchData.innings_1_score}}/{{matchData.innings_1_wkts}}</span><span class="over">({{matchData.innings_1_overs}})</span></p>
                            </div>

                            <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday=='Y' && matchData.status == 'C'">
                                <span>{{matchData['innings_' +matchData.team_1_group?.[0] + '_score'] }}/{{matchData['innings_' +matchData.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_1_group?.[1] + '_score'] > 0 ||matchData['innings_' +matchData.team_1_group?.[1] + '_score'] === 0">
                                    & {{matchData['innings_' +matchData.team_1_group?.[1] + '_score'] }}/{{matchData['innings_' +matchData.team_1_group?.[1] + '_wkts'] }}
                                </span>
                                <span *ngIf="matchData['innings_' +matchData.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;

                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 justify-content-center text-center mt-3 event p-0">Vs</div>
                    <div class="col-5 d-flex justify-content-evenly align-items-center">
                        <div class="col-4 logo-container d-flex justify-content-center text-end me-2">
                            <img src="assets/images/srmatchlogoup.png" class="img-fluid  d-block  " width="101%"
                                alt="">
                        </div>
                        <div
                            class="col-8 d-flex flex-column justify-content-between justify-content-md-evenly align-items-center">
                            <!-- <div class="fs-6 text-center" style="font-weight: 500;">{{matchData.teamTwo}}</div> -->
                            <div class="col-6 col-md-1 teams d-flex justify-content-center align-items-center">
                              <div *ngIf="matchData.status == 'P'">
                                  <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday != 'Y'">
                                    <span class="text-center text-nowrap">{{matchData.team2_score}}</span>
                                  </div>
                                  <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday == 'Y' && matchData.inningsStatus != 'L'">
                                    <span class="text-center">{{matchData.test2_score}}</span>
                                  </div>
                                  <div class="d-flex justify-content-center" *ngIf="matchData.inningsStatus == 'L'">
                                    <span class="text-center">{{matchData.followOnScore1}}</span>
                                  </div>
                                </div>
                              <div *ngIf="matchData.isMultiday !='Y'  && matchData.status == 'C'">
                                <p class="fs-6 text-center"><span class="me-1">{{matchData.innings_2_score}}/{{matchData.innings_2_wkts}}</span><span class="over">({{matchData.innings_2_overs}})</span></p>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="matchData.isMultiday=='Y' && matchData.status == 'C'">
                                <span>{{matchData['innings_' +matchData.team_2_group?.[0] + '_score'] }}/{{matchData['innings_' +matchData.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_2_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_2_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_2_group?.[1] + '_score'] > 0 ||matchData['innings_' +matchData.team_2_group?.[1] + '_score'] === 0">
                                    & {{matchData['innings_' +matchData.team_2_group?.[1] + '_score'] }}/{{matchData['innings_' +matchData.team_2_group?.[1] + '_wkts'] }}
                                </span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_2_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_2_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="matchData['innings_' +matchData.team_2_group?.[1] + '_status']?.split(' ')[1] !== 'D' &&matchData['innings_' +matchData.team_2_group?.[0] + '_status']?.split(' ')[1] === 'L'">f/o</span>&nbsp;
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-3 result fw-medium fs-6 result text-center mb-0">
                        {{matchData.result}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- web view Upcoming-->
<div class="card  mb-3 d-none d-md-block d-lg-block" *ngIf="matchData.status == 'S'">
  <div class="card-body  rounded-4 shadow"
  [ngStyle]="{ background: orgCode === 'SKA' ? 'linear-gradient(to right, #f5c400, white, lightblue)' : 'linear-gradient(to right, rgb(48 188 229), white, lightblue)' }">
    <div class="row m-1">
      <div class="col-12 pe-0">
        <div class="d-flex justify-content-center event text-uppercase fw-bolder fs-4">
          {{matchData.Event_name}}
        </div>
        <div class="row m-2">
          <div class="col-3 justify-content-center team">
            <h2 class="text-uppercase text-center mb-1 teamName">
              {{matchData.teamone}}
            </h2>
          </div>
          <div class="col-2  justify-content-center text-center logo mt-5 ms-0 ps-0 upcoming">
            <img src="assets/images/srmatchlogoup.png" width="27%">
          </div>
          <div class="col-2 d-flex justify-content-center text-uppercase">
            <!-- <div class="venueDate">
              <p class="text-center fw-medium mb-1"><i class="bi bi-geo-alt-fill text-warning"
                  style="color: #444444;"></i> {{matchData.Venue}}</p>
              <p class="text-center fw-normal"><i class="bi bi-calendar2-minus"></i>
                {{matchData.Match_Date}}</p>
            </div> -->
          </div>
          <div class="col-2 justify-content-center text-center logo mt-5 me-0 pe-0">
            <img src="assets/images/srmatchlogoup.png" width="27%">
          </div>
          <div class="col-3 justify-content-center team">
            <h2 class="text-uppercase text-center mb-1 teamName">
              {{matchData.teamtwo}}
            </h2>
          </div>
        </div>
      </div>
      <h2 class="m-0 text-center fw-bold testToStart"><span class="text-white px-3 py-1 rounded-pill" style="background-color: var(--primary-color);">YET TO START</span></h2>
    </div>
  </div>
</div>
<!-- moile view -->
<div class="container d-block d-lg-none d-md-none" *ngIf="matchData.status == 'S'">
    <div class="card rounded-4 shadow my-2"  [ngStyle]="{ background: orgCode === 'SKA' ? 'linear-gradient(to right, #f5c400, white, lightblue)' : 'linear-gradient(to right, rgb(48 188 229), white, lightblue)' }">
        <div class="row justify-content-center m-2 p-1">
            <div class="col-12 m-1">
                <p class="text-center mt-0 event m-0 fs-6 text-uppercase lives">{{matchData.Event_name}}</p>
                <hr>
                <div class="row my-2">
                    <div class="col-4 justify-content-evenly">
                        <div class="text-center">
                            <img src="assets/images/srmatchlogoup.png" class="img-fluid logoMobile" width="45%" alt="">
                        </div>
                        <div class="fs-6 text-center" style="font-weight: 500;font-size: 14px!important;">
                            {{matchData.teamone}}</div>
                    </div>
                    <div class="col-4 mt-3 justify-content-center">
                        <p class="m-0 venue fw-semibold text-uppercase text-center my-2" style="font-size: 14px;">
                            <i class="bi bi-geo-alt-fill text-body-secondary" style="color: #444444;"></i> {{matchData.Venue}}
                        </p>
                        <p class="date m-0 fw-noraml text-center text-nowrap"><i class="bi bi-calendar2-minus"></i>
                            {{matchData.Match_Date}}</p>
                    </div>
                    <div class="col-4 justify-content-evenly">
                        <div class="text-center">
                            <img src="assets/images/srmatchlogoup.png" class="img-fluid logoMobile" width="45%" alt="">
                        </div>
                        <div class="fs-6 text-center" style="font-weight: 500;font-size: 14px!important;">
                            {{matchData.teamtwo}}</div>
                    </div>
                </div>
            </div>
            <h2 class="m-0 text-center fw-bold testToStart"><span class="text-white px-3 fs-6 py-1 rounded-pill" style="background-color: var(--primary-color);">YET TO START</span></h2>

        </div>
    </div>
</div>
