<div class="p-1 mt-3 mb-5 fullscorecard">
    <mat-card>
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{matchDetail.teamOne}}
                    </mat-panel-title>
                    <mat-panel-description style="justify-content: flex-end;">
                        {{matchDetail.innings_1_score}}/{{matchDetail.innings_1_wkts}} in
                        ({{matchDetail.innings_1_overs}} ov)
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
                    <app-batsman-scorecard [innStats]="firstInnStats" [cricketMatchSumm]="cricketMatchSumm" 
                        [didNotBat]="didNotBatInTeamOne" [fallOfWickets]="firstFallOfWick" ></app-batsman-scorecard>
                    <app-bowler-scorecard [innBowStats]="firstInnBow"></app-bowler-scorecard>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card>
    <br>
    <mat-card>
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{matchDetail.teamTwo}}
                    </mat-panel-title>
                    <mat-panel-description style="justify-content: flex-end;">
                        {{matchDetail.innings_2_score}}/{{matchDetail.innings_2_wkts}} in
                        ({{matchDetail.innings_2_overs}} ov)
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
                    <app-batsman-scorecard [innStats]="secondInnStats" [cricketMatchSumm]="cricketMatchSumm"
                        [didNotBat]="didNotBatInTeamTwo" [fallOfWickets]="secFallOfWick"></app-batsman-scorecard>
                    <app-bowler-scorecard [innBowStats]="secInnBow"></app-bowler-scorecard>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card>
    <br>
    <!-- <mat-accordion *ngIf="data.isMultiDay === 'y' || data.isMultiDay === 'Y'">
        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{data.teamTwo}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end;">
                    {{data.innings_3_score}}/{{data.innings_3_wkts}} in
                    {{data.innings_3_overs}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
                <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
                    <app-batsman-scorecard [innStats]="secondInnStats" [cricketMatchSumm]="cricketMatchSumm"
                        [didNotBat]="didNotBatInTeamTwo" [fallOfWickets]="secFallOfWick"></app-batsman-scorecard>
                    <app-bowler-scorecard [innBowStats]="secInnBow"></app-bowler-scorecard>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br>
    <mat-accordion *ngIf="data.isMultiDay === 'y' || data.isMultiDay === 'Y'">
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{data.teamTwo}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end;">
                    {{data.innings_4_score}}/{{data.innings_4_wkts}} in
                    {{data.innings_4_overs}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
                <div [ngClass]="{'lh-base, webView' : windowWidth > 768, 'lh-lg, mobView': windowWidth <= 768 }">
                    <app-batsman-scorecard [innStats]="secondInnStats" [cricketMatchSumm]="cricketMatchSumm"
                        [didNotBat]="didNotBatInTeamTwo" [fallOfWickets]="secFallOfWick"></app-batsman-scorecard>
                    <app-bowler-scorecard [innBowStats]="secInnBow"></app-bowler-scorecard>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion> -->
</div>