import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-scorecard',
  templateUrl: './full-scorecard.component.html',
  styleUrls: ['./full-scorecard.component.css'],
})


export class FullScorecardComponent implements OnInit {

  @Input() matchDetail: any;
  @Input() cricketMatchSumm: any;
  @Input() firstInnStats: any;
  @Input() firstInnBow: any;
  @Input() firstFallOfWick: any;
  @Input() secondInnStats: any;
  @Input() secInnBow: any;
  @Input() secFallOfWick: any;
  @Input() didNotBatInTeamOne: any;
  @Input() didNotBatInTeamTwo: any;
  @Input() wagonCoordinateForplayers : any;
  step = 0;
  windowWidth: number = 0;
  windowHeight: number = 0;


  constructor() {
    this.step = 0;
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit() {
    this.getWindowSize();
  }

  getWindowSize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

}
