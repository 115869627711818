<div class="main-container m-3">
    <div class="justify-content-center d-flex">
        <ul class="nav nav-tabs nav-pills border-0 fw-semibold text-white justify-content-start ml-auto">
            <li class="nav-item w p-2">
                <button class="nav-link" mat-raised-button
                    (click)="displayStatus('All')" [ngClass]="{'active': activeTab === 'All'}">
                    <h3 [class]="activeTab === 'All' ? 'text-white btn-text m-0' : 'text-dark btn-text m-0' ">All</h3>
                </button>
            </li>
            <li *ngFor="let badmintonMatchResult of setDetails" class="nav-item w p-2">
                <button mat-raised-button *ngIf="badmintonMatchResult.won_by_team != 'null'" class="nav-link btn-1"
                    (click)="displayStatus(badmintonMatchResult.set_no)" [ngClass]="{'active': activeTab === badmintonMatchResult.set_no}">
                    <h3 [class]="activeTab === badmintonMatchResult.set_no ? 'text-white btn-text m-0' : 'text-dark btn-text m-0' ">
                    SET {{badmintonMatchResult.set_no}}</h3>
                </button>
            </li>
        </ul>
    </div>
</div>
<div class="main-container justify-content-center m-3" *ngIf="badmintonMatchDetails != null">
    <app-badminton-match-status [data]="badmintonMatchDetails"></app-badminton-match-status>
</div>