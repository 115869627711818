<div class="teamSumm">
  <div class="" *ngIf="mainScreen && !pageTourn">
    <div class=" mb-3 shadow profile p-3">
      <div class="container ">
        <div class=" my-3   p-3 d-none d-lg-block d-md-block">
          <div class="row h-100 align-items-center text-white  flex-md-row ">
            <div class="col-3 text-center"><img src="https://www.sportsruler.com/SR/user/img/boy-plain.png" alt="">
            </div>
            <div class="col-3 col-md-4 ">
              <ul class="list-unstyled playerProfile">
                <li>
                  <h3 class="m-0">{{teamName}}</h3>
                </li>
                <li>
                  <h4 class="m-0">IntellectsCaptain : {{captainId}}</h4>
                </li>
                <li class="cursor-pointer" (click)="showPlayerList()">Players : {{teamDetails?.length}} players</li>
              </ul>
            </div>
            <div class="col-3 text-center">
              <div class="d-flex flex-column">
                <span class="ps-1">About Team ! &nbsp;
                  <!-- <i class="bi bi-pencil-square  text-start" style="margin-left: 5px;"></i> --></span>
                <div class="">{{teamDesc}}</div>
              </div>

            </div>

          </div>
        </div>
        <div class=" my-3  p-3 d-block d-lg-none d-md-none">
          <div class="row h-100 align-items-start text-white  justify-content-between flex-column">
            <div class="col-1 text-end"><img src="https://www.sportsruler.com/SR/user/img/boy-plain.png" alt=""></div>
            <div class=" col-md-4 text-start">
              <ul class="list-unstyled">
                <li>
                  <h3 class="m-0">{{teamName}}</h3>
                </li>
                <li>
                  <h4 class="m-0">IntellectsCaptain : {{captainId}}</h4>
                </li>
                <li class="cursor-pointer" (click)="showPlayerList()">Players : {{teamDetails.length}} players</li>
                <div class=" mt-3  ">
                  <div class="d-flex  justify-content-between">
                    <span>About Team !</span>
                    <!-- <i class="bi bi-pencil-square ps-5"></i> -->
                  </div>
                  <span>{{teamDesc}}</span>
                </div>
              </ul>
            </div>
          </div>
        </div>



        <div class="">
          <ul class="stats-list row gap-3 justify-content-center">
            <li class="stats-item col-lg-3 col-md-3 col-6 d-flex flex-column justify-content-center align-items-center">
              <span class="number">{{ teamUserRecord?.[0]?.Matches_played ?? '-' }}</span>
              <span class="label">PLAYED</span>
            </li>
            <li class="stats-item col-lg-3 col-md-3 col-6 d-flex flex-column justify-content-center align-items-center">
              <span class="number">{{ teamUserRecord?.[0]?.Matches_won ?? '-' }}</span>
              <span class="label">WON</span>
            </li>
            <li class="stats-item col-lg-3 col-md-3 col-6 d-flex flex-column justify-content-center align-items-center">
              <span class="number">{{ teamUserRecord?.[0]?.lost ?? '-' }}</span>
              <span class="label">LOST</span>
            </li>
            <li class="stats-item col-lg-3 col-md-3 col-6 d-flex flex-column justify-content-center align-items-center">
              <span class="number">{{ draw ?? '-' }}</span>
              <span class="label">DRAW</span>
            </li>
            <li class="stats-item col-lg-3 col-md-3 col-6 d-flex flex-column justify-content-center align-items-center">
              <span class="number">{{ teamUserRecord?.[0]?.Matches_no_results ?? '-' }}</span>
              <span class="label">NR</span>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class=" mb-3 ">
      <div>
        <!-- sportsRulet -->
        <div>
          <div class="p-3  card shadow m-3 position-relative">
            <div class="d-none d-md-none d-lg-none ">
              <h2 class="m-0"> Teams Record</h2>
              <table class="table border">
                <thead>
                  <tr>
                    <th scope="col">Game</th>
                    <th scope="col">Played</th>
                    <th scope="col">Won</th>
                    <th scope="col">Lost</th>
                    <th>Nr</th>
                    <th>Draw</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cricket 20 overs</td>
                    <td>148</td>
                    <td>55</td>
                    <td>49</td>
                    <td>44</td>
                    <td>24</td>

                  </tr>
                </tbody>
              </table>
            </div>
            <div class="m-0  " style=" top: 10%;">
              <h4>
                GAME: &nbsp; {{teamUserRecord[0]?.Sports_subcat_name}}
              </h4>
            </div>
            <h2 class="m-0 text-center">Team Win%</h2>
            <div class="chart-container overflow-hidden d-flex justify-content-center" style="max-height: 400px;">
              <canvas id="myChart"></canvas>
            </div>
          </div>
          <div class="p-3 card shadow m-3">
            <h2 class="m-0">Scheduled Matches</h2>
            <div class="overflow-auto">
              <table class="table border scrollable"
                *ngIf="teamUpcomingMatches && teamUpcomingMatches.length>0; else noRecord">
                <thead>
                <tr>
                  <th scope="col">Event</th>
                  <th scope="col">Opponent</th>
                  <th scope="col">Venue</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of teamUpcomingMatches">
                  <td>{{ item.Event_name }}</td>
                  <td>{{ item.versus_team }}</td>
                  <td>{{ item.Venue }}</td>
                  <td>{{ item.Match_Date }} / {{ item.Match_Time }}</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div class="p-3 card shadow m-3">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Team level">
                <table class="table border" *ngIf="teamLeaderBoardList && teamLeaderBoardList.length>0; else noRecord">
                  <thead>
                    <tr>
                      <th scope="col">Record type</th>
                      <th scope="col">Player</th>
                      <th scope="col">Record</th>
                      <th scope="col">Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of teamLeaderBoardList">
                      <td>{{item.Team_name}}/{{item.Table_header}} </td>
                      <td>{{item.F_name}}</td>
                      <td>{{item.record}}</td>
                      <td>{{item.rank}}</td>
                    </tr>
                  </tbody>
                </table>

              </mat-tab>
              <mat-tab label="Event level">
                <table class="table border"
                  *ngIf="teamEventLeaderboardList && teamEventLeaderboardList.length > 0; else noRecord">
                  <thead>
                    <tr>
                      <th scope="col">Record type</th>
                      <th scope="col">Player</th>
                      <th scope="col">Record</th>
                      <th scope="col">Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of teamEventLeaderboardList">
                      <td>{{item.Event_name}}/{{item.Table_header}} </td>
                      <td>{{item.F_name}}</td>
                      <td>{{item.record}}</td>
                      <td>{{item.rank}}</td>
                    </tr>
                  </tbody>
                </table>
              </mat-tab>
            </mat-tab-group>
            <div class="text-end">
              <button class="btn btn-info border button" (click)="navigatToLeaderBoard()">leaderboard</button>
              <!-- <button class="btn btn-info border button"    (click)="navigatToLeaderBoard()"> Event leaderboard</button> -->
            </div>
          </div>
          <div class="p-3 card shadow m-3 ">
            <div class="d-flex justify-content-between">
              <h2 class="m-0">Recent results</h2>
              <button class="border allBtn bg-warning rounded-2 m-1 button px-2" *ngIf="teamRecent.length>3"
                (click)="showAllCompMatch()">More</button>
            </div>



            <div class="overflow-auto">
              <table class="table border table-scrollable " >
                <thead>
                  <tr>
                    <th scope="col">Event</th>
                    <th scope="col">Opponent</th>
                    <th scope="col">Result </th>
                    <th scope="col">Scorecard</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of teamRecent.slice(0,3)">
                    <td>{{item.Event_name}}</td>
                    <td>{{item.versus_team}}</td>
                    <td>{{item.result}}</td>
                    <td><button class="rounded-2 bg-success text-white border button"
                        (click)="navigateToScorecardDetaile(item.Match_id)">Scorecard</button></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</div>

  <div *ngIf="!mainScreen && !pageTourn && showCompletedMatch">
    <div class="m-3 mb-0 fw-bolder" (click)="showAllCompMatch()"> <i class="bi bi-chevron-left "></i>Back</div>
    <div class="p-3 card shadow m-3">
      <div class="d-flex justify-content-between">
        <h2 class="m-0">Recent results</h2>
      </div>

      <div class="overflow-auto">
        <table mat-table [dataSource]="paginatedItems" class="mat-elevation-z8">

          <ng-container matColumnDef="Event">
            <th mat-header-cell *matHeaderCellDef>Event</th>
            <td mat-cell *matCellDef="let element">{{element.Event_name}}</td>
          </ng-container>

          <ng-container matColumnDef="Opponent">
            <th mat-header-cell *matHeaderCellDef>Opponent</th>
            <td mat-cell *matCellDef="let element">{{element.versus_team}}</td>
          </ng-container>

          <ng-container matColumnDef="Result">
            <th mat-header-cell *matHeaderCellDef>Result</th>
            <td mat-cell *matCellDef="let element">{{element.result}}</td>
          </ng-container>

          <ng-container matColumnDef="Scorecard">
            <th mat-header-cell *matHeaderCellDef>Scorecard</th>
            <td mat-cell *matCellDef="let element">
              <button class="rounded-2 bg-success text-white border button"
                (click)="navigateToScorecardDetaile(element.Match_id)">
                Scorecard
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator (page)="onPageChange($event)" [length]="totalRecords" [pageSize]="5"
        [pageSizeOptions]="[5,25, 50, 100]"></mat-paginator>

    </div>

  </div>


  <ng-template #noRecord>
    <div>
      <h4 class="m-0 text-center">
        No Record Found!
      </h4>
    </div>
  </ng-template>

  <div *ngIf="pageTourn">
    <div class="mx-1">
      <ng-container *ngIf="eventDetails && eventDetails.length >0 ;else noRecords">
        <div *ngFor="let events of eventDetails"
          class="scrollable-container my-3 mx-1  content rounded-4 border overflow-hidden shadow">
          <div class="  " style="height:100%;">
            <div class="card-header p-0 mb-2" style="border-bottom:none">
              <div class="header p-2 py-3 bg-white d-flex ">
                <div class="title  text-center d-flex align-item-center m-auto tournament-font"
                  style="color: #074873; font-weight: 700; font-size: 19px; font-family: 'Assist-Bold';">
                  &nbsp;{{events.Event_name}}</div>
              </div>
            </div>
            <ul class="nav nav-tabs tabs p-2 d-flex justify-content-around  tournament-font"
              [ngStyle]="{'background-color': 'white'}" id="appendTabs">
              <li [ngClass]="{'active': activeTab === data.tabNumber}" *ngFor="let data of LeaderBoardDetails"
                style="transform: skew(-15deg);">
                <a data-toggle="tab"
                  style="height: 35px; cursor: pointer; padding: 7px; text-decoration: none; color: #015b7e;  display: inline-block; font-family: 'Assist-Bold';"
                  [ngClass]="{ 'active-tab': activeTab === data.Tab_header }"
                  (click)="leaderBoardTabUI(data.Tab_header,data.Tab_id); activeTab = data.Tab_header">
                  <span
                    style="transform: skew(15deg); display: inline-block; font-weight: 800; font-family: 'Assist-Bold';">{{
                    data.Tab_header }}</span>
                </a>
              </li>
            </ul>
            <div class="m-0" *ngIf="slectedLeaderboard==='Batting'">
              <mat-accordion>
                <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                  *ngFor="let data of leaderBoardTabDetails">
                  <mat-expansion-panel-header (click)="expend(data.Table_id)">
                    <h4 class="panel-title ng-binding pt-2 tournament-font">
                      {{data.Table_header}} <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                    </h4>
                  </mat-expansion-panel-header>
                  <div *ngIf="data.Table_header==='Batting Max Runs (Aggregate)'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Innings</th>
                            <th>Runs</th>
                            <th>Average</th>
                            <th>Strike Rate</th>
                            <th>Fours</th>
                            <th>Sixers</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.Innings}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.average}}</td>
                            <td>{{data.strike_rate}}</td>
                            <td>{{data.fours}}</td>
                            <td>{{data.sixers}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Average Batting'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Innings</th>
                            <th>Average</th>
                            <th>Runs</th>
                            <th>Strike Rate</th>
                            <th>Fours</th>
                            <th>Sixers</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.Innings}}</td>
                            <td>{{data.Average}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.strike_rate}}</td>
                            <td>{{data.fours}}</td>
                            <td>{{data.sixers}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Strike Rate'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Innings</th>
                            <th>Strike Rate</th>
                            <th>Runs</th>
                            <th>Average</th>
                            <th>Fours</th>
                            <th>Sixers</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.Innings}}</td>
                            <td>{{data.strike_rate}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.average}}</td>
                            <td>{{data.fours}}</td>
                            <td>{{data.sixers}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Max Sixers (Batting)'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Innings</th>
                            <th>Sixers</th>
                            <th>Runs</th>
                            <th>Average</th>
                            <th>Fours</th>
                            <th>Strike Rate</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.Innings}}</td>
                            <td>{{data.sixers}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.average}}</td>
                            <td>{{data.fours}}</td>
                            <td>{{data.strike_rate}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Max Fours (Batting)'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Innings</th>
                            <th>Fours</th>
                            <th>Runs</th>
                            <th>Average</th>
                            <th>Sixers</th>
                            <th>Strike Rate</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.Innings}}</td>
                            <td>{{data.fours}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.average}}</td>
                            <td>{{data.sixers}}</td>
                            <td>{{data.strike_rate}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Innings'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th> Rank</th>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Runs</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.rank}}</td>
                            <td>{{data.fname}}</td>
                            <td>{{data.Team_name}}</td>
                            <td>{{data.total_runs}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="m-0" *ngIf="slectedLeaderboard==='Bowling'">
              <mat-accordion>
                <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                  *ngFor="let data of leaderBoardTabDetails">
                  <mat-expansion-panel-header (click)="expend(data.Table_id)">
                    <h4 class="panel-title ng-binding pt-2 tournament-font">
                      {{data.Table_header}} <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                    </h4>
                  </mat-expansion-panel-header>
                  <div *ngIf="data.Table_header==='Most Wickets Bowling'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0 tournament-font">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Wickets</th>
                            <th>Overs Bowled</th>
                            <th>Runs Conceded</th>
                            <th>Economy</th>
                            <th>Average</th>
                            <th>Strike Rate</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.wickets}}</td>
                            <td>{{data.overs}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.economy}}</td>
                            <td>{{data.Average}}</td>
                            <td>{{data.strike_rate}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Economy Bowling '">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Economy</th>
                            <th>Overs Bowled</th>
                            <th>Runs Conceded</th>
                            <th>Wickets</th>
                            <th>Average</th>
                            <th>Strike Rate</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.economy}}</td>
                            <td>{{data.overs}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.wickets}}</td>
                            <td>{{data.Average}}</td>
                            <td>{{data.strike_rate}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Bowling Average (Runs / Wickets)'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Average</th>
                            <th>Overs Bowled</th>
                            <th>Runs Conceded</th>
                            <th>Wickets</th>
                            <th>Economy</th>
                            <th>Strike Rate</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.Average}}</td>
                            <td>{{data.overs}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.wickets}}</td>
                            <td>{{data.economy}}</td>
                            <td>{{data.strike_rate}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Bowling Strikerate (Balls / Wickets)'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Strike Rate</th>
                            <th>Overs Bowled</th>
                            <th>Runs Conceded</th>
                            <th>Wickets</th>
                            <th>Economy</th>
                            <th>Average</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.fname}}</td>
                            <td>{{data.team_name}}</td>
                            <td>{{data.strike_rate}}</td>
                            <td>{{data.overs}}</td>
                            <td>{{data.runs}}</td>
                            <td>{{data.wickets}}</td>
                            <td>{{data.economy}}</td>
                            <td>{{data.Average}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Bowling'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Rank</th>
                            <th>Player Name</th>
                            <th>Team Name</th>
                            <th>Best Bowling</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.rank}}</td>
                            <td>{{data.fname}}</td>
                            <td>{{data.Team_name}}</td>
                            <td>{{data.best_bowling}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="m-0" *ngIf="slectedLeaderboard==='Team Results'">
              <mat-accordion>
                <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                  *ngFor="let data of leaderBoardTabDetails">
                  <mat-expansion-panel-header (click)="expend(data.Table_id)">
                    <h4 class="panel-title ng-binding pt-2 tournament-font">
                      {{data.Table_header}} <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                    </h4>
                  </mat-expansion-panel-header>
                  <div *ngIf="data.Table_header==='NRR - Till latest round winners'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Rank</th>
                            <th>Team Name</th>
                            <th>Played</th>
                            <th>Won</th>
                            <th>NRR</th>
                            <th>Group</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.rank}}</td>
                            <td>{{data.Team_name}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Points Table'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Group</th>
                            <th>Team</th>
                            <th>Played</th>
                            <th>Won</th>
                            <th>Lost</th>
                            <th>NRR</th>
                            <th>Points</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Margin'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Team</th>
                            <th>Opponents</th>
                            <th>Run Rate Difference</th>
                            <th>Match Result</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.TEAM}}</td>
                            <td>{{data.OPPONENT}}</td>
                            <td>{{data.net_run_rate}}</td>
                            <td>{{data.match_result}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Margin - Batting First'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Team</th>
                            <th>Margin - Runs</th>
                            <th>Target Set (Runs)</th>
                            <th>Opponents</th>
                            <th>Match Result</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.WinningTeam}}</td>
                            <td>{{data.Margin}}</td>
                            <td>{{data.TargetSet}}</td>
                            <td>{{data.Opponents}}</td>
                            <td>{{data.match_result}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                  <div *ngIf="data.Table_header==='Best Margin - Chasing'">
                    <div style="overflow-x: auto; width: 100%;">
                      <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                        <div class="searchBtn">
                          <i class="bi bi-search" style="float: left;"></i>
                          <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                            [(ngModel)]="searchText" (input)="search()" aria-label="Search" />
                        </div>
                      </div>
                      <table class="table border table-striped p-0">
                        <thead class="tournament-font">
                          <tr>
                            <th>Team</th>
                            <th>Margin - Runs</th>
                            <th>Target Set (Runs)</th>
                            <th>Opponents</th>
                            <th>Match Result</th>
                          </tr>
                        </thead>
                        <tbody class="tournament-font">
                          <tr *ngFor="let data of paginatedTeams">
                            <td>{{data.Winner}}</td>
                            <td>{{data.BallsDiff}}</td>
                            <td>{{data.TargetRuns}}</td>
                            <td>{{data.Opponent}}</td>
                            <td>{{data.Result}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                        [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                      </mat-paginator>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div class="m-0" *ngIf="slectedLeaderboard==='Fielding'">
            <mat-accordion>
              <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                *ngFor="let data of leaderBoardTabDetails">
                <mat-expansion-panel-header (click)="expend(data.Table_id)">
                  <h4 class="panel-title ng-binding pt-2 tournament-font">
                    {{data.Table_header}} <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                  </h4>
                </mat-expansion-panel-header>
                <div *ngIf="data.Table_header==='Most Dismissals'">
                  <div style="overflow-x: auto; width: 100%;">
                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                      <div class="searchBtn">
                        <i class="bi bi-search" style="float: left;"></i>
                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                          [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                      </div>
                    </div>
                    <table class="table border table-striped p-0">
                      <thead class="tournament-font">
                        <tr>
                          <th>Rank</th>
                          <th>Player Name</th>
                          <th>Team Name</th>
                          <th>Innings</th>
                          <th>Total Dismissals</th>
                          <th>Catches</th>
                          <th>Run Out</th>
                          <th>Stumpings</th>
                        </tr>
                      </thead>
                      <tbody class="tournament-font">
                        <tr *ngFor="let data of paginatedTeams">
                          <td>{{data.rank}}</td>
                          <td>{{data.playername}}</td>
                          <td>{{data.Team_name}}</td>
                          <td>{{data.innings}}</td>
                          <td>{{data.dismissals}}</td>
                          <td>{{data.catches}}</td>
                          <td>{{data.Runout}}</td>
                          <td>{{data.stumpings}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                      [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                    </mat-paginator>
                  </div>
                </div>
                <div *ngIf="data.Table_header==='Most Stumpings'">
                  <div style="overflow-x: auto; width: 100%;">
                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                      <div class="searchBtn">
                        <i class="bi bi-search" style="float: left;"></i>
                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                          [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                      </div>
                    </div>
                    <table class="table border table-striped p-0">
                      <thead class="tournament-font">
                        <tr>
                          <th>Rank</th>
                          <th>Player Name</th>
                          <th>Team Name</th>
                          <th>Innings</th>
                          <th>Stumpings</th>
                        </tr>
                      </thead>
                      <tbody class="tournament-font">
                        <tr *ngFor="let data of paginatedTeams">
                          <td>{{data.rank}}</td>
                          <td>{{data.playername}}</td>
                          <td>{{data.Team_name}}</td>
                          <td>{{data.innings}}</td>
                          <td>{{data.stumpings}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                      [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                    </mat-paginator>
                  </div>
                </div>
                <div *ngIf="data.Table_header==='Most Catches'">
                  <div style="overflow-x: auto; width: 100%;">
                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                      <div class="searchBtn">
                        <i class="bi bi-search" style="float: left;"></i>
                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                          [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                      </div>
                    </div>
                    <table class="table border table-striped p-0">
                      <thead class="tournament-font">
                        <tr>
                          <th>Rank</th>
                          <th>Player Name</th>
                          <th>Team Name</th>
                          <th>Innings</th>
                          <th>Catches</th>
                        </tr>
                      </thead>
                      <tr *ngFor="let data of paginatedTeams">
                        <td>{{data.rank}}</td>
                        <td>{{data.playername}}</td>
                        <td>{{data.Team_name}}</td>
                        <td>{{data.innings}}</td>
                        <td>{{data.catches}}</td>
                      </tr>
                      <tbody class="tournament-font">
                      </tbody>
                    </table>
                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                      [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                    </mat-paginator>
                  </div>
                </div>
                <div *ngIf="data.Table_header==='Most Runouts'">
                  <div style="overflow-x: auto; width: 100%;">
                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                      <div class="searchBtn">
                        <i class="bi bi-search" style="float: left;"></i>
                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams"
                          [(ngModel)]="searchText" (input)="searchTeam()" aria-label="Search" />
                      </div>
                    </div>
                    <table class="table border table-striped p-0">
                      <thead class="tournament-font">
                        <tr>
                          <th>Rank</th>
                          <th>Player Name</th>
                          <th>Team Name</th>
                          <th>Innings</th>
                          <th>Run Out</th>
                        </tr>
                      </thead>
                      <tbody class="tournament-font">
                        <tr *ngFor="let data of paginatedTeams">
                          <td>{{data.rank}}</td>
                          <td>{{data.playername}}</td>
                          <td>{{data.Team_name}}</td>
                          <td>{{data.innings}}</td>
                          <td>{{data.runout}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize"
                      [pageSizeOptions]="[10, 25, 100]" (page)="onPageChange($event)">
                    </mat-paginator>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </ng-container>
      <ng-template #noRecords>
        <div class="p-3 text-center not-found fs-6 fw-bolder">No records found!</div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="showPlayers">
    <div class="m-3 mb-0 fw-bolder" (click)="showPlayerList()"> <i class="bi bi-chevron-left "></i>Back</div>
    <div class="container">
      <h2 class="m-0 p-0 text-center fw-bolder">{{teamDetails[0]?.Team_Name}} &nbsp; ({{teamDetails.length}}&nbsp;
        Players)</h2>
      <div class="row gap-2 px-2 justify-content-center my-3">
        <ng-container *ngFor="let item of teamDetails">
          <div class="col-lg-3 col-md-4 card shadow text-center ">
            <div class="d-flex flex-column p-3 cursor-pointer" (click)="navigateToPlayerProfile(item.User_id)">
              <!-- <img src="" alt="userImage"> -->
              <i class="bi bi-person-fill fs-1 userImg"></i>
              <h3 class="m-0 fw-bolder">{{item.F_name}}</h3>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
