<div *ngIf="data.identifier === 'forgotPassword'">
    <div mat-dialog-content>A Temporary Password has been send to your register E-mail </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="matDialogConfromation('forgotPassword')" >Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'CheckPassword'">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;">Old Password is incorrect</div>
    <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-button mat-dialog-close (click)="matDialogConfromation('CheckPasswordSuccess')" class="g-fold " style="font-size: 80%;" class="g-fold">Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'oldAndNewPassword'">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;">New password & old password can't be the same</div>
    <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-button mat-dialog-close (click)="matDialogConfromation('oldAndNewPasswordSuccess')" class="g-fold " style="font-size: 80%;" class="g-fold">Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'loader'">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;">
        <div class="loader">
            <app-loader class="d-flex justify-content-center  mx-auto"></app-loader>
        </div>
    </div>
</div>
<div *ngIf="data.identifier === 'Password Updated Success'">
    <div mat-dialog-content>Password updated successfully </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="matDialogConfromation('Password Updated Success')" >Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'Password Updated Faild'">
    <div mat-dialog-content>unable to update the Password</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="matDialogConfromation('')" >Ok</button>
    </div>
</div>
<div *ngIf="data.identifier === 'search'" class="search">
    <div mat-dialog-content style="font-size: 14px;font-weight: 500;" class="w-100">
        <div mat-dialog-actions class="p-0 position-absolute" style="z-index: 1;right: 14px;top: 3px;">
            <div mat-button  (click)="matDialogConfromation('')" >
                <i class="bi bi-x-lg text-end searchX"></i>
            </div>
        </div>
        <div >
            <app-home-search></app-home-search>
        </div>
    </div>
</div>

<div *ngIf="data.identifier === 'videoScorecard'"  class="videoScorecard">
    <div mat-dialog-actions style="z-index: 1;right: 14px;top: 3px;">
        <div mat-button  (click)="matDialogConfromation('')" style="cursor: pointer;" >
            <i class="bi bi-x-lg text-end searchX fw-bold fs-4" style="color: black;"></i>
        </div>
    </div>
    <div mat-dialog-content>
        <!-- <video class="w-100 h-100" controls autoplay type="video/mp4">
            <source [src]="data.url" >
        </video> -->
        <video  class="video-with-border scorecardVideoForBowler shadow video-js w-100 h-100"
            controlslist="nodownload" autoplay="autoplay" controls="controls" preload="auto" playsinline>
            <source [src]="data.url" type="video/mp4" />
        </video>
    </div>
</div>


<div *ngIf="data.identifier==='RunVideo'" class="videoScorecard">
    <mat-dialog-content>
        <div mat-dialog-actions style="z-index: 1;right: 14px;top: 3px;display: flex;">
            <div mat-button class="text-end" (click)="matDialogConfromation('')" style="cursor: pointer;">
                <i class="bi bi-x-lg text-end searchX fw-bold fs-4" style="color: black;"></i>
            </div>
        </div>
        <div *ngIf="data.videos && data.videos.length > 0 &&data.identifier==='RunVideo'" class="video-list-container">
            <div class="circle-container">
                <div class="circle-item" *ngFor="let video of data.videos; let i = index" (click)="playVideo(video)">
                    <span>{{ video.overBall }}</span>
                </div>
            </div>
            <video *ngIf="currentVideoURL" controls autoplay [src]="currentVideoURL" width="100%" controlslist="nodownload">
                Your browser does not support the video tag.
            </video>
        </div>
        <div *ngIf="!data.videos || data.videos.length === 0">
            <p>No videos available</p>
        </div>
    </mat-dialog-content>
</div>
<div *ngIf="data.identifier === 'wicketVideo'" class="videoScorecard">
    <mat-dialog-content>
        <div mat-dialog-actions class="col-12"
            style="z-index: 1;right: 14px;top: 3px; display: flex;justify-content:space-between;">
            <h3 class="col-9 text-center align-items-center mb-0" style="position: relative;left: 12%;">Wicket Video
            </h3>
            <div mat-button class="col-2 text-end" (click)="matDialogConfromation('')" style="cursor: pointer;">
                <i class="bi bi-x-lg text-end searchX fw-bold fs-4" style="color: black;"></i>
            </div>
        </div>
        <div *ngIf="data.videos &&  data.videos.length > 0 && data.identifier === 'wicketVideo'" class="video-container">
            <div class="circle-container">
                <div class="circle-item" *ngFor="let video of data.videos; let i = index" (click)="playVideo(video)">
                    <span>{{ video.overBall }}</span>
                </div>
            </div>
            <video *ngIf="currentVideoURL"  controls autoplay [src]="currentVideoURL" width="100%" controlslist="nodownload">
                Your browser does not support the video tag.
            </video>
        </div>
        <div *ngIf="!data.videos || data.videos.length === 0">
            <p>No video available for this wicket.</p>
        </div>
    </mat-dialog-content>
</div>
