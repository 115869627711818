import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent {

  years: number[] = [];

  @Input() userId: any;
  playerStats: any;
 selectedYear:any;
  @Input() playerName: any;

  constructor(private route :ActivatedRoute,private eventService:EventService,private router :Router){
  }

  ngOnInit(){
    this.updateOptions(this.selectedYear)
    const routeState = history.state;
    this.updateOptions(new Date().getFullYear());
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedYear'] && !changes['selectedYear'].isFirstChange()) {
      this.updateOptions(this.selectedYear)
    }
  }

  getPlayerStats( userId:any,year:any){
    this.eventService.getPlayerStats(userId,year).subscribe((response:any)=>{
      if(response.status == 'TRUE'){
        this.playerStats = response.result;
      } else {
        this.playerStats = [];
      }
    })
  }
  updateOptions(selectedYear: number): void {
    
 const currentYear = new Date().getFullYear();
    
    const minYear = 2016;

    this.years = [];

    for (let i = currentYear; i >= minYear; i--) {
      this.years.push(i);
    }
    this.selectedYear = selectedYear;

   this.getPlayerStats(this.userId,selectedYear);
  }

  backToMyTeams(){
    window.history.back();
    // this.router.navigate(['/playerInfo'],{state:{showTeamDetail:"Yes",teamId:this.teamId,sportId:this.sportId}});
  }
}
