import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiEndpoint } from '../common/constants/endpoint.def';

@Injectable({
  providedIn: 'root'
})
export class BadmintonService {

  constructor(private http: HttpClient) { }

  getBadmintonMatchList(matchId: string, sportId: string) {
    return this.http.get(environment.serviceUrl + ApiEndpoint.BADMINTON_MATCH_STAUTUS.GET_BADMINTON_MATCH_LIST.replace('{limit}', '').replace('{matchId}', matchId).replace('{matchStatus}', '').replace('{sportId}', sportId).replace('{userId}', ''))
  }

  getBadmintonPlayerScoreDetailForMatchForehand(matchId: string, sportId: string){
    return this.http.get(environment.serviceUrl+ApiEndpoint.BADMINTON_MATCH_STAUTUS.GET_BADMINTON_PLAYER_SCORE_DETAIL_FOR_MATCH_FOREHAND.replace('{limit}', '').replace('{matchId}', matchId).replace('{matchStatus}', '').replace('{sportId}', sportId).replace('{userId}', ''))
  }

  getPlayerProfile(){
    return this.http.get(environment.serviceUrl+ApiEndpoint.BADMINTON_SCORE_CARD.GET_PLAYER_PROFILE)
  }
  getRecentMatches(limit:any,matchStatus:any,sportId:any,userId:any){
    return this.http.get(environment.serviceUrl+ApiEndpoint.BADMINTON_MATCH_STAUTUS.GET_BADMINTON_MATCH_LIST.replace('{limit}',limit).replace('{matchId}', '').replace('{matchStatus}', matchStatus).replace('{sportId}', sportId).replace('{userId}', userId))
  }

  getPlayerTrack(){
    return this.http.get(environment.serviceUrl+ApiEndpoint.BADMINTON_SCORE_CARD.GET_PLAYER_WIN_AND_LOSE_TRACK)
  }
  getTeamsData(AggType:any){
    return this.http.get(environment.serviceUrl+ApiEndpoint.BADMINTON_SCORE_CARD.GET_TEAM_DETAIL.replace('{AggType}',AggType))
  }
}
