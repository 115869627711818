import { Component } from '@angular/core';
import * as moment from 'moment';
import { BadmintonService } from 'src/app/service/badminton.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-badminton-player-profile',
  templateUrl: './badminton-player-profile.component.html',
  styleUrls: ['./badminton-player-profile.component.css']
})
export class BadmintonPlayerProfileComponent {
  profileData: any;
  playerInfo: any;
  baseUrl = 'http://www.sportsruler.com'
  match: any;
  matchlist: any;
  teamOnePoint = 0;
  teamTwoPoint = 0;
  teamOneScore: any;
  teamtwoScore: any;
  matchResult: any;
  setDetails=[];
  playerData: any;
  playerResult: any;
  teamsResponce: any;
  teamMatches: any;
  
  

  constructor(private service: BadmintonService) { }

  toCamelCaseWithSpace(input: string): string {
    return input.split(' ').map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }

  ngOnInit() {
    this.getProfileDetails();
    this.getBadmintonMatchList('3','C','3','2156');
    this.getPlayerResultTrack();
    this.getTeamMatches('E');

  }

  getProfileDetails() {
    this.service.getPlayerProfile().subscribe((response) => {
      this.profileData = response
      if (this.profileData.result?.length > 0) {
        this.playerInfo = this.profileData.result?.[0]
        this.playerInfo.profile_pic = this.baseUrl + this.playerInfo.profile_pic.replace(/\.\.\//g, '/');
        this.playerInfo.d_o_b = moment(this.playerInfo.d_o_b).format('L');
      } else {
        console.log("the player details is not available");

      }
    })

  }

  getBadmintonMatchList(limit:any,matchStatus:any,sportId:any,userId:any){
    this.service.getRecentMatches(limit,matchStatus,sportId,userId).subscribe((resp)=>{
      this.match=resp
      if(this.match.result?.length>0){
        this.matchlist=this.match.result 
        this.matchlist.forEach((data: any) => {
          this.teamOnePoint =0;
          this.teamTwoPoint =0;
          data.match_date = moment(data.match_date).format("ll");
          data.match_time=moment(data.match_time,'HH:mm:ss').format('HH:mm A')
          data.team1_detail = data.team1_detail.map((name: any) => this.toCamelCaseWithSpace(name.player_name))
          data.team2_detail = data.team2_detail.map((name: any) => this.toCamelCaseWithSpace(name.player_name))          
          data.setDetail = data.setDetail.forEach((items: any) => {
            this.teamOneScore=items.team1_score
            this.teamtwoScore=items.team2_score
            if ((this.teamOneScore > this.teamtwoScore ) &&  this.teamOneScore !=0 && this.teamtwoScore !=0) {
              this.teamOnePoint += 1;
            } else if ((this.teamOneScore < this.teamtwoScore) &&  this.teamOneScore !=0 && this.teamtwoScore !=0) {
              this.teamTwoPoint += 1;
            }
          })
          data['teamOnePoint'] = this.teamOnePoint;
          data['teamTwoPoint'] = this.teamTwoPoint;
          if(data.teamOnePoint > data.teamTwoPoint){
            data['matchResult'] = data.team1_detail
          }else{
            data['matchResult'] = data.team2_detail
          }
        });
      }else{
        console.log('matchlist of the player not available');        
      }
    })
  }

  getPlayerResultTrack(){
    this.service.getPlayerTrack().subscribe((responce)=>{
      this.playerData=responce
      if(this.playerData.result?.length>0){
        this.playerResult=this.playerData.result?.[0]
      }else{
        console.log('the player match result datas not found');
      }   
    })
  }

  getTeamMatches(AggType:any){    
    this.service.getTeamsData(AggType).subscribe((responce)=>{
      this.teamsResponce=responce
      if(this.teamsResponce.result?.length>0){
        this.teamMatches=this.teamsResponce.result
      }else{
        console.log('The team matches data not available');
        
      }
    })
  }
  

}
