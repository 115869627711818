import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-scorecard-card',
  templateUrl: './scorecard-card.component.html',
  styleUrls: ['./scorecard-card.component.css']
})
export class ScorecardCardComponent implements OnInit{
  @Input() matchData : any;
  @Input() index : any;

  windowWidth: number = 0;
  windowHeight: number = 0;
  orgCode: any;

  getWindowSize() {    
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }
  
  constructor(private service : CricketScoreCardService, private router: Router,private orgFilter:OrgcodeFilterService){}

  ngOnInit(): void {  
    
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath

    this.getWindowSize();
  }
  
  showScorecard(matchId : any, Team1 : any, Team2 : any, teamone : any, teamtwo : any){
    let matchInfo = {matchId, Team1, Team2, teamone, teamtwo};
    this.service.matchUniqueId.next(matchInfo);
    this.router.navigate([`${this.orgCode}/scorecardDetails`,matchId]);
  }
}
