import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-top-performers',
  templateUrl: './top-performers.component.html',
  styleUrls: ['./top-performers.component.css']
})
export class TopPerformersComponent implements OnInit {
  @Input() matchId: any;
  FirstInnings = 1;
  SecondInnings = 2;
  inningsBatsmanSumm: any = [];
  teamName: any;
  inningsBowlingSumm: any;
  team1Active: boolean = false;
  team2Active: boolean = false;
  bothTeam: any;
  teams: any;
  inningsplayedfirst: any;
  battsmanplayed: boolean=false;
  constructor(private service: CommonService,private scoreService: CricketScoreCardService) { }
  ngOnInit(): void {
    this.matchId = this.matchId;
    this.getBothTeamData();
    this.innings1();
  }

  getInningsBattingSumm(matchId: any, innings: any,) {
    this.service.getBattingSumm(matchId, innings).subscribe((battingSumm: any) => {
      if (battingSumm.status === 'TRUE') {
        this.inningsplayedfirst=battingSumm.result.filter((data:any)=> data.balls_faced!==(0||null) && data.runs!==0);
        if(this.inningsplayedfirst.length>0){
          this.battsmanplayed=false;
        }else{
          this.battsmanplayed=true;
        }        
        this.inningsBatsmanSumm = battingSumm.result;
        this.teamName = this.inningsBatsmanSumm[0].Team_Name;
      }
    })
  }
  getInningsBowlingSumm(matchId: any, innings: any,) {
    this.service.getBowlingSumm(matchId, innings).subscribe((bowlingSumm: any) => {
      if (bowlingSumm.status === 'TRUE') {
        this.inningsBowlingSumm = bowlingSumm.result;
      }
    })
  }
  
  innings1() {
    this.getInningsBattingSumm(this.matchId, this.FirstInnings);
    this.getInningsBowlingSumm(this.matchId, this.FirstInnings);
    this.team1Active = true;
    this.team2Active = false;
  }
  innings2() {
    this.getInningsBattingSumm(this.matchId, this.SecondInnings);
    this.getInningsBowlingSumm(this.matchId, this.SecondInnings);
    this.team1Active = false;
    this.team2Active = true;
  }
  getBothTeamData() {
    this.scoreService.getCricketMatchScoresAndPlayingEleven(this.matchId).subscribe((response: any) => {
      if (Array.isArray(response.result)) {
        this.bothTeam = response.result[0];
        this.teams = this.bothTeam;
      } else {
        this.bothTeam = response.result.matchDetails;
        this.teams = this.bothTeam;
      }
    });
  }

}
