
<!-- <app-loader *ngIf="loaderService.isLoading | async"></app-loader> -->


<div class="container mt-3 mb-5" [hidden]="!(overCommentaryData.length > 0)">
  <mat-card>
    <mat-card-content>
      <mat-tab-group (selectedTabChange)="onChange($event)" *ngIf="matchDetail == 'C'">
        <mat-tab label="{{team1}}">
          <app-commentary-card [completedData]="overCommentaryData" (limit)="getMoreResultCommentary($event,team1)">
          </app-commentary-card>
        </mat-tab>
        <mat-tab label="{{team2}}">
          <app-commentary-card [completedData]="overCommentaryData" (limit)="getMoreResultCommentary($event,team2)">
          </app-commentary-card>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<div class="container mt-3 mb-5" [hidden]="!(liveCommentaryData.length > 0) || !(batsmanSumm.length > 0) || !(bowlerSumm.length > 0)
  || !(extraSumm.length > 0) || !(tenBalls.length > 0)">
  <mat-card *ngIf="matchDetail == 'P'">
    <mat-card-content>
      <app-commentary-card [batsmanSumm]="batsmanSumm" [bowlerSumm]="bowlerSumm" [extraSumm]="extraSumm"
      [tenBalls]="tenBalls" [liveData]="liveCommentaryData" (limit)="getMoreLiveCommentary($event)"></app-commentary-card>
    </mat-card-content>
  </mat-card>
</div>
