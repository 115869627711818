import { Component, Input, OnInit } from '@angular/core';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-matchinfo',
  templateUrl: './match-info.component.html',
  styleUrls: ['./match-info.component.css'],
})

export class MatchInfoComponent implements OnInit {

  venue!: string;
  @Input() matchId: any;
  cricketMatchScoreAndPlayingXi: any = [];
  venueStatus: any = [];
  venueStatsDetails: any = [];
  data: any = [];
  averageValue: any;
  highestFirstInnings: any;
  highestSecondInnings: any;
  firstLowest: any;
  secondLowest: any;


  constructor(private scorecardService: CricketScoreCardService) {
  }

  ngOnInit() {
    this.getCricketMatchScoresAndPlayingEleven(this.matchId);
  }

  getCricketMatchScoresAndPlayingEleven(matchId: number) {
    this.scorecardService.getCricketMatchScoresAndPlayingEleven(matchId).subscribe((response:any) => {
      if (response.status == "SUCCESS" ||"TRUE"){

      this.cricketMatchScoreAndPlayingXi = response.result.matchDetails;
        this.data = this.cricketMatchScoreAndPlayingXi;
        this.venue = this.data.venue;
        this.scorecardService.getVenueStats(this.venue).subscribe((response:any) => {
          if (!response.result) {
            console.log("there is no venu status");
          }
          else{
            this.venueStatsDetails = response.result;
            this.averageValue=this.venueStatsDetails.avgValueData[0];
            this.highestFirstInnings=this.venueStatsDetails.inn1HIGHList[0]
            this.highestSecondInnings=this.venueStatsDetails.inn2HIGHList[0]
            this.firstLowest=this.venueStatsDetails.inn1LOWList[0]
            this.secondLowest=this.venueStatsDetails.inn2LOWList[0]
          }
        })
      }else{
        console.log("there is no match details.");
      }
    })
  }
}
