import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { CommonService } from 'src/app/service/common.service';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';


interface Icon {
  name: string;
  shortCode: string;
  img: string;
  imgSelected: string;
  isSelected: boolean;
}
interface RecommandedItem {
  value: string;
}

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.css']
})
export class HomeSearchComponent {
  
  searchStr: String = '';
  @ViewChild('searchInput') searchInputRef!: ElementRef;
  currentPlaceholder = 'Start typing...';
  placeholderInterval: any;
  categories = ['', 'People', 'Academy', 'Teams', 'Events',];
  selectedCategory: string = this.categories[0];
  selectedCategories: string[] = [];
  icons: Icon[] = [
    { name: 'People', shortCode: 'u', img: 'assets/peoplesB.png', imgSelected: 'assets/peoplesW.png', isSelected: false },
    // { name: 'Academies', shortCode: 'a', img: 'assets/AcademyB.png', imgSelected: 'assets/AcademyW.png', isSelected: false },
    { name: 'Teams', shortCode: 't', img: 'assets/teamsB.png', imgSelected: 'assets/teamsW.png', isSelected: false },
    { name: 'Events', shortCode: 'e', img: 'assets/eventsB.png', imgSelected: 'assets/eventsW.png', isSelected: false },
    // { name: 'Communities', shortCode: 'c', img: 'assets/communitiesB.png', imgSelected: 'assets/communitiesW.png', isSelected: false }
  ];
  mainScreen: boolean = true;
  searchResults: any[] = []; // Store search results
  showPeople: boolean = true;
  showAcademies: boolean = true;
  showTeam: boolean = true;
  showCommunities: boolean = true;
  showEvents: boolean = true;
  shortCodes: any[] = [];
  people: any[] = [];
  academies: any[] = [];
  teams: any[] = []
  communities: any[] = []
  events: any[] = []
  recommandedArray: RecommandedItem[] = [
    { value: 'CSK' },
    { value: 'Junior Super Kings' },
    { value: 'CPC' },
    { value: 'ACPL' }
  ];
  dialogRef: MatDialogRef<CommonAlertsComponent> | any;
  totalPages: number = 0;
  currentPageEvents = 1;
  totalPagesEvents: number = 0;
  currentPageTeams = 1
  totalPagesTeams: number = 0;
  filteredPeople = [...this.people];
  filteredEvents = [...this.people];
  searchQuery: String = '';
  filteredTeam = [...this.people];
  searchTeam: String = ''
  searchEvent: String = ''
  isLoading: boolean=false;
  orgCode:any;
  
  constructor(private service: CommonService, private router: Router,private commonAlertsComponent:CommonAlertsComponent,private orgFilter:OrgcodeFilterService) { }

  dataSourcePeople = new MatTableDataSource(this.people);
  dataSourceEvents = new MatTableDataSource(this.events);
  dataSourceTeams = new MatTableDataSource(this.teams);

  @ViewChild('paginatorPeople') paginatorPeople: MatPaginator | any;
  @ViewChild('paginatorEvents') paginatorEvents: MatPaginator | any;
  @ViewChild('paginatorTeams') paginatorTeams: MatPaginator | any;

  ngOnInit() {
    this.dataSourcePeople.paginator = this.paginatorPeople;
    this.dataSourceEvents.paginator = this.paginatorEvents;
    this.dataSourceTeams.paginator = this.paginatorTeams;
    this.calculateTotalPages();
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  ngAfterViewInit(): void {
    this.searchInputRef.nativeElement.focus();
    this.startPlaceholderRotation();
  }

  ngOnDestroy(): void {
    clearInterval(this.placeholderInterval);
  }

  startPlaceholderRotation() {
    const placeholders = [
      'Search for People...',
      'Search for Academies...',
      'Search for Teams...',
      'Search for Events...',
    ];
    let index = 0;
    this.placeholderInterval = setInterval(() => {
      this.currentPlaceholder = placeholders[index];
      index = (index + 1) % placeholders.length;
    }, 1000);
  }

  updateCategory(category: Icon) {
    if (category.shortCode !== 'All') {
      if (category.isSelected) {
        this.selectedCategories = this.selectedCategories.filter(cat => cat !== category.name);
        category.isSelected = false;
        if (this.selectedCategories.length === 0) {
          this.selectedCategory = '';
        }
      } else {
        this.selectedCategories.push(category.name);
        category.isSelected = true;
        this.selectedCategory = category.name;
      }
    } else {
      this.selectedCategories = [];
      this.icons.forEach(icon => icon.isSelected = false);
      this.selectedCategory = '';
    }
  }

  search() {
    this.ngOnDestroy();
    this.getSearchResult(this.searchStr);
    this.mainScreen = !this.mainScreen;
    this.searchEvent=''
    this.searchQuery=''
    this.searchTeam=''
  }

  showResult() {
    this.searchStr=''
    this.mainScreen = !this.mainScreen;
    this.icons.forEach(element => {
      element.isSelected=false
    });
    this.selectedCategories=[]
  }

  getSearchResult(searchString: String) {
    this.isLoading=true;
    let filters = {
      academiesFilter: '',
      communityFilter: '',
      eventFilter: '',
      peopleFilter: '',
      teamFilter: '',
      sportFilter: '',
      userId: ''
    };
    if (this.selectedCategories.length === 0 || this.selectedCategories.includes('All')) {
      filters = {
        academiesFilter: 'a',
        communityFilter: '',
        eventFilter: 'e',
        peopleFilter: 'u',
        teamFilter: 't',
        sportFilter: '1',
        userId: 'id'
      };
    } else {
      this.selectedCategories.forEach(categoryName => {
        const category = this.icons.find(icon => icon.name === categoryName);
        if (category) {
          switch (category.shortCode) {
            case 'a':
              filters.academiesFilter = 'a';
              break;
            case 'c':
              filters.communityFilter = '';
              break;
            case 'e':
              filters.eventFilter = 'e';
              break;
            case 'u':
              filters.peopleFilter = 'u';
              break;
            case 't':
              filters.teamFilter = 't';
              break;
            case 'id':
              filters.userId = 'id'
          }
        }
      });
    }
    const sportFilter = '1'
    this.service.getSearchResult(
      searchString,
      filters.academiesFilter,
      filters.communityFilter,
      filters.eventFilter,
      filters.peopleFilter,
      filters.userId.toString(),
      filters.teamFilter,
      sportFilter
      // filters.sportFilter,
      // filters.userId
    ).subscribe((response: any) => {
      this.people = [];
      this.events=[];
      this.teams=[]
      this.searchResults = response.result;
      this.searchResults.forEach(searchResult => {
        if (searchResult.diff == 'U') {
          this.people.push(searchResult)
        } else if (searchResult.diff == 'T') {
          this.teams.push(searchResult)
        } else if ('E') {
          this.events.push(searchResult)
        } else if ('A') {
          this.academies.push(searchResult)
        }
      });
      this.calculateTotalPages()

      if(this.people.length>0|| this.teams.length>0|| this.events.length>0){
        this.isLoading=false;
      }else if(response.message=='No Record found'){
        this.isLoading=false;
      }
    });
  }
  navigateToPlayerprofile(userId: any) {
      this.commonAlertsComponent.matDialogConfromation('')
      this.router.navigate([`${this.orgCode}/playerprofile`, userId]);
  }
  navigateToCricketSumm(teamId: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        teamId: teamId,
      }
    };
    this.commonAlertsComponent.matDialogConfromation('')
    this.router.navigate([`${this.orgCode}/cricketTeamSumm`],  { queryParams: { Id: teamId } });
  }
  navigateToTournament(eventId: any) {
    const sportId = '1'
    this.commonAlertsComponent.matDialogConfromation('')
    this.router.navigate([`${this.orgCode}/tournament`, eventId, sportId])

  }
  recommend(data: any) {
    this.searchStr = data;
  }
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    const searchButton = document.getElementById('searchButton') as HTMLButtonElement;
    if (searchButton) {
      searchButton.click();
    }
  }
  currentPage = 1;
  itemsPerPage = 5;
  filterPeople() {
    this.filteredPeople = this.people.filter(person =>
      person.thename.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
    this.currentPage=1
    this.calculateTotalPages();
  }
  filterTeam() {
    this.filteredTeam = this.teams.filter(team =>
      team.thename.toLowerCase().includes(this.searchTeam.toLowerCase())
    );
    this.currentPageTeams=1
    this.calculateTotalPages();
  }
  filterEvent() {
    this.filteredEvents = this.events.filter(event =>
      event.thename.toLowerCase().includes(this.searchEvent.toLowerCase())
    );
    this.currentPageEvents=1
    this.calculateTotalPages();
  }


  get paginatedPeople() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if (this.searchQuery == '')
      return this.people.slice(startIndex, endIndex);
    else {
      this.totalPages = this.filteredPeople.length
      return this.filteredPeople.slice(startIndex, endIndex);
    }


  }
  changePage(page: number) {
    this.currentPage = page;
    this.calculateTotalPages()
  }
  calculateTotalPages() {
    this.totalPages = Math.ceil(this.people.length / this.itemsPerPage);
    this.totalPagesEvents = Math.ceil(this.events.length / this.itemsPerPage);
    this.totalPagesTeams = Math.ceil(this.teams.length / this.itemsPerPage);
  }
  get paginatedEvents() {
    const startIndex = (this.currentPageEvents - 1) * this.itemsPerPage;
    if (this.searchEvent == '')
      return this.events.slice(startIndex, startIndex + this.itemsPerPage);
    else {
      this.totalPagesEvents = this.filteredEvents.length
      return this.filteredEvents.slice(startIndex, startIndex + this.itemsPerPage);
    }

  }
  changePageEvents(page: number) {
    if (page >= 1 && page <= this.totalPagesEvents) {
      this.currentPageEvents = page;
    }
  }
  get paginatedTeams() {
    const startIndex = (this.currentPageTeams - 1) * this.itemsPerPage;
    if (this.searchTeam == '')
      return this.teams.slice(startIndex, startIndex + this.itemsPerPage);
    else {
      this.totalPagesTeams = this.filteredTeam.length
      return this.filteredTeam.slice(startIndex, startIndex + this.itemsPerPage);
    }
  }
  changePageTeams(page: number) {
    if (page >= 1 && page <= this.totalPagesTeams) {
      this.currentPageTeams = page;
    }
  }
  restrictSpecialCharacters(event: any) {
    const input = event.target.value;
    const filteredInput = input.replace(/[^a-zA-Z0-9 \-]/g, '');
    this.searchStr = filteredInput;

  }
  
}
