import { Component, Input } from '@angular/core';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-live-score-card-carousal',
  templateUrl: './live-score-card-carousal.component.html',
  styleUrls: ['./live-score-card-carousal.component.css']
})
export class LiveScoreCardCarousalComponent {

  // @Input() liveAndCompleteList: any;
  @Input() completeList:any;
  // @Input() liveList:any;
  @Input() data:any;
  @Input() upcomingMatch:boolean=false;
  orgCode:any

constructor(private orgFilter:OrgcodeFilterService){}

ngOnInit(){
  this.orgCode=this.orgFilter.storageItems.value[0].orgPath
}
  
  customOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      992: {
        items: 3
      }
    },
    nav: false,
  };
  customOptionsupcoming = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: false,
    // autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      992: {
        items: 2
      }
    },
    nav: false,
  };


}
