import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { VideoService } from 'src/app/service/video.service';

@Component({
  selector: 'app-video-detail-page',
  templateUrl: './video-detail-page.component.html',
  styleUrls: ['./video-detail-page.component.css']
})
export class VideoDetailPageComponent {

  video_id: any;
  category_id: any;
  videoByID: any[] = [];
  videoArrey: any[]=[];
  videoResponce: any;
  constructor(private videoService: VideoService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.video_id = this.route.snapshot.paramMap.get('videoID')
    this.category_id = this.route.snapshot.paramMap.get('catID')
    this.getVideoByVideoID();
  }

  getVideoByVideoID() {
    this.videoService.getVideoByID(this.video_id, this.category_id).subscribe((response: any) => {
        if (response.status === 'TRUE') {
          this.videoByID = response?.result[0];
          this.videoArrey.push(this.videoByID);
          this.videoArrey.forEach((data: any) => {
            data.created_date_time = moment(data.created_date_time).format("LL");
          });
        } else {
          console.error('Video request failed:', response.status);
        }
      }
      );
    }
}
