<div *ngIf="tournamentsLiveList" class="container-fluid my-3 p-0 border rounded-4 content shadow" style="width: 95%;">
<div class="card rounded-3 overflow-hidden">
    <div class="">
            <ul class="nav nav-tabs tabs p-2 d-flex justify-content-evenly d-lg-none tournament-font" [ngStyle]="{'background-color': 'white'}" id="appendTabs">
                <li [ngClass]="{'active': activeTab1 === data}" *ngFor="let data of tournamentTileList" style="transform: skew(-15deg); font-family: 'Assist-Bold';">
                    <a data-toggle="tab"
                       style="height: 35px; cursor: pointer; padding: 7px; text-decoration: none; color: #015b7e;  display: inline-block;   font-family: 'Assist-Bold'; "
                       [ngClass]="{ 'active-tab': activeTab1 === data }"
                       (click)="activeTab1 = data">
                        <span style="transform: skew(15deg); display: inline-block;">
                                <img [ngClass]="{ 'sportsImage': activeTab1 === data }" *ngIf="data=='Cricket'" src="/assets/ball.png" alt="CricketBall" style="height: 20px; width: 20px;">
                                <img [ngClass]="{ 'sportsImage': activeTab1 === data }"  *ngIf="data=='Table Tennis'" src="/assets/tableTennis.png" alt="CricketBall" style="height: 20px; width: 20px;">
                                <img [ngClass]="{ 'sportsImage': activeTab1 === data }"  *ngIf="data=='Batmindon'" src="/assets/batmiton ball.png" alt="CricketBall" style="height: 20px; width: 20px;">
                                <img [ngClass]="{ 'sportsImage': activeTab1 === data }"  *ngIf="data=='Tennis'" src="/assets/tennisball.png" alt="CricketBall" style="height: 20px; width: 20px;">
                                {{ data }}
                        </span>
                    </a>
                </li>
            </ul>
        <div class="d-flex justify-content-center  border-1 my-2" >
            <span class="title m-2  text-center d-flex align-item-center fs-4"
            style="color: #074873; font-weight: 700; font-family: 'Assist-Bold'; ">&nbsp;Tournaments</span>
        </div>

        <ng-container *ngIf="tournamentList &&tournamentList.length> 0 && activeTab1=='Cricket' ;else noRecords">
        <div class="">
           <div class="container">
                <div class="row justify-content-around container mb-2">
                    <ng-container  *ngFor="let data of tournamentList">
                        <span class="card col-lg-5 col-md-5 col-12 mx-2 text-center mt-2 p-3 fs-6 fw-medium tournamentlist" style="color: var(--primary-color);"(click)="tournaments(data.Event_id, data.Sport_id)">{{data.Event_name}}</span>
                    </ng-container>

                </div>
            </div>
            </div>
        </ng-container>
        <ng-template #noRecords>
            <div class="p-3 text-center not-found fs-5 fw-bolder">No records found!</div>
        </ng-template>
</div>
</div>
</div>
<div *ngIf="tournamentDetail" style="height: calc(100vh - 125px);">
    <span class="fs-6 mt-2 d-inline-block  fw-bolder container" style="color: var(--primary-color);" (click)="hideTournemt()"><i class="bi bi-chevron-left"></i></span>
   <div class="container-fluid card shadow rounded-3 px-0" style=" width: 95%; border-radius: 16px;">
    <ul class="filters">
        <li *ngFor="let data of TournamentScoreList"  [class.active]="data === selectedType" >
            <mat-radio-group [formControlName]="data">
                <button mat-button color="Basic" [value]="data" (click)="List(data)"
                  class="tournament-font-bold ">
                  <div class="d-flex flex-columns">
                  <i *ngIf="data.toUpperCase()=='POINTS TABLE'" class="bi bi-person-fill"></i>
                  <i *ngIf="data.toUpperCase()=='LEADER BOARD'" class="bi bi-people-fill"></i>
                  <i *ngIf="data.toUpperCase()=='FIXTURE'" class="bi bi-grid-3x3-gap-fill"></i>
                  <i *ngIf="data.toUpperCase()=='FACT SHEET'" class="bi bi-file-earmark-spreadsheet-fill"></i>
                  <!-- <i *ngIf="data.toUpperCase()==''" class="bi bi-megaphone-fill"></i> -->
                  <i *ngIf="data.toUpperCase()=='HOME'" class="bi bi-house-door-fill"></i>
                  &nbsp;
                  <span>{{ data.toUpperCase() }}</span>
                  </div>
                </button>

            </mat-radio-group>
        </li>
    </ul>
    <div *ngIf="selectedType=='Home'" class="row justify-content-around mb-3 gap-2">
        <div *ngFor="let events of eventDetails" class="scrollable-container  content col-lg-5 " style="height: max-content;">
            <div class="  border shadow overflow-hidden" style="height:100%; border-radius: 14px;">
                <div class=" text-light " style="border-bottom:none; background-color: var(--primary-color);" >
                    <div class="header   d-flex row">
                        <div class="title p-2  d-flex justify-content-center align-item-center tournament-font"
                            style=" font-weight: 700; font-size: 19px; font-family: 'Assist-Bold';">
                            &nbsp;{{events.Event_name}}</div>
                    </div>
                </div>
                <div class="eventDetails tournament-font" style="font-family: 'Assist-Bold';" >
                    <div class="p-2" style="font-size: 18px;">
                        {{events.sports_subcat_name}}</div>
                    <div class="p-2">
                        Starts On :- {{events.Start_date}}</div>
                    <div class="p-2">
                        Ends On :- {{events.End_date}}</div>
                    <div class="p-2">
                        Max Entries :-{{events.Max_entries}}</div>
                    <div class="p-2">
                        Status :- Scheduled</div>
                </div>
            </div>
        </div>
        <div class="col-lg-5 ">
          <div class=" border shadow live " style="min-height: 250px; border-radius: 14px; font-family: 'Assist-Bold'; max-height: 500px">
            <ul class="list-unstyled d-flex justify-content-between m-0" style="font-family: 'Assist-Bold'; background-color: var(--primary-color);">
                <li *ngFor="let data of liveScoreList" [class.active]="data === selectedScoreType">
                  <div [formGroup]="myFormGroup" class="">
                    <mat-radio-group [formControlName]="data">
                      <button mat-button color="Basic" class="text-white" [value]="data" (click)="ListLiveSCore(data)" style="font-size: small;">
                        {{ data.toUpperCase() }}
                      </button>
                    </mat-radio-group>
                  </div>
                </li>
              </ul>

            <div *ngIf="selectedScoreType==='Live Scores'" >
                <div class="scrollable-container content rounded-4 " style="max-height: 300px; overflow-y: scroll; ">
                    <div class="m-3d">
                        <ng-container *ngIf="currentLiveMatchList && currentLiveMatchList.length > 0; else noRecords">
                            <div class="card my-1 mx-2 p-1 teamDetails-card" *ngFor="let match of currentLiveMatchList;let i = index" [ngClass]="{'bg-white': i % 2 === 0, 'bg-gray': i % 2 !== 0}" style="cursor: pointer;" >
                                <div class="teamDetailsMatch p-2" (click)="navigateToScorecard(match.Match_id)">
                                    {{match.teamone}} Vs {{match.teamtwo}}
                                </div>
                                <div class="teamDetails" (click)="navigateToScorecard(match.Match_id)">
                                    {{match.teamone}} {{match.innings_1_score}}/{{match.innings_1_wkts}},
                                    {{match.teamtwo}} {{match.innings_2_score}}/{{match.innings_2_wkts}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noRecords>
                            <div class=" p-3 text-center not-found">No records found.</div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="selectedScoreType==='Live Videos'">
                <div class="scrollable-container m-3  content rounded-4 ">
                    <div class="">
                        <ng-template #noRecords>
                            <div class="p-3 text-center not-found">No records found.</div>
                        </ng-template>
                    </div>
                </div>
            </div> -->
            <div *ngIf="selectedScoreType==='Results'">
                <div class="scrollable-container m-3  content rounded-4 " style="max-height: 300px; overflow-y: scroll; ">
                    <div class="">
                        <ng-container *ngIf="allRecentResults && allRecentResults.length >0; else noRecords">
                            <div class="card p-1 my-1 mx-2 teamDetails-card" *ngFor="let result of allRecentResults;let i=index"  [ngClass]="{'bg-white': i % 2 === 0, 'bg-gray': i % 2 !== 0}" style="cursor: pointer;" >
                                <div class="teamDetailsMatch p-2" (click)="navigateToScorecard(result.match_id)">
                                    {{result.teamone}} Vs {{result.teamtwo}}
                                </div>
                                <div class="teamDetails" (click)="navigateToScorecard(result.match_id)">
                                    {{result.result}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noRecords>
                            <div class="p-3 text-center not-found">No records found.</div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedScoreType==='Fixture'">
               <div *ngIf="allEventFixtures.length >0; else noRecords">
                <ng-container>
                    <div class="card my-2 fixture mx-2" *ngFor="let fixture of eventSubCatDetails">
                      <mat-accordion class=" shadow">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{fixture.sports_subcat_name}}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="table-wrapper" style="max-height: 300px; overflow-y: scroll;">
                            <table class="table table-bordered overflow-hidden border text-center tournament-font">
                              <thead class="tournament-font">
                                <tr style="color: var(--secondar-color) !important;">
                                  <th scope="col">Round</th>
                                  <th scope="col">Match</th>
                                  <th scope="col">Venue</th>
                                  <th scope="col">Date/Time</th>
                                </tr>
                              </thead>
                              <tbody class="eventName P-2 ">
                                <tr *ngFor="let fixture of getCurrentPageData()" class="tournament-font secondary" style="color: var(--primary-color);">
                                  <td>{{fixture.round}}</td>
                                  <td>{{fixture.teamone}} VS {{fixture.teamtwo}}</td>
                                  <td>{{fixture.Venue}}</td>
                                  <td>{{fixture.Match_Date}}/{{fixture.Match_Time}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <mat-paginator *ngIf="allEventFixtures.length>5" [length]="allEventFixtures.length" [pageSize]="pageSize" [pageIndex]="pageIndex"
                  [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)">
                    </mat-paginator>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </ng-container>
                  <div>
            
                  </div>
                  </div>
               <ng-template #noRecords>
                <div class="p-3 text-center not-found">No records found.</div>
              </ng-template>
              </div>
          </div>
        </div>

    </div>
    <div *ngIf="selectedType=='Points Table'">
        <app-points-table [eventId]="currEventId"></app-points-table>
     </div>
    <div *ngIf="selectedType==='fixture'">
        <div *ngIf="allEventFixtures.length > 0; else noRecords">
          <div class="card my-2 fixture mx-2" *ngFor="let item of eventSubCatDetails">
            <mat-accordion class=" shadow">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{item.sports_subcat_name}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="table-wrapper">
                  <table class="table table-bordered overflow-hidden border text-center tournament-font">
                    <thead class="tournament-font">
                      <tr style="color: var(--secondar-color) !important;">
                        <th scope="col">Round</th>
                        <th scope="col">Match</th>
                        <th scope="col">Venue</th>
                        <th scope="col">Date/Time</th>
                      </tr>
                    </thead>
                    <tbody class="eventName P-2 ">
                      <tr *ngFor="let fixture of getCurrentPageData()" class="tournament-font secondary" style="color: var(--primary-color);">
                        <td>{{fixture.round}}</td>
                        <td>{{fixture.teamone}} VS {{fixture.teamtwo}}</td>
                        <td>{{fixture.Venue}}</td>
                        <td>{{fixture.Match_Date}}/{{fixture.Match_Time}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <mat-paginator *ngIf="allEventFixtures.length>=5" [length]="allEventFixtures.length" [pageSize]="pageSize" [pageIndex]="pageIndex"
                  [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)">
                    </mat-paginator>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <ng-template #noRecords>
          <div class="p-3 text-center not-found fw-bolder">No records found!</div>
        </ng-template>
    </div>





    <div *ngIf="selectedType=='Leader Board'" class="mx-1">
      <ng-container *ngIf="eventDetails && eventDetails.length >0 ;else noRecords">
        <div *ngFor="let events of eventDetails" class="scrollable-container my-3 mx-1  content rounded-4 border overflow-hidden shadow">
            <div class="  " style="height:100%;">
                <div class="card-header p-0 mb-2" style="border-bottom:none">
                    <div class="header p-2 py-3 bg-white d-flex ">
                        <div class="title  text-center d-flex align-item-center m-auto tournament-font"
                            style="color: #074873; font-weight: 700; font-size: 19px; font-family: 'Assist-Bold';">
                            &nbsp;{{events.Event_name}}</div>
                    </div>
                </div>
                <ul class="nav nav-tabs tabs p-2 d-flex justify-content-around  tournament-font" [ngStyle]="{'background-color': 'white'}" id="appendTabs">
                    <li [ngClass]="{'active': activeTab === data.tabNumber}" *ngFor="let data of LeaderBoardDetails" style="transform: skew(-15deg);">
                        <a data-toggle="tab"
                           style="height: 35px; cursor: pointer; padding: 7px; text-decoration: none; color: #015b7e;  display: inline-block; font-family: 'Assist-Bold';"
                           [ngClass]="{ 'active-tab': activeTab === data.Tab_header }"
                           (click)="leaderBoardTabUI(data.Tab_header,data.Tab_id); activeTab = data.Tab_header">
                            <span style="transform: skew(15deg); display: inline-block; font-weight: 800; font-family: 'Assist-Bold';">{{ data.Tab_header }}</span>
                        </a>
                    </li>
                </ul>
                <div class="m-0" *ngIf="slectedLeaderboard==='Batting'">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                            *ngFor="let data of leaderBoardTabDetails">
                            <mat-expansion-panel-header (click)="expend(data.Table_id)">
                                <h4 class="panel-title ng-binding pt-2 tournament-font">
                                    {{data.Table_header}} <i class="fa fa-chevron-down pull-right"
                                        aria-hidden="true" ></i>
                                </h4>
                            </mat-expansion-panel-header>
                            <div *ngIf="data.Table_header==='Batting Max Runs (Aggregate)'">
                                <div style="overflow-x: auto; width: 100%;"  *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font" >
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Innings</th>
                                                <th>Runs</th>
                                                <th>Average</th>
                                                <th>Strike Rate</th>
                                                <th>Fours</th>
                                                <th>Sixers</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                            <td>{{data.fname}}</td>
                                            <td>{{data.team_name}}</td>
                                            <td>{{data.Innings}}</td>
                                            <td>{{data.runs}}</td>
                                            <td>{{data.average}}</td>
                                            <td>{{data.strike_rate}}</td>
                                            <td>{{data.fours}}</td>
                                            <td>{{data.sixers}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Average Batting'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Innings</th>
                                                <th>Average</th>
                                                <th>Runs</th>
                                                <th>Strike Rate</th>
                                                <th>Fours</th>
                                                <th>Sixers</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.Innings}}</td>
                                                <td>{{data.Average}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.strike_rate}}</td>
                                                <td>{{data.fours}}</td>
                                                <td>{{data.sixers}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Strike Rate'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Innings</th>
                                                <th>Strike Rate</th>
                                                <th>Runs</th>
                                                <th>Average</th>
                                                <th>Fours</th>
                                                <th>Sixers</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.Innings}}</td>
                                                <td>{{data.strike_rate}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.average}}</td>
                                                <td>{{data.fours}}</td>
                                                <td>{{data.sixers}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Max Sixers (Batting)'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length >0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Innings</th>
                                                <th>Sixers</th>
                                                <th>Runs</th>
                                                <th>Average</th>
                                                <th>Fours</th>
                                                <th>Strike Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.Innings}}</td>
                                                <td>{{data.sixers}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.average}}</td>
                                                <td>{{data.fours}}</td>
                                                <td>{{data.strike_rate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Max Fours (Batting)'">
                                <div style="overflow-x: auto; width: 100%;"  *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Innings</th>
                                                <th>Fours</th>
                                                <th>Runs</th>
                                                <th>Average</th>
                                                <th>Sixers</th>
                                                <th>Strike Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.Innings}}</td>
                                                <td>{{data.fours}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.average}}</td>
                                                <td>{{data.sixers}}</td>
                                                <td>{{data.strike_rate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Innings'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="searchTeam()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th> Rank</th>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Runs</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.rank}}</td>
                                                <td>{{data.fname}}</td>
                                                <td>{{data.Team_name}}</td>
                                                <td>{{data.total_runs}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="m-0" *ngIf="slectedLeaderboard==='Bowling'">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                            *ngFor="let data of leaderBoardTabDetails">
                            <mat-expansion-panel-header (click)="expend(data.Table_id)">
                                <h4 class="panel-title ng-binding pt-2 tournament-font" >
                                    {{data.Table_header}} <i class="fa fa-chevron-down pull-right"
                                        aria-hidden="true"></i>
                                </h4>
                            </mat-expansion-panel-header>
                            <div *ngIf="data.Table_header==='Most Wickets Bowling'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0 tournament-font">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Wickets</th>
                                                <th>Overs Bowled</th>
                                                <th>Runs Conceded</th>
                                                <th>Economy</th>
                                                <th>Average</th>
                                                <th>Strike Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.wickets}}</td>
                                                <td>{{data.overs}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.economy}}</td>
                                                <td>{{data.Average}}</td>
                                                <td>{{data.strike_rate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Economy Bowling '">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Economy</th>
                                                <th>Overs Bowled</th>
                                                <th>Runs Conceded</th>
                                                <th>Wickets</th>
                                                <th>Average</th>
                                                <th>Strike Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.economy}}</td>
                                                <td>{{data.overs}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.wickets}}</td>
                                                <td>{{data.Average}}</td>
                                                <td>{{data.strike_rate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Bowling Average (Runs / Wickets)'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Average</th>
                                                <th>Overs Bowled</th>
                                                <th>Runs Conceded</th>
                                                <th>Wickets</th>
                                                <th>Economy</th>
                                                <th>Strike Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.Average}}</td>
                                                <td>{{data.overs}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.wickets}}</td>
                                                <td>{{data.economy}}</td>
                                                <td>{{data.strike_rate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Bowling Strikerate (Balls / Wickets)'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Strike Rate</th>
                                                <th>Overs Bowled</th>
                                                <th>Runs Conceded</th>
                                                <th>Wickets</th>
                                                <th>Economy</th>
                                                <th>Average</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.fname}}</td>
                                                <td>{{data.team_name}}</td>
                                                <td>{{data.strike_rate}}</td>
                                                <td>{{data.overs}}</td>
                                                <td>{{data.runs}}</td>
                                                <td>{{data.wickets}}</td>
                                                <td>{{data.economy}}</td>
                                                <td>{{data.Average}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Bowling'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="searchTeam()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Rank</th>
                                                <th>Player Name</th>
                                                <th>Team Name</th>
                                                <th>Best Bowling</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.rank}}</td>
                                                <td>{{data.fname}}</td>
                                                <td>{{data.Team_name}}</td>
                                                <td>{{data.best_bowling}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="m-0" *ngIf="slectedLeaderboard==='Team Results'">
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                            *ngFor="let data of leaderBoardTabDetails">
                            <mat-expansion-panel-header (click)="expend(data.Table_id)">
                                <h4 class="panel-title ng-binding pt-2 tournament-font" >
                                    {{data.Table_header}} <i class="fa fa-chevron-down pull-right"
                                        aria-hidden="true"></i>
                                </h4>
                            </mat-expansion-panel-header>
                            <div *ngIf="data.Table_header==='NRR - Till latest round winners'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="searchTeam()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Rank</th>
                                                <th>Team Name</th>
                                                <th>Played</th>
                                                <th>Won</th>
                                                <th>NRR</th>
                                                <th>Group</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.rank}}</td>
                                                <td>{{data.Team_name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Points Table'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Group</th>
                                                <th>Team</th>
                                                <th>Played</th>
                                                <th>Won</th>
                                                <th>Lost</th>
                                                <th>NRR</th>
                                                <th>Points</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Margin'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Team</th>
                                                <th>Opponents</th>
                                                <th>Run Rate Difference</th>
                                                <th>Match Result</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.TEAM}}</td>
                                                <td>{{data.OPPONENT}}</td>
                                                <td>{{data.net_run_rate}}</td>
                                                <td>{{data.match_result}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Margin - Batting First'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Team</th>
                                                <th>Margin - Runs</th>
                                                <th>Target Set (Runs)</th>
                                                <th>Opponents</th>
                                                <th>Match Result</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.WinningTeam}}</td>
                                                <td>{{data.Margin}}</td>
                                                <td>{{data.TargetSet}}</td>
                                                <td>{{data.Opponents}}</td>
                                                <td>{{data.match_result}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="data.Table_header==='Best Margin - Chasing'">
                                <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                        <div class="searchBtn">
                                            <i class="bi bi-search" style="float: left;"></i>
                                            <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                                (input)="search()" aria-label="Search" />
                                        </div>
                                    </div>
                                    <table class="table border table-striped p-0">
                                        <thead class="tournament-font">
                                            <tr>
                                                <th>Team</th>
                                                <th>Margin - Runs</th>
                                                <th>Target Set (Runs)</th>
                                                <th>Opponents</th>
                                                <th>Match Result</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tournament-font">
                                            <tr *ngFor="let data of paginatedTeams">
                                                <td>{{data.Winner}}</td>
                                                <td>{{data.BallsDiff}}</td>
                                                <td>{{data.TargetRuns}}</td>
                                                <td>{{data.Opponent}}</td>
                                                <td>{{data.Result}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="m-0" *ngIf="slectedLeaderboard==='Fielding'">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="start === 1" (opened)="setExpend(0)"
                        *ngFor="let data of leaderBoardTabDetails">
                        <mat-expansion-panel-header (click)="expend(data.Table_id)">
                            <h4 class="panel-title ng-binding pt-2 tournament-font" >
                                {{data.Table_header}} <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                            </h4>
                        </mat-expansion-panel-header>
                        <div *ngIf="data.Table_header==='Most Dismissals'">
                            <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                    <div class="searchBtn">
                                        <i class="bi bi-search" style="float: left;"></i>
                                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                            (input)="searchTeam()" aria-label="Search" />
                                    </div>
                                </div>
                                <table class="table border table-striped p-0">
                                    <thead class="tournament-font">
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player Name</th>
                                            <th>Team Name</th>
                                            <th>Innings</th>
                                            <th>Total Dismissals</th>
                                            <th>Catches</th>
                                            <th>Run Out</th>
                                            <th>Stumpings</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tournament-font">
                                        <tr *ngFor="let data of paginatedTeams">
                                            <td>{{data.rank}}</td>
                                            <td>{{data.playername}}</td>
                                            <td>{{data.Team_name}}</td>
                                            <td>{{data.innings}}</td>
                                            <td>{{data.dismissals}}</td>
                                            <td>{{data.catches}}</td>
                                            <td>{{data.Runout}}</td>
                                            <td>{{data.stumpings}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="data.Table_header==='Most Stumpings'">
                            <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                    <div class="searchBtn">
                                        <i class="bi bi-search" style="float: left;"></i>
                                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                            (input)="searchTeam()" aria-label="Search" />
                                    </div>
                                </div>
                                <table class="table border table-striped p-0">
                                    <thead class="tournament-font">
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player Name</th>
                                            <th>Team Name</th>
                                            <th>Innings</th>
                                            <th>Stumpings</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tournament-font">
                                        <tr *ngFor="let data of paginatedTeams">
                                            <td>{{data.rank}}</td>
                                            <td>{{data.playername}}</td>
                                            <td>{{data.Team_name}}</td>
                                            <td>{{data.innings}}</td>
                                            <td>{{data.stumpings}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="data.Table_header==='Most Catches'">
                            <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                    <div class="searchBtn">
                                        <i class="bi bi-search" style="float: left;"></i>
                                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                            (input)="searchTeam()" aria-label="Search" />
                                    </div>
                                </div>
                                <table class="table border table-striped p-0">
                                    <thead class="tournament-font">
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player Name</th>
                                            <th>Team Name</th>
                                            <th>Innings</th>
                                            <th>Catches</th>
                                        </tr>
                                    </thead>
                                    <tr *ngFor="let data of paginatedTeams">
                                        <td>{{data.rank}}</td>
                                        <td>{{data.playername}}</td>
                                        <td>{{data.Team_name}}</td>
                                        <td>{{data.innings}}</td>
                                        <td>{{data.catches}}</td>
                                    </tr>
                                    <tbody class="tournament-font">
                                    </tbody>
                                </table>
                                <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="data.Table_header==='Most Runouts'">
                            <div style="overflow-x: auto; width: 100%;" *ngIf="paginatedTeams && paginatedTeams.length>0; else noRecords">
                                <div class="col-lg-6 col-12 my-2 d-flex justify-content-center text-center p-lg-4">
                                    <div class="searchBtn">
                                        <i class="bi bi-search" style="float: left;"></i>
                                        <input class="px-1 tournament-font " type="search" placeholder="Search Teams" [(ngModel)]="searchText"
                                            (input)="searchTeam()" aria-label="Search" />
                                    </div>
                                </div>
                                <table class="table border table-striped p-0">
                                    <thead class="tournament-font">
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player Name</th>
                                            <th>Team Name</th>
                                            <th>Innings</th>
                                            <th>Run Out</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tournament-font">
                                        <tr *ngFor="let data of paginatedTeams">
                                            <td>{{data.rank}}</td>
                                            <td>{{data.playername}}</td>
                                            <td>{{data.Team_name}}</td>
                                            <td>{{data.innings}}</td>
                                            <td>{{data.runout}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <mat-paginator [length]="filteredTeams.length" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)">
                                    </mat-paginator>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="selectedType=='Fact Sheet'">
        <app-fact-sheet [eventId]="currEventId"></app-fact-sheet>
    </div>

   </div>
</div>
<ng-template #noRecords>
    <div class="d-flex justify-content-center w-100">
    <div class=" not-found p-3 fw-bolder">No records found!</div>
    </div>
</ng-template>
