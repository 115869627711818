import { Component, Input } from '@angular/core';
import { catchError } from 'rxjs';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-playervs-player',
  templateUrl: './playervs-player.component.html',
  styleUrls: ['./playervs-player.component.css']
})
export class PlayervsPlayerComponent {
  @Input()
  matchId: any;
  ballHit: any[] = [];
  team2: any;
  team1: any;
  team1innings: any;
  team2innings: any;
  batter: any;
  innings = 1;
  bowlerstats: any;
  team: any;
  team1Active: boolean = false;
  team2Active: boolean = false;
  isplayed: boolean=false;
  intervel:any;
  wagonFor='player';
  showWagon : boolean[] = [];

  constructor(private scoreService: CricketScoreCardService) {

  }

  ngOnInit(): void {
    this.getteamsdetail();
    // this.intervel = setInterval(() => {
    //   this.getteamsdetail();
    // }, 5000);
    // if(this.matchStatus == 'C'){
    //   clearInterval(this.intervel);
    // }
  }

  // ngOnDestroy(): void {
  //   if (this.intervel) {
  //     clearInterval(this.intervel);
  //   }
  // }

  changeTab(event: any) {
    this.innings=event;
    if(this.innings==1){
      this.getCricketMatchStats('1');
      this.team1Active = true;
      this.team2Active = false;
    }else if(this.innings==2){
      this.getCricketMatchStats('2');
      this.team1Active = false;
      this.team2Active = true;
    }
  }

  getteamsdetail() {
    this.scoreService.getTeamNamesForMultiDay(this.matchId).subscribe((resp: any) => {
      this.team=resp.result
      this.team1 = resp.result[0].Team_Name;
      this.team2 = resp.result[1].Team_Name;
      this.team1innings = resp.result[0].innings;
      this.team2innings = resp.result[1].innings;
      this.changeTab(this.innings);
    });
  }

  getCricketMatchStats(innings:any) {
    this.scoreService.getCricketMatchStats(this.matchId,innings).subscribe((Response: any) => {
      if(innings == '1'){
        this.batter = Response.result.innings1;
      } else if (innings =='2'){
        this.batter = Response.result.innings2;
      }
      
      this.batter= this.batter.filter((data:any)=>data.how_out !='Did Not Bat');
      if(this.batter.length>0){
        this.isplayed=true;
      }else{
        this.isplayed=false;
      }
    });
  }

  getsecondInningStatus() {
    this.scoreService.getSecondInningsStatus(this.matchId).subscribe((Response: any) => {
      this.batter = Response.result;
      this.batter= this.batter.filter((data:any)=>data.how_out !='Did Not Bat');
      if(this.batter.length>0){
        this.isplayed=true;
      }else{
        this.isplayed=false;
      }
    });
  }

  getcricketplayerAnalytics(data: any,index:any) {
    this.scoreService.getCricketPlayerAnalytics(this.matchId, data, this.innings).subscribe((data: any) => {
      // this.ballHit =[];
      if(data.status  == 'TRUE'){
      this.bowlerstats = data.result.playerRunsVsbowler;
      this.ballHit=data.result.AllballbyBallSumm;
      this.ballHit= this.ballHit.filter((data:any)=>data.where_hit_x !='0' && data.where_hit_y !='0')
      if(this.ballHit?.length > 0){
        this.showWagon[index] = !this.showWagon[index];
        }
      }
    });
    
  }
panelOpened(data: any,index:any) {
  this.getcricketplayerAnalytics(data,index);
}

}
