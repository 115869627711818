import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { CommonService } from 'src/app/service/common.service';
import * as CryptoJS from 'crypto-js';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {


  userDetails: any = {};
  email_id:string=""
  userId :any
  oldPassword: string = ''
  newPassword: string = ''
  reTypePassword: string = ''
  loginForm: any;
  whitespacePattern = /^\S.*$/;
  playedControl = new FormControl('', [Validators.required, Validators.pattern(this.whitespacePattern)]);
  isPasswordMatc:boolean=false;
  isPasswordMatch: boolean = false;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showReTypePassword: boolean = false;
  orgCode:any;
  constructor(private service: CommonService, private fb: FormBuilder, private dialog: MatDialog,private route: ActivatedRoute, private router:Router,private snackBar: MatSnackBar,private commonService :CommonService,
  private orgFilter:OrgcodeFilterService) { }

  ngOnInit(): void {
    this.userId= localStorage.getItem("userId")
    if(this.userId === null){
      this.router.navigate([`${this.orgCode}/home`]);
    } else {
      this.getUserDetails();
    }
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }
  getUserDetails() {
    this.commonService.getUserDetails(this.userId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
       this.email_id= response.result[0].email_id
       this.userDetails.uid=this.userId
      }
    });
  }

  changePassword() {
    try {
      const oldPasswordHash = CryptoJS.SHA256(this.email_id+this.oldPassword).toString(CryptoJS.enc.Hex);
      this.service.getOldPassword(this.userId, oldPasswordHash).subscribe((response: any) => {
        if (response.status == "TRUE") {
          if (this.newPassword.length > 0 && this.newPassword == this.reTypePassword ) {
            if(this.newPassword!=this.oldPassword){
              this.service.getEncp(this.newPassword, this.email_id.toString()).subscribe((res: any) => {
                if (res.status == "TRUE") {
                  this.userDetails.encPass = CryptoJS.SHA256(this.email_id+this.newPassword).toString(CryptoJS.enc.Hex)
                  this.userDetails.flag = 'updatepassword'
                  this.service.updateUserPassword(this.userDetails).subscribe((response) => {
                    this.snackBar.open('Password Updated Successfully', '', {
                      duration: 3000,
                      verticalPosition: 'bottom',
                    });
                    this.back();
                  })
                }
              })
            }else{
              const oldAndNewPassword = this.dialog.open(CommonAlertsComponent, {
                data: { identifier: 'oldAndNewPassword' },
                disableClose: true,
              })
            }
            
          }
        }
        else {
          const CheckPassword = this.dialog.open(CommonAlertsComponent, {
            data: { identifier: 'CheckPassword' },
            disableClose: true,
          })
        }
        if (this.newPassword != this.reTypePassword) {
         this.isPasswordMatch=true
         this.newPassword=''
         this.reTypePassword=''
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
  back(){
    window.history.back()
  }
}
