<div class="scrollable-container my-2 content overflow-hidden" *ngIf="profilepage">
    <div class=" m-2" style="height:100%;margin-bottom:20% !important;">
        <!-- <div class=" mb-4" style="border-bottom:none;">
            <div class=" px-3 py-5 d-flex row align-items-center" style="height: 100%;">
                <div class="col-6 modal-header d-flex justify-content-between  ">
                    <h4 class="title modal-title m-0 title fs-6" style="display:inline">
                        <i class="bi bi-chevron-left" (click)="Backtoprofile()"></i>&nbsp; Edit Profile
                    </h4>
                </div> -->
        <!-- <div class="col-6 d-flex align-items-center px-1 justify-content-end">
                    <button class="btn btn-success d-flex align-items-center justify-content-center rounded"
                        (click)="EditImg()">
                        <h4 class="m-0 create text-white">Edit Image &nbsp;<i class="bi bi-pencil"></i></h4>
                    </button>
                </div> -->
        <!-- </div>
        </div> -->
        <div class="row d-flex justify-content-center mt-2">
            <div class="col-12 mb-2 d-flex justify-content-center align-items-end">
                <img [src]="previewImageUrl" onerror="this.src='/assets/user-thumbnail.png';" alt="" class="image col-6" style="object-fit: cover;">
                <label (click)="EditImg()" class="rounded-circle col-1 custom-button"
                    style="position: relative; right: 37px;">
                    <i class="bi bi-camera-fill" style="font-size: 22px;"></i>
                </label>
            </div>

            <div class="col-lg-7 col-md-8 col-sm-10 d-flex justify-content-center">
                <form [formGroup]="editProfileForm" class="mt-3">
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label for="FirstName">First Name</mat-label>
                        <input matInput type="text" formControlName="FirstName" id="FirstName">
                        <mat-error *ngIf="editProfileForm.get('FirstName')?.hasError('required')">Please enter the First
                            Name</mat-error>
                        <mat-error *ngIf="editProfileForm.get('FirstName')?.hasError('pattern')">Invalid first name
                            format</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label for="LastName">Last Name</mat-label>
                        <input matInput type="text" formControlName="LastName" id="FirstName">
                        <mat-error *ngIf="editProfileForm.get('LastName')?.hasError('pattern')">Invalid last name
                            format</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label for="Email">Email</mat-label>
                        <!-- <mat-error *ngIf="editProfileForm.get('Email')?.hasError('required')"> Please enter the
                    email</mat-error>
                <mat-error
                    *ngIf="editProfileForm.get('Email')?.hasError('pattern') && editProfileForm.get('Email')?.touched">
                    Invalid email format
                </mat-error> -->
                        <input matInput type="text" formControlName="Email" id="Email" [disabled]="true" readonly
                            style="color: rgba(0, 0, 0, 0.38) ; ">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="formFeild w-100 my-1 ">
                        <mat-label>City</mat-label>
                        <input type="text" placeholder="select city" aria-label="City" matInput formControlName="city"
                            id="city" [matAutocomplete]="auto" (input)="setupAutocomplete($event)">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let city of CityArray " [value]="city">
                                {{ city.cityName }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="editProfileForm.get('City')?.hasError('required')">Please enter the city
                            Name</mat-error>
                        <mat-error *ngIf="editProfileForm.get('City')?.hasError('pattern')">Invalid city
                            format</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label for="MobileNo">Mobile No</mat-label>
                        <input matInput type="text" formControlName="MobileNo" id="MobileNo">
                        <mat-error *ngIf="editProfileForm.get('MobileNo')?.hasError('required')">Please enter the mobile
                            number
                        </mat-error>
                        <mat-error *ngIf="editProfileForm.get('MobileNo')?.hasError('pattern')">Invalid mobile number
                            format</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label for="DOB">Date of birth</mat-label>
                        <mat-error *ngIf="editProfileForm.get('DOB')?.hasError('required')">Please enter the date of
                            birth</mat-error>
                        <mat-error *ngIf="editProfileForm.get('DOB')?.hasError('pattern')">Invalid date
                            format</mat-error>
                        <input matInput [matDatepicker]="picker" formControlName="DOB" placeholder="Choose a date"
                            #inputField readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-12">
                        <mat-label for="PinCode">Pincode</mat-label>
                        <input matInput type="text" formControlName="PinCode" id="PinCode">
                        <mat-error *ngIf="editProfileForm.get('PinCode')?.hasError('required')">Please enter PinCode
                        </mat-error>
                        <mat-error *ngIf="editProfileForm.get('PinCode')?.hasError('pattern')">Invalid last PinCode
                            format</mat-error>
                    </mat-form-field>
                    <mat-card-content class="mt-1 mb-2">
                        <ul class="filters">
                            <li>
                                <mat-radio-group formControlName="gender" name="gender">
                                    <mat-radio-button value="F" (click)="Type('F')"><b>Female</b></mat-radio-button>
                                </mat-radio-group>
                            </li>
                            <li>
                                <mat-radio-group formControlName="gender" name="gender">
                                    <mat-radio-button value="M" (click)="Type('M')"><b>Male</b></mat-radio-button>
                                </mat-radio-group>
                            </li>
                        </ul>
                    </mat-card-content>
                    <div class="row">
                        <!-- <div class="col-6 d-flex align-items-center justify-content-end">
                    <button class="btn btn-danger d-flex align-items-center justify-content-center rounded "
                        (click)="updatePassword()">
                        <h4 class="m-0 create text-white">updatePassword</h4>
                    </button>
                </div> -->
                        <div class="col-12 mt-2 d-flex justify-content-evenly">
                            <button
                                class="btn btn-success d-flex align-items-center justify-content-center rounded col-5 btn-danger" (click)="cancel()">
                                <h4 class="m-0 create text-white">Cancel</h4>
                            </button>
                            <button
                                class="btn btn-success d-flex align-items-center justify-content-center rounded col-5 btn-success"
                                [disabled]="editProfileForm.invalid" (click)="updateProfile()">
                                <h4 class="m-0 create text-white">updateProfile</h4>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="container mt-2 my-5" *ngIf="!profilepage">
    <!-- <div class=" mb-4" style="border-bottom:none;">
        <div class=" px-3  d-flex row align-items-center" style="height: 100%;">
            <div class="col-6 modal-header d-flex justify-content-between  ">
                <h4 class="title fw-bold modal-title m-0 title fs-6" style="display:inline">
                    <i class="bi bi-chevron-left" (click)="goBack()"></i>&nbsp; My Profile
                </h4>
            </div>
        </div>
    </div> -->
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-9 col-sm-12">
            <div class="emty-div" style="height: 5vh;"></div>
            <div class="col-12 d-flex justify-content-center align-items-center">
                <img [src]="previewImageUrl" onerror="this.src='/assets/user-thumbnail.png';" alt="" class="image col-6" style="object-fit: cover;">
            </div>
            <div class="row my-3 d-flex justify-content-center" style="margin: auto;">
                <div class="col-12 my-1 d-flex justify-content-center align-items-center">
                    <div class="col-6 d-flex justify-content-center">
                        <i class="bi bi-person-fill col-1" style="font-size: medium;"></i>&nbsp;
                        <span class="col-11 fw-bold" style="font-size: medium;">{{userName}}</span>
                    </div>
                </div>
                <div class="col-12 my-1 d-flex justify-content-center align-items-center">
                    <div class="col-6 d-flex justify-content-center">
                        <i class="bi bi-envelope-at-fill col-1" style="font-size: medium;"></i>&nbsp;
                        <span class="col-11 fw-normal" style="font-size: small;">{{useremail}}</span>
                    </div>
                </div>
                <div class="col-12 my-1 d-flex justify-content-center align-items-center">
                    <div class="col-6 d-flex justify-content-center">
                        <i class="bi bi-telephone-fill col-1" style="font-size: medium;"></i>&nbsp;
                        <span class="col-11 fw-normal" style="font-size: smaller;">{{userNumber}}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3 mb-1 d-flex justify-content-center align-items-center">
                <div class="col-6 d-flex align-items-center justify-content-center">
                    <button class="btn  d-flex align-items-center justify-content-center rounded-4"
                        style="width: 90%; background-color: #0c3b5a;" (click)="updateProfilepage()">
                        <h4 class="m-0 create text-white">Edit Profile</h4>
                    </button>
                </div>
                <div class="col-6 d-flex align-items-center justify-content-center">
                    <button class="btn d-flex align-items-center justify-content-center rounded-4"
                        style="width: 90%; background-color: #0c3b5a;" (click)="updatePassword()">
                        <h4 class="m-0 create text-white">Change Password</h4>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>