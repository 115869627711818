<div class="main-container col-12 overflow-hidden background">
    <div class="row justify-content-center p-2">
        <div class="card col-lg-3 col-md-6 col-10 m-5">
            <div class="text-center  mt-2  rounded" style="padding-top: 5px !important; -bs-card-cap-padding-y: none;" >
                <h3 class="mb-2" style="color: var(--primary-color);">Login</h3>
            </div>
            <div class="card-body mt-2">

                <form [formGroup]="myForm" id="login-form" class="form">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email Address</mat-label>
                        <input matInput type="text" formControlName="email_id" placeholder="Username or Email or Mobile" required>
                        <mat-icon matSuffix>email</mat-icon>
                    </mat-form-field>
                      
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="passwd" placeholder="Password" required>
                        <mat-icon matSuffix>lock</mat-icon>
                    </mat-form-field>
                      
                    <div class="text-end mb-3">
                      <a (click)="forgotPassword()" class="text-end" style="cursor: pointer;">Forgot Password?</a>
                    </div>
                    <div *ngIf="showLoginError" class="error-message text-danger text-center m-3">
                      Invalid Mail or Password
                    </div>
                    <div class="d-grid gap-2 m-2">
                      <button (click)="onLoginFormSubmit()" type="onsubmit" class="btn btn-success p-2 fs-6">SIGN
                        IN</button>
                    </div>
                  </form>
                <div class="signup text-center ">
                    New to SportsRuler?
                    <a routerLink="/signUp">SignUp</a>
                </div>
            </div>
        </div>
    </div>
</div>