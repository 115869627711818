<div id="main" class="container-fluid">
  <h4 class="heading  mx-0 fs-6 text-nowrap my-0" *ngIf="upcomingmatchdetails && upcomingmatchdetails.length>0" [ngClass]="orgCode==='SKA'?'text-white':''">UPCOMING MATCHES</h4>
  <div class=" border-0 px-2 overflow-hidden bg-body-tertiary rounded cursor-pointer">
    <nav class="navbar py-0  position-relative justify-content-center" style="margin-bottom: 0px !important;">
      <div class="navbar-header ">
        <ul class="d-flex justify-content-center align-items-center match-nav gap-4">
          <li style="" *ngFor="let sport of SportsList">
            <ng-container *ngIf="orgCode=='SR'">
            <span class="logo active" (click)="changeTab(sport.type)">
              <span
                matTooltipPosition="above"
                matTooltip="{{ sport.type | uppercase }}"
                aria-label="Button that displays a tooltip when focused or hovered over"
                class="circle-bg"
              >
                <img
                  src="/assets/{{ sport.game }}.png"
                  alt=""
                  class="circle-img border-bottom img-fluid pb-1"
                  [class.active-tab]="sport.active === true"
                />
              </span>
            </span>
          </ng-container>
          <!-- <ng-template #SKA>
            <ng-container *ngIf="sport.type=='cricket'">
              <span class="logo active" (click)="changeTab(sport.type)">
                <span
                  matTooltipPosition="above"
                  matTooltip="{{ sport.type | uppercase }}"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                  class="circle-bg"
                >
                  <img
                    src="/assets/{{ sport.game }}.png"
                    alt=""
                    class="circle-img border-bottom img-fluid pb-1"
                    [class.active-tab]="sport.active === true"
                  />
                </span>
              </span>
            </ng-container>

          </ng-template> -->

          </li>
        </ul>
      </div>
    </nav>
    <div class="tab-content" [ngClass]="orgCode === 'SR' ? '' : 'col-lg-12'" style="background-color: white;">
      <div id="series" class="tab-pane fade in show active " [ngClass]="orgCode === 'SR' ? '' : 'upcomming'">
        <ng-container>
          <div class="pt-0" *ngIf="matchResult.length > 0; else noResults"  [ngClass]=" orgCode==='SKA'?'row pt-2':'SR'" [ngStyle]="{background: orgCode === 'SKA' ? '' : ''}">
            <ng-container *ngFor="let data of matchResult; let i = index">
              <div class="score-card" [ngClass]="{'col-lg-4 cardLg-class': orgCode==='SKA'}"  *ngIf="shouldDisplayCard(i)">
                <app-mini-matches [data]="data" [length]="matchResultLength" [selectedType]="selectedType"   *ngIf="orgCode==='SR'" ></app-mini-matches>
                <app-home-section-live-score-card [data]="data" style="justify-content: center; cursor: auto !important;" class="d-none d-lg-block" [upcomingMatch]="true" [length]="matchResultLength" *ngIf="orgCode==='SKA'" [selectedType]="selectedType" ></app-home-section-live-score-card>               
                <app-live-score-card-carousal class="d-block d-lg-none d-md-block"  [data]="matchResult" [length]="matchResultLength" [upcomingMatch]="true" [selectedType]="selectedType" *ngIf="orgCode==='SKA'"></app-live-score-card-carousal>
              </div>
            </ng-container>
            <div class="d-flex justify-content-end">
              <h5 *ngIf="matchResultLength >= 5" class="text-end fw-bolder more mb-0 " [ngClass]=" orgCode==='SKA'?'text-white':''" (click)="navigateToMatches('UPCOMING')">MORE<i class="bi bi-chevron-right"></i></h5>
            </div>
          </div>
          <ng-template #noResults>
            <app-mini-matches [length]="matchResultLength"></app-mini-matches>
          </ng-template>
        </ng-container>

      </div>
    </div>
  </div>

</div>
