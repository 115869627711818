<!-- After Header -->

<ng-container *ngIf="data">
    <div class="card border-0 p-3 my-3 rounded-4 mx-2 shadow col-11 py-2 px-lg-4 mx-2 justify-content-evenly gap-2" (click)="goToScoreCardDetailPage(data.Match_id)">
       
                <div class="d-flex mb-0 event-name justify-content-between align-items-center">

                    <div class="d-flex flex-column col-4 justify-content-center align-items-start">
                        <p class="col-4 text-nowrap m-0 venue"><i class="bi bi-geo-alt-fill text-warning me-1"></i>{{data.Venue}}</p>
                        <p class="col-8 text-start m-0 text-nowrap date"><i class="bi bi-calendar2-week me-1"></i>{{data.Match_Date}}</p>
                    </div>
                    <p class="col-7 text-uppercase event-name text-end fs-6 fw-bolder">{{data.Event_name}}</p>
                </div>

                <div class="row mt-2">
                    <div class="col-6 d-flex align-items-center justify-content-center">
                        <div>
                            <img src="assets/dummyLogoBlue1.png" class="image mx-1" alt="flag">
                        </div>
                        <div class="score px-2">
                            <p class="text-center m-0 mb-lg-2 fs-6 team" style="font-family: Assist-Bold;">{{data.teamone}}</p>
                            <div *ngIf="data.isMultiday !='Y'">
                                <p class="fs-6 text-center team"><span class="me-1">{{data.innings_1_score}}/{{data.innings_1_wkts}}</span><span class="over">({{data.innings_1_overs}})</span></p>
                            </div>
                            
                            <div class="d-flex justify-content-center" *ngIf="data.isMultiday=='Y'">
                                <span>{{ data['innings_' + data.team_1_group?.[0] + '_score'] }}/{{ data['innings_' + data.team_1_group?.[0] + '_wkts'] }}</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[1] + '_score'] > 0 || data['innings_' + data.team_1_group?.[1] + '_score'] === 0">
                                    & {{ data['innings_' + data.team_1_group?.[1] + '_score'] }}/{{ data['innings_' + data.team_1_group?.[1] + '_wkts'] }}
                                </span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_1_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                            
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-center">
                        <div class="score px-2">
                            <p class="text-center m-0 mb-lg-2 fs-6 team" style="font-family: Assist-Bold;">{{data.teamtwo}}</p>
                            <div *ngIf="data.isMultiday !='Y'">
                                <p class="fs-6 text-center team"><span class="me-1">{{data.innings_2_score}}/{{data.innings_2_wkts}}</span><span class="over">({{data.innings_2_overs}})</span></p>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="data.isMultiday=='Y'">
                                <span>{{ data['innings_' + data.team_2_group?.[0] + '_score'] }}/{{ data['innings_' + data.team_2_group?.[0] + '_wkts'] }}</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[0] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[0] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_score'] > 0 || data['innings_' + data.team_2_group?.[1] + '_score'] === 0">
                                    & {{ data['innings_' + data.team_2_group?.[1] + '_score'] }}/{{ data['innings_' + data.team_2_group?.[1] + '_wkts'] }}
                                </span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_status']?.split(' ')[1] === 'D'">d</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_status']?.split(' ')[1] === 'F'">F</span>&nbsp;
                                <span *ngIf="data['innings_' + data.team_2_group?.[1] + '_status']?.split(' ')[1] !== 'D' && data['innings_' + data.team_2_group?.[0] + '_status']?.split(' ')[1] === 'L'">f/o</span>&nbsp;
                              </div>
                        </div>
                        <div class="text-end">
                            <img src="assets/dummyLogoBlue1.png" class="image mx-1" alt="flag">
                        </div>
                    </div>
                </div>
                <div class="row border border-light-subtle  pb-1 pt-3 rounded-3" style="background-color: rgb(237, 246, 255);" *ngIf="data.result">
                    <p class="fs-6 text-center" >{{data.result}}</p>
                </div>
    </div>
</ng-container>
