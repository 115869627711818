import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-commentary',
  templateUrl: './commentary.component.html',
  styleUrls: ['./commentary.component.css']
})
export class CommentaryComponent implements OnInit {



  overCommentaryData: any = [];
  batsmanSumm: any = [];
  bowlerSumm: any = [];
  extraSumm: any = [];
  tenBalls: any = [];
  liveCommentaryData: any = [];
  @Input() team1 : any;
  @Input() team2 : any;
  minLimit = 0;
  maxLimit = 5;
  tempTeamId: any;
  matchDetail: any;
  overAllScore: any;
  overAllWickets: any;
  currentOver: any;
  @Input() matchId: any;
  @Input() team1Id: any;
  @Input() team2Id:any;
  teamId:any;


  constructor(private scoreCardService: CricketScoreCardService,private loaderService: LoaderService) { }

  ngOnInit() {
     this.tempTeamId = this.team1Id;
    this.getMatchCommentary(this.matchId,this.tempTeamId, this.minLimit, this.maxLimit);


  }

  getMatchCommentary(matchId: any, teamId: any, minLimit: any, maxLimit: any) {
    this.matchDetail = 'C';
    this.scoreCardService
      .getCommentaryDetails(matchId, teamId, minLimit, maxLimit)
      .subscribe((response: any) => {

        response.result?.overCommentaryDetails.forEach((item:any) => {
          this.overCommentaryData.push(item);
        });

        this.loaderService.hide();
      });
  }

  getMoreResultCommentary(event: any, name: any) {
    this.minLimit = this.minLimit + event;
    this.getMatchCommentary(this.matchId,this.toGetTempTeamId(name), this.minLimit, this.maxLimit);
  }

  incrementOverNo(overNo: any) {
    return parseInt(overNo) + 1;
  }

  toGetTempTeamId(name: any) {
    if (name == this.team1) {
      this.tempTeamId = this.team1Id;
    } else {
      this.tempTeamId = this.team2Id;
    }
    return this.tempTeamId;
  }

  onChange(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.overCommentaryData = [];
    this.minLimit = 0;
    this.maxLimit = 5;

    this.getMatchCommentary(this.matchId,this.toGetTempTeamId(tab), this.minLimit, this.maxLimit);
  }

  getLiveCommentary() {
    this.matchDetail = 'P';
    this.scoreCardService.getLastTenBalls(this.matchId, 1, this.minLimit, this.maxLimit)
      .subscribe((response: any) => {
        if (response.status == "SUCCESS"||"TRUE") {

        this.batsmanSumm = response.result.batsmanSumm;
        this.bowlerSumm = response.result.bowlerSumm;
        this.extraSumm = response.result.extrasSumm;
        this.tenBalls = response.result.recentBalls;
        response.result.overDetails.forEach((item:any) => {
          this.liveCommentaryData.push(item);
        });
      }
    })
  }

  getMoreLiveCommentary(event : any) {
    this.minLimit = this.minLimit + event;
    this.getLiveCommentary();
  }
}
