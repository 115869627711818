import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-bowler-scorecard',
  templateUrl: './bowler-scorecard.component.html',
  styleUrls: ['./bowler-scorecard.component.css']
})
export class BowlerScorecardComponent {
  @Input() innBowStats: any;
  wagonCoordinateForplayers: any;
  showWagon : boolean[] = [];
  @Input() matchId: any;
  @Input() innings:any
  wagonStatus: any='';
  showNoDataFound: boolean[] = [];
  pitchMapResponse: any;
  ballHit: any;
  wagonFor='player';
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  constructor(private scorecardService: CricketScoreCardService,private service :CommonService,private dialog:MatDialog) {
  }
  ngOnInit(): void {

  }
  openWagon(data : any, index : number) {
    this.scorecardService.getWagonCoordinateForBowler(this.matchId, data).subscribe((data: any) => {
      if(data.status =="TRUE"){
      this.wagonCoordinateForplayers = data.result;
      this.ballHit=this.wagonCoordinateForplayers.filter((data:any)=>data.where_hit_x !='0' && data.where_hit_y !='0')
      this.pitchMapResponse = this.wagonCoordinateForplayers.filter((data:any)=> data.where_pitch_x != '0.0' && data.where_pitch_y != '0.0' &&  data.where_pitch_x != null && data.where_pitch_y != null );
      this.showWagon[index] = !this.showWagon[index] ;
      } else {
        this.ballHit = [];
        this.pitchMapResponse = [];
        this.showWagon[index] = !this.showWagon[index] ;
      }
    });

    // if (this.showWagon[index]) {
    //   this.showNoDataFound[index] = false;
    // } else {
    // this.showNoDataFound[index] = !this.showNoDataFound[index];
    // }


  }
  bowlersumm: any[] = [];
  runsInOver: string[] | undefined;
  bowlerInfo: any; // Define bowlerInfo to hold the current bowler's data


  bowlerInningsSumm(userId: any, index: number) {
    this.service.bowlerInningsSumm(this.matchId, userId).subscribe((data: any) => {
      if (data.status === "TRUE") {
        this.bowlersumm[index] = data.result; // Store data in the index
        console.log(this.bowlersumm[index]);
      } else {
        this.showNoDataFound[index] = true;
      }
    });
  }



  isWicket(run: string): boolean {
    return isNaN(Number(run)) && (run.includes('Wk') || run.includes('B')|| run.includes('Lb'));
  }


  toggleDetails(userId: any, index: number) {
    // Toggle the visibility of the wagon details
    if (!this.showWagon[index]) {
      this.openWagon(userId, index);
    } else {
      this.showWagon[index] = false;
      this.ballHit = [];
      this.pitchMapResponse = [];
    }
  }


  videoScorecard(data:any){
    if(data !== 'Y' && data !== 'N' && data !== ''){
      this.dialogRef= this.dialog.open(CommonAlertsComponent, {
        data: { identifier: 'videoScorecard',url:data },
        disableClose:true
      });
    }
  }

  playWicketVideo(userId: number, type: string, index: number,totalWicket:any): void {
    const ballType = type === 'WK';

    this.service.bowlerInningsSumm(this.matchId, userId).subscribe((data: any) => {
      if (data.status === "TRUE") {
        const relevantVideos = data.result
        .flatMap((over: any) => 
            over.runs_in_over
              .filter((ball: any) => ball.run === 'Wk' && ball.videoURL.startsWith('http'))
              .map((ball: any) => ({
                ...ball,
                overBall: `${over.overs}.${ball.ball_no}`,
              }))
        );
        if (totalWicket!==0) {
          this.dialogRef = this.dialog.open(CommonAlertsComponent, {
            data: { identifier: 'wicketVideo', videos: relevantVideos },
            disableClose: true
          });
        } 
        // else {
        //   console.log(`No videos available for ${type} at index ${index}`);
        // }
      } else {
        console.error('Failed to fetch innings summary');
      }
    });
  }


}
