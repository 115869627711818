import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-header-contents',
  templateUrl: './header-contents.component.html',
  styleUrls: ['./header-contents.component.css']
})
export class HeaderContentsComponent implements OnInit {
  @Input() selectedItem: any;
   selectedMenuItem: string|null='';
   tap:any;
   DynamicMoreCotent: any;
  menu: string|null ='';
  currentYear=new Date().getFullYear();
  navData: any;
  showNav:  boolean = false;
  step = 0;
  produceAndService:any;
  constructor(private service:CommonService,private route: ActivatedRoute){

  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      const menuParam = params.get('menu');

      if (menuParam) {
        this.menu = menuParam;
        this.getDynamicMoreCotent();
        this.setSelectedMenuItem();
      }
    });
    if(this.menu == 'navMenu'){
      this.openNav();
    }
    }
  setSelectedMenuItem() {
    this.selectedMenuItem=this.menu
  }
  getDynamicMoreCotent() {
    this.service.getDynamicContent().subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.DynamicMoreCotent = response.result;
      }
    })
  }
  openNav() {
    this.navData = document.getElementById("myNav");
    if (this.navData && this.showNav === false) {
      this.showNav = true;
      this.navData.style.width = "100%";
      }
     else
     {
      this.navData.style.width = "0";
      this.showNav = false;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

}
