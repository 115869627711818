import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from '../orgcode-filter.service';

@Component({
  selector: 'app-common-alerts',
  templateUrl: './common-alerts.component.html',
  animations: [
    trigger('fadeInOut', [
      state('visible', style({ opacity: 1 })),
      transition('void => visible', [
        style({ opacity: 0 }),
        animate('1500ms ease-in', style({ opacity: 1 })),
      ]),
      transition('visible => void', [
        animate('1500ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
  styleUrls: ['./common-alerts.component.css']
})
export class CommonAlertsComponent implements OnInit{
  public animationState: 'visible' | 'hidden' = 'hidden'; 
  constructor(public dialogRef: MatDialogRef<CommonAlertsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private router: Router,
  private orgFilter:OrgcodeFilterService){}
  orgCode:any
  
  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }
  matDialogConfromation(data: string) {
    this.dialogRef.close({ isConfrom: data });
    if (data === 'scoring') {
      this.router.navigate([`${this.orgCode}/events/home`]);
    } else if (data === 'Password Updated Success') {
      this.dialogRef.close(this.data.identifier);
    }
  }

  closeAndNavigate(data: any) {
    this.dialogRef.close({ isConfrom: data });
  }
  closeAlert(data: any) {
    this.dialogRef.close({ isConfrom: data });
  }
  
navtologin(){
  if(this.data.identifier=="forgotPassword")
  this.router.navigate([`${this.orgCode}/login`])
  }
}
