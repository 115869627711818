<div class="PlayervsPlayer container-fluid mt-3 mb-3">
    <mat-card>
        <!-- <mat-card-header class="row text-center">
            <mat-card-title style="border-bottom: 1px solid #b3afaf83;">
                <h5 class="fw-bold fs-4">Player VS Player</h5>
            </mat-card-title>
        </mat-card-header> -->
        <mat-card-title style="border-bottom: 2px solid #b3afaf83;">
            <!-- <div class="row my-2"   >
                <div class="col-6 d-flex justify-content-center fw-bold px-0" (click)="" style="border: none;"> <span class="col-5 btn team-name" [class.active]="team1Active" style="border: none;">{{team1}}</span></div>
                <div class="col-6 d-flex justify-content-center fw-bold px-0" (click)="" style="border: none;"><span class="col-5 btn team-name" [class.active]="team2Active" style="border: none;">{{team2}}</span></div>
            </div> -->
            <div class="d-flex justify-content-evenly">
                <div class="col-lg-2 col-6 d-flex justify-content-center align-items-center px-0" (click)="changeTab(team1innings)"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team1Active"   style="border: none;">{{team1}}</span></div>
                <div class="col-lg-2 col-6 d-flex justify-content-center align-items-center px-0" (click)="changeTab(team2innings)"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team2Active" style="border: none;">{{team2}}</span></div>
            </div>
        </mat-card-title>
        <div  class="d-flex justify-content-center" style="align-items: center; height: 20vh;" *ngIf="!isplayed"><span>No Data Found</span></div>

        <mat-card-content class="mb-2" *ngIf="isplayed">
            <mat-accordion  multi="false">
                <mat-expansion-panel class="" *ngFor="let data of batter ; let i = index" >
                    <mat-expansion-panel-header style="background-color: #f5f5f5;"(click)="getcricketplayerAnalytics(data.batsman_user_id,i)">
                        <mat-panel-title style="font-size: 0.875rem !important; color: inherit;">
                           {{data.batsman_name}} - {{data.runs}} ({{data.balls_faced}})
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 sm-padding">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="background_color_cream" style="background-color:#eee !important">
                                        <th>v Bowler</th>
                                        <th>Runs</th>
                                        <th>Balls</th>
                                        <th>SR</th>
                                    </tr>
                                </thead>
                                <tbody class="ng-scope" *ngFor="let details of bowlerstats">
                                    <tr>
                                        <td class=""><span class="option-input radio bg_grey"
                                                style="margin-right: 5px;" name="wagonbvsp"></span>{{details.F_name}}</td>
                                        <td class="">{{details.runs}}</td>
                                        <td class="">{{details.balls}}</td>
                                        <td class="">{{(details.runs*100/details.balls)|number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 sm-padding" *ngIf="showWagon[i] else wagOnWheel">
                            <app-wagon-wheel  [showTeams]="false" [wagonFor]="wagonFor" [wagonCoordinatesForPlayer]="ballHit"></app-wagon-wheel>
                            
                        </div>
                        <ng-template #wagOnWheel>
                            <app-wagon-wheel  [showTeams]="false" [wagonFor]="wagonFor" [wagonCoordinatesForPlayer]="ballHit"></app-wagon-wheel>
                        </ng-template>
                        
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>

</div>