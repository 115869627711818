<div class="container-fluid mx-1">
    <div class="justify-content-between d-none d-md-flex">
      <h1 class="bold primary">Points Table</h1>
      <div class="pointsTable">
        <mat-form-field appearance="fill">
          <input matInput (keyup)="applyFilter($event)" placeholder="Search">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="d-block d-md-none d-lg-none">
      <h1 class="bold">Points Table</h1>
      <div class="col-12 d-flex justify-content-end pointsTable">
        <mat-form-field class="col-12 col-lg-6" appearance="fill">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Search">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="border mb-2 shadow rounded-3" style="overflow-x: auto;">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
        <ng-container matColumnDef="Group_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
          <td mat-cell *matCellDef="let element"> {{element.Group_name}} </td>
        </ng-container>
        <ng-container matColumnDef="Team_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.Team_name}} </td>
        </ng-container>
        <ng-container matColumnDef="played">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mat </th>
          <td mat-cell *matCellDef="let element"> {{element.played}} </td>
        </ng-container>
        <ng-container matColumnDef="won">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Won </th>
          <td mat-cell *matCellDef="let element"> {{element.won}} </td>
        </ng-container>
        <ng-container matColumnDef="lost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Lost </th>
          <td mat-cell *matCellDef="let element"> {{element.lost}} </td>
        </ng-container>
        <ng-container matColumnDef="tied">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tie </th>
          <td mat-cell *matCellDef="let element"> {{element.tied}} </td>
        </ng-container>
        <ng-container matColumnDef="no_result">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> NR </th>
          <td mat-cell *matCellDef="let element"> {{element.no_result}} </td>
        </ng-container>
        <ng-container matColumnDef="points">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Points </th>
          <td mat-cell *matCellDef="let element"> {{element.points}} </td>
        </ng-container>
        <ng-container matColumnDef="net_run_rate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> NRR </th>
          <td mat-cell *matCellDef="let element"> {{element.net_run_rate}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator class="mb-2" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  