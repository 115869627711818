import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoService } from 'src/app/service/video.service';

@Component({
  selector: 'app-video-highlights',
  templateUrl: './video-highlights.component.html',
  styleUrls: ['./video-highlights.component.css']
})
export class VideoHighlightsComponent {
  team1Active: boolean = false;
  team2Active: boolean = false;
  innings: any;

  @ViewChild('videoPlayer') videoPlayer: any;
  matchId: any;
  videoListbyfilter: any[]=[];
  highlightsdetail: any;
  selectedFilter: any;
  filterlist: any[] = [
    {value: "All"},
    {value: "4's"},
    {value: "6's"},
    {value: "Wicket"},
  ];
  BattingPlayers: any[] = [];
  BowlingPlayers: any[] = [];
  selectedplayname: any;
  uniquePlayers: any[] = [];
  selectedbatsman: any;
  selectedbolwer: any;
  highlightsList: any[] = [];
  selectedbatsmandetail: any;
  selectedbowlerdetail: any;
  filteredbybatsman: any[] = [];
  filteredbybolwer: any[] = [];
  constructor(private service:VideoService,private route:ActivatedRoute){

  }  
ngOnInit(){
  this.matchId = this.route.snapshot.paramMap.get('matchId');
this.innings1();

}

  innings1() {
    this.innings = 1;
    this.getVideoHighlights();
    this.team1Active = true;
    this.team2Active = false;
  }
  innings2() {
    this.innings = 2;
    this.getVideoHighlights();
    this.team1Active = false;
    this.team2Active = true;
  }

  
  videoList: string[] = [];
  currentVideoIndex: number = 0;

  // Current video to be played
  get currentVideo(): string {
    return this.videoList[this.currentVideoIndex];
  }

  ngAfterViewInit() {
    // Ensure the video element is initialized after view has loaded
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
     this.videoPlayer.nativeElement.load();
    }
    else{
      console.log('No videos found for the selected innings');
      
    }
  }

  // Handle video ended event
  onVideoEnded(): void {
    this.currentVideoIndex++;

    // Check if there are more videos to play
    if (this.currentVideoIndex < this.videoList.length) {
      this.videoPlayer.nativeElement.load(); // Reload the video element to apply the new source
      this.videoPlayer.nativeElement.play(); // Start playing the next video
    } else {
      this.currentVideoIndex = 0; // Loop back to the first video if needed
      this.videoPlayer.nativeElement.load(); // Reload the first video
      this.videoPlayer.nativeElement.play(); // Start playing the first video
    }
  }
   // Forward the video by 5 seconds
   forwardVideo(): void {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.currentTime += 5; // Add 5 seconds to the current time
  }
  backwardVideo():void{
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.currentTime -= 5; // Sub 5 seconds to the current time
  }
  getVideoHighlights(){
    this.service.getVideoHighlights(this.matchId,this.innings).subscribe((response: any) => {
      if(response.status=="TRUE"){
        this.highlightsList=response.result;
        this.highlightsdetail=this.highlightsList;
        this.extractBattingPlayers();
        this.extractBowlingPlayers();
        this.filter("All","All","All")

      }else{
        console.log('No videos found for the selected innings');
      }
    });
      
  }
  filterVideoList(value:any){
    this.selectedFilter =value;
    if (value=="Wicket") {
      this.videoListbyfilter = this.filteredbybolwer.filter((data: any) => data.isWicket === "Y");
      this.videoList = this.videoListbyfilter.map((item: any) => item.videoURL);
    }else if (value=="4's") {
      this.videoListbyfilter = this.filteredbybolwer.filter(
        (data: any) => data.isWicket === "N" && data.ballType === "4"
      );
      this.videoList = this.videoListbyfilter.map((item: any) => item.videoURL);

    }else if (value=="6's") {
      this.videoListbyfilter = this.filteredbybolwer.filter(
        (data: any) => data.isWicket === "N" && data.ballType === "6"
      );
      this.videoList = this.videoListbyfilter.map((item: any) => item.videoURL);

    }else if("All"){
      this.videoList = this.filteredbybolwer.map((item: any) => item.videoURL);
    }
    this.currentVideoIndex = 0;
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.load(); // Load the updated video source
    }
  }

  filterbybatsman(batsmanId:any,bowlerId:any,event:any){
    
    if(batsmanId.type=="Batsman"){
      this.filteredbybatsman =this.highlightsdetail.filter((data: any) => data.batsmanId == batsmanId.id);
      setTimeout(() => {
        this.filterbybowler(bowlerId,event);
      }, 100);
    }else if( batsmanId=="All"){
      this.filteredbybatsman=this.highlightsdetail;
      setTimeout(() => {
        this.filterbybowler(bowlerId,event);
      }, 100);
    }
  }

  filterbybowler(bowlerId:any,event:any){    

    if(bowlerId.type=="Bowler"){
      this.filteredbybolwer =this.filteredbybatsman.filter((data: any) => data.bowlerId == bowlerId.id);
      setTimeout(() => {
       this.filterVideoList(event);
     }, 100); 
    }else if( bowlerId=="All"){
      this.filteredbybolwer=this.filteredbybatsman;
      setTimeout(() => {
        this.filterVideoList(event);
      }, 100); 
    }

  }


  extractBattingPlayers(){
    const uniqueBatsmanMap = new Map<number, string>();
    this.highlightsdetail.forEach((detail: any) => {
      // Add unique batsmen
      if (!uniqueBatsmanMap.has(detail.batsmanId)) {
        uniqueBatsmanMap.set(detail.batsmanId, detail.batsmanName);
      }
    });
    this.BattingPlayers = [
      ...Array.from(uniqueBatsmanMap, ([id, name]) => ({ id, name, type: 'Batsman' })),
    ];

  }
  extractBowlingPlayers(){
    const uniqueBowlerMap = new Map<number, string>();
    this.highlightsdetail.forEach((detail: any) => {
      // Add unique bowlers
      if (!uniqueBowlerMap.has(detail.bowlerId)) {
        uniqueBowlerMap.set(detail.bowlerId, detail.bowlerName);
      }
    });

    this.BowlingPlayers = [
      ...Array.from(uniqueBowlerMap, ([id, name]) => ({ id, name, type: 'Bowler' })),
      
    ];
    
  }




filter(batsman:any,bolwer:any,event:any){
  if(batsman=="All"){
    this.selectedbatsmandetail="All";
    this.selectedbatsman="All";

  }else{
    this.selectedbatsman=batsman.name;
    this.selectedbatsmandetail=batsman;
  }
  if(bolwer=="All"){
    this.selectedbowlerdetail="All";
    this.selectedbolwer="All";
  }else{
    this.selectedbolwer=bolwer.name;
    this.selectedbowlerdetail=bolwer;
  }
  this.filterbybatsman(batsman,bolwer,event)
}


}
