<div class="container m-3" *ngIf="tennisMatchList && tennisMatchList?.length >0">
    <div class="justify-content-center d-flex text-center">
        <ul class="nav nav-tabs nav-pills border-0 fw-semibold text-white justify-content-start ml-auto">
            <li class="nav-item w p-2">
                <button class="nav-link btn-text" mat-raised-button
                    [ngStyle]="{'background-color': selectedSet == 'all' ? 'var(--primary-color)' : '', 'color' :selectedSet == 'all' ? 'white' : 'black'}"
                    (click)="setData('all')">All</button>
            </li>
            <li *ngFor="let data of tennisMatchList[0]?.setDetail" class="nav-item w p-2">
                <div *ngIf="data.won_by_team !== null && data.won_by_team !== 'null'">
                    <button mat-raised-button class="btn-1 btn-text" (click)="setData(data.set_no)"
                        [ngStyle]="{'background-color': data.set_no === selectedSet ? 'var(--primary-color)' : '', 'color': data.set_no === selectedSet ? 'white' : ''}">
                        SET{{data.set_no}}
                    </button>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="container" *ngIf="tennisMatchList && tennisMatchList?.length >0">
    <app-tennis-score-card [teamDetail]="tennisMatchList[0]" [team1playerDetails]="team1SetDetails"
        [team2playerDetails]="team2SetDetails"></app-tennis-score-card>
</div>