import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiEndpoint } from '../common/constants/endpoint.def';
import { Observable } from 'rxjs';
import { OrgcodeFilterService } from '../common/orgcode-filter.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient,private commonStorage:OrgcodeFilterService) {}
   private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Orgcode': `${this.commonStorage.storageItems.value[0].orgPath}`
    });
  }
  getCityList(){
    const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.CREATE_EVENT.GET_CITY,{ headers : headers });
  }

  updateEvenMaster(fromData:any){
    const headers = this.getHeaders();

    return this.http.post(environment.serviceUrl +ApiEndpoint.EDIT_PROFILE.UPDATE_USER_MASTER,fromData,{ headers : headers })
  }

  getPlayerStats(playerId:any,year:any){
    const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.MYTEAMS.GET_PLAYER_STATS.replace('{playerId}',playerId).replace('{year}',year),{ headers : headers })
  }

  updateProfilePic(userId: string, imageFile: File): Observable<any> {
    const headers = this.getHeaders();

    const formData: FormData = new FormData();
    formData.append('USER_ID', userId);
    formData.append('imageFile', imageFile, imageFile.name);
    return this.http.post(environment.serviceUrl+ApiEndpoint.EDIT_PROFILE.UPDATE_PROFILE_PIC,formData,{ headers : headers });
  }
}
