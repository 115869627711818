import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mini-matches',
  templateUrl: './mini-matches.component.html',
  styleUrls: ['./mini-matches.component.css']
})
export class MiniMatchesComponent {
  @Input() data: any;
  @Input() length : any;
  @Input() selectedType : any;


  constructor(){
  }
  

  ngOnInit() {
  }
}
