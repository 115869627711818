import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';



@Component({
  selector: 'app-manhattan',
  templateUrl: './manhattan.component.html',
  styleUrls: ['./manhattan.component.css'],

})
export class ManhattanComponent implements OnInit {
  chart: Highcharts.Chart | undefined;
  Highcharts: typeof Highcharts = Highcharts;
  @Input()team1: any;
  @Input()team2: any;

  barOptions: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Runs per over',
      style: {
        fontFamily: 'Assist-Bold'
      }
    },
    subtitle: {
      text: 'Source: sportsruler.com'
    },
    xAxis: {
      categories: [],
      crosshair: true,
      title: {
        text: 'Over',
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      },
      labels: {
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Runs (per over)',
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      },
      labels: {
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} run</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
      style: {
        fontFamily: 'Assist-Bold'
      }
    }
  };

  result: any = []
  @Input() matchId: any;
  barLables: any = [];
  barTeamOneRuns: any = [];
  barTeamTwoRuns: any = [];
  intervel:any;

  constructor(private scoreService: CricketScoreCardService, private cdr: ChangeDetectorRef) { 
  }
ngOnInit(): void {
    this.getGraph();
    this.initChart();
  }

  initChart() {
    this.chart = Highcharts.chart('barchartContainer', this.barOptions);
  }


  getGraph() {
    this.scoreService.getCricketGraph(this.matchId).subscribe((resp: any) => {
      this.result = resp.result;

      if (Array.isArray(this.result)) {
        const categories = this.result.map((item: { over_no: any; }) => item.over_no);
        const teamOneRunsData = this.result.map((item: { firstRuns: any; }) => parseFloat(item.firstRuns));
        const teamTwoRunsData = this.result.map((item: { secondRuns: any; }) => parseFloat(item.secondRuns));

        if (this.chart) {
          this.chart.xAxis[0].setCategories(categories);

          this.chart.addSeries({
              type: 'column',
              name: this.team1,
              data: teamOneRunsData
          });
          this.chart.addSeries({
              type: 'column',
              name: this.team2,
              data: teamTwoRunsData
          })

          this.chart.redraw(); 
        }
        this.cdr.detectChanges();
      }
    });
  }

}