import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-score-card-carousel',
  templateUrl: './score-card-carousel.component.html',
  styleUrls: ['./score-card-carousel.component.css']
})
export class ScoreCardCarouselComponent implements OnInit {


   cricketLive : []|any=[];
   limit = 5;
   badmintonLive : any;
   tennisLive:any
   tableTennisLive:any
   cricketComplete:any;
   badmintonComplete:any;
   tableTennisComplete:any;
   tennisComplete:any;
  selectedItem: any;
  currentUrl: boolean=true;
  loginStatusValue!: boolean;
  userInformation: string | undefined;
  tournamentList: any = [];
  currEventId:any;
  sportsId: any;
  intervalId:any;
  orgCode:string='';

  constructor(private route: ActivatedRoute,private router: Router,private commonService: CommonService,private scoreCardService: CricketScoreCardService,private orgFilter:OrgcodeFilterService){}
  ngOnInit() {
    this.orgFilter.orgDetails$.subscribe(items => {
      if (items && items.length > 0) {
        this.orgCode = items[0].orgPath;
        if(this.orgCode){
           this.getCarousal();
        }
      } else {
        console.error('No items found in storageItems');
      }
    });
    // this.getMatches();
  }
  getCarousal(){
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url === '/') {
          this.currentUrl = true;
        } else {
          this.currentUrl = false;
        }
      }
    });

    if (localStorage.getItem("userId") === null) {
      this.loginStatusValue = false;
    }else if((localStorage.getItem("userId") !== null)&&(localStorage.getItem("Login_Type")==="T")){
      this.loginStatusValue = false;
    }
     else {
      this.loginStatusValue = true;
      const firstName = localStorage.getItem('firstName');
      const lastName = localStorage.getItem('lastName');
      this.userInformation =  firstName + ' ' + lastName;
    }
    this.getMatches();

    this.getTournamentsList()
  }

getMatches() {
   forkJoin({
     cricket: this.scoreCardService.getCricketLiveMatch(this.limit),
   }).subscribe(({ cricket, }: any) => {
     let livematches = cricket.result;
    if(livematches){
     livematches?.forEach((match: any) => {
       match.team1_score = `${match.innings_1_score}/${match.innings_1_wkts} (${match.innings_1_overs})`,
         match.team2_score = `${match.innings_2_score}/${match.innings_2_wkts} (${match.innings_2_overs})`,
         match.test1_score = `${match.innings_1_score}/${match.innings_1_wkts} & ${match.innings_3_score}/${match.innings_3_wkts}`,
         match.test2_score = `${match.innings_2_score}/${match.innings_2_wkts} & ${match.innings_4_score}/${match.innings_4_wkts}`,
         match.inningsStatus = match.innings_2_status?.slice(-1),
         match.followOnScore1 = `${match.innings_2_score}/${match.innings_2_wkts} & ${match.innings_3_score}/${match.innings_3_wkts}`,
         match.followOnScore2 = `${match.innings_1_score}/${match.innings_1_wkts}`
     });
    }
     this.cricketLive = livematches;
     if (this.cricketLive?.length <= 0 || !this.cricketLive) {
       this.getCompletedMatch()
     }
     else {
     this.intervalId= setTimeout(() => {
        this.getMatches()
      }, 5000);
     }
   });
 }
getCompletedMatch(){
  forkJoin({
  cricket:this.scoreCardService.getCricketCompletedMatch(this.limit),
}).subscribe(({ cricket  }: any) => {
    this.cricketComplete = cricket.result;
    this.cricketComplete = this.cricketComplete.map((s:any) => {
     s.Match_Date = moment(s.Match_Date).format("ll");
     s.Match_Time=moment(s.Match_Time,'HH:mm:ss').format('LT:mm A')
     s.team_1_group = [];
     s.team_2_group = [];
     s.matchInnings = [];
     s.leadRuns = 0;
     s.isLead = false;
     s.trailRuns = 0;
     s.team1Score = 0;
     s.team2Score = 0;
     s.teamScore = 0;
     s.currentBattingTeam = '';
     s.currentBowlingTeam = '';
     [1, 2, 3, 4].forEach(r => {
       if (r <= s.curr_innings) {
         s.matchInnings.push(r);
         const inningsStatus = s[`innings_${r}_status`]?.split(' ')[0];
         if (inningsStatus === s.Team1.toString()) {
           s.team_1_group.push(r);
         } else if (inningsStatus === s.Team2.toString()) {
           s.team_2_group.push(r);
         }
         else {
           if (s.curr_batting_team_id === s.Team1) {
             s.team_1_group.push(r);
           }
           if (s.curr_batting_team_id === s.Team2) {
             s.team_2_group.push(r);
           }
         }
       }
     });
     s.teamScore = s[`innings_${s.curr_innings}_score`];
     s.team_1_group.forEach((r:any) => {
       s.team1Score += parseInt(s[`innings_${r}_score`], 10);
     });
     s.team_2_group.forEach((r:any) => {
       s.team2Score += parseInt(s[`innings_${r}_score`], 10);
     });
     if (s.curr_batting_team_id === s.Team1) {
       s.currentBattingTeam = s.teamOne;
       s.currentBowlingTeam = s.teamTwo;
       if (parseInt(s.team1Score.toString(), 10) < parseInt(s.team2Score.toString(), 10)) {
         s.isLead = false;
         s.trailRuns = parseInt(s.team2Score.toString(), 10) - parseInt(s.team1Score.toString(), 10);
       } else {
         s.isLead = true;
         s.leadRuns = parseInt(s.team1Score.toString(), 10) - parseInt(s.team2Score.toString(), 10);
       }
     }

     if (s.curr_batting_team_id === s.Team2) {
       s.currentBattingTeam = s.teamTwo;
       s.currentBowlingTeam = s.teamOne;
       if (parseInt(s.team2Score.toString(), 10) < parseInt(s.team1Score.toString(), 10)) {
         s.isLead = false;
         s.trailRuns = parseInt(s.team1Score.toString(), 10) - parseInt(s.team2Score.toString(), 10);
       } else {
         s.isLead = true;
         s.leadRuns = parseInt(s.team2Score.toString(), 10) - parseInt(s.team1Score.toString(), 10);
       }
     }
     return s;
 })
  });
  this.ngOnDestroy()
}

  getTournamentsList() {
    this.commonService.getTournaments().subscribe((tournaments: any) => {
      this.tournamentList = tournaments?.result;
      if (this.tournamentList&&this.tournamentList.length > 0) {
        this.currEventId = this.tournamentList[0].Event_id;
        this.sportsId = this.tournamentList[0].Sport_id;
      }
    })
  }

  customOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 3
      },
      1170: {
        items: 3
      },
      1331: {
        items: 4
      }

    },
    nav: true,
    navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>']
  };
  more() {
    this.router.navigate([`${this.orgCode}/matches`])
  }
  ngOnDestroy() {
    clearInterval(this.intervalId)
  }
}

