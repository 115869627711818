<div class=" mb-2 bg-body-tertiary border-0 card-design " *ngIf="innBowStats?.length > 0 ">
    <table class="table  col-12">
        <thead>
            <tr>
                <th scope="col">Bowlers</th>
                <th scope="col">O</th>
                <th scope="col">M</th>
                <th scope="col">R</th>
                <th scope="col">W</th>
                <th scope="col" class="hide-mobile">WD</th>
                <th scope="col" class="hide-mobile">NB</th>
                <th scope="col">Eco</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let firstBow of innBowStats; let i =index ">
            <tr class="p-0 sm-screen-fs" (click)="openWagon(firstBow.bowler_user_id , i) ;bowlerInningsSumm(firstBow.bowler_user_id,i)" *ngIf="firstBow.over > 0 || firstBow.overBalls > 0"  >
                <td>{{firstBow.bowler_name}}</td>
                <td>{{firstBow.overs}}</td>
                <td>{{firstBow.maidens}}</td>
                <td>{{firstBow.runs}}</td>
                <td (click)="playWicketVideo(firstBow.bowler_user_id, 'WK', i,firstBow.wickets)" style="cursor: pointer;">{{firstBow.wickets}}</td>
                <td class="hide-mobile">{{firstBow.wides}}</td>
                <td class="hide-mobile">{{firstBow.noBalls}}</td>
                <td>{{firstBow.economy}}</td>
                <td >
                    <div *ngIf="!showWagon[i] && !showNoDataFound[i]" ><mat-icon>keyboard_arrow_down</mat-icon></div>
                    <div *ngIf="showWagon[i] || showNoDataFound[i]"><mat-icon>keyboard_arrow_up</mat-icon></div>
                </td>
            </tr>
            <tr *ngIf="showWagon[i]">
                <td colspan="12" class="p-0 mx-0">
                    <app-wagon-wheel [showTeams]="false" [wagonFor]="wagonFor"  [wagonCoordinatesForPlayer]="ballHit" ></app-wagon-wheel>
                    <app-pitch-map [showTeams]="false" [pitchMapFor]="wagonFor" [pitchMapResponseForPlayer]="pitchMapResponse" ></app-pitch-map>
                    <div class="d-flex justify-content-center">
                        <table class="table rounded-3 overflow-hidden shadow table-borderless text-start bowlertable">
                            <thead>
                                <tr>
                                    <th scope="col">Ovr No</th>
                                    <th scope="col" style="text-align: center;">Ball By Ball</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="bowlersumm">
                                <tr *ngFor="let item of bowlersumm[i]; let ind=index" class="border-bottom">
                                    <ng-container *ngIf="item.innings == innings">
                                        <!-- Overs Column -->
                                        <td class="d-flex justify-content-center align-items-center" style="height: 39px; font-size: smaller;">
                                            {{ item.overs }}
                                        </td>

                                        <!-- Runs in Over Column -->
                                        <td>
                                            <div class="d-flex gap-lg-3 gap-md-2 gap-sm-2 spacegap">
                                                <div *ngFor="let run of item.runs_in_over;let i=index" class=" d-flex justify-content-center align-items-center">
                                                    <!-- Show video icon if videoURL exists -->

                                                     <div [ngClass]="{'runWicket': isWicket(run.run)}"
                                                     class="run text-center justify-content-center align-items-center d-flex" (click)="videoScorecard(run.videoURL)">
                                                         <span class="m-0">{{ run.run }}
                                                        </span>
                                                        <i class="bi bi-play-fill videoicon " [ngClass]="{'runWicketicon': isWicket(run.run)}" *ngIf="run.videoURL && run.videoURL !== 'Y' && run.videoURL !== 'N'"  ></i>

                                                     </div>
                                                </div>
                                            </div>
                                        </td>

                                        <!-- Total Runs Column -->
                                        <td class="d-flex justify-content-center align-items-center" style="height:39px; font-size: smaller;">
                                            {{ item.runs }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>


                        </table>
                    </div>
                </td>
            </tr>
            <!-- <tr *ngIf="showNoDataFound[i] && !showWagon[i]">
                <td colspan="9" >
                    <h3 class="text-center m-0 p-0">No Record Found </h3>
                </td>
            </tr>          -->
        </ng-container>
        </tbody>
    </table>
</div>
