import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Chart } from 'chart.js/auto';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-team-summary',
  templateUrl: './team-summary.component.html',
  styleUrls: ['./team-summary.component.css']
})
export class TeamSummaryComponent implements AfterViewInit, OnInit, OnDestroy {
  mainScreen: boolean = true;
  chart: Chart | null|any = null;
  teamId: any;
  teamDetails: any;
  teamRecent: any[] = [];
  teamUpcomingMatches: any;
  teamLeaderBoardList: any;
  pagedMatches: any[] = [];
  eventLeaderBoardList: any;
  eventId: any;
  teamUserRecord: any;
  draw: number = 0;
  sportsId: any;
  teamupcominglength: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  displayedColumns: string[] = ['Event', 'Opponent', 'Result', 'Scorecard'];
  dataSource: MatTableDataSource<any>|any = new MatTableDataSource<any>();
  paginatedItems: any;
  filteredItems: any;
  pageIndex: number = 0;
  pageSize: number = 5; 
  totalRecords: number = 0;
  tournament:any=[];
  pageTourn: boolean=false;
  paginatedTeams: any;
  eventDetails: any;
  LeaderBoardDetails: any=[];
  leaderBoardTabDetails: any;
  searchText: string = '';
  filteredTeams:any=[] ;
  BattingAggregateDetails: any=[];
  slectedLeaderboard: any ='Batting';
  start =0;
  currEventId: any;
  activeTab1:any='Cricket';
  currTabId: any;
  type='Event';
  events: any;
  
  showPlayers:boolean=false
  showCompletedMatch:boolean=false;
  teamEventLeaderboardList: any;
  teamName:any;
  captainId:any;
  teamDesc: any;
  orgCode: any;


  constructor(private service: CommonService, private route: ActivatedRoute, private router: Router,
    private orgFilter:OrgcodeFilterService
  ) {
    // const routeState = history.state;
    // this.teamId = routeState.teamId;
    // console.log(history.state.teamId);
    // const navigation = this.router.getCurrentNavigation();
  
    // if (navigation?.extras?.state) {
    //   this.teamId = navigation.extras.state['teamId'];
    // }
    // const navigation = this.router.getCurrentNavigation();
    // if (navigation?.extras?.state) {
    //   this.teamId = navigation.extras.state['teamId'];
    // }
  }

  ngOnInit(): void {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath

    this.route.queryParams.subscribe(params => {
      this.teamId = params['Id'];
      console.log('User ID:', this.teamId);  // Debug log
    });
    this.route.paramMap.subscribe(params => {
      this.events= params.get('EVENTS')
      if(this.events=="EVENTS"){
        this.getevnets();
        }
        else{

        }
    });
    this.allTeamRecent(this.teamId);
    this.cricketTeamUpcomingMatches(this.teamId);
    this.teamLeaderBoard(this.teamId);
    this.displayTeamName();
    this.cricketTeamUserRecord();
    this.teamEventLeaderboard(this.teamId);
  }
  ngAfterViewInit(): void {
    this.createChart();
  }
  

  ngOnDestroy(): void {
    this.destroyChart();
  }

  destroyChart(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  createChart(): void {
    if (!this.teamUserRecord || this.teamUserRecord.length === 0 || !this.teamDetails || this.teamDetails.length === 0) {
      return;
    }

    const matchesWon = this.teamUserRecord[0]?.Matches_won || 0;
    const matchesLost = this.teamUserRecord[0]?.lost || 0;
    const matchesNoResults = this.teamUserRecord[0]?.Matches_no_results || 0;
    const totalMatches = this.teamUserRecord[0]?.Matches_played || 0;
    this.draw = totalMatches - (matchesWon + matchesLost + matchesNoResults);

    const xValues = ["Win", "Lost", "NR", "Draw"];
    const yValues = [matchesWon, matchesLost, matchesNoResults, this.draw];
    const total = yValues.reduce((acc, value) => acc + value, 0);

    const barColors = ["#b91d47", "#00aba9", "#2b5797", "#e8c3b9", "#1e7145"];
    const percentages = yValues.map(value => ((value / total) * 100).toFixed(2) + '%');

    this.chart = new Chart("myChart", {
      type: "pie",
      data: {
        labels: xValues.map((label, index) => `${label} (${percentages[index]})`),
        datasets: [{
          backgroundColor: barColors,
          data: yValues
        }]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `${this.teamDetails[0].Team_Name} - Cricket Player Summary in %`
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const label = context.label || '';
                const value = context.raw || 0;
                return `${label}: ${value} (${percentages[context.dataIndex]})`;
              }
            }
          }
        }
      }
    });
  }

  showAllCompMatch(): void {
    this.mainScreen = !this.mainScreen;
    this.showCompletedMatch=!this.showCompletedMatch
    if (this.mainScreen) {
      setTimeout(() => this.createChart(), 0);
    }
    this.updatePaginatedTeams();
  }

  displayTeamName(): void {
    this.service.displayTeamName(this.teamId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.teamDetails = response.result;
        this.teamName=this.teamDetails[0]?.Team_Name
        this.captainId=this.teamDetails[0]?.captain_id
        this.teamDesc=this.teamDetails[0]?.Team_desc
      }
    });
  }

  cricketTeamUserRecord(): void {
    this.service.cricketTeamUserRecord(this.teamId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.teamUserRecord = response.result;
        this.createChart();
      }
    });
    
  }

  allTeamRecent(teamId: any): void {
    this.service.allTeamRecent(teamId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.teamRecent = response.result;
        this.eventId = this.teamRecent[0].Event_id;
        this.sportsId = this.teamRecent[0].Sport_id;
        this.dataSource.data = this.teamRecent; 
        this.dataSource.paginator = this.paginator; 
        this.updatePaginatedTeams();
      }
    });
  }

  cricketTeamUpcomingMatches(teamId: any): void {
    this.service.cricketTeamUpcomingMatches(teamId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.teamUpcomingMatches = response.result;
        this.teamupcominglength = this.teamUpcomingMatches.length;
      }
    });
  }

  teamLeaderBoard(teamId: any): void {
    this.service.teamLeaderBoard(teamId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.teamLeaderBoardList = response.result;
        // this.eventLeaderBoard(teamId);
      }
    });
  }

  // eventLeaderBoard(eventId: any): void {
  //   this.service.eventLeaderBoard(eventId).subscribe((response: any) => {
  //     if (response.status === 'TRUE') {
  //       this.eventLeaderBoardList = response.result;
  //     } else {
  //       this.iseventLeaderBoard = false;
  //     }
  //   });
  // }

  teamEventLeaderboard(teamId: any): void {
    this.service.teamEventLeaderboard(teamId).subscribe((response: any) => {
      if (response.status === 'TRUE') {
        this.teamEventLeaderboardList = response.result;
      }
    });
  }

  // navigatToLeaderBoard(): void {
  
    
  //   // this.router.navigateByUrl(`cricketPlayerSumm/#${navigationExtras}`);
  //   // this.router.`navigate`(['/tournament', this.eventId, this.sportsId], navigationExtras);
  //   const navigationExtras: NavigationExtras = {
  //     state: {
  //       EVENTS: 'Events',
  //     }
  //   };
  //   const routeParam=`${this.eventId}/${this.sportsId}`
  //   this.router.navigate([`/cricketPlayerSumm/#${routeParam}`], navigationExtras);
  // }
  navigatToLeaderBoard(): void {
    // const routeParam = `${this.eventId}/${this.sportsId}`;
    // const navigationExtras: NavigationExtras = {
    //   state: {
    //     EVENTS: 'Events',
    //   }
    // };
    this.getevnets();
    // this.router.navigate([`/cricketPlayerSumm/#`, this.eventId,this.sportsId], navigationExtras);
  }
  
  getevnets(){
    this.pageTourn=true;
    this.slectedLeaderboard='Batting';
    this.getLiveUserEvents(this.eventId);
    this.leaderBoardTabUI("Batting",3)
    this.getLeaderUi(this.type,this.sportsId)
  }

  navigateToScorecardDetaile(matchId: any): void {
    this.router.navigate([`${this.orgCode}/scorecardDetails`, matchId]);
  }
  updatePaginatedTeams() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedItems = this.dataSource.data.slice(startIndex, endIndex);
    this.totalRecords=this.dataSource.data.length
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePaginatedTeams();
  }
  showPlayerList(){
    this.mainScreen=!this.mainScreen
    this.showPlayers=!this.showPlayers
  }
  navigateToPlayerProfile(userId:any) {
    // this.router.navigate(['/playerprofile'], { queryParams: { Id: userId } });
    this.router.navigate([`${this.orgCode}/playerprofile`,userId]);
  }

  navigateToTournament(eventId: any ): void {
    this.router.navigate([`${this.orgCode}/tournament`, eventId, this.sportsId]);

  } 
  
  getLiveUserEvents(currEventId:any){
    this.service.getUserEvents(currEventId).subscribe((events: any) => {
      this.eventDetails = events.result;
      });
  }

  getLeaderUi(type:any,sportId:any){
    this.service.getLeaderUi(type,sportId).subscribe((leaderBoard:any)=>{
     if(leaderBoard.status==='TRUE'){
       this.LeaderBoardDetails = leaderBoard.result;
     }
    })
   }

   getLeaderTabUi(currTabId:any){
    this.service.getLeaderTabUi(currTabId).subscribe((leaderBoardTab:any)=>{
          if(leaderBoardTab.status==='TRUE'){
      this.leaderBoardTabDetails=leaderBoardTab.result;
    }
    })
  }
  
  search() {
    if (this.searchText) {
      this.filteredTeams = this.BattingAggregateDetails.filter((team: { team_name: string  }) => {
        return team.team_name.toLowerCase().includes(this.searchText.toLowerCase());
      });
      this.updatePaginatedTeams();
      this.searchText='';
    }
  }
  searchTeam(){
    if(this.searchText){
      this.filteredTeams = this.BattingAggregateDetails.filter((team: { Team_name: string  }) => {
        return team.Team_name.toLowerCase().includes(this.searchText.toLowerCase());
      });
      this.updatePaginatedTeams();
    }
  }
  setExpend(index: number) {
    this.start = index;
  }
  activeTab: string = 'Batting';

  expend(tableId: any) {
    this.getBattingAggregate(this.currEventId, tableId);
  }
  getBattingAggregate(currEventId: any, tableId: any) {
    this.service.getBattingAggregate(currEventId, tableId).subscribe((battingAggregate: any) => {
      this.BattingAggregateDetails = battingAggregate.result;
      this.filteredTeams = this.BattingAggregateDetails.slice();
      this.updatePaginatedTeams();
    });
  }
  
  leaderBoardTabUI(value: string,tabId:any) {
    this.currTabId=tabId;
    this.slectedLeaderboard=value;
    this.getLeaderTabUi(this.currTabId);
  }
}
