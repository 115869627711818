import { Component } from '@angular/core';

@Component({
  selector: 'app-tennis-card',
  templateUrl: './tennis-card.component.html',
  styleUrls: ['./tennis-card.component.css']
})
export class TennisCardComponent {

}
