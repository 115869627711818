<div class="main-container col-12 background">
  <div class="d-flex justify-content-center">
    <div class="card col-lg-4  col-10 my-3">
      <div class="text-center card-heading my-3 ">
        <h3>Register</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="signUpForm" autocomplete="off" (submit)="registerUser()">
          <div class="row g-2">
            <mat-form-field appearance="outline" class="form-group my-1 col-lg-6 col-md-6 col-12 ">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="First Name" formControlName="firstName">
              <mat-error *ngIf="f['firstName'].invalid && f['firstName'].touched">
                <span *ngIf="f['firstName'].errors?.['pattern']">Enter valid name.</span>
                <span *ngIf="f['firstName'].errors?.['required']">First Name is Required.</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-group my-1 col-lg-6 col-md-6 col-12 ">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" formControlName="lastName">
              <mat-error *ngIf="f['lastName'].invalid && f['lastName'].touched">
                <span *ngIf="f['lastName'].errors?.['pattern']">Enter valid name.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="position-relative">
            <mat-form-field appearance="outline" class="form-group my-1" style="width: 100%;">
              <mat-label>User Name</mat-label>
              <input matInput placeholder="User Name" formControlName="userName"
                [class.invalid]="f['userName'].touched && f['userName'].invalid" (blur)="checkUserName($event)"
                (keydown.space)="$event.preventDefault()">
              <mat-error *ngIf="signUpForm.get('userName')?.hasError('required')">User Name is Required.</mat-error>
            </mat-form-field>
            <mat-error *ngIf="isUserExist && !f['userName'].errors?.['required'] " class="text-start already"> User
              already exist.</mat-error>
          </div>
          <div class="position-relative">
            <mat-form-field appearance="outline" class="form-group my-1" style="width: 100%;">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email" [class.invalid]="isEmailExist"
                (input)="checkEmail($event)" (keydown.space)="$event.preventDefault()">
              <mat-error *ngIf="signUpForm.get('email')?.hasError('required')">Email is required.</mat-error>
              <mat-error *ngIf="signUpForm.get('email')?.hasError('email')">Enter a valid email.</mat-error>
            </mat-form-field>
            <mat-error
              *ngIf="isEmailExist&& !signUpForm.get('email')?.hasError('email')&&!signUpForm.get('email')?.hasError('required')"
              class="text-start already">Email Id already exist.</mat-error>
          </div>
          <div class="position-relative">
            <mat-form-field appearance="outline" class="form-group my-1" style="width: 100%;">
              <mat-label>Mobile Number</mat-label>
              <input matInput placeholder="Mobile Number" formControlName="mobileNo" maxlength="12"
                [class.invalid]="f['mobileNo'].touched && f['mobileNo'].invalid" (blur)="checkMobileNo($event)">
              <mat-error *ngIf="signUpForm.get('mobileNo')?.hasError('pattern')">Enter a valid number.</mat-error>
              <mat-error *ngIf="signUpForm.get('mobileNo')?.hasError('required')">Mobile Number is Required</mat-error>
            </mat-form-field>
            <mat-error
              *ngIf="isMobileExist &&!signUpForm.get('mobileNo')?.hasError('required')&&!signUpForm.get('mobileNo')?.hasError('pattern')"
              class="text-start already">Mobile No already exist.</mat-error>
          </div>
          <mat-form-field appearance="outline" class="form-group my-1" style="width: 100%;">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="dobPicker" [max]="today" placeholder="Date of Birth" formControlName="dob">
            <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
            <mat-datepicker #dobPicker></mat-datepicker>
            <mat-error *ngIf="f['dob'].invalid && f['dob'].touched&&!f['dob'].errors?.['invalidDOB']">Date of Birth is
              required.</mat-error>
            <mat-error *ngIf="f['dob'].errors?.['invalidDOB']">Your age is less than 6.</mat-error>
          </mat-form-field>
          <div class="row g-2">
            <mat-form-field appearance="outline" class="form-group my-1 col-lg-6 col-md-6 col-12 ">
              <mat-label>Password</mat-label>
              <input matInput [type]="isPasswordVisible ? 'text' : 'password'" placeholder="Password"
                formControlName="pwd">
              <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button">
                <mat-icon>{{isPasswordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="f['pwd'].invalid && f['pwd'].touched">
                <span *ngIf="f['pwd'].errors?.['minlength']">Password must be at least 6 characters.</span>
                <span *ngIf="f['pwd'].errors?.['required']">Password is required.</span>
              </mat-error>
            </mat-form-field>
            <!-- <div class="position-relative"> -->
            <mat-form-field appearance="outline" class="form-group my-1 col-lg-6 col-md-6 col-12 ">
              <mat-label>Confirm Password</mat-label>
              <input matInput type="password" #password placeholder="Confirm Password" formControlName="confirmPwd">
              <mat-error *ngIf="f['confirmPwd'].invalid && f['confirmPwd'].touched">
                <span *ngIf="f['confirmPwd'].errors?.['required']">Confirm password is required.</span>
                <span *ngIf="signUpForm?.hasError('mismatch')">Password does not match.</span>
              </mat-error>
              <!-- <mat-error *ngIf="signUpForm.errors?.['mismatch']">password does not match.</mat-error>  -->
              <!-- <mat-error *ngIf="signUpForm.errors?.['mismatch']&&!f['confirmPwd'].errors?.['required']" >password does not match.</mat-error> -->
              <!-- <mat-error *ngIf="f['confirmPwd'].errors?.['required']">Confirm password is required</mat-error>-->
            </mat-form-field>
            <!-- </div> -->
          </div>
          <div class="d-flex justify-content-center ">
            <button type="submit"
              [disabled]="(signUpForm.invalid || (isEmailExist === true || isUserExist === true  || isMobileExist === true))"
              class="btn btn-success submit mt-2">Create </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>