import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { BadmintonService } from 'src/app/service/badminton.service';

@Component({
  selector: 'app-player-info',
  templateUrl: './player-info.component.html',
  styleUrls: ['./player-info.component.css']
})
export class PlayerInfoComponent {

  @Input() playerInfo:any;

  @Output() sendData=new EventEmitter<string>();

  personalData(){
    this.sendData.emit('P');
  }


}
