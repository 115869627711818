<!-- <div class="score-card-body row" *ngIf="data && length > 0">
    <div class="justify-content-center country-info col-4">
        <div class="flag-avatar">
            <figure >
                <img src="/assets/img/{{ selectedType }}.png" alt=" ">
            </figure>
            <span class="country-name">{{data.teamone}}</span>
        </div>
    </div>
    <div class="justify-content-center score-update text-center col-4">
        <p class="fw-medium text-uppercase" style="padding: 5px 0px;margin:2px;font-size:10px">
            {{data.Venue}}
        </p>
        <div style="font-weight: 400;">
            <h5 class="fw-lighter text-nowrap">{{data.Match_Date | date: 'd MMM y'}}</h5>
            <h5 class="fw-lighter">{{data.Match_Time }}</h5>
        </div>
    </div>
    <div class="justify-content-center country-info col-4">
        <div class="flag-avatar">
            <figure>
                <img src="/assets/img/{{ selectedType }}.png" alt=" ">
            </figure>
            <span class="country-name">{{data.teamtwo}}</span>
        </div>
    </div>
</div> -->
<div class="score-card-body row mb-3"  *ngIf="data && length > 0"
    style="box-shadow: 0px 5px 8px 3px lightgray;border: none;" [ngClass]="{'white-bg': orgCode === 'SR'}">
    <div class="col-12  d-flex my-1 mx-1">
        <div class="col-9 pb-1">
            <div class="d-flex justify-content-center">
                <div class="d-flex flex-column align-items-center mt-1 mx-0  w-100" style="text-align: center;">
                    <div class="col-12 p-0 d-flex justify-content-center w-100">
                        <div class="flag-avatar ">
                            <figure>
                                <img src="assets/images/srmatchlogoup.png" alt="">
                            </figure>&nbsp;
                            <span class="country-name ">{{data.teamone}}</span>
                        </div>
                    </div>
                    <div class="col-12 p-0 text-center">
                        <span class="col-9">VS</span>
                    </div>
                    <div class="col-12 p-0 d-flex  justify-content-center w-100">
                        <div class="flag-avatar">
                            <figure>
                                <img src="assets/images/srmatchlogoup.png" alt="">
                            </figure>&nbsp;
                            <span class="country-name ">{{data.teamtwo}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center ">
                <div class="my-1 col-12 d-flex justify-content-center" style="border-top: 1px solid lightgray;">
                    <i class="bi bi-geo-alt-fill mt-2 text-warning"></i>&nbsp;
                    <span class="fw-normal mt-2 text-uppercase" style="font-size: small;">{{data.Venue}}</span>
                </div>
            </div>
        </div>
        <div class=" d-flex justify-content-center align-items-center score-update text-center col-3 pb-1 ms-1  "
          style="border-left: 1px solid lightgray;">
          <div style="font-weight: 400;">
            <h5 class="fw-lighter text-nowrap">{{data.Match_Date | date: 'd MMM y'}}</h5>
            <h5 class="fw-lighter">{{data.Match_Time }}</h5>
          </div>
        </div>
    </div>
</div>



<div *ngIf="length == 0 && orgCode==='SR'">
    <h4 class="d-flex justify-content-center">No Records</h4>
</div>
