<div class="partnership container-fluid mt-3 mb-3">
    <mat-card>
        <mat-card-title style="border-bottom: 2px solid #b3afaf83;">
            <div class="d-flex justify-content-evenly">
                <div class="col-lg-2 col-6 d-flex justify-content-center align-items-center px-0" (click)="innings1()"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team1Active" style="border: none;">{{teams.teamOne}}</span></div>
                <div class="col-lg-2 col-6 d-flex justify-content-center align-items-center px-0" (click)="innings2()"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team2Active"   style="border: none;">{{teams.teamTwo}}</span></div>
            </div>
        </mat-card-title>
        <div class="d-flex justify-content-center" style="align-items:center;height: 16.5vh;" *ngIf="!ispartnership">No Data Found</div>
        <mat-card-content *ngIf="ispartnership">
                <div class="col-12 m-2">
                    <div class="d-flex  my-2" *ngFor="let data of PartnerShip">
                        <div class="col-3" style="display: grid;justify-content: center;">
                            <p  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{data.batsman_1_name}}</p>
                            <span style="display: flex; justify-content: center;">{{data.batsman_1_contribution}}
                                ({{data.batsman_1_balls}})</span>
                        </div>
                        <div class="col-6 d-flex flex-column">
                            <p class="text-center">{{data.runs}} ({{data.balls}})</p>
                            <div class="d-flex justify-content-center">
                            <div class="col-6" style="position: relative; height: 16px;">
                                <div [style.width.%]="data.batsman_1_contribution" style="position: absolute; right: 0; background-color: #07be07; height: 100%; border-radius:0 1px 1px 0;"></div>
                            </div>
                            <div class="col-6" style="position: relative; height: 16px;">
                                <div [style.width.%]="data.batsman_2_contribution" style="position: absolute; left: 0; background-color: #2b2bda; height: 100%; border-radius: 1px 0 0 1px;"></div>
                            </div>
                        </div>
                        </div>
                        
                        <div class="col-3" style="display: grid;justify-content:center;">
                            <p style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{data.batsman_2_name}} </p>
                            <span style="display: flex; justify-content: center;">{{data.batsman_2_contribution}}
                                ({{data.batsman_2_balls}})</span>
                        </div>
                    </div>
                </div>
        </mat-card-content>
    </mat-card>
</div>