import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';
import { PlayerService } from 'src/app/service/player.service';
import { CommonAlertsComponent } from '../common-alerts/common-alerts.component';
import { OrgcodeFilterService } from '../orgcode-filter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  cricketLive: any[] = [];
  badmintonLive: any[] = [];
  tableTennisLive: any[] = [];
  tennisLive: any[] = [];
  liveList = [];
  cricketComplete: any[] = [];
  badmintonComplete: any[] = [];
  tableTennisComplete: any[] = [];
  tennisComplete: any[] = [];
  completeList: any[] = [];
  profileTogling: boolean = false;
  liveStatus: string = 'P';
  completedStatus:string = 'C'
  limit = 5;
  loginStatusValue!: boolean;
  userInformation: string | undefined;
  currentUrl: boolean = true;
  activeTab: any;
  isMoreDropdown: boolean = false;
  teamOnePoint = 0;
  teamTwoPoint = 0;
  teamOneScore: any;
  teamtwoScore: any;
  matchResult: any;
  setDetails=[];
  showSportsData :boolean= false;
  showVideosData :boolean= false;
  selectedItem: any;
  dropCricketEvent:boolean=false;
  showDiv: boolean=false;
  displayCountForCompleted: number = 5;
  displayCountForUpcoming : number =5;
  displayCountForLive: number = 5;
  liveMatch: any;
  liveMatchData: any;
  completedMatch: any;
  completedMatchData: any;
  upcomingMatchData: any;
  upcomingMatch : any;
  MatchId: any;
  dropTableTennis: boolean=false;
  dropBadminton: boolean=false;
  dropTennis: boolean=false;
  tournamentList: any = [];
  currEventId:any;
  sportsId: any;
  liveAndCompleteList: any[] = [];
  isLoading: boolean = true;
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  moreActive: boolean = false;
  moreEventsActive: boolean = false;
  orgCode:string='';
  icon: any;
  background: any;



  constructor(private scoreCardService: CricketScoreCardService, private commonService: CommonService, private playerService: PlayerService,private route: ActivatedRoute,
    private router: Router,private dialog:MatDialog,private orgFilter:OrgcodeFilterService) {

  }

  ngOnInit() {
    // this.orgCode=this.orgFilter.storageItems.value[0].orgPath
    this.orgFilter.orgDetails$.subscribe(items => {
      if (items && items.length > 0) {
        this.orgCode = items[0].orgPath;
        this.icon=items[0].icon;
        this.background=items[0].background;
        if(this.orgCode){        
           this.getCarousal();
        }
      } else {
        console.error('No items found in storageItems');
      }
    });
this.headerArray = this.headerArray.map(item => {
    if (item.tab === 'About Us') {
      item.routerLink = `/${this.orgCode}/HeaderContent/AboutUs`;
    } else if (item.tab === 'How To Use') {
      item.routerLink = `/${this.orgCode}/HeaderContent/HowToUse`;
    }
    return item;
  });
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.selectedItem = fragment;}
    });
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('/home')) {
          this.currentUrl = true;
        } else {
          this.currentUrl = false;
        }
        this.tabActive(val.url);
      }
    });
    // this.dialogRef= this.dialog.open(CommonAlertsComponent, {
    //   data: { identifier: 'loader' }
    // });

  }

  headerArray = [
    { tab: 'Home', id: 1, active: true, routerLink: '/home' },
    { tab: 'Events', id: 2, active: false },
    { tab: 'Matches', id: 3, active: false, routerLink: '/matches' },
    { tab: 'SR TV', id: 4, active: false, routerLink:'/videos' },
    { tab: 'About Us', id: 5, active: false, routerLink: '/HeaderContent/AboutUs' },
    { tab: 'How To Use', id: 6, active: false, routerLink: '/HeaderContent/HowToUse' },
    { tab: 'More', id: 7, active: false },

  ]
  sports = [
    { tab: 'Cricket',  active: true, routerLink: '/matches', subLink: '/scorecardDetails' },
    { tab: 'Table Tennis',  active: false, routerLink: '', subLink: ''},
    { tab: 'Badminton',  active: false, routerLink: '' },
    { tab: 'Tennis', active: false, routerLink: '' }
  ];
  menuItems = [
    { tab: 'Features', id: 7, active: false, routerLink: '/HeaderContent' },
    { tab: 'Terms & Condition', id: 8, active: false, routerLink: '/HeaderContent' },
    { tab: 'Contact Us', id: 9, active: false, routerLink: '/HeaderContent' },
    { tab: 'Privacy Policy', id: 9, active: false, routerLink: '/HeaderContent' }
  ];

  showSports(){
    this.isMoreDropdown=false;
    if(this.showSportsData==false){
      this.showSportsData=true;
    }
  }
  showMore() {
    this.showSportsData=false;
    if(this.isMoreDropdown==false){
      this.isMoreDropdown=true;
    }
  }
  hideMore(){
    this.isMoreDropdown = false;
  }
  hideAll() {
    this.isMoreDropdown=false;
    this.showSportsData=false;
  }
  navigateTo(menu:any){
    this.router.navigateByUrl(menu);
  }
  navigateToheaderContent(menu: any) {
    if (menu.startsWith("//")) {
        menu = menu.slice(2);
    } else if (menu.startsWith("/")) {
        menu = menu.slice(1);
    }
    if (this.orgCode.endsWith("/")) {
        this.orgCode = this.orgCode.slice(0, -1);
    }
    const test = `${this.orgCode}/${menu}`;
    this.router.navigateByUrl(test);
}


  nativegateMore(item:any){
    if(item!='Events'){
      const fliteredItem = item.replace(/\s/g, '');
      this.router.navigate([`${this.orgCode}/HeaderContent`,fliteredItem]);
      this.isMoreDropdown = false;
      this.moreActive=true;
      this.moreEventsActive=false;
    }
  }




  tabActive(path: any) {
    if (path) {
      if (path == '/') {
        this.headerArray?.forEach((header: any, index: number) => {
          header.active = index === 0;
        });
      } else if (path == '/features' || path == '/termsandconditions' || path == '/contactus' || path == '/privacypolicy') {
        this.headerArray?.forEach((header: any, index: number) => {
          header.active = index === 5;
        });
      } else {
        this.headerArray.map((headerPath: any) => {
          if (headerPath.routerLink == path || (path.includes(headerPath.subLink)) && headerPath.subLink != '') {
            headerPath.active = true;
          } else {
            headerPath.active = false;
          }
        })
      }
    }
  }

  getBadmintonMatchList() {
  }

  profileToggle() {
    this.profileTogling = !this.profileTogling;
  }
  editProfile(){
    this.router.navigate([`${this.orgCode}/edit-profile`])
  }
  onLogout() {
    localStorage.clear();
    this.router.navigate([`${this.orgCode}/home`]).then(() => {
      window.location.reload();
    });
  }

  selectItem(item: any) {
    this.selectedItem = item;
  }
  isActive(tab: string): boolean {
    return this.activeTab === tab;
}

showRelated(showContent: string) {
  if (showContent === "Cricket") {
    this.dropCricketEvent = true;
    this.dropTableTennis=false;
    this.dropBadminton=false;
    this.dropTennis=false;
  }else if(showContent === "Table Tennis"){
    this.dropCricketEvent = false;
    this.dropTableTennis=true;
    this.dropBadminton=false;
    this.dropTennis=false;
  }
  else if(showContent === "Badminton"){
    this.dropCricketEvent = false;
    this.dropTableTennis=false;
    this.dropBadminton=true;
    this.dropTennis=false;
  }
  else if(showContent === "Tennis"){
    this.dropCricketEvent = false;
    this.dropTableTennis=false;
    this.dropBadminton=false;
    this.dropTennis=true;
  }

}

hideRelated() {
  this.dropCricketEvent = false;
}

showEvent(section:any,tab:any){
  let currEventId:any
  let sportsId:any
  this.moreEventsActive=true;
  this.commonService.getTournaments().subscribe((tournaments: any) => {
    if (this.tournamentList.length > 0) {
       currEventId = this.tournamentList[section].Event_id;
       sportsId = this.tournamentList[section].Sport_id;
    }
    if(tab!='All'){
      this.router.navigate([`${this.orgCode}/tournament`,currEventId,sportsId])
     }else{
      this.router.navigate([`${this.orgCode}/tournament`,'All',sportsId])
     }
  })
  this.moreActive=false;
}
getTournamentsList() {
  this.commonService.getTournaments().subscribe((tournaments: any) => {
    this.tournamentList = tournaments?.result;

    if (this.tournamentList &&this.tournamentList.length > 0) {
      this.currEventId = this.tournamentList[0].Event_id;
      this.sportsId = this.tournamentList[0].Sport_id;
    }
    // this.dialogRef?.close();
  })
}

navigateSub(tab:any,routelink:any){
  const navigationExtras: NavigationExtras = {
    state: {
      section: tab
    }
  };
  this.router.navigate([`${this.orgCode}${routelink}`], navigationExtras);
}

// getCricketLiveMatch() {
//   this.scoreCardService.getCricketLiveMatch(this.limit).subscribe((data: any) => {
//     if (data.result?.length < 0 || data.status == "NOROWS") {
//     }
//     else {
//       this.liveList = data.result;

//       this.liveList.map((data:any)=>{
//         data.Match_Date=moment(data.Match_Date).format("LL");
//       })
//       this.isLoading = false;
//     }
//   });
// }
 toCamelCaseWithSpace(input: string): string {
  return input.split(' ').map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}
navigateToMatches(menu:any){
  if(this.tournamentList.length>0){
  this.router.navigateByUrl(menu);
}
}
getCarousal(){


  if (localStorage.getItem("userId") === null) {
    this.loginStatusValue = false;
  }else if((localStorage.getItem("userId") !== null)&&(localStorage.getItem("Login_Type")==="T")){
    this.loginStatusValue = false;
  }
   else {
    this.loginStatusValue = true;
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    this.userInformation =  firstName + ' ' + lastName;
  }
  this.getTournamentsList()

}
  more() {
    this.router.navigate([`${this.orgCode}/matches`])
  }
  handleMore(item:any){
    this.selectedItem=''
    if(item!='Events'){
      this.moreEventsActive=false;
      this.moreActive=false
    }

  }


  openSearch(){
    this.dialogRef= this.dialog.open(CommonAlertsComponent, {
      data: { identifier: 'search' },
      disableClose:true
    });
  }
}
