import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-commentary-card',
  templateUrl: './commentary-card.component.html',
  styleUrls: ['./commentary-card.component.css']
})
export class CommentaryCardComponent implements OnInit {

  @Input() completedData : any;
  @Input() batsmanSumm : any;
  @Input() bowlerSumm : any;
  @Input() extraSumm : any;
  @Input() tenBalls : any;
  @Input() liveData : any;
  @Input() liveScore:any;
  @Output() limit = new EventEmitter();
  @Input() showLoadMore:boolean=true;

  matchDetail: any;

  displayedBatsmen: string[] = ['batsmen', 'runs', 'balls', 'fours', 'sixes', 'strikeRate'];
  displayedBowlers: string[] = ['bowler', 'overs', 'maidens', 'runs', 'wickets', 'economy'];
  emitCount=0;

  ngOnInit() {
    if(this.completedData) {
      this.matchDetail = 'C';
    } else {
      this.matchDetail = 'P';
    }
  }

  incrementOverNo(overNo: any) {
    return parseInt(overNo) + 1;
  }

  getMoreCommentary() {
    this.limit.emit(5);
    this.emitCount += 5;
  }
}
