<div *ngIf="mainScreen" class="container  mainScreen py-3 pt-0  my-4 mt-3">
    <div class="col-lg-6 col-12 d-flex justify-content-start w-100 text-center ps-0">
        <!-- <div class="searchBtn">
            <input #searchInput [(ngModel)]="searchStr" type="text" class="searchStr py-1 w-100"
                [placeholder]="currentPlaceholder" aria-label="Search" />
            <i class="bi bi-search"></i>
        </div> -->
        <div class="searchBtn">
            <input #searchInput [(ngModel)]="searchStr" type="text" class="searchStr py-1 w-100"
                [placeholder]="currentPlaceholder" aria-label="Search" (input)="restrictSpecialCharacters($event)" />
            <i class="bi bi-search"></i>
        </div>
        </div>

    <div class="d-flex justify-content-between my-4 row-gap-4">
        <div class="col-8">
            <span class="text-secondary">CATEGORIES</span>
            <span class="text-dark">
                {{ selectedCategory === 'All' ? selectedCategory : selectedCategories.join(', ') }}
            </span>
        </div>
        <div class="col-4 text-end" *ngIf="searchStr.length > 0">
            <button id="searchButton" class="btn border" (click)="search()">Let's find it</button>
        </div>
    </div>

    <div class="row gap-3 justify-content-center">
        <div class="col-auto" *ngFor="let category of icons">
            <img class="catImg p-2 img-fluid cursor-pointer" (click)="updateCategory(category)"
                [src]="category.isSelected ? category.imgSelected : category.img" alt="{{ category.name }}">
        </div>
    </div>
</div>
<div *ngIf="!mainScreen" class="container my-3 secondScreen minHeight" [ngClass]="{'minHeightforLoader': isLoading}" >
    <div class="m-3  fw-bolder cursor-pointer" style="width: max-content;" (click)="showResult()">
        <i class="bi bi-chevron-left"></i>Back
    </div>
    <div class="row ">
        <div class="col-12">
            <div *ngIf="isLoading" class="text-center d-flex align-items-center justify-content-center my-2"><app-loader class=" "></app-loader></div>
            <mat-tab-group class="tab-container" *ngIf="((showPeople && people.length > 0)||(showTeam && teams.length > 0)||(showEvents && events.length > 0))&& !isLoading else noRecord">
                <mat-tab [label]="'People (' + people.length + ')' "
                    *ngIf="selectedCategory === '' ? (people.length>0) : icons[0].isSelected">
                    <div class="tab-content" *ngIf="showPeople && people.length > 0 else noRecord">
                        <div class="px-2 d-flex justify-content-between h-100 align-items-center  py-2">
                            <div class="col-12">
                                <div class="d-flex justify-content-end">
                                    <div class="searchBtn">
                                        <input [(ngModel)]="searchQuery" (input)="filterPeople()" type="text"
                                            class="searchStr py-1 w-100" placeholder="Search for people"
                                            aria-label="Search" />
                                        <i class="bi bi-search"></i>
                                    </div>
                                </div>
                                <div class="my-2">
                                    <ng-container *ngIf="paginatedPeople.length>0 else noRecord">
                                        <ng-container *ngFor="let item of paginatedPeople; let i = index">
                                            <div *ngIf="item.diff === 'U'" class="row justify-content-between py-2 border-bottom w-100">
                                                <div class="col-lg-3 col-2">
                                                    <img [src]="item.img || 'assets/user-thumbnail.png'" alt="test" class="border userImage px-2 p-3">
                                                </div>
                                                <span class="cursor-pointer col-lg-5 col-6">
                                                    <span class="">
                                                        {{ item.thename }} (SR{{ item.theid }})
                                                    </span>
                                                </span>
                                                <button class="btn viewBtn my-auto col-3" (click)="navigateToPlayerprofile(item.theid)">View</button>
                                            </div>
                                           
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="paginatedPeople.length>0">
                                    <div class="d-flex justify-content-end" *ngIf="people.length > itemsPerPage">
                                        <button class="btn border-0 me-1" [disabled]="currentPage === 1" (click)="changePage(currentPage - 1)">
                                            <i class="bi bi-chevron-left"></i>
                                        </button>
                                        <span class="my-auto">Page {{ currentPage }} of {{ totalPages }}</span>
                                        <button class="btn border-0 ms-2" [disabled]="currentPage >= totalPages" (click)="changePage(currentPage + 1)">
                                            <i class="bi bi-chevron-right"></i>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="'Teams('+teams.length+')'"
                    *ngIf="selectedCategory === '' ? (teams.length>0) : icons[1].isSelected">
                    <div class="tab-content" *ngIf="showTeam && teams.length > 0 else noRecord">
                        <div class="px-2 d-flex col-12 h-100 align-items-center  py-2">
                            <div class="col-12">
                                <div class="d-flex justify-content-end">
                                    <div class="searchBtn">
                                        <input [(ngModel)]="searchTeam" (input)="filterTeam()" type="text"
                                            class="searchStr py-1 w-100" placeholder="Search for team"
                                            aria-label="Search" />
                                        <i class="bi bi-search"></i>
                                    </div>
                                </div>
                                <ng-container *ngIf="paginatedTeams.length>0 else noRecord">
                                    <div class="my-2">
                                        <ng-container *ngFor="let item of paginatedTeams">
                                            <div class="row justify-content-between py-2 border-bottom w-100">
                                                <div class="col-lg-3 col-2">
                                                    <img [src]="item.img || 'assets/user-thumbnail.png'" alt="test" class="border p-3 userImage">
                                                </div>
                                                <div class="col-5 my-auto">
                                                    {{ item.thename }}
                                                </div>
                                                <button class="btn my-auto viewBtn col-3" (click)="navigateToCricketSumm(item.theid)">View</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="d-flex justify-content-end" *ngIf="teams.length > itemsPerPage">
                                        <button class="border-0 btn me-1" [disabled]="currentPageTeams === 1" (click)="changePageTeams(currentPageTeams - 1)">
                                            <i class="bi bi-chevron-left"></i>
                                        </button>
                                        <span class="my-auto">Page {{ currentPageTeams }} of {{ totalPagesTeams }}</span>
                                        <button class="border-0 btn ms-2" [disabled]="currentPageTeams >= totalPagesTeams" (click)="changePageTeams(currentPageTeams + 1)">
                                            <i class="bi bi-chevron-right"></i>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="'Events('+events.length+')'"
                    *ngIf="selectedCategory === '' ? (events.length>0) : icons[2].isSelected">
                    <div class="tab-content" *ngIf="showEvents && events.length > 0 else noRecord">
                        <div class="px-2 d-flex justify-content-between  py-2"
                            [ngClass]="{'minHeightForPag': currentPageEvents === 1}">
                            <div class="col-12">
                                <div class="d-flex justify-content-end">
                                    <div class="searchBtn">
                                        <input [(ngModel)]="searchEvent" (input)="filterEvent()" type="text"
                                            class="searchStr py-1 w-100" placeholder="Search for event"
                                            aria-label="Search" />
                                        <i class="bi bi-search"></i>
                                    </div>
                                </div>
                                <ng-container *ngIf="paginatedEvents.length>0 else noRecord">
                                    <div class="my-2">
                                        <ng-container *ngFor="let item of paginatedEvents">
                                            <div class="row justify-content-between py-2 border-bottom w-100">
                                                <div class="col-lg-3 col-2">
                                                    <img [src]="item.img || 'assets/user-thumbnail.png'" alt="test" class="border p-3 userImage">
                                                </div>
                                                <div class="col-5 my-auto">
                                                    {{ item.thename }}
                                                </div>
                                                <button class="col-3 btn viewBtn my-auto" (click)="navigateToTournament(item.theid)">View</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="d-flex justify-content-end" *ngIf="events.length > itemsPerPage">
                                        <button class="btn border-0 me-1" [disabled]="currentPageEvents === 1" (click)="changePageEvents(currentPageEvents - 1)">
                                            <i class="bi bi-chevron-left"></i>
                                        </button>
                                        <span class="my-auto">Page {{ currentPageEvents }} of {{ totalPagesEvents }}</span>
                                        <button class="btn border-0 ms-2" [disabled]="currentPageEvents >= totalPagesEvents" (click)="changePageEvents(currentPageEvents + 1)">
                                            <i class="bi bi-chevron-right"></i>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <ng-template #noRecord >
                <div *ngIf="!isLoading" class="text-center d-flex align-items-center justify-content-center my-5">
                    <h4 class="m-0 my-2 my-5">No Record Found!</h4>
                </div>
            </ng-template>
        </div>
    </div>
    
</div>