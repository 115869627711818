<div class="boundarytracker container-fluid mt-3 mb-3">
    <mat-card>
        <!-- <mat-card-header class="row text-center">
            <mat-card-title style="border-bottom: 1px solid #b3afaf83;">
                <h5 class="fw-bold fs-4">Boundary Tracker</h5>
            </mat-card-title>
        </mat-card-header> -->
        <mat-card-title style="border-bottom: 2px solid #b3afaf83;">
            <div class="d-flex">
                <div class="col-2 d-flex justify-content-center align-items-center px-0" (click)="changeTab('All')"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="allBoundarys"   style="border: none;">All</span></div>
                <div class="col-5 d-flex justify-content-center align-items-center px-0" (click)="changeTab(team1innings)"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team1Active"   style="border: none;">{{team1}}</span></div>
                <div class="col-5 d-flex justify-content-center align-items-center px-0" (click)="changeTab(team2innings)"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team2Active" style="border: none;">{{team2}}</span></div>
            </div>
        </mat-card-title>
        <div class="col-lg-12 col-md-12 m-2  col-sm-12 mb-2 d-flex justify-content-center" style="align-items: center; height: auto; "
                    *ngIf="!isboundary">
                    No Data Found
                </div>
        <div class="container mt-2">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="isboundary">
                    <mat-card-title>
                        <div class="col-12 d-flex justify-content-between">
                            <!-- <div class="row my-2"> -->
                            <span class="col-6 col-lg-6 col-md-6 col-sm-12">Total Fours : {{Fours}} </span>
                            <img src="/assets/fours.png" class="col-6 pull-right hidden-xs d-flex justify-content-end"
                                style="width: 45px; height: 45px; object-fit: cover;">
                            <!-- </div> -->
                        </div>
                    </mat-card-title>
                    <table class="table " style="--bs-table-bg:none;--bs-table-color:none;">
                        <thead class="">
                            <tr style="background-color:#333333 !important; color: white;">
                                <th>Batsman Name</th>
                                <th>Team</th>
                                <th>Fours</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let data of boundaryFours;let i=index"
                            class="{{i % 2 === 0 ? 'even-row' : 'odd-row'}}">
                            <tr class=""
                                [ngStyle]="i === 0 ? {'background-color': '#0177b5', 'box-shadow': '1px 3px 10px 0px rgba(0, 0, 0, 0.75)', 'color': 'white'} : null">
                                <td class="">{{data.batsman_name}}</td>
                                <td class="">{{data.Team_Name}}</td>
                                <td class="">{{data.fours}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="col-lg-6 col-md-6 col-sm-12 mb-2" *ngIf="isBoundarySixers">

                    <mat-card-title>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="col-6 col-lg-6 col-md-6 col-sm-12">Total Sixes : {{Sixes}} </span>
                            <img src="/assets/sixes.png" class="col-6 pull-right hidden-xs d-flex justify-content-end"
                                style="width: 45px; height: 45px; object-fit: cover;">
                        </div>
                    </mat-card-title>
                    <table class="table " style="--bs-table-bg:none;--bs-table-color:none;">
                        <thead class="">
                            <tr style="background-color:#333333 !important; color: white;">
                                <th>Batsman Name</th>
                                <th>Team</th>
                                <th>Sixes</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let data of boundarySixers;let i=index"
                            class="{{i % 2 === 0 ? 'even-row' : 'odd-row'}}">
                            <tr class=""
                                [ngStyle]="i === 0 ? {'background-color': '#0177b5', 'box-shadow': '1px 3px 10px 0px rgba(0, 0, 0, 0.75)', 'color': 'white'} : null">
                                <td class="">{{data.batsman_name}}</td>
                                <td class="">{{data.Team_Name}}</td>
                                <td class="">{{data.sixers}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-12 mb-2 d-flex justify-content-center" style="align-items: center; height:auto;"
                    *ngIf="!isboundary">
                    NO Data Found
                </div> -->
            </div>
        </div>
    </mat-card>

</div>