import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-tennis-scorecard',
  templateUrl: './table-tennis-scorecard.component.html',
  styleUrls: ['./table-tennis-scorecard.component.css']
})
export class TableTennisScorecardComponent {

  @Input() data : any;
}
