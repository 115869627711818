<!-- <div class="container"> -->
    <div class="container py-3">
      <div class="card shadow card-height rounded-4  p-0 pb-0 mb-1 minheight d-flex justify-content-center"  (click)="getVideoDetail(video.video_id,video.vedio_category_id)" >
        <img class="card-img rounded-top-4" [src]="video.video_thumbnail_image" onerror="this.src='/assets/images/World.webp';"  alt="Card image cap">
        <!-- <img class="card-img" src="../../../assets/img/live_image.jpg"> -->
        <div class="card-body">
          <p class="card-text px-2 title lh-base">{{video.video_title}}</p>
          <p class="card-text px-2 desc">{{video.desc}}</p>
          <p class="dateFormat text-end m-0">{{video.created_date_time}}</p>
        </div>
      </div>
    </div>
<!-- </div> -->
