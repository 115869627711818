import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiEndpoint } from '../common/constants/endpoint.def';
import { OrgcodeFilterService } from '../common/orgcode-filter.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient,private commonStorage:OrgcodeFilterService) {}
  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Orgcode': `${this.commonStorage.storageItems.value[0].orgPath}`
    });
  }

  

  getCricketBowlingAchieved(userId:string) {
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_CRICKET_BOWLING_ACHIEVED.replace('{userId}',userId), { headers : headers })
  }

  getEventHappening(){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.EVENTS_HAPPENING, { headers : headers })
  }

  getLiveStreamingUrl(){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_LIVE_STREAMING_URL, { headers : headers })
  }

  getUserAcademy(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_USER_ACADEMY.replace('{userId}',userId), { headers : headers })
  }

  getUserDetails(searchString:string){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_USER_DETAILS.replace('{searchString}',searchString), { headers : headers })
  }

  getUserVideoLinks(userId:string){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_USER_VIDEO_LINKS.replace('{userId}',userId), { headers : headers })
  }

  getNewsForAnonymousUser(){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.NEWS_FOR_ANONYMOUS_USER, { headers : headers })
  }

  getUserHomeInformation(userId:string){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.USER_HOME_INFORMATION.replace('{userId}',userId), { headers : headers })
  }
  getPlayerPersonalDetails(sportsId: any, userId: any) {
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_PLAYER_PERSONAL_DETAILS.replace('{userId}', userId).replace('{sportsId}', sportsId), { headers : headers });
  }
  displaySportTeam(userId:any,sportsId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.DISPLAY_SPORTS_TEAM.replace('{userId}', userId).replace('{sportsId}', sportsId), { headers : headers });
  }
  cricketUserLastResults(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_CRICKET_USER_LAST_RESUTLS.replace('{userId}', userId), { headers : headers });
  }
  getuserLeaderBoard(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_USER_LEADERBOARD.replace('{userId}', userId), { headers : headers });
  }
  getUserBattingRecord(userId:any,year:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_USER_BATTING_RECORD.replace('{userId}', userId).replace('{year}', year), { headers : headers });
  }
  getUserBowlingRecord(userId:any,year:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_USER_BOWLING_RECORD.replace('{userId}', userId).replace('{year}', year), { headers : headers });
  }
  getUserFieldingRecord(userId:any,year:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_USER_FIELDING_RECORD.replace('{userId}', userId).replace('{year}', year), { headers : headers });
  }
  getCricketBatsmanUserGraph(batsmanUserId: any, limit: any, subCatName: any, year: any) {
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_CRICKET_BATSMAN_USER_GRAPH
      .replace('{userId}', batsmanUserId)
      .replace('{limit}', limit)
      .replace('{subCatName}', subCatName)
      .replace('{year}', year), { headers : headers }
  );
}
getCricketBowlingUserGraph(batsmanUserId: any, limit: any, subCatName: any, year: any) {
  const headers = this.getHeaders();
  return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_CRICKET_BOWLING_USER_GRAPH
    .replace('{userId}', batsmanUserId)
    .replace('{limit}', limit)
    .replace('{subCatName}', subCatName)
    .replace('{year}', year), { headers : headers }
);
}


getTrendGraphBattingDatails(batsmanUserId: any, limit: any, subCatName: any, year: any,aggregationType:any){
  const headers = this.getHeaders();
  return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_TREND_GRAPH_BATTING_DETAILS
    .replace('{userId}', batsmanUserId)
    .replace('{limit}', limit)
    .replace('{subCatName}', subCatName)
    .replace('{year}', year)
    .replace('{aggregationType}',aggregationType), { headers : headers }
);
}
getTrendGraphBowlingDatails(batsmanUserId: any, limit: any, subCatName: any, year: any,aggregationType:any){
  const headers = this.getHeaders();
  return this.http.get(environment.serviceUrl + ApiEndpoint.PLAYER_PROFILE.GET_TREND_GRAPH_BOWLING_DETAILS
    .replace('{userId}', batsmanUserId)
    .replace('{limit}', limit)
    .replace('{subCatName}', subCatName)
    .replace('{year}', year)
    .replace('{aggregationType}',aggregationType), { headers : headers }
);
}

  getCricketBattingAchieved(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_CRICKET_BATTING_ACHEIVED.replace('{userId}',userId), { headers : headers })
  }

  getUserNotes(userId:any,limit:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ ApiEndpoint.PLAYER_PROFILE.GET_USER_NOTES.replace('{userId}',userId).replace('{limit}',limit), { headers : headers })
  }

  getInstituteId(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_INSTITUTE_ID.replace('{userId}',userId), { headers : headers })
  }

  getCurrentLIveMatch(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_CURRENT_LIVE_MATCH.replace('{userId}',userId), { headers : headers })
  }

  getUserVideoUrl(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_USER_VIDEO_LINKS.replace('{userId}',userId), { headers : headers })
  }

  getAchievementNotes(userId:any,limit:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_ACHIEVEMENT_NOTES.replace('{userId}',userId).replace('{limit}',limit), { headers : headers })
  }
  userHomechild(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.USER_HOMECHILD.replace('{parentUserId}',userId), { headers : headers })
  }
  getallCompletedMatch(userId:any,limit:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.ALL_COMPLETED_MATCH.replace('{userId}',userId).replace('{limit}',limit), { headers : headers })
  }
  getAllUpcomingMatch(userId:any,limit:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_ALL_UPCOMING_MATCH.replace('{userId}',userId).replace('{limit}',limit), { headers : headers })
  }
  getUserPosts(userId:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ApiEndpoint.PLAYER_PROFILE.GET_USER_POSTS.replace('{userId}',userId), { headers : headers })

  }

}
