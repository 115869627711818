import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PopModelComponent } from '../pop-model/pop-model.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from 'src/app/service/player.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-player-profile',
  templateUrl: './player-profile.component.html',
  styleUrls: ['./player-profile.component.css']
})
export class PlayerProfileComponent {

  // SPORTS_RULER_WEB_URL = 'https://www.sportsruler.com';
  SPORTS_RULER_WEB_URL=environment.ImagebaseUrl

  previewImageUrl: string | ArrayBuffer | null = '/assets/user-thumbnail.png';
  navList: { title: string }[];
  userdetails: boolean = false;
  nohome: boolean = true;
  isScores: boolean = false;
  photos: boolean = false;
  video: boolean = false;
  userdetail: any;
  Academies: any;
  imageUrl: { link: SafeResourceUrl }[] = [];
  videoUrl: { link: SafeResourceUrl }[] = [];
  videourls: { url: SafeResourceUrl }[] = [];
  LIVE: boolean = false;
  video_URL: any[] = [];
  UserVideo: any[] = [];
  UserImages: any[] = [];
  ACHIEVEMENTS: boolean = false;
  FRIENDS: boolean = false;
  HOME: boolean = true;
  personalbatting: any;
  personalbowling: any;
  COMMUNITIES: boolean = false;
  POSTS: boolean = false;
  FAMILY: boolean = false;
  SCORES: boolean = false;
  myprofile: boolean = false;
  user_id: any;
  userId: any ;
  userAchievementNotes: any;
  userChild: any;
  sports_mapping: any[];
  Sprots: any;
  live_selected: string = 'live';
  completedMatchs: any;
  CricketLivematch: any;
  itemsToShow = 4;
  itemsIncrement = 4;
  CricketupcomingMatch: any;
  posts: any;
  isposts: boolean = false;
  cricketmatchdetail: boolean = false;
  cricketmatchdetaillive: boolean = false;
  cricketmatchdetailupcoming: boolean = false;
  isfamily: boolean = false;
  isLive: boolean = false;
  noAcademies: boolean=false;
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
orgCode:any;


  constructor( private dialog:MatDialog, private router: Router, private service: PlayerService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private location: Location,private orgFilter:OrgcodeFilterService) {
    this.navList = [
      { title: "About Me" },
      { title: "Achievements" },
      { title: "Friends" },
      { title: "Communities" },
      { title: "Posts" },
      { title: "Family" },
      { title: "Live" },
      { title: "Scores" },
    ];
    this.sports_mapping = [
      { sportsId: '1', sports: 'Cricket', sportImg: '/assets/ball.png' },
      { sportsId: '2', sports: 'Tennis', sportImg: '/assets/tennisball.png' },
      { sportsId: '3', sports: 'Badminton', sportImg: '/assets/batmiton ball.png' },
      { sportsId: '4', sports: 'BasketBall', sportImg: '/assets/ball.png' },
      { sportsId: '5', sports: 'FootBall', sportImg: '/assets/ball.png' },
      { sportsId: '6', sports: 'TableTennis', sportImg: '/assets/tableTennis.png' },
      { sportsId: '7', sports: 'Volleyball', sportImg: '/assets/ball.png' },
      { sportsId: '8', sports: 'ThrowBall', sportImg: '/assets/ball.png' },
      { sportsId: '9', sports: 'Archery', sportImg: '/assets/ball.png' },
    ]
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this. userId = params.get('userId');
    });
    this.user_id = localStorage.getItem("userId");
    if (this.userId === this.user_id && this.userId !== null) {
      this.myprofile = true;
    }
    this.getplayerDetails();
    this.getcricketBattingAchieved();
    this.getcricketBowlingAchieved();
    this.getuserAcademy();
    this.getuserVideoUrl();
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }


  handleClick(data: any) {
    this.HOME = this.ACHIEVEMENTS = this.FRIENDS = this.COMMUNITIES = this.POSTS = this.FAMILY = this.LIVE = this.SCORES = false;
    if (data == "Achievements") {
      this.ACHIEVEMENTS = true;
      this.getachievementNotes();
    } else if (data == "Friends") {
      this.FRIENDS = true;
    } else if (data == "Communities") {
      this.COMMUNITIES = true;
    } else if (data == "Posts") {
      this.POSTS = true;
      this.getuserPosts();
    } else if (data == "Family") {
      this.FAMILY = true;
      this.UserHomeChild();
    } else if (data == "Live") {
      this.LIVE = true;
      this.getliveSTreamingUrl();
    } else if (data == "Scores") {
      this.SCORES = true;
      this.AllCompletedMatch();
      this.getuserHomeInformation();
      this.getcurrentLIveMatch();
      this.getallUpcomingMatch();
    } else {
      this.HOME = true;
    }

  }
  uploadimg() {
    this.dialog.open(PopModelComponent, {
      data: { identifier: 'uploadimg' },
      disableClose: true,
    });
  }

  updateMe() {
    this.dialog.open(PopModelComponent, {
      data: { identifier: 'AboutMe' },
      disableClose: true,
    });
  }

  Strengths() {
    this.dialog.open(PopModelComponent, {
      data: { identifier: 'Strengths' },
      disableClose: true,
    });
  }
  Weakness() {
    this.dialog.open(PopModelComponent, {
      data: { identifier: 'Weakness' },
      disableClose: true,
    });
  }
  EditImg() {
    this.router.navigate([`${this.orgCode}/updateProfilepic`])
  }


  redirectToAcademy() {
    this.router.navigate([`${this.orgCode}/home`])
  }

  Onchange(data: any) {
    this.userdetails = this.isScores = this.photos = this.video = this.ACHIEVEMENTS = this.FRIENDS = this.COMMUNITIES = this.POSTS = this.FAMILY = this.LIVE = this.SCORES = false;
    this.nohome = false;
    if (data === 'details') {
      this.userdetails = true;
      this.router.navigateByUrl(`playerprofile#${'AboutMe'}`);
    } else if (data === 'stats') {
      this.isScores = true;
      this.router.navigateByUrl(`playerprofile#${'stats'}`);
    } else if (data === 'photos') {
      this.photos = true;
      this.router.navigateByUrl(`playerprofile#${'photos'}`);
    } else if (data === 'video') {
      this.video = true;
      this.router.navigateByUrl(`playerprofile#${'video'}`);
    } else if (data === 'Achievements') {
      this.ACHIEVEMENTS = true;
      this.router.navigateByUrl(`playerprofile#${'Achievements'}`);
      this.getachievementNotes();
    } else if (data === 'Friends') {
      this.FRIENDS = true;
      this.router.navigateByUrl(`playerprofile#${'Friends'}`);
    } else if (data === 'Communities') {
      this.COMMUNITIES = true;
      this.router.navigateByUrl(`playerprofile#${'Communities'}`);
    } else if (data === 'Posts') {
      this.POSTS = true;
      this.getuserPosts();
      this.router.navigateByUrl(`playerprofile#${'Posts'}`);
    } else if (data === 'Family') {
      this.FAMILY = true;
      this.UserHomeChild();
      this.router.navigateByUrl(`playerprofile#${'Family'}`);
    } else if (data === 'Live') {
      this.LIVE = true;
      this.getliveSTreamingUrl();
      this.router.navigateByUrl(`playerprofile#${'Live'}`);
    } else if (data === 'Scores') {
      this.router.navigateByUrl(`playerprofile#${'Scores'}`);
      this.SCORES = true;
      this.AllCompletedMatch();
      this.getuserHomeInformation();
      this.getcurrentLIveMatch();
      this.getallUpcomingMatch();
    } else {
      this.nohome = true;
    }

  }
  exit() {
    this.nohome = true;
    this.router.navigateByUrl(`playerprofile`);
    this.userdetails = this.isScores = this.photos = this.video = this.ACHIEVEMENTS = this.FRIENDS = this.COMMUNITIES = this.POSTS = this.FAMILY = this.LIVE = this.SCORES = false;
  }

  getplayerDetails() {
    this.service.getUserDetails(this.userId).subscribe((data: any) => {
      this.userdetail = data.result[0];
      this.previewImageUrl = this.SPORTS_RULER_WEB_URL+ this.userdetail.path_to_pic;
    });
  }
  getcricketBattingAchieved() {
    this.service.getCricketBattingAchieved(this.userId).subscribe((data: any) => {
      this.personalbatting = data;

    });
  }
  getcricketBowlingAchieved() {
    this.service.getCricketBowlingAchieved(this.userId).subscribe((data: any) => {
      this.personalbowling = data;
  
    });
  }
  getEventHappening() {
    this.service.getEventHappening().subscribe((data: any) => {
    })
  }


  getuserAcademy() {
    this.service.getUserAcademy(this.userId).subscribe((response: any) => {
      this.Academies = response.result;
      if(this.Academies.length>0){
        this.Academies = response.result[0];
        this.noAcademies=false;
      }else{
        this.noAcademies=true;
      }
      
    });
  }

  getuserVideoUrl() {
    this.service.getUserVideoUrl(this.userId).subscribe((data: any) => {
      this.video_URL = data.result;
      this.UserVideo = this.video_URL.filter((video: any) => video.link_type === 'VID');
      this.videoUrl = this.video_URL.filter((video: { link: string; link_type: string }) => video.link_type === "VID").map((video: { link: string, link_type: string }) => {
        return { link: this.sanitizer.bypassSecurityTrustResourceUrl(video.link), link_type: video.link_type };
      });
      this.UserImages = this.video_URL.filter((image: any) => image.link_type === 'IMG');
    })
  }


  getliveSTreamingUrl() {
    this.service.getLiveStreamingUrl().subscribe((data: any) => {
      if (data.status = "TRUE") {
        this.videourls = data.result.map((video: { url: string }) => {
          return { url: this.sanitizer.bypassSecurityTrustResourceUrl(video.url) };
        });
        this.isLive = false;
      } else {
        this.isLive = true;
      }

    })
  }

  getachievementNotes() {
    this.service.getAchievementNotes(this.userId, 3).subscribe((data: any) => {
      this.userAchievementNotes = data.result;
    })
  }

  Acheivement() {
    window.location.reload();
  }

  UserHomeChild() {
    this.service.userHomechild(this.userId).subscribe((data: any) => {
      if (data.status == "TRUE") {
        this.userChild = data.result;
        this.isfamily = false;
      } else {
        this.isfamily = true;
      }
    });
  }

  getuserHomeInformation() {
    this.service.getUserHomeInformation(this.userId).subscribe((data: any) => {
      if (data.status === "TRUE") {
        // this.Sprots = data.result;
        this.Sprots = data.result.map((sport: any) => {
          return this.sports_mapping.find(mapping => mapping.sportsId === sport.sport_id);
        });

      }
    });
  }
  selectedsport() {

  }

  AllCompletedMatch() {
    this.service.getallCompletedMatch(this.userId, 10).subscribe((data: any) => {

      if (data.status == 'TRUE') {
        this.cricketmatchdetail = false;
        this.completedMatchs = data.result;
      } else {
        this.cricketmatchdetail = true;
      }
    });
  }


  getcurrentLIveMatch() {
    this.service.getCurrentLIveMatch(this.userId).subscribe((data: any) => {
      if (data.status == 'TRUE') {
        this.cricketmatchdetaillive = false;
        this.CricketLivematch = data.result;
      } else {
        this.cricketmatchdetaillive = true;
      }
    })
  }
  getallUpcomingMatch() {
    this.service.getAllUpcomingMatch(this.userId, 10).subscribe((data: any) => {
      if (data.status == "TRUE") {
        this.cricketmatchdetailupcoming = false;
        this.CricketupcomingMatch = data.result;
      } else {
        this.cricketmatchdetailupcoming = true;
      }
    })
  }

  showTab(tab: string): void {
    this.live_selected = tab;
    this.renderFilteredMatch(tab);
  }

  renderFilteredMatch(tab: string): void {
    switch (tab) {
      case 'live':
        this.itemsToShow = 4;
        break;
      case 'upcoming':
        this.itemsToShow = 4;
        break;
      case 'completed':
        this.itemsToShow = 4;
        break;
    }
  }

  showMoreItems(): void {
    this.dialogRef= this.dialog.open(CommonAlertsComponent, {
      data: { identifier: 'loader' }
    });
    this.itemsToShow += this.itemsIncrement;
    this.dialogRef?.close();
  }


  getuserPosts() {
    this.service.getUserPosts(this.userId).subscribe((data: any) => {
      if (data.status == "TRUE") {
        if (data.message == "Data fetch successfully") {
          this.isposts = false;
          this.posts = data.result;
        } else {
          this.isposts = true;
        }
      } else {
        this.isposts = true;
      }
    })
  }

  navigateToScorecardDetail(matchId: any) {
    this.router.navigate([`${this.orgCode}/scorecardDetails`, matchId])
  }


}
