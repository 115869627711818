import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';

@Component({
  selector: 'app-scorecard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.css']
})
export class ScorecardComponent implements OnInit {
  liveMatch: any;
  completedMatch: any;
  upcomingMatch : any;
  showOrHide: any;
  activeTab: string = 'LIVE';
  displayCountForLive: number = 5;
  displayCountForCompleted: number = 5;
  displayCountForUpcoming : number =5;
  searchQuery: string = '';
  liveMatchData: any;
  completedMatchData: any;
  upcomingMatchData: any;
  showLiveMatch:boolean = false;
  section: any;
  isloading:boolean=false;
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  constructor(private scorecardService: CricketScoreCardService, private router :Router, private commonService : CommonService,private dialog:MatDialog) {
  }

  ngOnInit() {
    this.isloading=true;
    this.getCricketLiveMatch();
    this.getCricketCompletedMatch();
    this.getCricketUpcomingMatch();
  }
  showData(data: any) {
    this.activeTab = data;
    this.searchQuery = '';
  }
  loadMoreForLive() {
   this.dialogRef= this.dialog.open(CommonAlertsComponent, {
      data: { identifier: 'loader' }
    });
    this.displayCountForLive += 5;
    this.getCricketLiveMatch();
  }
  loadMoreForCompleted() {
    this.dialogRef= this.dialog.open(CommonAlertsComponent, {
      data: { identifier: 'loader' }
    });
    this.displayCountForCompleted += 5;
    this.getCricketCompletedMatch();
  }
  loadMoreForUpcoming () {
    this.dialogRef=this.dialog.open(CommonAlertsComponent, {
      data: { identifier: 'loader' }
    });
    this.displayCountForUpcoming += 5;
    this.getCricketUpcomingMatch();
  }
  getCricketLiveMatch() {
    this.scorecardService.getCricketLiveMatch(this.displayCountForLive).subscribe((data: any) => {
      let liveData = data?.result;
        liveData?.map((x: any) => {
          x.Match_Date = moment(x?.Match_Date).format('D MMMM YYYY');
          let teamOne = x?.teamone?.split(" ").map((word: any) => word.charAt(0)).join('');
          x["teamOne"] = teamOne;
          let teamTwo = x?.teamtwo?.split(" ").map((word: any) => word.charAt(0)).join('');
          x["teamTwo"] = teamTwo;
          let tossWonTeam = x?.Team1 === x?.toss_won ? x?.teamone : x?.teamtwo
          x["tossWon"] = tossWonTeam;
          x.team1_score = `${x.innings_1_score}/${x.innings_1_wkts} (${x.innings_1_overs})`,
          x.team2_score = `${x.innings_2_score}/${x.innings_2_wkts} (${x.innings_2_overs})`,
          x.test1_score = `${x.innings_1_score}/${x.innings_1_wkts} & ${x.innings_3_score}/${x.innings_3_wkts}`,
          x.test2_score = `${x.innings_2_score}/${x.innings_2_wkts} & ${x.innings_4_score}/${x.innings_4_wkts}`,
          x.inningsStatus = x.innings_2_status?.slice(-1),
          x.followOnScore1 = `${x.innings_2_score}/${x.innings_2_wkts} & ${x.innings_3_score}/${x.innings_3_wkts}`,
          x.followOnScore2 = `${x.innings_1_score}/${x.innings_1_wkts}`
        });
        this.liveMatch = liveData;
        this.liveMatchData = liveData;
        if (this.liveMatch != null && this.liveMatch.length > 0) {
          this.showLiveMatch = true;
          this.showData('LIVE');
          this.isloading=false;
          this.dialogRef?.close();
        } else {
          this.showLiveMatch = false;
          this.showData('RESULT');
          this.isloading=false;
          this.dialogRef?.close();
        }
        this.getNavExtra()
    });
  }


  getCricketCompletedMatch() {
    this.scorecardService.getCricketCompletedMatch(this.displayCountForCompleted).subscribe(res => {
      let completedMatch = res.result;
        completedMatch?.map((x: any) => {
          x.Match_Date = moment(x?.Match_Date).format('D MMMM YYYY');
          let teamOne = x?.teamone?.split(" ").map((word: any) => word.charAt(0)).join('');
          x["teamOne"] = teamOne;
          let teamTwo = x?.teamtwo?.split(" ").map((word: any) => word.charAt(0)).join('');
          x["teamTwo"] = teamTwo;
          let tossWonTeam = x?.Team1 === x?.toss_won ? x?.teamone : x?.teamtwo
          x["tossWon"] = tossWonTeam;
          x.Match_Time=moment(x.Match_Time,'HH:mm:ss').format('LT:mm A')
          x.team_1_group = [];
          x.team_2_group = [];
          x.matchInnings = [];
          x.leadRuns = 0;
          x.isLead = false;
          x.trailRuns = 0;
          x.team1Score = 0;
          x.team2Score = 0;
          x.teamScore = 0;
          x.currentBattingTeam = '';
          x.currentBowlingTeam = '';
         
    
          [1, 2, 3, 4].forEach(r => {
            if (r <= x.curr_innings) {
              x.matchInnings.push(r);
              const inningsStatus = x[`innings_${r}_status`]?.split(' ')[0];
              if (inningsStatus === x.Team1.toString()) {
                x.team_1_group.push(r);
              } else if (inningsStatus === x.Team2.toString()) {
                x.team_2_group.push(r);
              } 
              // else {
              //   if (s.curr_batting_team_id === x.Team1) {
              //     x.team_1_group.push(r);
              //   }
              //   if (s.curr_batting_team_id === x.Team2) {
              //     x.team_2_group.push(r);
              //   }
              // }
            }
          });
    
          x.teamScore = x[`innings_${x.curr_innings}_score`];
    
          x.team_1_group.forEach((r:any) => {
            x.team1Score += parseInt(x[`innings_${r}_score`], 10);
          });
    
          x.team_2_group.forEach((r:any) => {
            x.team2Score += parseInt(x[`innings_${r}_score`], 10);
          });
    
          // if (s.curr_batting_team_id === x.Team1) {
          //   x.currentBattingTeam = x.teamOne;
          //   x.currentBowlingTeam = x.teamTwo;
          //   if (parseInt(s.team1Score.toString(), 10) < parseInt(s.team2Score.toString(), 10)) {
          //     x.isLead = false;
          //     x.trailRuns = parseInt(s.team2Score.toString(), 10) - parseInt(s.team1Score.toString(), 10);
          //   } else {
          //     x.isLead = true;
          //     x.leadRuns = parseInt(s.team1Score.toString(), 10) - parseInt(s.team2Score.toString(), 10);
          //   }
          // }
    
          // if (s.curr_batting_team_id === x.Team2) {
          //   x.currentBattingTeam = x.teamTwo;
          //   x.currentBowlingTeam = x.teamOne;
          //   if (parseInt(s.team2Score.toString(), 10) < parseInt(s.team1Score.toString(), 10)) {
          //     x.isLead = false;
          //     x.trailRuns = parseInt(s.team1Score.toString(), 10) - parseInt(s.team2Score.toString(), 10);
          //   } else {
          //     x.isLead = true;
          //     x.leadRuns = parseInt(s.team2Score.toString(), 10) - parseInt(s.team1Score.toString(), 10);
          //   }
          // }
    
          return x;
        // });
        });
        this.completedMatch = completedMatch;
        this.completedMatchData = completedMatch;
        this.isloading=false;
        this.dialogRef?.close();
    })
  }

  getCricketUpcomingMatch() {
    this.scorecardService.getCricketUpcomingMatch(this.displayCountForUpcoming).subscribe((res : any) => {
      let upcomingMatch = res.result;
        upcomingMatch?.forEach((x: any) => {
          x.match_Date = moment(x?.match_Date).format('D MMMM YYYY');
          let teamOne = x?.teamone?.split(" ").map((word: any) => word.charAt(0)).join('');
          x["teamOne"] = teamOne;
          let teamTwo = x?.teamtwo?.split(" ").map((word: any) => word.charAt(0)).join('');
          x["teamTwo"] = teamTwo;
        });
      this.upcomingMatch = upcomingMatch;
      this.upcomingMatchData = upcomingMatch;
      this.isloading=false;
      this.dialogRef?.close();
    })
  }

  search() {
    const searchQueryLower = this.searchQuery.toLowerCase();
  
    if (this.activeTab === 'LIVE') {
      this.liveMatch = this.liveMatchData.filter((item: any) =>
        item.Event_name.toLowerCase().includes(searchQueryLower) || item.teamone.toLowerCase().includes(searchQueryLower) || item.teamtwo.toLowerCase().includes(searchQueryLower) ||  item.Venue.toLowerCase().includes(searchQueryLower) ||
      item.Match_Date.toLowerCase().includes(searchQueryLower));
    } else if (this.activeTab === 'RESULT') {
      this.completedMatch = this.completedMatchData.filter((item: any) =>
        item.Event_name.toLowerCase().includes(searchQueryLower) || item.teamone.toLowerCase().includes(searchQueryLower) || item.teamtwo.toLowerCase().includes(searchQueryLower) ||  item.Venue.toLowerCase().includes(searchQueryLower) ||
      item.Match_Date.toLowerCase().includes(searchQueryLower));
    } else {
      this.upcomingMatch = this.upcomingMatchData.filter((item: any) =>
   item.Event_name.toLowerCase().includes(searchQueryLower) || item.teamone.toLowerCase().includes(searchQueryLower) || item.teamtwo.toLowerCase().includes(searchQueryLower) ||  item.Venue.toLowerCase().includes(searchQueryLower) ||
      item.Match_Date.toLowerCase().includes(searchQueryLower));
    }
  }
  
  matchesFilter(item: any, searchQueryLower: string): boolean {
    return (
      item.Event_name.toLowerCase().includes(searchQueryLower) ||
      item.teamone.toLowerCase().includes(searchQueryLower) ||
      item.teamtwo.toLowerCase().includes(searchQueryLower) ||
      item.Venue.toLowerCase().includes(searchQueryLower) ||
      item.Match_Date.toLowerCase().includes(searchQueryLower)
    );
  }
getNavExtra(){
  const navigationState = window.history.state;
    if (navigationState && navigationState.section) {
      this.section = navigationState.section;
      this.activeTab=this.section.toUpperCase()
    }
}
}