import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiEndpoint } from '../common/constants/endpoint.def';
import { OrgcodeFilterService } from '../common/orgcode-filter.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http: HttpClient,private commonStorage:OrgcodeFilterService) {}
  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Orgcode': `${this.commonStorage.storageItems.value[0].orgPath}`
    });
  }

  getTopVideoList(vedioCateID: any) {
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.NEWS.GET_ALL_TOP_VIDEOlIST_BY_VIDEOCATE_ID.replace('/vedioCateID', '?vedioCateID='+vedioCateID),{headers:headers});
  }
  getVideoListAll(categoryId:any) {
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.VIDEOS.GET_ALL_VIDEOS_BY_CATEGORY.replace('/vedioCateID', '?vedioCateID='+categoryId),{headers:headers});
  }

  getCategory(){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl + ApiEndpoint.VIDEOS.GET_ALL_CATEGORY,{headers:headers});
  }
  getVideoByID(videoID:any,catID:any){
    const headers = this.getHeaders();
    return this.http.get(environment.serviceUrl+ ApiEndpoint.VIDEOS.GET_BY_VIDEO_ID.replace('{videoID}',videoID).replace('{catID}',catID),{headers:headers})
  }


}

