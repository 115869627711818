<div class="container cricket-profile-info px-0 my-2 shadow" *ngIf="mainScreen else performance">
  <div class=" d-lg-none d-md-block d-block card">

    <div class=" py-2" style="background-color: #015b7e;  border-radius: 5px 5px 0px 0px ">

      <div class="row px-4 py-1">
        <div class="d-flex align-items-center gap-4">
          <img src="https://www.sportsruler.com/customImage/userAvatarM.png" alt="" style="height: 60px; width: 60px;">
          <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder text-light">{{playerinfo?.F_name}}</h1>
        </div>
      </div>
      <div class="row px-4 py-1">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Personal Details
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <ul style="list-style: none;" class="p-0 ">
                <li>D.O.B: <span>{{playerinfo?.d_o_b}}</span></li>
                <li>Role: <span>{{playerinfo?.role_1}}</span></li>
                <li>Teams: <span *ngFor="let item of teams" class="text-info" (click)="navigateTo(item.Team_id)" style="cursor: pointer;">{{item.Team_Name}} <span *ngIf=""></span>
                  </span></li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="row px-4 py-1">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Recent Performances
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <ul style="list-style: none;" class="p-0 m-auto">
                <li class="text-info" *ngFor="let item of lastResults; let i=index">
                  <span class="small_font_size text-nowrap" *ngIf="i < 5"
                    (click)="navigateToScoreCardDetails(item.match_id)" style="cursor: pointer;">
                    {{ item.result }} vs
                    {{ item.team2 ? (item.team2.length > 15 ? item.team2.substring(0, 15) + '...' : item.team2) : '' }}
                  </span>
                </li>
                <div *ngIf="lastResults.length>5" class="d-flex justify-content-end ">
                  <button class="btn border btn-success " (click)="isShowmainScreen()"> MORE</button>
                </div>
              </ul>

            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="row px-4 py-1" *ngIf="hideLeaderBoard">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                LeaderBoard
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Record type</th>
                    <th scope="col">Record</th>
                    <th scope="col">Rank</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of leaderboard" class="small_font_size">
                    <td>{{ item.table_header }}</td>
                    <td>{{ item.record }}</td>
                    <td>{{ item.rank }}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </div>
    <div class="p-4">
      <div class="row justify-content-center">
        <div class=" row justify-content-between">
          <div class="col-8">
            <button class="btn border btn-success me-2" [ngClass]="{'btn-success': tournament==true}"
              (click)="tournamentOrsocial('tournaments')"> Tournament</button>
            <!-- <button class="btn border" [ngClass]="{'btn-success': social==true}" (click)="tournamentOrsocial('social')">
              Social</button> -->
              <button class="btn border" 
              (click)=" tournamentOrsocial('stats')" [ngClass]="{'btn-success': stats==true}"> Stats</button>
          </div>
          <div class="col-4" *ng-if="!stats">
            <div class="card shadow my-1">
              <mat-form-field appearance="fill">
                <mat-select [formControl]="disableSelect" [(ngModel)]="selectedYear"
                  (selectionChange)="onYearSelected($event.value)">
                  <mat-option [value]="'All'">All</mat-option>
                  <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="tournament">
          <div *ngIf="hideBattingRecords || hideBowlingRecord || hideFieldingRecord">
            <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder" style="color:var(--primary-color);">Batting</h1>
            <div class="row p-0 card border shadow my-2" style="overflow:auto;">
              <table class="table">
                <thead class="thead-dark">
                  <tr class="table-info">
                    <th scope="col">Career</th>
                    <th scope="col">M</th>
                    <th scope="col">Inn</th>
                    <th scope="col">R</th>
                    <th scope="col">BF</th>
                    <th scope="col">NO</th>
                    <th scope="col">Avg</th>
                    <th scope="col">SR</th>
                    <th scope="col">HS</th>
                    <th scope="col">30s</th>
                    <th scope="col">50s</th>
                    <th scope="col">100s</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of battingRecord">
                    <td scope="row">{{item.Sports_subcat_name}}</td>
                    <td>{{item.matches}}</td>
                    <td>{{item.innings}}</td>
                    <td>{{item.runs}}</td>
                    <td>{{item.balls_faced}}</td>
                    <td>{{item.notout}}</td>
                    <td>{{item.avg}}</td>
                    <td>{{item.sRate}}</td>
                    <td>{{item.high_score}}</td>
                    <td>{{item.thirties}}</td>
                    <td>{{item.fifties}}</td>
                    <td>{{item.hundreds}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder" style="color:var(--primary-color);">Bowling</h1>
            <div class="row p-0 card border shadow my-2" style="overflow:auto;">
              <table class="table">
                <thead class="thead-dark p-0">
                  <tr class="table-info">
                    <th scope="col">Career</th>
                    <th scope="col">M</th>
                    <th scope="col">Inn</th>
                    <th scope="col">O</th>
                    <th scope="col">Wkts</th>
                    <th scope="col">R</th>
                    <th scope="col">Mi</th>
                    <th scope="col">Avg</th>
                    <th scope="col">SR</th>
                    <th scope="col">3W</th>
                    <th scope="col">5W</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of bowlingRecord">
                    <td scope="row">{{item.overs}}&nbsp; overs</td>
                    <td>{{item.matches}}</td>
                    <td>{{item.innings}}</td>
                    <td>{{item.overs}}</td>
                    <td>{{item.wickets}}</td>
                    <td>{{item.runs}}</td>
                    <td>{{item.maidens}}</td>
                    <td>{{item.avg}}</td>
                    <td>{{item.sRate}}</td>
                    <td>{{item.threewickets}}</td>
                    <td>{{item.fivewickets}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder" style="color:var(--primary-color);">Fielding</h1>
            <div class="row p-0 card border shadow my-2" style="overflow:auto;">
              <table class="table">
                <thead class="thead-dark">
                  <tr class="table-info" >
                    <th scope="col">Career</th>
                    <th scope="col">M</th>
                    <th scope="col">C</th>
                    <th scope="col">S</th>
                    <th scope="col">RO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of fieldingRecord">
                    <td scope="row">{{item.sports_subcat_name}}</td>
                    <td>{{item.matches}}</td>
                    <td>{{item.catches}}</td>
                    <td>{{item.stumpings}}</td>
                    <td>{{item.runouts}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="!hideBattingRecords && !hideBowlingRecord && !hideFieldingRecord"
            class="d-flex justify-content-center fs-4 mt-5">
            No record found
          </div>
        </div>

        <div *ngIf="stats">
          <app-stats  [playerName]="playername" [userId]="userId"></app-stats>
        </div>
      </div>
    </div>
  </div>
  <div class=" d-lg-block d-md-none d-none card my-3 "
    style="height: max-content;  overflow-y: auto; overflow-x: hidden;">
    <div class="row vh-100  pe-2">
      <div class="col-4 position-relative py-2"
        style="background-color: #015b7e; left: 1%; border-radius: 8px 0px 0px 8px;">

        <div class="row px-4 py-1">
          <div class="d-flex align-items-center gap-4">
            <img src="https://www.sportsruler.com/customImage/userAvatarM.png" alt=""
              style="height: 60px; width: 60px;">
            <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder text-light">{{playerinfo?.F_name}}</h1>
          </div>
        </div>
        <div class="row px-4 py-1">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Personal Details
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <ul style="list-style: none;" class="p-0 ">
                  <li>D.O.B: <span>{{playerinfo?.d_o_b}}</span></li>
                  <li>Role: <span>{{playerinfo?.role_1}}</span></li>
                  <li>Teams: <span *ngFor="let item of teams" class="text-info" (click)="navigateTo(item.Team_id)" style="cursor: pointer;">{{item.Team_Name}},<span
                        *ngIf=""></span> </span></li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="row px-4 py-1">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Recent Performances
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <ul style="list-style: none;" class="p-0 m-auto">
                  <li class="text-info" *ngFor="let item of lastResults; let i=index">
                    <span class="small_font_size text-nowrap" *ngIf="i < 5"
                      (click)="navigateToScoreCardDetails(item.match_id)" style="cursor: pointer;">
                      {{ item.result }} vs
                      {{ item.team2 ? (item.team2.length > 15 ? item.team2.substring(0, 15) + '...' : item.team2) : ''
                      }}
                    </span>
                  </li>
                  <div *ngIf="lastResults.length>5" class="d-flex justify-content-end ">
                    <button class="btn border btn-success " (click)="isShowmainScreen()"> MORE</button>
                  </div>
                </ul>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="row px-4 py-1" *ngIf="hideLeaderBoard">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  LeaderBoard
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <table class="table table-striped border">
                  <thead>
                    <tr>
                      <th scope="col">Record type</th>
                      <th scope="col">Record</th>
                      <th scope="col">Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of leaderboard" class="small_font_size">
                      <td>{{ item.table_header }}</td>
                      <td>{{ item.record }}</td>
                      <td>{{ item.rank }}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

      </div>
      <div class="col-8 d-flex justify-content-center py-2">
        <div class="row  justify-content-center" style="width: 97%; height: max-content;">
          <div class=" d-flex justify-content-between">
            <div class="">
              <button class="btn border btn-success me-2" [ngClass]="{'btn-success': tournament==true}"
                (click)="tournamentOrsocial('tournaments')"> Tournament</button>
              <!-- <button class="btn border" [ngClass]="{'btn-success': social==true}"
                (click)="tournamentOrsocial('social')"> Social</button> -->
                <button class="btn border" (click)=" tournamentOrsocial('stats')" [ngClass]="{'btn-success': stats==true}"> Stats</button>
            </div>
            <div *ngIf="!stats">
              <div class="card shadow my-1">
                <mat-form-field appearance="fill">
                  <mat-select [formControl]="disableSelect" [(ngModel)]="selectedYear"
                    (selectionChange)="onYearSelected($event.value)">
                    <mat-option [value]="'All'">All</mat-option>
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div *ngIf="tournament">
            <div *ngIf="hideBattingRecords || hideBowlingRecord || hideFieldingRecord">
              <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder" style="color:var(--primary-color);">Batting</h1>
              <div class="row p-0 card border shadow my-2" style="overflow:auto;">
                <table class="table">
                  <thead class="thead-dark">
                    <tr class="table-info">
                      <th scope="col">Career</th>
                      <th scope="col">M</th>
                      <th scope="col">Inn</th>
                      <th scope="col">R</th>
                      <th scope="col">BF</th>
                      <th scope="col">NO</th>
                      <th scope="col">Avg</th>
                      <th scope="col">SR</th>
                      <th scope="col">HS</th>
                      <th scope="col">30s</th>
                      <th scope="col">50s</th>
                      <th scope="col">100s</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of battingRecord">
                      <td scope="row">{{item.Sports_subcat_name}}</td>
                      <td>{{item.matches}}</td>
                      <td>{{item.innings}}</td>
                      <td>{{item.runs}}</td>
                      <td>{{item.balls_faced}}</td>
                      <td>{{item.notout}}</td>
                      <td>{{item.avg}}</td>
                      <td>{{item.sRate}}</td>
                      <td>{{item.high_score}}</td>
                      <td>{{item.thirties}}</td>
                      <td>{{item.fifties}}</td>
                      <td>{{item.hundreds}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder" style="color:var(--primary-color);">Bowling</h1>
              <div class="row p-0 card border shadow my-2" style="overflow:auto;">
                <table class="table">
                  <thead class="thead-dark p-0">
                    <tr class="table-info">
                      <th scope="col">Career</th>
                      <th scope="col">M</th>
                      <th scope="col">Inn</th>
                      <th scope="col">O</th>
                      <th scope="col">Wkts</th>
                      <th scope="col">R</th>
                      <th scope="col">Mi</th>
                      <th scope="col">Avg</th>
                      <th scope="col">SR</th>
                      <th scope="col">3W</th>
                      <th scope="col">5W</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of bowlingRecord">
                      <td scope="row">{{item.overs}}&nbsp; overs</td>
                      <td>{{item.matches}}</td>
                      <td>{{item.innings}}</td>
                      <td>{{item.overs}}</td>
                      <td>{{item.wickets}}</td>
                      <td>{{item.runs}}</td>
                      <td>{{item.maidens}}</td>
                      <td>{{item.avg}}</td>
                      <td>{{item.sRate}}</td>
                      <td>{{item.threewickets}}</td>
                      <td>{{item.fivewickets}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h1 class="p-0 m-0 align-content-center fs-6 fw-bolder" style="color:var(--primary-color);">Fielding</h1>
              <div class="row p-0 card border shadow my-2" style="overflow:auto;">
                <table class="table">
                  <thead class="thead-dark">
                    <tr class="table-info">
                      <th scope="col">Career</th>
                      <th scope="col">M</th>
                      <th scope="col">C</th>
                      <th scope="col">S</th>
                      <th scope="col">RO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="fieldingRecord.length>0 else norecord"></ng-container>
                    
                    <tr *ngFor="let item of fieldingRecord else norecord">
                      <td scope="row">{{item.sports_subcat_name}}</td>
                      <td>{{item.matches}}</td>
                      <td>{{item.catches}}</td>
                      <td>{{item.stumpings}}</td>
                      <td>{{item.runouts}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hideBattingRecords && !hideBowlingRecord && !hideFieldingRecord"
              class="d-flex justify-content-center fs-4 mt-5">
              No record found
            </div>
          </div>
          <div *ngIf="stats">
            <app-stats   [playerName]="playername" [userId]="userId"></app-stats>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #performance>
  <div class="my-3 container-lg container-fluid  my-2 cricket-profile-info performnace">
    <div class="m-0 p-0  my-2 d-flex " style="color: var(--primary-color);" (click)="isShowmainScreen()">
      <span class="fs-5 fw-bolder mt-2"><i class="bi bi-chevron-left fw-bolder"></i>Back</span>
    </div>
    
    <mat-card>
      <h2 class="pt-3  text-center  " style="color: var(--primary-color);">{{playerinfo?.F_name}}'s &nbsp; Performances</h2>
      
      <!-- <div class="d-flex justify-content-between">
        <h2 class="pt-3 w-75 text-center position-absolute " style="color: var(--primary-color);">{{playerinfo?.F_name}}'s &nbsp; Performances</h2>
        <mat-form-field class="search-field border-0 d-flex">
          <div class="d-flex">
          <i class="bi bi-search text-black"></i>
          <input  matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </div>
        </mat-form-field>
      </div> -->
      <div class="d-flex justify-content-center">
        <mat-form-field class="search-field border-0 d-flex">
          <div class="d-flex">
          <i class="bi bi-search text-black"></i>
          <input  matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </div>
        </mat-form-field>
      </div>
      <table mat-table [dataSource]="paginatedTeams" class="mat-elevation-z8 custom-table">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Recent Performance </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="opponent">
          <th mat-header-cell *matHeaderCellDef> Opponent </th>
          <td mat-cell *matCellDef="let element"> {{element.opponent}} </td>
        </ng-container>

        <ng-container matColumnDef="Scorecard">
          <th mat-header-cell *matHeaderCellDef> Scorecard </th>
          <td mat-cell *matCellDef="let element" (click)="navigateToScoreCardDetails(element.Scorecard)">
            <button class="btn btn-success small_font_size ">View</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
      <mat-paginator (page)="onPageChange($event)" [length]="totalRecords" [pageSize]="25"
        [pageSizeOptions]="[25, 50, 100]" ></mat-paginator>
    </mat-card>
  </div>
</ng-template>
<ng-template #norecord>
  <tr>
    <td [colSpan]="5">
      <div class="d-flex justify-content-center fs-5 m-auto w-100">
        No record found
      </div>
    </td>
  </tr>
</ng-template>
