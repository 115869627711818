import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullScorecardComponent } from '@components/cricket/full-scorecard/full-scorecard.component';
import { WormComponent } from '@components/cricket/worm/worm.component';
import { LiveStreamCardComponent } from '@components/live-stream-card/live-stream-card.component';
import { OtpVerificationComponent } from '@components/otp-verification/otp-verification.component';
import { TournamentsComponent } from '@components/tournaments/tournaments.component';
import { BadmintonScorecardDetailComponent } from '@pages/badminton/badminton-scorecard-detail/badminton-scorecard-detail.component';
import { CricketPlayerInfoComponent } from '@pages/cricket/cricket-player-info/cricket-player-info.component';
import { TennisCardDetailComponent } from '@pages/tennis/tennis-card-detail/tennis-card-detail.component';
import { PlayerProfileComponent } from '@pages/users/player-profile/player-profile.component';
import { VideoDetailPageComponent } from '@pages/videos/video-detail-page/video-detail-page.component';
import { VideoLandingPageComponent } from '@pages/videos/video-landing-page/video-landing-page.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { HeaderContentsComponent } from './common/header-contents/header-contents.component';
import { LoginPageComponent } from './common/login-page/login-page.component';
import { SignUpComponent } from './common/sign-up/sign-up.component';
import { CommentaryComponent } from './components/cricket/commentary/commentary.component';
import { ManhattanComponent } from './components/cricket/manhattan/manhattan.component';
import { MatchInfoComponent } from './components/cricket/match-info/match-info.component';
import { MiniMatchesComponent } from './components/cricket/mini-matches/mini-matches.component';
import { PlayingXiComponent } from './components/cricket/playing-xi/playing-xi.component';
import { UpcomingMatchComponent } from './components/cricket/upcoming-match/upcoming-match.component';
import { HomeSectionLiveScoreCardComponent } from './components/home/home-section-live-score-card/home-section-live-score-card.component';
import { HomeSectionVideoComponent } from './components/home/home-section-video/home-section-video.component';
import { HomePageComponent } from './pages/cricket/home/home-page.component';
import { ScorecardDetailsComponent } from './pages/cricket/scorecard-details/scorecard-details.component';
import { ScorecardComponent } from './pages/cricket/scorecard/scorecard.component';
import { EditProfileComponent } from '@pages/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from '@pages/change-password/change-password.component';
import { UpdateProfilepicComponent } from '@pages/update-profilepic/update-profilepic.component';
import { StatsComponent } from '@components/stats/stats.component';
import { TeamSummaryComponent } from '@components/team-summary/team-summary.component';
import { HomeSearchComponent } from '@components/home-search/home-search.component';
import { OrgCodeResolverService } from './common/org-code-resolver.service';

const routes: Routes = [
  {
    path: ':orgcode',
    resolve: {
      orgcode: OrgCodeResolverService
    },

    children: [
      {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'news',
    component: HomeSectionVideoComponent,
  },
  {
    path: 'full',
    component: FullScorecardComponent,
  },
  {
    path: 'liveScore',
    component: HomeSectionLiveScoreCardComponent
  },
  {
    path: 'upcomingMatch',
    component: UpcomingMatchComponent
  },
  {
    path: 'miniMatch',
    component: MiniMatchesComponent
  },
  {
    path: 'forgotPassword/:Login_Type', component: ForgotPasswordComponent
  },
  {
    path: 'forgotPassword', component: ForgotPasswordComponent
  },
  {
    path: 'manhattan',
    component: ManhattanComponent
  },
  {
    path: 'worm',
    component: WormComponent
  },
  {
    path : 'login',
    component:LoginPageComponent
  },
  {
    path : 'signUp',
    component:SignUpComponent
  },
  {
    path: 'matchinfo',
    component: MatchInfoComponent
  },
  {
    path: 'playing-xi',
    component: PlayingXiComponent
  },
  {
    path: 'commentry',
    component: CommentaryComponent
  },
  {
    path:'matches',
    component: ScorecardComponent
  },
  {
    path:'tournament/:eventId/:sportsId',
    component:TournamentsComponent
  },
  {
    path:'videos',
    component:VideoLandingPageComponent
  },
  {
    path:'videodetail/:videoID/:catID',
    component:VideoDetailPageComponent
  },
  {
    path:'badmintonMatchStatus',
    component:BadmintonScorecardDetailComponent
  },
  {
    path:'batminton',
    component:BadmintonScorecardDetailComponent
  },
  {
    path:'tennisDetail',
    component:TennisCardDetailComponent
  },
  {
    path:'HeaderContent/:menu',
    component:HeaderContentsComponent
  },
  {
    path:'playerprofile/:userId',
    component:PlayerProfileComponent
  },
  {
    path:'streaming:matchId/:showImage',
    component:LiveStreamCardComponent
  },
  {
    path: 'scorecardDetails/:matchId',
    component: ScorecardDetailsComponent,
  },
  {
    path: 'playerInfo',
    component: CricketPlayerInfoComponent,
  },

 {
    path:'verfication',
    component:OtpVerificationComponent
  },

  {
    path: 'edit-profile',
    component: EditProfileComponent
  },
  {
    path: 'ChangePassword',
    component: ChangePasswordComponent
  },

  {
    path: 'updateProfilepic',
    component: UpdateProfilepicComponent
  },
  {
    path:'stats',
    component:StatsComponent
  },
  {
    path: 'cricketTeamSumm',
    component: TeamSummaryComponent
  },
  {
    path:'SearchAll',
    component:HomeSearchComponent
  },
  { path: 'Matches', redirectTo: 'matches', pathMatch: 'full' },
]
// }
// ]
},
{ path: '', redirectTo: `/home`, pathMatch: 'full' }, 
{ path: '**', redirectTo: '/home' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes),RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  constructor(){
  }
}
