import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-worm',
  templateUrl: './worm.component.html',
  styleUrls: ['./worm.component.css']
})
export class WormComponent implements OnInit {
  chart: Highcharts.Chart | undefined;
  Highcharts: typeof Highcharts = Highcharts;
  @Input()team1: any;
  @Input()team2: any;
  intervel:any;


  lineOptions: Highcharts.Options = {

    title: {
      text: '',
      style: {
        fontFamily: 'Assist-Bold'
      }
    },

    xAxis: {
      labels: {
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      },
      title: {
        text: 'Over',
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      }
    },

    yAxis: {
      labels: {
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      },
      title: {
        text: 'Runs (per over)',
        style: {
          fontFamily: 'Assist-SemiBold'
        }
      }
    },

    tooltip: {
      enabled: true,
      style: {
        fontFamily: 'Assist-Bold'
      }
    }
  }


  result: any = []
 @Input() matchId: any;
  barLables: any = [];
  barTeamOneRuns: any = [];
  barTeamTwoRuns: any = [];

  constructor(private scoreService: CricketScoreCardService, private cdr: ChangeDetectorRef) { 
  }


  ngOnInit(): void {
    this.getGraph();
    this.initChart();
  }
  
  initChart() {
    this.chart = Highcharts.chart('linechartContainer', this.lineOptions);
  }

  addSeries(chart: Highcharts.Chart, name: string, type: any, data: any) {
    chart.addSeries({
      name: name,
      type: type,
      data: data,
      marker: {
        enabled: false
      }
    });
  }

  getGraph() {
    this.scoreService.getCricketGraph(this.matchId).subscribe((resp: any) => {
      this.result = resp.result;
      const categories = this.result.map((item: { over_no: number }) => item.over_no);
      const teamOneRunsData: any = [];
      const teamTwoRunsData: any = [];
      var teamOneTotalRuns = 0;
      var teamTwoTotalRuns = 0;
      for (const item of this.result) {
        const teamOneRuns = parseFloat(item.firstRuns);
        const teamTwoRuns = parseFloat(item.secondRuns);
        teamOneTotalRuns += teamOneRuns;
        teamTwoTotalRuns += teamTwoRuns;
        teamOneRunsData.push(teamOneTotalRuns);
        teamTwoRunsData.push(teamTwoTotalRuns);
      }
      const teamOneWickets = this.result.map((item: { over_no: any; firstWickets: any; }) => {
        const over = parseFloat(item.over_no);
        const wicket = parseFloat(item.firstWickets);
        const run = teamOneRunsData[over - 1];
        return {
          over: over,
          wicket: wicket,
          run: run
        };
      });
      const teamTwoWickets = this.result.map((item: { over_no: any; secondWickets: any; }) => {
        const over = parseFloat(item.over_no);
        const wicket = parseFloat(item.secondWickets);
        const run = teamTwoRunsData[over - 1];
        return {
          over: over,
          wicket: wicket,
          run: run
        };
      });
      if (this.chart) {
        this.chart.yAxis[0].update({
          tickInterval: 20,
        });
        this.chart.xAxis[0].setCategories(categories);

        this.addSeries(this.chart, this.team1, 'spline', teamOneRunsData);
        this.addSeries(this.chart, this.team2, 'spline', teamTwoRunsData);

        this.chart.addSeries({
          name: this.team1 + ' Wickets',
          type: 'scatter',
          data: teamOneWickets.filter((wicket: any) => wicket.wicket == 1).map((wicket: any) => ({
            x: wicket.over - 1,
            y: wicket.run,
          })),
          marker: {
            symbol: 'circle',
            radius: 3,
          },
          tooltip: {
            headerFormat: 'Over: {point.x}<br>',
            pointFormat: 'Runs: {point.y}<br>'
          }
        });
        this.chart.addSeries({
          name: this.team2 + ' Wickets',
          type: 'scatter',
          data: teamTwoWickets.filter((wicket: any) => wicket.wicket == 1).map((wicket: any) => ({
            x: wicket.over - 1,
            y: wicket.run,
          })),
          marker: {
            symbol: 'circle',
            radius: 3,
          },
          tooltip: {
            headerFormat: 'Over: {point.x}<br>',
            pointFormat: 'Runs: {point.y}<br>'
          }
        });
        this.chart.redraw();
      }
      this.cdr.detectChanges();
      }
    );
  }


}
