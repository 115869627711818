<div class="d-lg-none d-md-none d-block mb-5">
<div class="container-fluid mt-3" *ngIf="data">
  <mat-card>
    <mat-card-header class="p-2 m-2 row text-center border-bottom border-secondary">
      <mat-card-title>MATCH DETAILS</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Toss :</span>
        <span class="col border-bottom pb-2"> {{data.toss_won_by??'-'}} won the toss and elected to
          {{data.Elected_to}}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Match Date :</span>
        <span class="col border-bottom pb-2"> {{data.match_date??'-'}}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Format :</span>
        <span class="col border-bottom pb-2"> {{data.category??'-'}}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Venue :</span>
        <span class="col border-bottom pb-2"> {{data.venue??'-'}}</span>
      </div>
    </mat-card-content>
    <mat-card-content  *ngIf="data.scorer_name!==''">
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2" >Scorer :</span>
        <span class="col border-bottom pb-2"> {{data.scorer_name??'-'}}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal">Result :</span>
        <span class="col"> {{data.match_result??'-'}}</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>


<!-- venue status -->
<div class="container mt-3">
  <mat-card>
    <mat-card-header class="p-2 m-2 row text-center border-bottom border-secondary">
      <mat-card-title>VENUE STATUS</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Average 1st inn Score :</span>
        <span class="col border-bottom pb-2">{{averageValue?.firstAvg??'-'}}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Average 2nd inn Score :</span>
        <span class="col border-bottom pb-2">{{averageValue?.secondAvg??'-'}}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Highest 1st inn Total :</span>
        <span class="col border-bottom pb-2">{{highestFirstInnings?.max1score??'-'}}/{{highestFirstInnings?.innings_1_wkts??'-'}}
          ({{highestFirstInnings?.inn1Ovs??'-'}} Ovs)</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Highest 2nd inn Total :</span>
        <span
          class="col border-bottom pb-2">{{highestSecondInnings?.max2score??'-'}}/{{highestSecondInnings?.innings_2_wkts??'-'}}
          ({{highestSecondInnings?.inn2Ovs??'-'}} Ovs)</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal border-bottom pb-2">Lowest 1st inn Total :</span>
        <span class="col border-bottom pb-2">{{firstLowest?.min1score??'-'}}/{{firstLowest?.innings_1_wkts??'-'}}
          ({{firstLowest?.inn1Ovs??'-'}} Ovs)</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="row m-2">
        <span class="col text-center fw-normal pb-2">Lowest 2nd inn Total :</span>
        <span class="col pb-2">{{secondLowest?.min2score??'-'}}/{{secondLowest?.innings_2_wkts??'-'}}
          ({{secondLowest?.inn2Ovs??'-'}} Ovs)</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
</div>


<div class="container-fluid match_info px-1 py-2" style=" ">
<mat-card class=" d-none d-lg-block d-md-block px-0" style="height: max-content;">
  <mat-card-content>
    <mat-tab-group >
      <mat-tab label="MATCH DETAILS" >
        <mat-card style="width: 95%; margin-top: 10%; height: max-content ;" class="container mt-3  rounded-4 mb-2 pb-3 " *ngIf="data">
          <div class="p-0 m-0">
            <div>
              <div class="row m-2">
                <span class="col text-center fw-normal border-bottom pb-2">Toss :</span>
                <span class="col border-bottom pb-2"> {{data.toss_won_by??'-'}} won the toss and elected to
                  {{data.Elected_to}}</span>
              </div>
            </div>
            <div>
              <div class="row m-2">
                <span class="col text-center fw-normal border-bottom pb-2">Match Date :</span>
                <span class="col border-bottom pb-2"> {{data.match_date??'-'}}</span>
              </div>
            </div>
            <div>
              <div class="row m-2">
                <span class="col text-center fw-normal border-bottom pb-2">Format :</span>
                <span class="col border-bottom pb-2"> {{data.category??'-'}}</span>
              </div>
            </div>
            <div>
              <div class="row m-2">
                <span class="col text-center fw-normal border-bottom pb-2">Venue :</span>
                <span class="col border-bottom pb-2"> {{data.venue??'-'}}</span>
              </div>
            </div>
            <div>
              <div class="row m-2"  *ngIf="data.scorer_name!==''">
                <span class="col text-center fw-normal border-bottom pb-2">Scorer :</span>
                <span class="col border-bottom pb-2"> {{data.scorer_name??'-'}}</span>
              </div>
            </div>
            <div>
              <div class="row m-2">
                <span class="col text-center fw-normal">Result :</span>
                <span class="col"> {{data.match_result??'-'}}</span>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="VENUE DETAILS">
        <mat-card style="width: 95%; margin-top: 10%; height: max-content ;" class="container mt-3 pb-3 rounded-4 mb-2 " *ngIf="data">
          <div class="container mt-3">
            <div>
              <div>
                <div class="row m-2">
                  <span class="col text-center fw-normal border-bottom pb-2">Average 1st inn Score :</span>
                  <span class="col border-bottom pb-2">{{averageValue?.firstAvg??'-'}}</span>
                </div>
              </div>
              <mat-card-content>
                <div class="row m-2">
                  <span class="col text-center fw-normal border-bottom pb-2">Average 2nd inn Score :</span>
                  <span class="col border-bottom pb-2">{{averageValue?.secondAvg??'-'}}</span>
                </div>
              </mat-card-content>
              <mat-card-content>
                <div class="row m-2">
                  <span class="col text-center fw-normal border-bottom pb-2">Highest 1st inn Total :</span>
                  <span class="col border-bottom pb-2">{{highestFirstInnings?.max1score??'-'}}/{{highestFirstInnings?.innings_1_wkts??'-'}}
                    ({{highestFirstInnings?.inn1Ovs??'-'}} Ovs)</span>
                </div>
              </mat-card-content>
              <mat-card-content>
                <div class="row m-2">
                  <span class="col text-center fw-normal border-bottom pb-2">Highest 2nd inn Total :</span>
                  <span
                    class="col border-bottom pb-2">{{highestSecondInnings?.max2score??'-'}}/{{highestSecondInnings?.innings_2_wkts??'-'}}
                    ({{highestSecondInnings?.inn2Ovs??'-'}} Ovs)</span>
                </div>
              </mat-card-content>
              <mat-card-content>
                <div class="row m-2">
                  <span class="col text-center fw-normal border-bottom pb-2">Lowest 1st inn Total :</span>
                  <span class="col border-bottom pb-2">{{firstLowest?.min1score??'-'}}/{{firstLowest?.innings_1_wkts??'-'}}
                    ({{firstLowest?.inn1Ovs??'-'}} Ovs)</span>
                </div>
              </mat-card-content>
              <mat-card-content>
                <div class="row m-2">
                  <span class="col text-center fw-normal pb-2">Lowest 2nd inn Total :</span>
                  <span class="col pb-2">{{secondLowest?.min2score??'-'}}/{{secondLowest?.innings_2_wkts??'-'}}
                    ({{secondLowest?.inn2Ovs??'-'}} Ovs)</span>
                </div>
              </mat-card-content>
            </div>
          </div>
        </mat-card>
      </mat-tab>
        </mat-tab-group>
  </mat-card-content>
</mat-card>
</div>