import { Component } from '@angular/core';

@Component({
  selector: 'app-related-videos-section',
  templateUrl: './related-videos-section.component.html',
  styleUrls: ['./related-videos-section.component.css']
})
export class RelatedVideosSectionComponent {

}
