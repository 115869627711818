<div class="container py-5 otp">
    <div class="">
      <div class="container d-flex justify-content-center">
        <mat-card class="  mb-2 py-3 rounded-3" style="width: 90%;     box-shadow: 2px 2px 14px 2px #00000073 !important; ">
          <mat-card-header class="d-flex justify-content-center line py-2 m-auto rounded-2" style="width: 95%; background-color:#015b7e;">
            <mat-card-title class="text-white Primary-font">EMAIL VERIFICATION</mat-card-title>
          </mat-card-header>
          <div class="m-auto mt-3">
            <h4 class=" m-0 p-0" style="font-family: 'Assist-Medium'; color: rgba(128, 128, 128, 0.779);">Verification code has been sent to your email.</h4>
          </div>
          <mat-card-content class="d-flex justify-content-center m-3">
            <form class="example-form">
              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>OTP</mat-label>
                    <input #otp  matInput [formControl]="emailFormControl" placeholder="Enter OTP" maxlength="6" (input)="onInputChange($event)" >
                    <mat-error *ngIf="emailFormControl.hasError('required')">OTP is required</mat-error>
                  </mat-form-field>
                  <span *ngIf="isValidOtp &&!emailFormControl.hasError('required')" class="position-absolute" style="color: #f44336; font-size: small; top: 73%; left: 3%;">Enter the valid otp</span>
              </div>
            </form>
          </mat-card-content>
          <mat-card-actions class="d-flex justify-content-center py-2">
            <button mat-flat-button class="rounded-4 Primary-font" style="background-color:#015b7e; color: white;" (click)="onSubmit()">SUBMIT</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
