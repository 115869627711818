import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiEndpoint } from '../common/constants/endpoint.def';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrgcodeFilterService } from '../common/orgcode-filter.service';


@Injectable({
  providedIn: 'root'
})

export class CricketScoreCardService {
  public matchUniqueId = new BehaviorSubject<any>(null);

  public wagonRun: BehaviorSubject<string> = new BehaviorSubject<string>('All');

  constructor(private http: HttpClient,private commonStorage:OrgcodeFilterService) { }
  private getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Orgcode': `${this.commonStorage.storageItems.value[0].orgPath}`
    });
  }

  getCricketMatchSumm(matchId : any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_CRICKET_MATCH_SUMM.replace('{matchId}', matchId),{headers:headers});
  }

  getCricketMatchStats(matchId:any,innings:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_CRICKET_MATCH_STATS.replace('{matchId}', matchId).replace('{innings}',innings ),{headers:headers});
  }

  getFallOfWickets(matchId:any,innings:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_FIRST_FALL_OF_WICKETS.replace('{matchId}', matchId).replace('{innings}', innings),{headers:headers});
  }

  getFirstBowling(matchId:any,innings:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_BOWLER_STAS.replace('{matchId}', matchId).replace('{innings}', innings),{headers:headers});
  }

  getSecondInnings_stas(matchId:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_SECOND_INNINGS_STAS.replace('{matchId}', matchId),{headers:headers});
  }

  getSecondFallOfWickets(matchId:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_SECONS_FALL_OF_WICKETS.replace('{matchId}', matchId),{headers:headers});
  }

  getSecondBowlingStas(matchId:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_BOWLING_STATS_TWO.replace('{matchId}', matchId),{headers:headers});
  }

  getDidNotBatBatsman(matchId:any,team1Id:any, team2Id:any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_DID_NOT_BAT_BATSMAN.replace('{matchId}', matchId).replace('{team1Id}',team1Id).replace('{team2Id}',team2Id),{headers:headers});
  }

  getWagOnWheelById(matchId: any ,innings: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.WAGONWHEEL.GET_WAH_ON_WHEEL.replace('{matchId}', matchId).replace('{innings}', innings),{headers:headers});
  }

  getWagonCoordinateForPlayer(matchId: any, batsmanUserId: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.WAGONWHEEL.GET_PLAYER_WAGON_COORDINATE.replace('?MATCH_ID=&BATSMAN_ID=', '?MATCH_ID=' + matchId + '&BATSMAN_ID=' + batsmanUserId),{headers:headers});
  }
  getCommentaryDetails(matchId: any, teamId: any, minLimit: any, maxLimit: any) {
        const headers = this.getHeaders();

    return this.http.get((environment.serviceUrl + ApiEndpoint.COMMENTARY.GET_COMMMENTARY_DETAILS)
      .replace('{matchId}', matchId).replace('{teamID}', teamId).replace('{minLimit}', minLimit)
      .replace('{maxLimit}', maxLimit),{headers:headers});
  }

  getLastTenBalls(matchId: any, innings: any, minLimit: any, maxLimit: any) {
        const headers = this.getHeaders();

    return this.http.get((environment.serviceUrl + ApiEndpoint.COMMENTARY.GET_LAST_TEN_BALLS_AND_COMMENTARY)
      .replace('{matchId}', matchId).replace('{innings}', innings).replace('{minLimit}', minLimit)
      .replace('{maxLimit}', maxLimit),{headers:headers})
  }

  getCricketLiveMatch(limit:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SCORE.GET_ALL_CURRENT_MATCH.replace('{limit}', limit),{headers:headers});
  }

  getCricketCompletedMatch(limit:any):Observable<any>{
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SCORE.GET_ALL_COMPLETED_MATCH.replace('{limit}', limit),{headers:headers});
  } 

  getCricketUpcomingMatch(limit:any):Observable<any>{
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.UPCOMING_MATCH.GET_ALL_UPCOMING_MATCH.replace('{limit}', limit),{headers:headers});
  } 

  getCricketMatchScoresAndPlayingEleven(matchId: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.MATCH_INFO.GET_CRICKET_MATCH_SCORES_AND_PLAYING_ELEVEN.replace('{matchId}', matchId),{headers:headers});
  }

  getVenueStats(venue: string) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.MATCH_INFO.GET_VENUE_STATUS.replace('{venue}', venue),{headers:headers});
  }

  getCricketPlayingEleven(matchId: String, teamId: string) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.MATCH_INFO.GET_CRICKET_PLAYING_ELEVEN.replace('{matchId}', matchId.toString()).replace('{teamId}', teamId),{headers:headers});
  }
  getCricketGraph(matchId: any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.GRAPH.GET_CRICKET_GRAPH.replace('{matchID}', matchId),{headers:headers});
  }
  
  getCricketPartnershipAnalytics(matchId:any, innings:any){
        const headers = this.getHeaders();

    
    return this.http.get(environment.serviceUrl + ApiEndpoint.CRICKET_SCORE.GET_CRICKET_PARTENERSHIP_ANALYSTISCS.replace('{matchId}',matchId).replace('{innings}',innings),{headers:headers});
  }

  getTeamNamesForMultiDay(matchId:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_TEAMNAMES_FOR_MULTIDAY.replace('{matchId}',matchId),{headers:headers});
  }
  getCricketPlayerAnalytics(matchId:any,batsmanId:any,innings:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_CRICKET_PLAYER_ANALYTICS.replace('{matchId}',matchId).replace('{batsmanId}',batsmanId).replace('{innings}',innings),{headers:headers});

  }
  getSecondInningsStatus(matchId : any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.FULL_SCORE_CARD.GET_SECOND_INNINGS_STAS.replace('{matchId}', matchId),{headers:headers});
  }

  getBoundaryDetails(matchId:any, teamID:any,innings:any){
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl+ ApiEndpoint.FULL_SCORE_CARD.GET_BOUNDARYDETAILS.replace('{matchId}',matchId).replace ('{teamId}',teamID).replace('{innings}',innings),{headers:headers});
  }

  getWagonCoordinateForBowler(matchId: any, bowlermanUserId: any) {
        const headers = this.getHeaders();

    return this.http.get(environment.serviceUrl + ApiEndpoint.WAGONWHEEL.GET_BOWLER_WAGON_COORDINATE.replace('?MATCH_ID=&bolwerId=', '?MATCH_ID=' + matchId + '&bolwerId=' + bowlermanUserId),{headers:headers});
  }

  getLiveScoring(matchId:any){
        const headers = this.getHeaders();

    return this.http.get('https://www.sportsruler.com/getLiveStreamingApi?MATCH_ID=matchId'.replace('matchId',matchId),{headers:headers});
  }
  getOverSequence(matchId:any ,currInnings:any){
        const headers = this.getHeaders();

    return this.http.get('https://www.sportsruler.com/getcurrentoverballdetails?MATCHID=matchId&INNINGS=currInnings'.replace('currInnings',currInnings).replace('matchId',matchId),{headers:headers})
  }


}
