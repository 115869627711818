import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badminton-scorecard-card',
  templateUrl: './badminton-scorecard-card.component.html',
  styleUrls: ['./badminton-scorecard-card.component.css']
})
export class BadmintonScorecardCardComponent{
 
  @Input() data : any;
 
}
