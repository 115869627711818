<div style="position: relative;" > 
    <!-- <img src="https://static.standard.co.uk/s3fs-public/thumbnails/image/2014/07/14/10/63cricket1407a.jpg?w968" alt="" class="w-100"> -->

<footer class="container-fluid rounded-pill showWebcard" style="position: absolute;" *ngIf="showWebcard">
    <div class="d-flex align-items-center h-100">
        <div class="col-2 teamName">{{liveScoringDetail?.currentBattingTeam}}</div>
        <div class="col-8 d-flex border border-5 h-100 detailSection border-top-0 border-bottom-0 rounded-pill">
            <div class="col-4 d-flex flex-column justify-content-center px-4">
                <div *ngIf="showBatsmanDetail">
                    <div class="d-flex align-items-center gap-2">
                        <span class="playerName">{{liveScoringDetail?.stiker_name}} </span>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                        <span class="playerName">{{liveScoringDetail?.non_stiker_name}}</span>
                    </div>
                </div>
                <div class="wicketCard rounded-pill w-100 d-flex align-items-center" *ngIf="showFourOrSix">
                    <svg class="arrow arrow-left">
                        <path class="a1" d="M0 0 L22 30 L0 60"></path>
                        <path class="a2" d="M20 0 L42 30 L20 60"></path>
                        <path class="a3" d="M40 0 L62 30 L40 60"></path>
                      </svg>
                      <div class="px-2 col-6 text-center infotext">{{lastBallRun}}</div>
                      <svg class="arrow">
                        <path class="a1" d="M0 0 L22 30 L0 60"></path>
                        <path class="a2" d="M20 0 L42 30 L20 60"></path>
                        <path class="a3" d="M40 0 L62 30 L40 60"></path>
                      </svg>
                </div>
            </div>
            <div class="col-4 scoreDetail rounded-pill px-2">
                <div
                    class="border border-5 h-100 borderColor  border-top-0 border-bottom-0 rounded-pill d-flex flex-column justify-content-center gap-2" *ngIf="showTotalSCore">
                    <div class="d-flex align-items-center justify-content-center  gap-2">
                        <div class="teamShortName border border-3 border-top-0 border-bottom-0 border-start-0 pe-3">{{liveScoringDetail?.currentBattingTeamShortname}}
                        </div>
                        <div class="scoreWicket border border-3 border-top-0 border-bottom-0 border-start-0 pe-3">
                            {{liveScoringDetail?.currentBattingScore}}</div>
                        <div class="overs" style="font-weight: 500;">{{liveScoringDetail?.currentBattingOvers}}</div>
                    </div>
                    <div class="text-center">

                        <p class="bottomContents m-0" *ngIf="showTossDetail">{{liveScoringDetail?.tossWinBy}}</p>
                        <p class="bottomContents m-0" *ngIf="showCRRandRRR">CRR&nbsp;:&nbsp;{{liveScoringDetail?.currentRunrate}} &nbsp;&nbsp; RRR&nbsp;:{{liveScoringDetail?.requiredRunrate}}</p>
                        <p class="bottomContents m-0" *ngIf="showLiveFeed">{{liveScoringDetail?.livefeed}}</p> 
                        <p class="bottomContents m-0" *ngIf="showProjectedScore">Projected Score&nbsp;:{{liveScoringDetail?.projectedScore}}</p> 
                        <p class="bottomContents m-0" *ngIf="showCRR">CRR&nbsp;:{{liveScoringDetail?.currentRunrate}}</p> 
                    </div>
                </div>
                <div class="text-center my-5" *ngIf="showBreakDetail">

                     <p class="bottomContents m-0" >{{liveScoringDetail?.livefeed}}</p> 
                     <p class="bottomContents m-0" >{{liveScoringDetail?.target}}</p>
                   

                </div>
            </div>
            <div class="col-4 d-flex flex-column justify-content-center align-items-center  px-2">
                <div *ngIf="showBowlerDetail">
                    <div class="d-flex align-items-center gap-2">
                        <span class="playerName">{{liveScoringDetail?.bowler_name}}</span>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="thisOver?.length>0">
                        <span class="currentOver text-white text-nowrap fs-4 me-2">This over :</span>
                        <div class="scrolling-container">
                            <span class="col-8 text-nowrap sequence text-white" #sequence style="font-size: 1.3rem;">{{overDetail?.over_sequence}}</span>
                        </div>
                    </div>
                </div>
                <div class="wicketCard rounded-pill w-100 d-flex align-items-center" *ngIf=showWicketEXtras>
                    <svg class="arrow arrow-left">
                        <path class="a1" d="M0 0 L22 30 L0 60"></path>
                        <path class="a2" d="M20 0 L42 30 L20 60"></path>
                        <path class="a3" d="M40 0 L62 30 L40 60"></path>
                      </svg>
                      <div class="px-2 col-6 text-center infotext">{{lastBallRun}}</div>
                      <svg class="arrow">
                        <path class="a1" d="M0 0 L22 30 L0 60"></path>
                        <path class="a2" d="M20 0 L42 30 L20 60"></path>
                        <path class="a3" d="M40 0 L62 30 L40 60"></path>
                      </svg>
                </div>

            </div>
        </div>
        <div class="col-2 teamName">{{liveScoringDetail?.currentBowlingTeam}}</div>
    </div>

</footer>
</div>

<footer class="scoreCardImage" *ngIf="scoreCardImage">
        <img src="../../../assets/ScoreBand with line.png" alt="" class="w-100 cardImage">
     
        <div class="mainContent">
            <div class="team-names">
                <div class="teamName">{{liveScoringDetail?.currentBattingTeam}}</div>
                <div class="teamName text-end">{{liveScoringDetail?.currentBowlingTeam}}</div>
            </div>
            <div *ngIf="showBatsmanDetail" class="w-25 position-absolute" style="bottom: 25px; left: 15%;">
                <div class="d-flex align-items-center gap-2">
                    <span class="playerName">{{liveScoringDetail?.stiker_name}} </span>
                </div>
                <div class="d-flex align-items-center gap-2">
                    <span class="playerName">{{liveScoringDetail?.non_stiker_name}}</span>
                </div>
                <div class="wicketCard rounded-pill w-100 d-flex align-items-center">
                    
                </div>
            </div>
            <div *ngIf="showBowlerDetail" class="w-25 position-absolute" style="bottom: 25px; left: 65%;">
                <div class="d-flex align-items-center gap-2">
                    <span class="playerName">{{liveScoringDetail?.bowler_name}}</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="thisOver?.length>0">
                    <span class="currentOver text-white text-nowrap fs-4 me-2">This over :</span>
                    <div class="scrolling-container">
                        <span class="col-8 text-nowrap sequence text-white" #sequence style="font-size: 1.3rem;">{{overDetail?.over_sequence}}</span>
                    </div>
                </div>
                
                <div class="wicketCard rounded-pill w-100 d-flex align-items-center">
                 
                </div>
            </div>
            <div class="w-25 position-absolute" style="bottom: 15px; left: 38%;">
                <div class=" h-100  rounded-pill d-flex flex-column justify-content-center gap-2" *ngIf="showTotalSCore">
                    <div class="d-flex align-items-center justify-content-evenly">
                        <div class="teamShortName">{{liveScoringDetail?.currentBattingTeamShortname}}</div>
                        <div class="scoreWicket">{{liveScoringDetail?.currentBattingScore}}</div>
                        <div class="overs" style="font-weight: 500;">{{liveScoringDetail?.currentBattingOvers}}</div>
                    </div>
                    <div class="text-center">
                        <p class="bottomContents m-0" *ngIf="showTossDetail">{{liveScoringDetail?.tossWinBy}}</p>
                        <p class="bottomContents m-0" *ngIf="showCRRandRRR">CRR&nbsp;:&nbsp;{{liveScoringDetail?.currentRunrate}} &nbsp;&nbsp; RRR&nbsp;:{{liveScoringDetail?.requiredRunrate}}</p>
                        <p class="bottomContents m-0" *ngIf="showLiveFeed">{{liveScoringDetail?.livefeed}}</p> 
                        <p class="bottomContents m-0" *ngIf="showProjectedScore">Projected Score&nbsp;:{{liveScoringDetail?.projectedScore}}</p> 
                        <p class="bottomContents m-0" *ngIf="showCRR">CRR&nbsp;:{{liveScoringDetail?.currentRunrate}}</p> 
                    </div>
                </div>
                <div class="text-center my-5" *ngIf="showBreakDetail">
                    <p class="bottomContents m-0" >{{liveScoringDetail?.livefeed}}</p> 
                    <p class="bottomContents m-0" >{{liveScoringDetail?.target}}</p>
                </div>
            </div>
            <div class="position-absolute" style="bottom: 3px; left: 0%;" *ngIf="showWicketEXtras"  [class]="lastBallRun === 'FREE HIT' ? 'position-absolute freeHit' : 'position-absolute'">
                <img src="assets/WIde_00241.png" alt="" class="w-100" *ngIf="lastBallRun === 'WIDE'">
                <img src="assets/Wicket_00242.png" alt="" class="w-100" *ngIf="lastBallRun === 'WICKET'">
                <img src="assets/NoBall_00242.png" alt="" class="w-100" *ngIf="lastBallRun === 'NO BALL'">
                <img src="assets/FreeHit_00243.png" alt="" class="w-100" *ngIf="lastBallRun === 'FREE HIT'">
            </div>
            <div class="position-absolute" style="bottom: 3px; left: 0%;" *ngIf="showFourOrSix" >
                <img src="assets/SIX_00241.png" alt="" class="w-100" *ngIf="lastBallRun === 'SIX'">
                <img src="assets/Four_00241.png" alt="" class="w-100" *ngIf="lastBallRun === 'FOUR'">
            </div>
        </div>
</footer>