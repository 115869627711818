<mat-card-title class="mx-1">
  <mat-tab-group *ngIf="showTeams" animationDuration="0ms" (selectedTabChange)="changeTab($event)">

      <mat-tab label="{{teamOneName}}"></mat-tab>
      <mat-tab label="{{teamTwoName}}"></mat-tab>
  </mat-tab-group>
</mat-card-title>
<div id="openWagon" *ngIf="!wagonNotavailable">
<section class="d-flex justify-content-center">
  <div class="example-button-row" >
    <button mat-raised-button class="btn-all text-white" (click)="selectedRun = 'All'">All</button>
    <button mat-raised-button class="text-white btn-1s" (click)="selectedRun = 1">1s</button>
    <button mat-raised-button class="text-white btn-2s" (click)="selectedRun = 2">2s</button>
    <button mat-raised-button class="text-white btn-3s" (click)="selectedRun = 3">3s</button>
    <button mat-raised-button class="text-white btn-4s" (click)="selectedRun = 4">4s</button>
    <button mat-raised-button class="text-white btn-6s" (click)="selectedRun = 6">6s</button>
  </div>
</section>
<mat-divider></mat-divider>
<mat-card-content class="text-center" >
  <div class="d-flex justify-content-center">
    <div class="wheel d-none d-md-block d-lg-block" id="wagOnWheel">
      <svg height="620px" width="620px">
        <ng-container *ngFor="let ball of ballHit">
          <line stroke-width="2px" *ngIf="ball.runs == selectedRun || selectedRun == 'All'"  x1="310" y1="273"   [attr.x2]="getShortenedEndpoint(620, 273, ball.where_hit_x, ball.where_hit_y).x" 
          [attr.y2]="getShortenedEndpoint(620, 273, ball.where_hit_x, ball.where_hit_y).y"
          [attr.style]="getLineStyle(ball.runs)"  />
        </ng-container>
        </svg>
    </div>
    <div class="wheel d-lg-none d-md-none d-block mt-2" id="wagOnWheelMobile">
      <svg height="300px" width="300px">
        <ng-container *ngFor="let ball of ballHit">
          <line stroke-width="2px" x1="150" y1="132" [attr.x2]="ball.where_hit_x * scaleMobileX"
            [attr.y2]="ball.where_hit_y * scaleMobileY" *ngIf=" ball.runs == selectedRun  || selectedRun == 'All'" [attr.style]="getLineStyle(ball.runs)"
             />
            </ng-container>
      </svg>
    </div>
    <div class="wheel d-none d-xs-block mt-2" id="wagOnWheelMobileFold">
      <svg height="280px" width="280px">
        <ng-container *ngFor="let ball of ballHit">
          <line stroke-width="2px" x1="113" y1="125" [attr.x2]="ball.where_hit_x * scaleFoldX"
            [attr.y2]="ball.where_hit_y * scaleFoldY" [attr.style]="getLineStyle(ball.runs)"
            *ngIf="ball.runs === selectedRun || selectedRun === 'All'" />
          </ng-container>
          </svg>
    </div>
  </div>
</mat-card-content>
</div>
<div class="d-flex justify-content-center"  *ngIf="wagonNotavailable">
  <div  class="m-2">
    <section class="d-flex justify-content-center opacity-50">
      <div class="example-button-row" >
        <button mat-raised-button class="btn-all text-white" (click)="selectedRun = 'All'">All</button>
        <button mat-raised-button class="text-white btn-1s" (click)="selectedRun = 1">1s</button>
        <button mat-raised-button class="text-white btn-2s" (click)="selectedRun = 2">2s</button>
        <button mat-raised-button class="text-white btn-3s" (click)="selectedRun = 3">3s</button>
        <button mat-raised-button class="text-white btn-4s" (click)="selectedRun = 4">4s</button>
        <button mat-raised-button class="text-white btn-6s" (click)="selectedRun = 6">6s</button>
      </div>
    </section>
      <!-- <div class="wheel text-center" id="wagNotAvailabel" style="opacity: 0.5;"></div>  -->
      <img src="/assets/wheel/wagon.webp" alt="" class="w-100 wagNotAvailabel mt-lg-5" style="opacity: 0.5;">
      <h1 class="d-flex justify-content-center align-items-center text-center wagon_not_available">NO DATA FOUND</h1>
  </div>
</div>
