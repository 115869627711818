import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { OrgcodeFilterService, } from './orgcode-filter.service';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiEndpoint } from './constants/endpoint.def';

@Injectable({
  providedIn: 'root',
})
export class OrgCodeResolverService implements Resolve<any> {


  constructor(private orgCodeFilterService: OrgcodeFilterService, private router: Router, private commonService: CommonService, private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> | string {
    return this.http.get<any>(environment.serviceUrl + ApiEndpoint.ORGANIZATION.GET_ALL_ORGANIZATION_DETAIL).pipe(
      map((data: any) => {
        const result = data.result;
        const orgcode = this.orgCodeFilterService.getAppBaseHref(result);
        const currentUrl = state.url;
        let modifiedUrlSegments = currentUrl.split('/').filter(x => x !== '')
        // if (modifiedUrl.length != 1&& modifiedUrl.includes('SR'||'SKA')) modifiedUrl.shift()
        if (modifiedUrlSegments.length != 1 && (modifiedUrlSegments.includes('SR') || modifiedUrlSegments.includes('SKA'))) {
          modifiedUrlSegments.shift();
        }

        // Join segments and replace commas with slashes
        let modifiedUrl = modifiedUrlSegments.join('/').replace(/,/g, '/');

        if (!currentUrl.startsWith(`/${orgcode}/`)) {
          const targetUrl = `/${orgcode}/${modifiedUrl}`;
          this.router.navigateByUrl(targetUrl);
        }
        return '';
      }),
      catchError((error) => {
        console.error('Error fetching organization data', error);
        return ' ';
      })
    );
  }
}