<div class="container mt-2 py-5">
    <div class=" mb-4" style="border-bottom:none;">
        <div class=" px-3  d-flex  align-items-center" style="height: 100%;">
            <div class="col-12 modal-header d-flex justify-content-between  ">
                <h4 class=" modal-title fw-bold m-0  fs-6" style="display:inline">
                    <!-- <i class="bi bi-chevron-left" (click)="back()"></i>&nbsp; -->
                    Change Password
                </h4>
            </div>
        </div>
    </div>
    <div class=" card container">
        <form #myForm="ngForm">
            <div class="container gap-2 row   justify-content-center pt-3 changePassword">
                <div class="my-1 col-lg-8 col-md-10 col-sm-12">
                    <mat-card-content class="mt-1">
                        <mat-form-field appearance="outline" class="formFeild w-100">
                            <mat-label>Old Password</mat-label>
                            <div class="d-flex">
                                <input matInput [type]="showOldPassword ? 'text' : 'password'" name="oldPassword"
                                    id="oldPassword" [(ngModel)]="oldPassword" placeholder="Old Password" required>
                            </div>
                        </mat-form-field>
                    </mat-card-content>
                </div>
                <div class="my-1 col-lg-8 col-md-10 col-sm-12">
                    <mat-card-content class="mt-1">
                        <mat-form-field appearance="outline" class="formFeild w-100">
                            <mat-label>New Password</mat-label>
                            <div class="d-flex">
                                <input matInput [type]="showNewPassword ? 'text' : 'password'" name="newPassword"
                                    id="newPassword" [(ngModel)]="newPassword" placeholder="New Password" required>
                            </div>
                        </mat-form-field>
                        <span class="text-danger m-2" *ngIf="isPasswordMatch &&newPassword.length==0 "> Password Doesn't
                            match</span>
                    </mat-card-content>
                </div>
                <div class="my-1 col-lg-8 col-md-10 col-sm-12">
                    <mat-card-content class="mt-1">
                        <mat-form-field appearance="outline" class="formFeild w-100">
                            <mat-label>Confirm password</mat-label>
                            <div class="d-flex">
                                <input matInput [type]="showReTypePassword ? 'text' : 'password'" name="reNewPassword"
                                    id="reNewPassword" [(ngModel)]="reTypePassword" placeholder="ReType New Password"
                                    required>
                            </div>
                        </mat-form-field>
                    </mat-card-content>
                </div>

            </div>
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8 col-md-10 col-sm-12 d-flex justify-content-evenly">
                    <button class="my-3 col-4 rounded-4 p-2 btn btn-danger" (click)="back()">Cancel</button>
                    <button type="submit" class="my-3 rounded-4 p-2 col-4" (click)="changePassword()"
                        [disabled]="myForm.invalid"
                        [ngClass]="{ 'title': !myForm.invalid, 'isdisable': myForm.invalid }">Change Password</button>

                </div>
            </div>

        </form>
    </div>
</div>