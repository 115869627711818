
<ng-container *ngIf="selectedMenuItem == 'Features'">
    <ng-container *ngTemplateOutlet="Features"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedMenuItem == 'Terms&Condition'">
    <ng-container *ngTemplateOutlet="TermsAndConditions"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedMenuItem == 'ContactUs'">
    <ng-container *ngTemplateOutlet="ContactUs"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedMenuItem == 'PrivacyPolicy'">
    <ng-container *ngTemplateOutlet="PrivacyPolicy"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedMenuItem == 'AboutUs'">
    <ng-container *ngTemplateOutlet="AboutUs"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedMenuItem == 'HowToUse'">
    <ng-container *ngTemplateOutlet="HowToUse"></ng-container>
  </ng-container>
  <ng-container *ngIf="selectedMenuItem == 'ProductServices'">
    <ng-container *ngTemplateOutlet="ProductServices"></ng-container>
  </ng-container>

<div id="myNav" class="myNav d-block d-lg-none d-xl-none">
    <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" class="footerExpand">
            <mat-expansion-panel-header>
                <mat-panel-title> Features </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="scroll-design"><ng-container *ngTemplateOutlet="Features"></ng-container></mat-card>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" class="footerExpand">
            <mat-expansion-panel-header>
                <mat-panel-title>Terms & Condition</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="scroll-design"><ng-container *ngTemplateOutlet="TermsAndConditions"></ng-container></mat-card>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" class="footerExpand">
            <mat-expansion-panel-header>
                <mat-panel-title>Contact Us</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="scroll-design"><ng-container *ngTemplateOutlet="ContactUs"></ng-container> </mat-card>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" class="footerExpand">
            <mat-expansion-panel-header>
                <mat-panel-title>Privacy Policy</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="scroll-design"><ng-container *ngTemplateOutlet="PrivacyPolicy"></ng-container> </mat-card>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" class="footerExpand">
            <mat-expansion-panel-header>
                <mat-panel-title>How To Use</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="scroll-design"><ng-container *ngTemplateOutlet="HowToUse"></ng-container> </mat-card>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" class="footerExpand">
            <mat-expansion-panel-header>
                <mat-panel-title>About Us</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card class="scroll-design"> <ng-container *ngTemplateOutlet="AboutUs"></ng-container> </mat-card>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)" class="footerExpand">
          <mat-expansion-panel-header>
              <mat-panel-title>Product / Services</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card class="scroll-design"> <ng-container *ngTemplateOutlet="ProductServices"></ng-container> </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
</div>
<ng-template #Features>
<div class="container my-3 scrollable-container-about">
  <div class=" row justify-content-center m-2">
    <ng-container *ngFor="let index of [0,1,2,3,4,5]">
      <div class="card col-sm-11 col-md-5 col-lg-5 mx-3  mb-3"  [ngClass]="{'even-card': index === 1 || index === 2 || index === 5, 'odd-card': index === 0 || index === 3 || index === 4,'even-card-sm': index % 2 === 0,'odd-card-sm': index % 2 !== 0}">
        <h2 class="card-title text-uppercase p-2 fs-4">{{DynamicMoreCotent[index]?.content}}</h2>
        <div class="text-styel card-text" [innerHTML]="DynamicMoreCotent[index]?.categories"></div>
      </div>
    </ng-container>
</div>
</div>
</ng-template>
<ng-template #TermsAndConditions>
<div class="container my-3 scrollable-container-about">
  <div class="card rounded-3 shadow p-3">
    <div class="card-desc fs-6">
      <ng-container *ngFor="let index of [20,6,7,8,9,10,11,12,13,14,15,16,17,18,19]">
          <h2 class="card-title text-uppercase p-2 fs-4">{{DynamicMoreCotent[index]?.content}}</h2>
          <div  class="body-color text-styel card-text" [innerHTML]="DynamicMoreCotent[index]?.categories"></div>
      </ng-container>
  </div>
  </div>
</div>
</ng-template>
<ng-template #PrivacyPolicy>
<div class="container my-3 scrollable-container-about">
  <div class="card rounded-3 shadow p-3">
    <ng-container *ngFor="let index of [21,22,23,24]">
          <h2 class="card-title text-uppercase p-2 fs-4">{{DynamicMoreCotent[index]?.content}}</h2>
          <div class="body-color text-styel card-text" [innerHTML]="DynamicMoreCotent[index]?.categories"></div>
      </ng-container>
    </div>
</div>
</ng-template>
<ng-template #AboutUs>
<div class="container my-3 scrollable-container-about">
  <div class=" p-3">
        <!-- <ng-container *ngFor="let index of [27,28,29]">
          <h2 class="card-title text-uppercase p-2 fs-5 ">{{DynamicMoreCotent[index]?.content}}</h2>
          <div class="p-1 text-styel mb-4" [innerHTML]="DynamicMoreCotent[index]?.categories"></div>
      </ng-container> -->
      <div class="row  justify-content-evenly" >
        <div class="col-lg-3 col-md-3 col-sm-11 px-0 card mb-3 odd-card">
          <h2 class="card-title   text-uppercase  p-2 fs-3 ">{{DynamicMoreCotent[27]?.content}}</h2>
          <div class="py-1 px-2 text-styel mb-4 card-text" [innerHTML]="DynamicMoreCotent[27]?.categories"></div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-11 px-0 card mb-3 even-card ">
          <h2 class="card-title text-uppercase p-2 fs-3 ">{{DynamicMoreCotent[28]?.content}}</h2>
          <div class="py-1 px-2 text-styel mb-4 card-text" [innerHTML]="DynamicMoreCotent[28]?.categories"></div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-11 px-0 card odd-card">
          <h2 class="card-title text-uppercase p-2 fs-3 ">{{DynamicMoreCotent[29]?.content}}</h2>
          <div class="py-1 px-2 text-styel mb-4  card-text" [innerHTML]="DynamicMoreCotent[29]?.categories"></div>
        </div>
      </div>
    </div>
</div>
</ng-template>
<ng-template #ContactUs>
  <div class="container my-3 scrollable-container-userManual text-center">
    <div class="card rounded-3 shadow p-3">
      <div class="row justify-content-evenly">
        <div class=" card col-lg-5 col-md-5 col-sm-12 px-0 mb-2  odd-card">
          <h2 class="card-title text-uppercase fs-3 mt-4">{{DynamicMoreCotent[25]?.content}}.</h2>
          <h3 class="card-text fs-5">{{DynamicMoreCotent[25]?.categories}}</h3>
        </div>
        <div class=" card col-lg-5 col-md-5 col-sm-12 px-0 mb-2 odd-card">
          <h2 class="card-title mt-4 fs-3">{{DynamicMoreCotent[26]?.content}}</h2>
          <div class="adress lh-base fs-5" [innerHTML]="DynamicMoreCotent[26]?.categories"></div>
        </div>
      </div>
    <div class="shadow mt-2">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d873.9907719743941!2d80.25499275211196!3d13.020111056350954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52673b4b7e62bf%3A0xae86ae7836f9d1cf!2sSportsRuler!5e0!3m2!1sen!2sin!4v1695805043420!5m2!1sen!2sin"
            width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="footer border-top">
        <div class="d-flex align-items-center py-3 justify-content-lg-center">
            <p class="m-0 card-text office-name"><i class="bi bi-c-circle"></i><span class="m-0 text-nowrap office-name">
                    {{currentYear}} </span>
                <img src="assets/images/World.webp" class="world-img" alt="">
                <span class="neon-text fw-medium">Prodian infotech Pvt Ltd.</span>All rights reserved
            </p>
        </div>
    </div>
  </div>
</div>
</ng-template>
<ng-template #HowToUse>
<div class="container bg-white rounded-2 p-2">
  <div class="card rounded-3 shadow p-3">
    <div class="row">
        <div class="col-lg-7 col-12 d-flex align-items-center mt-2 mt-lg-0">
            <img src="assets/images/SR_GIF.gif" class="w-100" alt="">
        </div>
        <div class="col-lg-3 col-12 py-2">
            <div class="row flex-column align-items-center justify-content-evenly">
                <div class="col-5 d-flex flex-column align-items-center mt-2">
                    <h4 class="card-title text-center text-nowrap fs-3 fw-bold m-0">Available On</h4>
                    <img src="assets/images/QR code android.webp" alt="" class="w-100">
                    <p class="text text-nowrap text-center">Scan this QR code for Android App</p>
                </div>
                <div class="col-4 d-flex flex-column align-items-center my-2">
                    <h1 class="card-title text-nowrap fs-3 fw-bold m-0">The SportsRuler App</h1>
                    <h3 class="text text-nowrap m-0">Prodian Info Tech</h3>
                    <h5 class="text m-0">Free</h5>
                    <a href="https://play.google.com/store/apps/details?id=com.prodian.sportsruler">
                        <button type="button" class="btn  btn-lg text-white text-nowrap text"
                            style="background-color:#023c53 ;">Get The App</button>
                    </a>
                </div>
                <div class="col-3 d-flex flex-column align-items-center my-2">
                    <h2 class="text text-nowrap">Everyone 10+</h2>
                    <img src="assets/images/howto_sr_esrb.webp" class="w-75 h-50" alt="">
                </div>
            </div>
        </div>
    </div>


    <div class="p-2">
        <h3 class="card-title fs-4 text-white m-0 p-1" >STEP 1:</h3>
        <p class="text fs-6 p-2 lh-sm m-0 body-color">Now download and install Sportsruler app: Android</p>
        <h3 class="card-title fs-4 text-white m-0 p-1">STEP 2:</h3>
        <p class="text fs-6 p-2 lh-sm m-0 body-color">Now enter your User ID and Password. If your New to Sportsruler just Click Sign
            Up . Then write Details which
            Sportsruler want to set as your new Account and then Sign Up.</p>
        <h3 class="card-title fs-4 text-white m-0 p-1 ">STEP 3:</h3>
        <p class="text fs-6 p-2 lh-sm m-0 body-color">After few seconds you will receive a Email with verfication code. You have to
            Enter that verfication code to
            complete the Sports Ruler registration.</p>
        <h3 class="card-title fs-4 text-white m-0 p-1 ">STEP 4:</h3>
        <p class="text fs-6 p-2 lh-sm m-0 body-color">Now lets get started by selecting your preferences</p>
    </div>
</div>
</div>
</ng-template>
<ng-template #ProductServices>
  <div class="container my-3 scrollable-container-about">
    <div class="card rounded-3 shadow p-3">
      <ng-container *ngFor="let index of [30]">
        <h2 class="card-title fw-medium fs-5 m-1">{{DynamicMoreCotent[index]?.content}}</h2>
        <div class="p-1 body-color text-styel card-text" [innerHTML]="DynamicMoreCotent[index]?.categories"></div>
      </ng-container>
    </div>
  </div>
</ng-template>

