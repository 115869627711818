import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-model',
  templateUrl: './pop-model.component.html',
  styleUrls: ['./pop-model.component.css']
})
export class PopModelComponent {


  imageError: boolean = false;
  userDetails: { about_me: string } = { about_me: '' };
  userStringths:{ Stringths:string}= {Stringths:''};
  userWeakness: { Weakness:string}={Weakness:''};

  constructor(public dialogRef: MatDialogRef<PopModelComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  showFileSize(event: any) {
    const file = event.target.files[0];
    if (file && file.size > 2097152) { // 2MB in bytes
      this.imageError = true;
    } else {
      this.imageError = false;
    }
  }

  onSubmit() {
    // Add your form submission logic here
    if (!this.imageError) {
      this.dialogRef.close();
    }
  }

  updateAboutMe() {
    // Handle the "About Me" form submission
    // You can add logic here to save the user details
    this.dialogRef.close(this.userDetails);
  }

  updateStrengths() {
   
    this.dialogRef.close(this.userStringths);
  }

  updateWeakness(){
    this.dialogRef.close(this.userWeakness);
  }

}
