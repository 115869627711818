import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/service/common.service';

export interface PointsTableData {
  played: number;
  won: number;
  lost: number;
  tied: number;
  net_run_rate: number | null;
  points: number;
  no_result: number;
  Group_name: string;
  Team_name: string;
}

@Component({
  selector: 'app-points-table',
  templateUrl: './points-table.component.html',
  styleUrls: ['./points-table.component.css'],
})
export class PointsTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['Group_name', 'Team_name', 'played', 'won', 'lost', 'tied', 'no_result', 'points', 'net_run_rate'];
  dataSource: MatTableDataSource<PointsTableData> = new MatTableDataSource<PointsTableData>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() eventId: any;
  pointstable: any;

  constructor(private _liveAnnouncer: LiveAnnouncer, private service: CommonService) {}

  ngOnInit() {  
    this.getPointsTable();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const textToSearch = data.Group_name + data.Team_name + data.played + data.won + data.lost + data.tied + data.no_result + data.points + data.net_run_rate;
      return textToSearch.toLowerCase().includes(filter.toLowerCase());
    };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getPointsTable() {
    this.service.getPointsTable(this.eventId).subscribe((data: any) => {
      this.pointstable = data.result;
      const points: PointsTableData[] = this.pointstable;  
      this.dataSource.data = points;
      this.dataSource.sort = this.sort;
    });
  }
}
