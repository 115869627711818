import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-home-section-live-score-card',
  templateUrl: './home-section-live-score-card.component.html',
  styleUrls: ['./home-section-live-score-card.component.css'],
})
export class HomeSectionLiveScoreCardComponent {
  @Input() data:any;
  @Input() upcomingMatch:boolean=false;
  orgCode:any
  isSmallScreen: boolean=false;

  constructor(private commonService: CommonService, private router : Router,private orgFilter:OrgcodeFilterService,private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe(result => {
      this.isSmallScreen = result.matches;
    });
    
  }

  goToScoreCardPage() {
    this.router.navigateByUrl('/scorecard')
  }


  goToScoreCardDetailPage(matchId : any) {
    this.router.navigate([`${this.orgCode}/scorecardDetails`,matchId]);
  }
}
