<!-- <mat-card> -->
    <mat-card-title class="mx-1 pitchMap">
        <mat-tab-group *ngIf="showTeams" animationDuration="0ms" (selectedTabChange)="changePitchMapTab($event)">

            <mat-tab label="{{teamOneName}}"></mat-tab>
            <mat-tab label="{{teamTwoName}}"></mat-tab>
        </mat-tab-group>
    </mat-card-title>
    <div class="d-flex justify-content-center align-items-center">
        <div *ngIf="pitchNotAvailable" class="mt-lg-5 position-relative">
            <img src="assets/wheel/pitchmap.jpg" class="pichmapImage my-3" width="300" height="500" style="opacity: 0.5;">
            <div class="d-flex justify-content-center align-items-center position-absolute w-100 h-100 d-none" style="top: 0; left: 0;">
                <h1 class="text-center pitchmap_not_available">NO DATA FOUND</h1>
            </div>
        </div>
        <svg id="pitchMap" width="300" height="500" *ngIf="!pitchNotAvailable" class="mt-lg-5">
            <image href="assets/wheel/pitchmap.jpg" x="0" y="0" width="100%" height="100%"/>
            <rect x="137" y="85" fill="#00000057" style="width: 25; height: 335;"></rect>
            <rect x="75" y="80" fill="#ffff0057" width="148" height="42"></rect>
            <rect x="75" y="120" fill="#79a75b8c" width="148" height="68"></rect>
            <rect x="75" y="186" fill="#f4433678" width="148" height="46"></rect>
            <rect x="75" y="233" fill="#9c9cd0c2" width="148" height="150"></rect>
            <ng-container *ngFor="let coordinate of coordinates">
                <text class="fw-bold fs-italic" [attr.x]="coordinate.x" [attr.y]="coordinate.y" text-anchor="middle"
                    fill="white" font-size="16">{{ coordinate.text }}</text>
            </ng-container>

            <ng-container *ngFor="let coordinate of currentCoordinates">
                <image id="ball3D" [attr.x]="coordinate.x - 10" [attr.y]="coordinate.y - 10" width="20" height="20"
                    href="assets/wheel/red3D.png" />
            </ng-container>

            <line *ngFor="let line of lines" [attr.x1]="line.startX" [attr.y1]="line.startY" [attr.x2]="line.endX"
                [attr.y2]="line.endY" [attr.stroke]="line.stroke" stroke-width="1" stroke-dasharray="4,4" />
        </svg>


    </div>
<!-- </mat-card> -->