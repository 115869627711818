import { Component } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { CommonAlertsComponent } from '../common-alerts/common-alerts.component';
import { OrgcodeFilterService } from '../orgcode-filter.service';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  passWordForm: FormGroup;
  emailValidationToggle: boolean = false;
  errorMessage: string = '';
  results: any;
  emailId: any;
  status1: string = '';
  mailConfirmationStatus: any = '';
  showpassworError:boolean=false;
  changepassword:boolean=false;
  Login_Type: any;
  email: any;
  userDetails: any = {};
  emailForm: FormGroup;
  userId: any;
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  orgCode:any

  constructor(private fb: FormBuilder, private commonService: CommonService,private router:Router,private dialog:MatDialog,private route: ActivatedRoute,
    private orgFilter:OrgcodeFilterService
  ) {
    this.passWordForm = this.fb.group({
      temppassword: ['',[Validators.required]],
      newpassword: ['', [Validators.minLength(6)]],
      confirmpassword: [''],
    }, {
      validators: this.passwordMatchValidator
    });
    this.emailForm=this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],

    })
  }
  ngOnInit() {
    this.Login_Type=this.route.snapshot.paramMap.get('Login_Type');
   if (this.Login_Type=="T"){
    this.changepassword=true;
   }else {
    this.changepassword=false;
   }
   this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }


  emailAvailable() {
    this.emailId = this.emailForm.value.email;
    if (this.emailForm.valid) {
      this.dialogRef= this.dialog.open(CommonAlertsComponent, {
        data: { identifier: 'loader' }
      });
      this.commonService.getMemberByMailId(this.emailId).subscribe((response) => {
        this.results = response;
        if (this.results.status == 'NOROWS') {
          this.emailValidationToggle = true;
          this.errorMessage = 'email not existed';
          this.dialogRef?.close();
        } else {
          this.emailValidationToggle = false;
          this.commonService.sendEmailForForgotPassword(this.emailId).subscribe((response) => {
            this.mailConfirmationStatus = response;
            if(this.mailConfirmationStatus.status == 'TRUE'){
              this.dialogRef?.close();
              this.changepassword=true;
              this.dialog.open(CommonAlertsComponent, {
                data: { identifier: 'forgotPassword' },
                disableClose: true,
              });
              this.userId = this.mailConfirmationStatus.result.userId;
              localStorage.setItem('tempUseId',this.userId);
              localStorage.setItem('tempMailId',this.emailId)
            }else{
              this.dialogRef?.close();
              console.error('Error occurred in sending mail');
            }
          })

        }
      });
    } else {
      this.emailValidationToggle = true;
      if (this.emailId == '') {
        this.errorMessage = "please enter mailId";
      } else {
        this.errorMessage = "please enter valid emailId";
      }
    }
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('newpassword');
    const confirmpasswordControl = formGroup.get('confirmpassword');
  
    if (passwordControl && confirmpasswordControl) {
      const password = passwordControl.value;
      const confirmpassword = confirmpasswordControl.value;
  
      if (password !== confirmpassword) {
        confirmpasswordControl.setErrors({ mismatch: true });
      } else {
        confirmpasswordControl.setErrors(null);
      }
    }
  }

  getOldPassword(){
    const passwd = CryptoJS.SHA256(localStorage.getItem('tempMailId')+this.passWordForm.value.temppassword).toString(CryptoJS.enc.Hex);
    const user_id=localStorage.getItem('tempUseId');
   this.commonService.getoldPassword(user_id,passwd).subscribe((data:any)=>{
    this.email=data.result[0].email_id;
    this.getEncp();
   });
  }
  
  getEncp(){
    const passwd=this.passWordForm.value.newpassword;
    this.commonService.getencp(passwd,this.email).subscribe((data:any)=>{
      if(data.status=="TRUE"){
        localStorage.removeItem("email");
       this.updatePassword();
      }
    });
  }

updatePassword(){
  const sha256Hash1 = CryptoJS.SHA256(this.email+ this.passWordForm.value.confirmpassword); 
  this.userDetails.flag = 'changePassword'
  this.userDetails.encPass=sha256Hash1.toString(CryptoJS.enc.Hex);
  this.userDetails.uid=localStorage.getItem('tempUseId')
  this.commonService.updateuserpassword(this.userDetails).subscribe((response)=>{
    if(response.status='TRUE'){
      console.log("Password updated successfully");
      localStorage.setItem('Login_Type',"U");
       this.dialogRef = this.dialog.open(CommonAlertsComponent, {
          data: { identifier: 'Password Updated Success' },
          disableClose: true,
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 'Password Updated Success') {
          this.router.navigate([`${this.orgCode}/home`]);
        }
      });
    }else{
      console.log("unable to update the Password")
      this.dialog.open(CommonAlertsComponent, {
        data: { identifier: 'Password Updated Faild' },
        disableClose: true,
      });
    }
  });
}

  submit(){
    const hasError = this.passWordForm.hasError('mismatch');
    if (!hasError) {
      this.showpassworError=false;
      this.getOldPassword();
    }else{
     this.showpassworError=true;
    }
  }
  
}

