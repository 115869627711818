import { Component } from '@angular/core';

@Component({
  selector: 'app-live-section-card',
  templateUrl: './live-section-card.component.html',
  styleUrls: ['./live-section-card.component.css']
})
export class LiveSectionCardComponent {

}
