import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { VideoService } from 'src/app/service/video.service';
import { OrgcodeFilterService } from '../orgcode-filter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  vedioCateID = 3;
  videoList: any[] = [];
  navData: any
  activeTab: any;
  showNav: boolean = false;
  panelOpenState = false;
  step =0;
  dataPolicies: any;
  currentYear=new Date().getFullYear();
  produceAndService: any;
  orgCode:any

  constructor(private VideoService: VideoService,private router: Router,private commonService: CommonService,private orgFilter:OrgcodeFilterService) {
    this.step = 0;
  }

  ngOnInit(): void {
    this.orgFilter.storageItems.subscribe(items => {
      if (items && items.length > 0) {
        this.orgCode = items[0].orgPath;
      } else {
        console.error('No items found in storageItems');
      }
    });
    this.currentYear = new Date().getFullYear();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tabActive(event.urlAfterRedirects);
      }
    });
  }
  
  FooterArray = [

    { tab: 'Matches', id: 2, icon:'bi bi-clipboard-data-fill', active: false, routerLink: '/matches',detailPath:'/scorecardDetails' },
    { tab: 'Events', id: 4, icon: 'bi bi-calendar3', active: false, routerLink: `/tournament/All/1` },
    { tab: 'Home', id: 1, icon:'bi bi-house-fill', active: true, routerLink: '/home' },
    { tab: 'SR TV', id: 3, icon:'bi bi-play-btn-fill', active: false, routerLink: '/videos',detailPath:'/videodetail' },
    { tab: 'More', id: 5, icon:'bi bi-list', active: false },
  ]
  menuItems = [
    { tab: 'Features', id: 1, active: false, routerLink: '/features'},
    { tab: 'Terms & Condition', id: 2, active: false, routerLink: '/HeaderContent/Terms & Condition' },
    { tab: 'Contact Us', id: 3, active: false, routerLink: '/contactus' },
    { tab: 'Privacy Policy', id: 4, active: false, routerLink: '/privacypolicy'},
    { tab: 'How To Use', id: 6, active: false, routerLink: '/HeaderContent/How To Use' },
    { tab: 'About', id: 7, active: false, routerLink: '/HeaderContent/About Us' },

  ];

  tabActive(path: any) {
    if(path) {
      if(path == '/') {
        this.FooterArray.forEach((footer:any,index :number)=>{
          footer.active=index==2;
        })

      } else {
        this.FooterArray.map((footerPath: any)=>{
          if (footerPath.routerLink == path|| (path.includes(footerPath.detailPath)) && footerPath.detailPath != '') {
            footerPath.active = true;
          }else if(path.includes("/HeaderContent")) {
            this.FooterArray.forEach((footer: any, index: number) => {
              footer.active = index === 4;
            });
          } else {
            footerPath.active = false;
          }
        })
      }
    }
  }
  openNav() {
    this.navData = document.getElementById("myNav");
    if ( this.showNav === false) {
      this.showNav = true;
      this.router.navigate([`${this.orgCode}/HeaderContent`,'navMenu'])
      }
     else
     {
      window.history.back();
      this.showNav = false;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  getDynamicUrl(path: string): string {
    return `${this.orgCode}${path}`;
  }
}
