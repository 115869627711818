import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';
import { EventService } from 'src/app/service/event.service';
import { HeaderParameterService } from 'src/app/service/header-parameter.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  editProfileForm!: FormGroup;
  SEARCHSTR: any;
  cityLists: { value: number; cityName: string }[] = [];
  CityArray: { value: number; cityName: string }[] = [];
  editProfile: boolean = true;
  genderType: any;
  userId: any;
  emailId: any;
  oldPassword: string = '';
  userName: any;
  useremail: any;
  userNumber: any;
  profilepage: boolean = false;

  previewImageUrl: string | ArrayBuffer | null = '/assets/user-thumbnail.png';
  dateofbirth: any;
  maxDate = new Date();
  orgCode:any

  constructor(private router: Router, private formBuilder: FormBuilder, private serivice: EventService, private dialog: MatDialog, private snackBar: MatSnackBar, private headerparam: HeaderParameterService, private commonService: CommonService,private orgFilter:OrgcodeFilterService) {
    this.editProfileForm = this.formBuilder.group({
      FirstName: this.createFormControlWithValidators([Validators.required]),
      LastName: this.createFormControlWithValidators([]),
      Email: this.createFormControlWithValidators([Validators.required, Validators.email]),
      city: ['', Validators.required],
      MobileNo: this.createFormControlWithValidators([Validators.required]),
      DOB: ['', Validators.required],
      PinCode: this.createFormControlWithValidators([Validators.required]),
      gender: ['']
    })
  }
  ngOnInit(): void {

    this.SEARCHSTR = localStorage.getItem('userId');
    if (this.SEARCHSTR === null) {
      this.router.navigate([`${this.orgCode}/home`]);
    } else {
      this.getUserDetails();
      this.getEventCityList();
    }
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
  }

  private createFormControlWithValidators(validators: any[]) {
    return ['', validators.concat(Validators.pattern(/^(?!\s)(?:(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|(?:\d{10})|(?:[a-zA-Z0-9_\- ]+))$/))];
  }
  goBack() {
    this.router.navigate([`${this.orgCode}/home`]);
  }

  Backtoprofile() {
    this.profilepage = false;
    this.getUserDetails();
  }

  EditImg() {
    this.router.navigate([`${this.orgCode}/updateProfilepic`])
  }
  updateProfilepage() {
    this.profilepage = true;
  }
  getUserDetails() {
    try {
      this.commonService.getUserDetails(this.SEARCHSTR).subscribe((response: any) => {
        if (response.status === 'TRUE') {
          const userProfileDetails = response.result[0];
          this.userName = userProfileDetails.F_name + " " + userProfileDetails.l_name;
          this.useremail = userProfileDetails.email_id;
          this.userNumber = userProfileDetails.mobile_no;
          this.dateofbirth = userProfileDetails.d_o_b;
          this.editProfileForm.patchValue({
            FirstName: userProfileDetails.F_name,
            LastName: userProfileDetails.l_name,
            Email: userProfileDetails.email_id,
            city: { 'value': userProfileDetails.city, 'cityName': userProfileDetails.City_name },
            MobileNo: userProfileDetails.mobile_no,
            DOB: userProfileDetails.d_o_b,
            PinCode: userProfileDetails.pin_code,
            gender: userProfileDetails.Gender
          })
          const timestamp = new Date().getTime();
          this.previewImageUrl = environment.serviceUrl + userProfileDetails.path_to_pic + "?" + timestamp;
          this.userId = userProfileDetails.user_id;
          this.oldPassword = CryptoJS.SHA256(userProfileDetails?.email_id).toString();

          this.Type(userProfileDetails.gender)
          if (userProfileDetails == 'Female') {
            this.editProfileForm.value.gender = 'F';
          } else if (userProfileDetails == 'Male') {
            this.editProfileForm.value.gender = 'M';
          }

        } else if (response.status == 'FALSE') {
          this.dialog.open(CommonAlertsComponent, {
            data: { identifier: 'INVALID' },
            disableClose: true,
          });
        }
      }, (error: any) => {
        console.error('Error occurred:', error);
        this.dialog.open(CommonAlertsComponent, {
          data: { identifier: 'EXCEPTION' },
          disableClose: true,
        });
      })
    } catch (error) {
      console.error('Error in HTTP request:', error);
      this.dialog.open(CommonAlertsComponent, {
        data: { identifier: 'EXCEPTION' },
        disableClose: true,
      });
    }
  }
  updatePassword() {
    this.editProfile = false;
    this.router.navigate([`${this.orgCode}/ChangePassword`])
  }
  updateProfile() {
    this.UpdateProfileDetails();
  }
  getEventCityList() {
    try {
      this.serivice.getCityList().subscribe((response: any) => {
        if (response.status === 'TRUE') {
          this.cityLists = this.mapCityResponse(response.result);
        } else if (response.status == 'FALSE') {
          this.dialog.open(CommonAlertsComponent, {
            data: { identifier: 'INVALID' },
            disableClose: true,
          });
        }
      }, (error: any) => {
        console.error('Error occurred:', error);
        this.dialog.open(CommonAlertsComponent, {
          data: { identifier: 'EXCEPTION' },
          disableClose: true,
        });
      })
    } catch (error) {
      console.error('Error in HTTP request:', error);
      this.dialog.open(CommonAlertsComponent, {
        data: { identifier: 'EXCEPTION' },
        disableClose: true,
      });
    }
  }

  mapCityResponse(data: any): { value: number; cityName: string }[] {
    return Object.keys(data).map(key => ({
      value: data[key].City_id,
      cityName: data[key].City_name,
    }));
  }

  public setupAutocomplete(event: any): void {
    const cityValue = event.target.value;
    if (cityValue === undefined || cityValue === null) {
      console.error('City value is undefined or null.');
      return;
    }

    this.CityArray = this._filter(cityValue);
  }


  private _filter(city: string): { value: number; cityName: string }[] {
    if (!city) {
      return this.CityArray;
    }

    const filterValue = city.toLowerCase();

    return this.cityLists ? this.cityLists.filter((city: { value: number; cityName: string }) =>
      city.cityName.toLowerCase().includes(filterValue)) : [];
  }


  displayFn(city: { value: number, cityName: string }): string {
    if (city && typeof city === 'object' && 'cityName' in city) {
      return city ? `${city.cityName}` : '';
    } else {
      console.error('Invalid city object:', city);
      return '';
    }
  }
  Type(value: string) {
    this.genderType = value;
  }
  UpdateProfileDetails() {
    if (this.editProfileForm.valid) {
      const formData = {
        fname: this.editProfileForm.value.FirstName,
        lname: this.editProfileForm.value.LastName,
        city: this.editProfileForm.value.city.value,
        state: "",
        country: "",
        phone: this.editProfileForm.value.MobileNo,
        d_o_b: this.editProfileForm.value.DOB,
        gender: this.editProfileForm.value.gender,
        pinCode: this.editProfileForm.value.PinCode,
        zone: this.editProfileForm.value.city.cityName,
        uid: this.SEARCHSTR,
        flag: "profiledetails",
      }
      try {
        this.serivice.updateEvenMaster(formData).subscribe((response: any) => {

          if (response.status === 'TRUE') {
            //  this.dialog.open(CommonAlertsComponent,{
            //   data:{identifier:'editProfileUpdate'},
            //   disableClose: true,
            //  })
            this.headerparam.setfirstName(this.editProfileForm.value.FirstName);
            this.headerparam.setlastName(this.editProfileForm.value.LastName);
            localStorage.setItem('firstName', this.editProfileForm.value.FirstName);
            localStorage.setItem('lastName', this.editProfileForm.value.LastName);
            this.snackBar.open('Profile Updated Successfully', '', {
              duration: 3000,
              verticalPosition: 'bottom',
            });
            this.Backtoprofile()
          } else if (response.status == 'FALSE') {
            this.dialog.open(CommonAlertsComponent, {
              data: { identifier: 'INVALID' },
              disableClose: true,
            });
          }
        }, (error: any) => {
          console.error('Error occurred:', error);
          this.dialog.open(CommonAlertsComponent, {
            data: { identifier: 'EXCEPTION' },
            disableClose: true,
          });
        })
      } catch (error) {
        console.error('Error in HTTP request:', error);
        this.dialog.open(CommonAlertsComponent, {
          data: { identifier: 'EXCEPTION' },
          disableClose: true,
        });
      }
    }
  }

  cancel() {
   this.profilepage = false;
  }

}
