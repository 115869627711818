import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';
import { LoaderService } from 'src/app/service/loader.service';


@Component({
  selector: 'app-scorecard-details',
  templateUrl: './scorecard-details.component.html',
  styleUrls: ['./scorecard-details.component.css'],
})
export class ScorecardDetailsComponent{
  matchDetail: any;
  firstInnStats: [] = [];
  firstInnBow: any;
  firstFallOfWick: any;
  secondInnStats: any;
  secInnBow: any;
  secFallOfWick: any;
  cricketMatchSumm: any;
  Summ: any;
  didNotBatInTeamOne: any;
  didNotBatInTeamTwo: any;
  ballHit: any[]=[];
  selectedIndex = 1;
  wagonCoordinateForplayers : any;
  matchId : any;
  team1Id : any;
  team2Id : any;
  teamOneName : any;
  teamTwoName : any;
  matchdetails:any = [];
  tabs=['app-scorecard-details', 'app-scorecard-details','app-scorecard-details','app-scorecard-details','app-scorecard-details'];
  teamId:any;
  isLoading=true;
  step = 0;
  windowWidth: number = 0;
  windowHeight: number = 0;
  overCommentaryData: any = [];
  minLimit = 0;
  maxLimit = 5;
  tempTeamId: any;
  overAllScore: any;
  overAllWickets: any;
  currentOver: any;
  batsmanSumm: any = [];
  bowlerSumm: any = [];
  extraSumm: any = [];
  tenBalls: any = [];
  liveCommentaryData: any = [];
  inningsOneScore: any;
  inningsTwoScore: any;
  inningsOneWkts: any;
  inningsOneOvers: any;
  inningsTwoWkts: any;
  inningsTwoOvers: any;
  tabGroup: any;
  selectedTabIndex = 0;
  pitchMapResponse: any;
  currInn:any;
  fragmentStr: any;
  intervel: any;
  matchSummIntervel:any;
  showLoadMore: boolean=true;
  thirdInnStats: any;
  fourthInnStats: any;
  thirdfallOfWicket: any;
  fourthFallOfWick: any;
  fourthInnBow: any;
  thirdInnBow: any;
  matchDetailstatus: any;
  oversumm: any;
  matchInnings: any;
  liveScore: any=[];
  firstInnStatsDNB: any;
  secondInnStatsDNB: any;
  thirdInnStatsDNB: any;
  fourthInnStatsDNB: any;
  getLiveCommentarystatus: any;
  getCompletedCommentarystatus: any;
  orgCode:any
  matTabsLive: any = [
    'Commentary',
    'Scorecard',
    'Top Performers',
    'Stats',
    'Teams',
    'Match Info',
    'VideoHighlights']
    matTabsCompletd: any = [
      'Scorecard',
      'Commentary',
      'Top Performers',
      'Stats',
      'Teams',
      'Match Info',
      'VideoHighlights']
    selectedMainTab: string  = 'Scorecard';
  inningsData: any;
  isMobile: boolean=false;
  constructor(private scoreCardService: CricketScoreCardService, private commonService : CommonService,private route:ActivatedRoute,
    private loaderService: LoaderService,private router: Router,private location: Location,private orgFilter:OrgcodeFilterService,private breakpointObserver: BreakpointObserver) {
    this.step = 0;
  }
  selectedMobileIndex:any=0;

  ngOnInit(): void {
    this.orgFilter.storageItems.subscribe(items => {
      if (items && items.length > 0) {
        this.orgCode = items[0].orgPath;
      } else {
        console.error('No items found in storageItems');
      }
    });
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.matchId = this.route.snapshot.paramMap.get('matchId');
    this.getCricketBattingStats('All');
    this.getMatchDetail();
    this.getWindowSize();
    this.scoreCardService.wagonRun.next('All');
    this.getCricketMatchSumm();
    this.setTimer();
    this.setMatchSummTimer();

  }
  setMatchSummTimer(){
    if(this.cricketMatchSumm?.status=='P')
    {
      this.matchSummIntervel = setInterval(() => {
        if(this.cricketMatchSumm?.status=='P')
          {
        this.getCricketMatchSumm();
          }
      }, 5000);
  }
  }
  setTimer(){
    this.intervel = setInterval(() => {
      this.getMatchDetail();
    }, 5000);
  }

  ngOnDestroy(): void {
      clearInterval(this.intervel);
      clearInterval(this.matchSummIntervel);
  }


  getMatchDetail() {
    this.scoreCardService.getCricketMatchScoresAndPlayingEleven(this.matchId).subscribe((data: any) => {
      if (data.status == "SUCCESS"||"TRUE"){

      this.matchDetail = data.result.matchDetails;
      this.inningsOneScore=this.matchDetail.innings_1_score
      this.inningsOneWkts=this.matchDetail.innings_1_wkts
      this.inningsOneOvers=this.matchDetail.innings_1_overs
      this.inningsTwoScore=this.matchDetail.innings_2_score
      this.inningsTwoWkts=this.matchDetail.innings_2_wkts
      this.inningsTwoOvers=this.matchDetail.innings_2_overs
      this.teamOneName=this.matchDetail.teamOne
      this.teamTwoName=this.matchDetail.teamTwo
      this.team1Id=this.matchDetail.team_1;
      this. isLoading=false;
      this.team2Id = this.matchDetail.team_2;
                if(this.matchDetail.match_status=="C"){
                  for (let inning = 1; inning <= 4; inning++) {
                    this.getDidNotBatBatsman(inning);
                  } 
        this.getMatchCommentary(this.matchId,this.team1Id, this.minLimit, this.maxLimit);
      }else{
        setTimeout(() => {
          this.getLiveCommentary()
        }, 10);
      }
      // this.getCricketBattingStats('All');
      this.getFallOfWickets('All');
      this.getBowSumm("All");
      // this.getSecondInnStats();
      // this.getSecFallOfWickets();
      // this.getSecInnBow();
      }else{
        console.log("there is no match details.");
      }
    });
    // if (this.cricketMatchSumm?.status === 'P') {
    //   this.router.navigateByUrl(`scorecardDetails/${this.matchId}#commentary`);
    // } else {
    //   this.router.navigateByUrl(`scorecardDetails/${this.matchId}#scorecardDetails`);
    // }
    if (this.cricketMatchSumm?.status === 'P') {
      this.location.replaceState(`${this.orgCode}/scorecardDetails/${this.matchId}#commentary`);
    } else {
      this.location.replaceState(`${this.orgCode}/scorecardDetails/${this.matchId}#ScorecardDetails`);
    }
  }

  getCricketMatchSumm() {
    this.scoreCardService.getCricketMatchSumm(this.matchId).subscribe((data: any) => {
      this.cricketMatchSumm = data.result[0];
         this.cricketMatchSumm.team_1_group = [];
         this.cricketMatchSumm.team_2_group = [];
         this.cricketMatchSumm.matchInnings = [];
         this.cricketMatchSumm.leadRuns = 0;
         this.cricketMatchSumm.isLead = false;
         this.cricketMatchSumm.trailRuns = 0;
         this.cricketMatchSumm.team1Score = 0;
         this.cricketMatchSumm.team2Score = 0;
         this.cricketMatchSumm.teamScore = 0;
         this.cricketMatchSumm.currentBattingTeam = '';
         this.cricketMatchSumm.currentBowlingTeam = '';

         if(this.cricketMatchSumm.status == 'C'){
          this.ngOnDestroy();
        }


          [1, 2, 3, 4].forEach(r => {
            if (r <=this.cricketMatchSumm.curr_innings) {
             this.cricketMatchSumm.matchInnings.push(r);
              const inningsStatus = this.cricketMatchSumm[`innings_${r}_status`]?.split(' ')[0];
              if (inningsStatus ===this.cricketMatchSumm.team_1.toString()) {
               this.cricketMatchSumm.team_1_group.push(r);
              } else if (inningsStatus ===this.cricketMatchSumm.team_2.toString()) {
               this.cricketMatchSumm.team_2_group.push(r);
              } else {
                if (this.cricketMatchSumm.curr_batting_team_id ===this.cricketMatchSumm.team_1) {
                 this.cricketMatchSumm.team_1_group.push(r);
                }
                if (this.cricketMatchSumm.curr_batting_team_id ===this.cricketMatchSumm.team_2) {
                 this.cricketMatchSumm.team_2_group.push(r);
                }
              }
            }
          });

         this.cricketMatchSumm.teamScore = this.cricketMatchSumm[`innings_${this.cricketMatchSumm.curr_innings}_score`];

         this.cricketMatchSumm.team_1_group.forEach((r:any) => {
           this.cricketMatchSumm.team1Score += parseInt(this.cricketMatchSumm[`innings_${r}_score`], 10);
          });

         this.cricketMatchSumm.team_2_group.forEach((r:any) => {
           this.cricketMatchSumm.team2Score += parseInt(this.cricketMatchSumm[`innings_${r}_score`], 10);
          });
          if (this.cricketMatchSumm.curr_batting_team_id ===this.cricketMatchSumm.Team1) {
           this.cricketMatchSumm.currentBattingTeam =this.cricketMatchSumm.teamOne;
           this.cricketMatchSumm.currentBowlingTeam =this.cricketMatchSumm.teamTwo;
            if (parseInt(this.cricketMatchSumm.team1Score.toString(), 10) < parseInt(this.cricketMatchSumm.team2Score.toString(), 10)) {
             this.cricketMatchSumm.isLead = false;
             this.cricketMatchSumm.trailRuns = parseInt(this.cricketMatchSumm.team2Score.toString(), 10) - parseInt(this.cricketMatchSumm.team1Score.toString(), 10);
            } else {
             this.cricketMatchSumm.isLead = true;
             this.cricketMatchSumm.leadRuns = parseInt(this.cricketMatchSumm.team1Score.toString(), 10) - parseInt(this.cricketMatchSumm.team2Score.toString(), 10);
            }
          }

          if (this.cricketMatchSumm.curr_batting_team_id ===this.cricketMatchSumm.Team2) {
           this.cricketMatchSumm.currentBattingTeam =this.cricketMatchSumm.teamTwo;
           this.cricketMatchSumm.currentBowlingTeam =this.cricketMatchSumm.teamOne;
            if (parseInt(this.cricketMatchSumm.team2Score.toString(), 10) < parseInt(this.cricketMatchSumm.team1Score.toString(), 10)) {
             this.cricketMatchSumm.isLead = false;
             this.cricketMatchSumm.trailRuns = parseInt(this.cricketMatchSumm.team1Score.toString(), 10) - parseInt(this.cricketMatchSumm.team2Score.toString(), 10);
            } else {
             this.cricketMatchSumm.isLead = true;
             this.cricketMatchSumm.leadRuns = parseInt(this.cricketMatchSumm.team2Score.toString(), 10) - parseInt(this.cricketMatchSumm.team1Score.toString(), 10);
            }
          }
          this.cricketMatchSumm.thirdInningsBattingTeam = this.cricketMatchSumm.curr_batting_team_id ===this.cricketMatchSumm.Team1 ? this.cricketMatchSumm.teamOne : this.cricketMatchSumm.teamTwo;
          this.cricketMatchSumm.fourthInningsBattingTeam = this.cricketMatchSumm.curr_batting_team_id ===this.cricketMatchSumm.Team1 ? this.cricketMatchSumm.teamOne : this.cricketMatchSumm.teamTwo;

      this.cricketMatchSumm.match_date=moment(data.result[0].match_date).format('LL')
    });

  }

  getCricketBattingStats(innings: any) {
    this.scoreCardService.getCricketMatchStats(this.matchId, innings).subscribe((data: any) => {
      const battingStats = data.result;

      if (battingStats.innings1) {
        this.firstInnStats = battingStats.innings1.filter((data: any) => data.how_out.toLowerCase() !== 'did not bat');
        this.firstInnStatsDNB = battingStats.innings1.filter((data: any) => data.how_out.toLowerCase() == 'did not bat');
        this.matchInnings=1;
      }
      if (battingStats.innings2) {
        this.secondInnStats = battingStats.innings2.filter((data: any) => data.how_out.toLowerCase() !== 'did not bat');
        this.secondInnStatsDNB = battingStats.innings2.filter((data: any) => data.how_out.toLowerCase() == 'did not bat');
        this.matchInnings=2;
      }
      if (battingStats.innings3) {

        this.thirdInnStats = battingStats.innings3.filter((data: any) => data.how_out.toLowerCase() !== 'did not bat');
        this.thirdInnStatsDNB = battingStats.innings3.filter((data: any) => data.how_out.toLowerCase() == 'did not bat');
        this.matchInnings=3;
      }
      if (battingStats.innings4) {
        this.fourthInnStats = battingStats.innings4.filter((data: any) => data.how_out.toLowerCase() !== 'did not bat');
        this.fourthInnStatsDNB = battingStats.innings4.filter((data: any) => data.how_out.toLowerCase() == 'did not bat');
        this.matchInnings=4;
      }
    });
  }


  getBowSumm(innings:any) {
    this.scoreCardService.getFirstBowling(this.matchId,innings).subscribe((data: any) => {
      const bowlingSumm = data.result;
      if(bowlingSumm.innings1){
        this.firstInnBow = bowlingSumm.innings1;
        this.firstInnBow.forEach((data: any) => {
          data.over = data.overs.split('.')[0];
          data.overBalls = data.overs.split('.')[1] || '0';
        });
      }
      if(bowlingSumm.innings2){
        this.secInnBow = bowlingSumm.innings2;
        this.secInnBow.forEach((data: any) => {
          data.over = data.overs.split('.')[0];
          data.overBalls = data.overs.split('.')[1] || '0';

        });
      }
      if(bowlingSumm.innings3){
        this.thirdInnBow = bowlingSumm.innings3;
        this.thirdInnBow.forEach((data: any) => {
          data.over = data.overs.split('.')[0];
          data.overBalls = data.overs.split('.')[1] || '0';
        });
      }
      if(bowlingSumm.innings4){
        this.fourthInnBow = bowlingSumm.innings4;
        this.fourthInnBow.forEach((data: any) => {
          data.over = data.overs.split('.')[0];
          data.overBalls = data.overs.split('.')[1] || '0';

        });
      }

    });

  }

  getFallOfWickets(innings:any) {
    this.scoreCardService.getFallOfWickets(this.matchId,innings).subscribe((data: any) => {
      if(data.status == "TRUE"){
        const fallOfWicket = data.result;
        if(fallOfWicket.innings1){
          this.firstFallOfWick = data.result.innings1;
          console.log(data.result.innings4,'data.result.innings4');
          
        }
        if(fallOfWicket.innings2){
          this.secFallOfWick = data.result.innings2;
        }
        if(fallOfWicket.innings3){
          this.thirdfallOfWicket = data.result.innings3;
        }
        if(fallOfWicket.innings4){
          this.fourthFallOfWick = data.result.innings4;
          console.log(data.result.innings4,'data.result.innings4');
          
        }
      }


    });
  }

  getSecondInnStats() {
    this.scoreCardService.getSecondInnings_stas(this.matchId).subscribe((data: any) => {
      let secondInn = data.result;
      this.secondInnStats = secondInn.filter((data: any) => data.how_out.toLowerCase() !== 'did not bat');
    });
  }

  getSecFallOfWickets() {
    this.scoreCardService.getSecondFallOfWickets(this.matchId).subscribe((data: any) => {
      this.secFallOfWick = data.result;

    });
  }

  getSecInnBow() {
    this.scoreCardService.getSecondBowlingStas(this.matchId).subscribe((data: any) => {
      this.secInnBow = data.result;
      this.secInnBow.forEach((data: any) => {
        data.over = data.overs.split('.')[0];
        data.overBalls = data.overs.split('.')[1] || '0';

      });
    });

  }
  // getDidNotBatBatsman() {
  //   this.scoreCardService.getDidNotBatBatsman(this.matchId,this.team1Id,this.team2Id).subscribe((data: any) => {
  //     if(data.status=='TRUE'){
  //     let didNotBatBatsman = data.result;
  //     this.didNotBatInTeamOne = didNotBatBatsman.teamOneDNB;
  //     this.didNotBatInTeamTwo = didNotBatBatsman.teamTwoDNB;

  //     console.log("kkkkkkkkkkkkkk", didNotBatBatsman);
      
  //   }
  //   });
  // }


  getDidNotBatBatsman(inning: number) {
    this.scoreCardService.getDidNotBatBatsman(this.matchId, this.team1Id, this.team2Id, inning).subscribe((data: any) => {
      if (data.status === 'TRUE') {
        const didNotBatBatsman = data.result;

        // Initialize inning data if not already present
        if (!this.inningsData[inning]) {
          this.inningsData[inning] = { teamOneDNB: [], teamTwoDNB: [] };
        }

        // Update Team One and Team Two data for this inning
        this.inningsData[inning].teamOneDNB = this.removeDuplicates(
          [...this.inningsData[inning].teamOneDNB, ...(didNotBatBatsman.teamOneDNB || [])],
          'batsman_user_id'
        );

        this.inningsData[inning].teamTwoDNB = this.removeDuplicates(
          [...this.inningsData[inning].teamTwoDNB, ...(didNotBatBatsman.teamTwoDNB || [])],
          'batsman_user_id'
        );
      }
    });
  }

  // Utility function to remove duplicates based on a specific key
  removeDuplicates(array: any[], key: string): any[] {
    const seen = new Set();
    return array.filter(item => {
      const uniqueKey = item[key];
      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);
        return true;
      }
      return false;
    });
  }

  
  

//   getDidNotBatBatsman() {
//     this.scoreCardService.getDidNotBatBatsman(this.matchId, this.team1Id, this.team2Id).subscribe((data: any) => {
//         if (data.status === 'TRUE') {
//             let didNotBatBatsman = data.result;


//             this.didNotBatInTeamOne = this.getUniquePlayers(didNotBatBatsman.teamOneDNB);

//             this.didNotBatInTeamTwo = this.getUniquePlayers(didNotBatBatsman.teamTwoDNB);

//             console.log("Filtered Team One DNB:", this.didNotBatInTeamOne);
//             console.log("Filtered Team Two DNB:", this.didNotBatInTeamTwo);
//         }
//     });
// }
// getUniquePlayers(players: any[]) {
//     const uniquePlayers = players.filter((player, index, self) =>
//         index === self.findIndex(p => p.batsman_user_id === player.batsman_user_id)
//     );
//     return uniquePlayers;
// }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  getWindowSize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }
  getMatchCommentary(matchId: any, teamId: any, minLimit: any, maxLimit: any) {
    this.matchDetailstatus = 'C';
    this.scoreCardService
      .getCommentaryDetails(matchId, teamId, minLimit, maxLimit)
      .subscribe((response: any) => {
         if(response.status=="TRUE"){
          this.getCompletedCommentarystatus=response.status;
          this.overCommentaryData = this.minLimit === 0 ? response.result?.overCommentaryDetails : [...this.overCommentaryData, ...response.result?.overCommentaryDetails];
          this.showLoadMore = response.result?.overCommentaryDetails.length >= (maxLimit - minLimit);          this.showLoadMore=true
          this.loaderService.hide();
         }else{
          this.showLoadMore=false
         }
      });
  }

  getMoreResultCommentary(event: any, name: any) {
    this.maxLimit = this.maxLimit + event;
    this.getMatchCommentary(this.matchId,this.toGetTempTeamId(name), this.minLimit, this.maxLimit);
  }

  incrementOverNo(overNo: any) {
    return parseInt(overNo) + 1;
  }

  toGetTempTeamId(name: any) {
    if (name == this.teamOneName) {
      this.tempTeamId = this.team1Id;
    } else {
      this.tempTeamId = this.team2Id;
    }
    return this.tempTeamId;
  }

  onChange(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.overCommentaryData = [];
    this.minLimit = 0;
    this.maxLimit = 5;

    this.getMatchCommentary(this.matchId,this.toGetTempTeamId(tab), this.minLimit, this.maxLimit);
  }

  getLiveCommentary() {
    this.matchDetailstatus = 'P';
    this.scoreCardService.getLastTenBalls(this.matchId,this.matchInnings , this.minLimit, this.maxLimit)
      .subscribe((response: any) => {
        if (response.status == "SUCCESS"||"TRUE") {
        this.getLiveCommentarystatus=response.status
        this.batsmanSumm = response.result.batsmanSumm;
        this.bowlerSumm = response.result.bowlerSumm;
        this.extraSumm = response.result.extrasSumm;
        this.tenBalls = response.result.recentBalls;
        this.liveScore=response.result.matchDetails;
        // response.result.overDetails.forEach((item:any) => {
        //   this.liveCommentaryData.push(item);
        // });
        this.liveCommentaryData=response.result.overDetails;
      }
    })
  }

  getMoreLiveCommentary(event : any) {
    this.maxLimit = this.maxLimit + event;
    this.getLiveCommentary();
  }


tabLabels: string[] = [
  'ScorecardDetails',
  'Commentary',
  'TopPerformer',
  'Stats',
  'Teams',
  'MatchInfo',
  'VideoHighlights',
];
tabLabels2: string[] = [
  'Commentary',
  'ScorecardDetails',
  'TopPerformer',
  'Stats',
  'Teams',
  'MatchInfo',
  'VideoHighlights',
];

tabChanged(index: number) {
  clearInterval(this.intervel);
  if(index==0 || index==1){
    this.getMatchDetail();
    this.setTimer();
    this.setMatchSummTimer();
    this.getCricketMatchSumm();
  }
  const selectedTabLiveMatch = this.tabLabels[index];
  const selectedTabCompletedMatch=this.tabLabels2[index];
  if (!selectedTabLiveMatch || !selectedTabCompletedMatch ) {
    console.error('Invalid tab index:', index);
    return;
  }
  if(this.cricketMatchSumm?.status === 'P'){
  //   this.router.navigateByUrl(`scorecardDetails/${this.matchId}#${selectedTabCompletedMatch}`);
  // }else{
  //   this.router.navigateByUrl(`scorecardDetails/${this.matchId}#${selectedTabLiveMatch}`);
  // }
    this.location.replaceState(`${this.orgCode}/scorecardDetails/${this.matchId}#${selectedTabCompletedMatch}`);
  } else {
    this.location.replaceState(`${this.orgCode}/scorecardDetails/${this.matchId}#${selectedTabLiveMatch}`);
  }
  this.selectedMobileIndex = index;
}

formatOversAndBalls(overs: number): string {
  const totalBalls = Math.round(overs * 10);
  const oversPart = Math.floor(totalBalls / 10);
  const ballsPart = totalBalls % 10;
  const totalOvers = oversPart + Math.floor(ballsPart / 6);
  const remainingBalls = ballsPart % 6;
  return `${totalOvers}.${remainingBalls}`;
}


// selectTab(tab: string): void {
//   this.selectedMainTab = tab;
// }
}
