export const ApiEndpoint = {
    VIDEOS : {
        GET_TOP_VIDEO_BY_ID :'/getTopVideoListDel/vedioCateID',
        GET_ALL_VIDEOS_BY_CATEGORY:'/getVedioByCategory/vedioCateID',
        GET_ALL_CATEGORY:'/getVedioCategories',
        GET_BY_VIDEO_ID:'/getVedioByVedioID?vedioID={videoID}&catIDid={catID}'
    },
    CRICKET_SCORE : {
        GET_ALL_COMPLETED_MATCH : '/allCompletedMatch?limit={limit}',
        GET_ALL_CURRENT_MATCH : '/currentLiveMatch?limit={limit}',
        GET_CRICKET_MATCH_DETAILS_AND_TEAM_DTLS : '/getCricketMatchDetailsAndTeamDtls?matchId=&eventId=',
        GET_CRICKET_PARTENERSHIP_ANALYSTISCS :'/cricketPartnershipAnalytics?MATCHID={matchId}&INNINGS={innings}',

    },
    NEWS:{
        GET_ALL_TOP_VIDEOlIST_BY_VIDEOCATE_ID: '/getTopVideoListDel/vedioCateID'
    },
    UPCOMING_MATCH : {
        GET_ALL_UPCOMING_MATCH : '/allUpcomingMatch?limit={limit}',
        GET_TENNIS_UPCOMING_MATCH : '/getTennisMatchList?match_id=&match_status={status}&limit={limit}&user_id=',
        GET_BADMINTON_UPCOMING_MATCH :'/getBadmintonMatchList?match_id=&match_status={status}&limit={limit}&user_id=',
        GET_TABLE_TENNIS_UPCOMING_MATCH :'/getTableTennisMatchList?match_id=&match_status={status}&limit={limit}&user_id=',
    },
    PLAYER_PROFILE : {
        GET_CRICKET_BOWLING_ACHIEVED : '/cricketBowlingAcheived?USER_ID={userId}',
        EVENTS_HAPPENING : '/EventsHappening',
        GET_LIVE_STREAMING_URL : '/getLiveStreamingUrl',
        GET_USER_ACADEMY : '/getUserAcademy?USER_ID={userId}&USER_TYPE=%20',
        GET_USER_DETAILS : '/getUserdetails?SEARCHSTR={searchString}',
        GET_USER_VIDEO_LINKS : '/getUserVideoLinks/{userId}',
        NEWS_FOR_ANONYMOUS_USER : '/newsForAnonymousUser',
        USER_HOME_INFORMATION : '/userHomeInformation?USER_ID={userId}',
        GET_PLAYER_PERSONAL_DETAILS:'/playerPersonalDetails?USER_ID={userId}&sport_id={sportsId}',
        DISPLAY_SPORTS_TEAM: '/displaySportTeam?USER_ID={userId}&SPORT_ID={sportsId}',
        GET_CRICKET_USER_LAST_RESUTLS:'/cricketUserLastResults?USER_ID={userId}',
        GET_USER_LEADERBOARD:'/userLeaderBoard?USER_ID={userId}',
        GET_USER_BATTING_RECORD:'/userBattingRecord?USER_ID={userId}&YEAR={year}',
        GET_USER_BOWLING_RECORD: '/userBowlingRecord?USER_ID={userId}&YEAR={year}',
        GET_USER_FIELDING_RECORD:'/userFieldingRecord?USER_ID={userId}&YEAR={year}',
        GET_CRICKET_BATSMAN_USER_GRAPH: '/getCricketBatsmanUserGraph?BATSMAN_USER_ID={userId}&LIMIT={limit}&SUBCATNAME={subCatName}&YEAR={year}',
        GET_CRICKET_BOWLING_USER_GRAPH: '/getCricketBowlingUserGraph/{userId}/{limit}/{subCatName}/{year}',
        INNINGS_BATTING_SUM:'/inningsBattingSumm/{matchId}/{innings}',

        GET_TREND_GRAPH_BATTING_DETAILS:'/getTrendGraphBattingDatails?BATSMAN_USER_ID={userId}&LIMIT={limit}&SUBCATNAME={subCatName}&YEAR={year}&AGGREGATION={aggregationType}',
        GET_TREND_GRAPH_BOWLING_DETAILS:'/getTrendGraphBowlingDatails?BATSMAN_USER_ID={userId}&LIMIT={limit}&SUBCATNAME={subCatName}&YEAR={year}&AGGREGATION={aggregationType}',

        GET_CRICKET_BATTING_ACHEIVED:'/cricketBattingAcheived?USER_ID={userId}',
        GET_USER_NOTES:'/getUserNotes?USER_ID={userId}&LIMIT={limit}',
        GET_INSTITUTE_ID:'/getinstituteId&USER_ID={userId}',
        GET_CURRENT_LIVE_MATCH:'/currentLiveMatch?USER_ID={userId}',
        GET_ACHIEVEMENT_NOTES:'/getAchievementNotes?USER_ID={userId}&LIMIT={limit}',
        USER_HOMECHILD :'/userHomeChild/{parentUserId}',
        ALL_COMPLETED_MATCH:'/allCompletedMatch?USER_ID={userId}&limit={limit}',
        GET_ALL_UPCOMING_MATCH:'/allUpcomingMatch?USER_ID={userId}&limit={limit}',
        GET_USER_POSTS:'/getUserPosts?USER_ID={userId}',
        BOWLER_INNINGS_SUMM:'/bowlerInningsSumm?MATCH_ID={matchId}&USER_ID={userId}'

    },
    FORGOT_PASSWORD : {
        CHECK_MEMBER_TEAM:'/checkMemberTeam?EMAIL={email}',
        CHECK_EMAIL:'/checkEmail?EMAIL={email}',
        GET_OLD_PASSWORD:'/getOldPassword?USER_ID={userId}&PASS={passwd}',
        GET_ENCP:'/getEncp?password={passwd}&email={email}',
        UPDATE_PASSWORD:'/updateUserMaster',
    },
    USER : {
        LOGIN:'/login',
        CHECK_USER_EXIST :'/checkIfUserNameExist?user_name=',
        CHECK_USER_EMAIL_EXIST : '/checkIfEmailIdExist?email=',
        CHECK_MOBILE_NO_EXIST : '/checkIfMobileNoExist?mobile_no='
    },
    SIGN_UP : {
        // REGISTER_USER : '/registerUser?EMAIL_ID={email}&mobile_no={mobileNo}&PASSWORD={password}&NAME={name}',
        REGISTER_USER: '/registerUser',
        GET_USER_ID: '/getUserid?EMAIL={emailId}'
    },
    GRAPH:{
        GET_CRICKET_GRAPH : '/getCricketGraph?MATCH_ID={matchID}'
    },
    STATS :{
        GET_CRICKET_STATS : '/cricketMatchStats?MATCH_ID={matchID}'
    },
    FULL_SCORE_CARD : {
        GET_CRICKET_MATCH_SUMM: '/cricketMatchSumm?MATCH_ID={matchId}',
        GET_CRICKET_MATCH_STATS: '/cricketMatchStats/{matchId}/{innings}',
        GET_FIRST_FALL_OF_WICKETS: '/cricketFallWickets/{matchId}/{innings}',
        GET_BOWLER_STAS: '/cricketBowlerSumm/{matchId}/{innings}',
        GET_SECOND_INNINGS_STAS: '/cricketSecondInningsStats?MATCH_ID={matchId}',
        GET_SECONS_FALL_OF_WICKETS: '/cricketSecondFallWickets?MATCH_ID={matchId}',
        GET_BOWLING_STATS_TWO: '/cricketBowlerSumm/{matchId}/{innings}',
        GET_DID_NOT_BAT_BATSMAN: '/getDidNotBatBatsman?MATCHID={matchId}&TEAM1ID={team1Id}&TEAM2ID={team2Id}',
        GET_TEAMNAMES_FOR_MULTIDAY:'/getTeamNamesForMultiDay?MATCH_ID={matchId}',
        GET_CRICKET_PLAYER_ANALYTICS:'/cricketPlayerAnalytics?MATCH_ID={matchId}&BATSMAN_ID={batsmanId}&INNINGS={innings}',
        GET_BOUNDARYDETAILS:'/getBoundaryDetails?MATCHID={matchId}&TEAMID={teamId}&INNINGS={innings}',
    },
    WAGONWHEEL : {
        GET_WAH_ON_WHEEL: '/getWagonCoordinates?MATCH_ID={matchId}&INNINGS={innings}',
        GET_PLAYER_WAGON_COORDINATE: '/getWagonCoordinatesForPlayer?MATCH_ID=&BATSMAN_ID=',
        GET_BOWLER_WAGON_COORDINATE:'/getWagonCoordinatesForBowler?MATCH_ID=&bolwerId='
    },
    COMMENTARY : {
        GET_COMMMENTARY_DETAILS: '/getCommentaryForCompletedMatches?MATCHID={matchId}&TEAMID={teamID}&MINLIMIT={minLimit}&MAXLIMIT={maxLimit}',
        GET_LAST_TEN_BALLS_AND_COMMENTARY: '/getLastTenBallsAndCommentry?MATCHID={matchId}&INNINGS={innings}&MINLIMIT={minLimit}&MAXLIMIT={maxLimit}'
    },
    MATCH_INFO : {
        GET_CRICKET_PLAYING_ELEVEN: '/getCricketPlayingEleven?MATCH_ID={matchId}&TEAM_ID={teamId}',
        GET_CRICKET_MATCH_SCORES_AND_PLAYING_ELEVEN: '/getCricketMatchScoresAndPlayingEleven?MATCHID={matchId}',
        GET_VENUE_STATUS: '/getVenueStats?VENUE={venue}'
    },
    BADMINTON_MATCH_STAUTUS : {
        GET_BADMINTON_MATCH_LIST:'/getBadmintonMatchList?limit={limit}&match_id={matchId}&match_status={matchStatus}&sport_id={sportId}&user_id={userId}',
        GET_BADMINTON_PLAYER_SCORE_DETAIL_FOR_MATCH_FOREHAND:'/getBadmintonPlayerScoreDetailForMatchForehand?limit={limit}&match_id={matchId}&match_status={matchStatus}&sport_id={sportId}&user_id={userId}'
    },
    BADMINTON_SCORE_CARD:{
        GET_BADMINTON_SCORE_CARD:'/getBadmintonMatchList?match_id=&match_status=C&limit={limit}&user_id=',
        GET_PLAYER_PROFILE:'/playerPersonalDetails?USER_ID=2156&sport_id=6',
        GET_PLAYER_WIN_AND_LOSE_TRACK:'/getBadmintonPerformanceBreakDownForUserSingles?user_id=2156',
        GET_TEAM_DETAIL:'/getBadmintonMyTeamDetail?aggregation_type={AggType}&sport_id=3&user_id=4513'
    },
    TENNIS_SCORE_CARD :{
        GET_TENNIS_MATCH_LIST:'/getTennisMatchList?limit=&match_id=6&match_status=&sport_id=6&user_id=',
        GET_TENNIS_PLAYER_SCOREDETAIL_FOR_MATCH_FOREHAND:'/getTennisPlayerScoreDetailForMatchForehand?limit=&match_id=6&match_status=&sport_id=6&user_id='
    },
    DYNAMIC_CONTENTS:{
      GET_DYNAMIC_DATA:'/getDynamicContent',
      GET_HOME_DYNAMIC_CONTENT:'/getHomeDynamicContent'
    },
    TOP_PERFORMERS:{
       INNINGS_BATTING_SUM:'/inningsBattingSumm/{matchId}/{innings}',
       INNINGS_BOWLING_SUMM:'/inningsBowlingSumm/{matchId}/{innings}'
    },
    TOURNAMENTS:{
        EVENTS_HAPPENING:'/EventsHappening',
        GET_USER_EVENTS:'/getUserEvents?SEARCHSTR={SEARCHSTR}',
        GET_CURRENT_EVENT_LIVE_MATCHES:'/currentEventLiveMatch?EVENT_ID={eventId}',
        GET_POINTSTABLE:'/pointsTable?EVENT_ID={eventId}',
        GET_EVENT_SUBCAT:'/getEventSubCat?EVENT_ID={eventId}&SPORT_ID={sportId}',
        GET_LEADER_UI:'/getLeaderUI?TYPE={type}&SPORT_ID={sportId}',
        GET_LEADER_TAB_UI:'/getLeaderTabUI?TAB_ID={tabId}',
        GET_BATTING_AGGREGATING:'/getBattingAggregate?TEAM_ID={teamId}&TYPE={type}',
        GET_FACTSHEET:'/getFactSheet?eventId={eventId}',
        GET_ALL_RESULTS:'/getAllResult/{eventId}/{status}',
        GET_ALL_EVENT_FXITURES:'/getAllEventFxitures?EVENT_ID={eventId}&STATUS={status}',
        GET_ALL_FACTSHEET:'/getAllFactsheet',
    },
    OTP_VERIFICATION: {
        GET_VERFICATION_CODE: '/getverificationCode?USER_ID={userId}',
        UPDATE_LOGIN_TYPE: '/updateLoginType/{emailId}'
      },
      EDIT_PROFILE: {
        GET_USER_DETAILS: '/getUserdetails',
        UPDATE_USER_MASTER:'/updateUserMaster',
        UPDATE_PROFILE_PIC :'/updateProfilePic'
      },
      CREATE_EVENT:{

        GET_CITY:'/getEventCityList'
      },
      MYTEAMS:{
        GET_PLAYER_STATS:'/getPlayerSummary/{playerId}/{year}',
      },
      LOGIN : {
        GET_OLD_PASSWORD:"getOldPassword",
        GET_ENCP:"getEncp",
        UPDATE_PASSWORD: '/updateUserMaster'
      },
      CRICKET_SUMMERY: {
        DISPLAY_TEAM_NAME: '/displayTeamName/{teamId}',
        ALL_TEAM_RECENT:'/allTeamRecent/{teamId}',
        CRICKET_TEAM_UPCOMING_MATCHES:'/cricketTeamUpcomingMatches/{teamId}',
        TEAM_LEADER_BOARD:'/teamLeaderBoard/{teamId}',
        EVENT_LEADER_BOARD:'/eventLeaderBoard/{eventId}',
        CRICKET_TEAM_USER_RECORD:'/cricketTeamUserRecord/{teamId}',
        TEAM_EVENT_LEADER_BOARD:'/teamEventLeaderboard/{teamId}'
      },
      COMMON_SEARCH: {
        GET_SEARCH_RESULT: '/getSearchResult?searchString={searchString}&academiesFilter={academiesFilter}&communityFilter=c&eventFilter={eventFilter}&peopleFilter={peopleFilter}&userId={userId}&teamFilter={teamFilter}&sportFilter={sportFilter}'
      },
      ORGANIZATION : {
        GET_ALL_ORGANIZATION_DETAIL :'/getAllOrganizationDetail',
    }

}
