<div class="container-fluid m-auto mb-5 px-1">
    <div class="card my-2  shadow bg-body-tertiary" >
        <mat-card-title style="border-bottom: 2px solid #b3afaf83;">
            <div class="row justify-content-around ">
                <div class="col-5 d-flex justify-content-center align-items-center px-0 " (click)="innings1()"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team1Active" style="border: none;">{{teams?.teamOne}}</span></div>
                <div class="col-5 d-flex justify-content-center align-items-center px-0" (click)="innings2()"><span class="fw-bold col-5 btn team-name rounded-5 py-2 w-100" [class.active]="team2Active"   style="border: none;">{{teams?.teamTwo}}</span></div>
            </div>
        </mat-card-title>
        <div class="header2 col-4 col-lg-3 mt-2" *ngIf="!battsmanplayed">
            <div class="Batsman  m-1 p-2 text-center w-100">
                <div><span>BATSMAN</span></div>
            </div>
        </div>
        <div class="performer row" *ngIf="!battsmanplayed">
            <div class="container-fluid d-flex gap-3 justify-content-around py-2 card-container"
                style="flex-wrap: wrap;">
                <div *ngFor="let data of inningsBatsmanSumm" style="min-height:100px;">
                    <div class="scorecard p-2">
                        <div class="logo-container">
                            <img src="/assets/BatsManImg.png" alt="" class="player-logo">
                        </div>
                        <div class="row p-2 batsman"> <b class="d-flex justify-content-center">{{data.batsman_name}}</b></div>
                        <!-- <div class="row-12 runs p-2"
                            style="display: flex; justify-content: center; color: rgb(14, 14, 132);"><b>RUNS
                                {{data.runs}} ({{data.balls_faced}})</b>
                        </div>
                        <div class="row p-2">
                            <div class="col-6 score p-2">
                                <div><b>4'S {{data.fours}}</b></div>
                            </div>
                            <div class="col-6 score p-2">
                                <div> <b>6'S {{data.sixers}}</b></div>
                            </div>
                        </div> -->
                        <div class="col-12 justify-content-center d-flex mt-3 fw-semibold" style="color: white;"><span  >{{data.runs}}</span>&nbsp;-&nbsp;<span>{{data.balls_faced}}</span>&nbsp;-&nbsp;<span> {{data.fours}}</span>&nbsp;-&nbsp;<span>{{data.sixers}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header2 col-4 col-lg-3" *ngIf="!battsmanplayed">
            <div class="Batsman m-1 p-2 text-center w-100">
                <div>BOWLER</div>
            </div>
        </div>
        <div class="performer row mb-4" *ngIf="!battsmanplayed">
            <div class="container-fluid d-flex gap-3 justify-content-around py-2 card-container"
                style="flex-wrap: wrap;">
                <div *ngFor="let data of inningsBowlingSumm" style="min-height:100px;">
                    <div class="scorecard scorecardTwoHeigth p-2">
                        <div class="logo-container">
                            <img src="/assets/BatsManImg.png" alt="" class="player-logo">
                        </div>
                        <div class="row p-2 batsman text-center"> <b>{{data.bowler_name}}</b></div>
                        <!-- <div class="row p-2">
                            <div class="col-6 score p-2">
                                <div><b>OVERS ({{data.overs}})</b></div>
                            </div>
                            <div class="col-6 score p-2">
                                <div> <b>RUNS ({{data.runs}})</b></div>
                            </div>
                        </div>
                        <div class="row p-2">
                            <div class="col-6 score p-2">
                                <div><b>MAIDEN ({{data.maidens}})</b></div>
                            </div>
                            <div class="col-6 score p-2">
                                <div> <b>WICKETS ({{data.wickets}})</b></div>
                            </div>
                        </div> -->
                        <div class="col-12 justify-content-center d-flex mt-3 fw-semibold" style="color: white;"><span>{{data.overs}}</span>&nbsp;-&nbsp;<span> {{data.runs}}</span>&nbsp;-&nbsp;<span>{{data.maidens}}</span>&nbsp;-&nbsp;<span>{{data.wickets}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="battsmanplayed " class="d-flex justify-content-center">
            <span class="p-3 text-center not-found d-flex align-items-center" style="height: 20vh;">No records found</span>
        </div >
    </div>

</div>
