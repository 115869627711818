
<div class="bg heightFix bgcl-lightblue">
    <div class="container">
        <div class="row bgcl-lightblue">
            <div class="col-lg-9 col-sm-12 px-0">
                <!-- <h4 class="heading mx-3 my-3 fs-4" >TOP PERFORMERS</h4> -->
                <!-- <app-top-performers-card ></app-top-performers-card> -->
                <ng-container *ngTemplateOutlet="topPerformer_card"></ng-container>
                <h4 class="heading mx-3 my-3 fs-4">RESULT</h4>
                <app-live-score-card-carousal  [completeList]="cricketComplete"></app-live-score-card-carousal>
                    <!-- <app-score-card-carousel [cricketLive]="cricketLive" [tennisLive]="tennisLive" [badmintonLive]="badmintonLive"
                        [tableTennisLive]="tableTennisLive"  [cricketComplete]="cricketComplete" [badmintonComplete]="badmintonComplete"
                          [tableTennisComplete]="tableTennisComplete" [tennisComplete]="tennisComplete"></app-score-card-carousel> -->
                          <span *ngIf="cricketComplete.length>2" class="d-flex justify-content-end m-2 me-4 more" (click)="navigateToMatches('RESULT')">MORE <i class="bi bi-chevron-right"></i></span>
                <div class="d-block d-lg-none d-md-block">
                    <h4 class="heading m-0 m-3 fs-4 text-nowrap">UPCOMING</h4>
                    <app-upcoming-match></app-upcoming-match>
                </div>
                <div [ngClass]="{'d-none position-absolute': videoArrey.length == 0}" *ngIf="orgCode==='SR'">
                  <div *ngIf="videoArrey.length>0">
                <h4 class="heading mx-3 my-2 fs-4" *ngIf="videoArrey">PRACTICE</h4>
                <ng-container *ngTemplateOutlet="verticalArticalVideo"></ng-container>
                <span *ngIf="videoArrey[0].length>2" class="d-flex justify-content-end m-2 me-4 more" (click)="navigateToVideos('/videos','practice')">MORE <i class="bi bi-chevron-right"></i></span>
                <h4 class="heading mx-3 my-2 fs-4" *ngIf="videoArrey[1]">NEWS</h4>
                <ng-container *ngTemplateOutlet="HorizontalArticalvideo"></ng-container>
                <span *ngIf="videoArrey[1].length>3" class="d-flex justify-content-end m-2 me-4 more" (click)="navigateToVideos('/videos','news')">MORE <i class="bi bi-chevron-right"></i></span>
                <h4 class="heading mx-3 my-2 fs-4" *ngIf="videoArrey[2]">INTERVIEW</h4>
                <ng-container *ngTemplateOutlet="homeCaurosalInterviewVideo"></ng-container>
                </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-12 px-0">
                <div class="d-none d-lg-block">
                <h4 class="heading m-0 m-3 fs-4 text-nowrap">UPCOMING MATCHES</h4>
                <app-upcoming-match></app-upcoming-match>
                </div>
                <ng-container *ngIf="orgCode==='SR'">
                    <h4 class="heading m-0 m-3 fs-4" *ngIf="videoList.length>0">POPULAR NEWS</h4>
                <ng-container *ngTemplateOutlet="recentVideos"></ng-container>
                <span *ngIf="videoList.length>5" class="d-flex justify-content-end m-2 me-4 more" (click)="navigateToVideos('/videos','')">MORE <i class="bi bi-chevron-right"></i></span>
                </ng-container>
                
            </div>
            <div class="col-lg-9 col-sm-12 px-0">
                <ng-container *ngTemplateOutlet="homeBottomContent"></ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #verticalArticalVideo>
    <div class="container bgcl-lightblue" *ngIf="videoArrey">
        <div class="card overflow-hidden" *ngFor="let data of videoArrey[0]?.slice(-2)">
          <div class="d-flex flex-wrap" (click)="getVideoDetail(data.video_id,data.vedio_category_id)">
            <div class="col-lg-5 col-12 d-flex justify-content-center">
              <img class="img-fluid" [src]="data.video_thumbnail_image" onerror="this.src='/assets/images/World.webp';" alt="Card image cap">
              <!-- <img class="card-img" src="../../../assets/img/live_image.jpg"> -->
            </div>
            <div class="card-body col-lg-7 col-12 d-flex flex-column justify-content-evenly">
              <p class="card-text title">{{data.video_title}}</p>
              <p class="card-text desc lh-base" style="padding-bottom: 1px;">{{data.video_desc}}</p>
              <p class="dateFormat date text-end pe-2">{{data.created_date_time}}</p>
            </div>
          </div>
        </div>
      </div>
</ng-template>
<ng-template #HorizontalArticalvideo>
    <div class="container bgcl-lightblue col-sm-12">
        <div class="row" *ngIf="videoArrey[1]">
          <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let data of videoArrey[1]?.slice(-3)">
            <div class="card aticalTwoCard overflow-hidden rounded-4 border-0 my-2 d-flex justify-content-center" style="display: flex; justify-content: center; min-height: 395px !important;"  (click)="getVideoDetail(data.video_id,data.vedio_category_id)">
              <img class="card-img-top-news  img-fluid " [src]="data.video_thumbnail_image" onerror="this.src='/assets/images/World.webp';"  alt="Card image cap">
              <!-- <img class="card-img" src="../../../assets/img/live_image.jpg"> -->
              <div class="card-body">
                <p class="card-text titleArticalTwo">{{data.video_title}}</p>
                <p class="card-text-desc videoarticaltwo lh-base">{{data.video_desc}}</p>
                <p class="dateFormat dateformat-css text-end">{{data.created_date_time}}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
</ng-template>
<ng-template #homeCaurosalInterviewVideo>
    <div class="container bgcl-lightblue" *ngIf="videoArrey[2]" style="width: 98%;">
        <div class="interview carousel">
            <owl-carousel-o [options]="customOptions" class="overflow-hidden p-0">
                <ng-container class="carousel-container">
                    <ng-template carouselSlide *ngFor="let data of videoArrey[2]?.slice(-4); let i = index;" >
                        <app-home-section-carousal-card [video]="data"></app-home-section-carousal-card>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</ng-template>
<ng-template #topPerformer_card>
    <app-top-performers-card style="display: flex; justify-content: center;"></app-top-performers-card>
</ng-template>
<ng-template #recentVideos>
    <div class="container bgcl-lightblue" *ngIf="videoList.length>0">
        <div class="card rounded-4 border-0">
            <div class="row flex-nowrap m-2" *ngFor="let data of videoList.slice(0,5)">
                <div class="col-6 p-1" (click)="getVideoDetail(data.video_id,data.vedio_category_id)">
                    <img class="card-img-top rounded-2 img-fluid" [src]="data.video_thumbnail_image" alt="Card image cap">
                </div>
                <div class="col-6 p-1" (click)="getVideoDetail(data.video_id,data.vedio_category_id)">
                    <div class="card-body">{{data.video_title}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #homeBottomContent>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 mt-3">
            <div class="row">
                <div class="col-12 col-lg-4 d-none d-lg-block">
                    <h2 class="fs-4">{{dynamicData[0]?.content}}</h2>
                    <p class="text lh-base fs-5">{{dynamicData[0]?.categories}}</p>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="home-bottom">
                        <owl-carousel-o [options]="carousal" class="overflow-hidden p-0">
                            <ng-container>
                                <ng-template carouselSlide *ngFor="let data of banner">
                                    <img src="{{data.image}}" class="rounded-2 img-fluid">
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 my-3">
            <h2 class="fw-semibold text-center fs-4">{{dynamicData[1]?.content}}</h2>
            <p class="text lh-base fs-5 pe-3"> {{dynamicData[1]?.categories}} </p>
        </div>
    </div>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 my-3">
            <div class="col-12 text-center">
                <img src="assets/images/sr-mock-devices.webp" class="our-service">
                <h2 class="fw-medium fs-4 mt-2">{{dynamicData[2]?.content}}</h2>
            </div>

            <p class="text lh-base fs-5 pe-3">{{dynamicData[2]?.categories}}</p>
            </div>
    </div>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 my-3">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <img src="assets/images/Digitizing_tournamnets.webp" class="w-100">
                </div>
                <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
                    <h2 class="text-wrap text-center text-lg-start fs-3 mt-4">{{dynamicData[3]?.content}}</h2>
                    <div [innerHTML]="dynamicData[3]?.categories"></div>
                </div>
            </div>
            </div>
    </div>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 my-3">
            <div class="col-12 text-center  mt-4">
                <img src="assets/images/digitizing_academies.gif" class="w-100">
                <div class="text-start mt-3">
                    <h2 class="fs-4 text-center text-lg-start mt-4">{{dynamicData[4]?.content}}
                    </h2>
                <div [innerHTML]="dynamicData[4]?.categories"></div>
                </div>
            </div>
            </div>
    </div>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 my-3">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <img src="assets/images/players.webp" class="w-100">
                </div>
                <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
                    <h2 class="text-wrap text-center text-lg-start fs-4 mt-4">{{dynamicData[5]?.content}}</h2>
                   <div [innerHTML]="dynamicData[5]?.categories"></div>
                </div>
            </div>
            </div>
    </div>
    <div class="container mt-4 homeDynamicContent" *ngIf="dynamicData">
        <div class="card border-0 shadow p-3 my-3">
            <div class="row">

                <div class="col-12 col-lg-6 d-flex flex-column justify-content-center ps-2">
                    <h2 class="text-wrap fs-4 text-center text-lg-start mt-4">{{dynamicData[6]?.content}}</h2>
                    <div [innerHTML]="dynamicData[6]?.categories"></div>
                </div>
                <div class="col-12 col-lg-6">
                    <img src="assets/images/team_img.webp" class="w-100">
                </div>
            </div>
            </div>
            </div>
</ng-template>
