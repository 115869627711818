import { Dialog } from '@angular/cdk/dialog';
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isArray } from 'highcharts';
import * as moment from 'moment';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-top-performers-card',
  templateUrl: './top-performers-card.component.html',
  styleUrls: ['./top-performers-card.component.css']
})
export class TopPerformersCardComponent {
  data: any;
  eventId: any;
  factSheet: any;
  factSheets: any[] = [];
  statsCard: any = [];
  topRunGetter: any[]=[];
  highestSR: any[]=[];
  leadingWickettaker: any[]=[];
  eventDetails: any = [];
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;

  
  constructor(private service: CommonService, private route: Router,private dialog:MatDialog) { }

  ngOnInit() {
    this.getallFactSheet();
  }
  getallFactSheet() {
    this.service.getAllFactSheet().subscribe((data: any) => {
      this.data = data?.result;
      this.data?.forEach((d: any) => {
        d.eventDetails.Start_date= moment(d.eventDetails.Start_date).format("ll");
        d.eventDetails.End_date= moment(d.eventDetails.End_date).format("ll");
        const events = d.eventDetails;
        this.eventDetails.push(events);
        const fact = d.factSheet
        fact.EventDeatil = events
        this.factSheets.push(fact);
      })
      // if (this.factSheets.length > 0) { 
      //   this.factSheets.forEach((fact: any) => {
      //       this.topRunGetter.push(fact.battingToppers);
      //       this.highestSR.push(fact.battingToppers[0]);
      //       this.leadingWickettaker.push(fact.bowlingToppers);
      //     });
      //   }
      // this.dialog.closeAll();
    });

  }

  isTopPerformersExist(): boolean {
    return this.factSheets.some(factSheet =>
      factSheet.battingToppers.slice(0, 2).length > 0 ||
      factSheet.battingToppers.slice(2, 4).length > 0 ||
      factSheet.bowlingToppers.length > 0
    );
  }
  
  
  toperOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      992: {
        items: 2
      }
    },
    nav: false,
  };

  customOptions = {
    loop: true,
    dots: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    navText: [ '<i class="bi bi-chevron-left"></i>','<i class="bi bi-chevron-right"></i>'],
  };

  
  navigateto(eventId: any, Sport_id: any) {
    this.route.navigate(['/tournament', eventId, Sport_id])
  }
}
