<div class="container-fluid header" >
  <section class="topbar pb-0">
    <div class="container-fluid d-flex justify-content-lg-evenly justify-content-between align-items-center" style="background-color: var(--secondary-color)">
      <!-- <section class="main-header "> -->
        <div class="col-6 col-lg-3 d-flex h-100 align-items-center" >
          <a href="" class="logo">
            <!-- <img src="assets/images/SportsRuler.png" alt="logo"  class="img-fluid"/> -->
            <img [src]="icon" ngClass="" alt="logo"  class="img-fluid"/>
          </a>
          <i  class="bi bi-search text-white fs-4 ps-0 position-relative allSearch" (click)="openSearch()" style="float: left;"></i>
        </div>
        <div class=" col-lg-7">
          <nav class="navbar navbar-expand-lg navbar-dark d-none d-lg-block d-xl-block">
            <div class="container-fluid nav-menu ">
              <div id="main_nav " class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                  <li  i class="nav-item" *ngFor="let menu of headerArray" style="cursor: pointer;" (mouseleave)="hideMore()">
                    <ng-container *ngIf="orgCode==='SR' else SKA">
                      <a class="nav-link h_over dropdown" *ngIf="menu.tab !== 'Events' && menu.tab !== 'Videos'  && menu.tab !== 'More' "[ngClass]="{'mainMenuActive': isActive(menu.tab),'active-heading': menu.active}"  (mouseenter)="hideAll()"   (click)="handleMore(menu.tab);navigateToheaderContent(menu.routerLink)">{{ menu.tab }}</a>
                      <a class="nav-link h_over testt" *ngIf=" menu.tab === 'Events' || menu.tab === 'Videos'" (mouseenter)="menu.tab === 'Events' ? showSports() : hideAll();" [ngClass]="{'mainMenuActive': isActive(menu.tab),'active-heading': moreEventsActive}"  (click)="menu.tab === 'Videos' ? navigateTo('/videos') :  nativegateMore(menu.tab)" (click)="handleMore(menu.tab)">{{ menu.tab }}</a>
                      <a class="nav-link h_over" *ngIf="menu.tab === 'More'" (mouseenter)="showMore();"  [ngClass]="{'mainMenuActive': isActive(menu.tab), 'active-heading':  moreActive}" >{{ menu.tab }}</a>
                    </ng-container>
                    <ng-template #SKA>
                      <ng-container *ngIf="menu.tab !== 'More' && menu.tab !== 'How To Use' && menu.tab !== 'About Us'&& menu.tab !== 'Videos'">
                        <a class="nav-link dropdown" *ngIf="menu.tab !== 'Tournaments' && menu.tab !== 'SR TV' && menu.tab !== 'More'"[ngClass]="{'mainMenuActive': isActive(menu.tab)}"  (mouseenter)="hideAll()" (click)="navigateTo(menu.routerLink)" >{{ menu.tab }}</a>
                        <!-- <a class="nav-link testt" *ngIf="menu.tab === 'Tournaments' || menu.tab === 'Videos'" (mouseenter)="menu.tab === 'Tournaments' ? showSports() : hideAll();" [ngClass]="{'mainMenuActive': isActive(menu.tab)}"  (click)="menu.tab === 'Videos' ? navigateTo('/videos') :  nativegateMore(menu.tab)">{{ menu.tab }}</a>
                        <a class="nav-link" *ngIf="menu.tab === 'More'" (mouseenter)="showMore()"  [ngClass]="{'mainMenuActive': isActive(menu.tab)}" >{{ menu.tab }}</a> -->
                      </ng-container>
                     </ng-template>
                      <div *ngIf="isMoreDropdown" class="arrow upForMore " >
                        <ul class="submenu more d-flex">
                          <li *ngFor="let item of menuItems" [ngClass]="{'activeSubNav': selectedItem === item}">
                            <div class="d-flex justify-content-between h-100 align-items-center" (click)="nativegateMore(item.tab);selectItem(item)" style="cursor: pointer;">
                              <a class="dropdown-item pb-3"  [ngClass]="{'activeSubNavItem': selectedItem === item}"style="cursor: pointer;">{{ item.tab }}</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                </ul>
                <div>
                </div>
                <div *ngIf="showSportsData" class="bg-white position-absolute d-none d-lg-block d-md-none  tournamentMenu" [ngClass]="{'fill-in': showSportsData}" style="width: max-content; z-index: 11; border-radius: 0 0 10px 10px; top: 100%; "
                 (mouseleave)="hideAll();hideRelated()">
                  <ul class="d-flex flex-column arrow up justify-content-around" >
                    <li class="d-flex flex-row justify-content-center">
                      <ng-container *ngFor="let item of sports">
                      <div class="p-4 px-1 d-flex"  [ngClass]="{'activeSubNav': selectedItem === item}"  style="cursor: pointer;" (mouseenter)="showRelated(item.tab);selectItem(item)" >
                        <img *ngIf="item.tab=='Cricket'" src="/assets/ball.png" alt="CricketBall" style="height: 20px; width: 20px;">
                        <img *ngIf="item.tab=='Table Tennis'" src="/assets/tableTennis.png" alt="CricketBall" style="height: 20px; width: 20px;">
                        <img *ngIf="item.tab=='Badminton'" src="/assets/batmiton ball.png" alt="CricketBall" style="height: 20px; width: 20px;">
                        <img *ngIf="item.tab=='Tennis'" src="/assets/tennisball.png" alt="CricketBall" style="height: 20px; width: 20px;">
                        <a class="dropdown-item text-dark fs-6" [ngClass]="{'activeSubNavItem': selectedItem === item}">{{ item.tab }}</a>
                      </div>
                    </ng-container>
                    </li>
                    <li class="d-flex flex-row" *ngFor="let item of sports"  >
                      <div *ngIf="item.tab==='Cricket' && dropCricketEvent" class="dropdowntest ">
                        <ng-container *ngIf="tournamentList?.slice(0, 5).length > 0; ">
                          <ul class="p-2 ps-3" style="cursor: pointer; color: var(--secondary-color);" *ngFor="let Match of tournamentList.slice(0, 5); let i =index">
                            <li class="Link" (click)="showEvent(i,''); hideRelated(); hideAll()">
                              {{tournamentList[i].Event_name}}
                            </li>
                          </ul>
                        </ng-container>

                        <ul class="Link text-center" *ngIf="(tournamentList && tournamentList.length > 5)" >
                          <li class="card1 p-2 m-2" (click)="showEvent(1,'All'); hideRelated(); hideAll()">
                            <i class="fs-3 bi bi-arrow-right-circle"></i>
                          </li>
                        </ul>
                      </div>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div class="col-3 col-lg-2 d-flex justify-content-center">
          <div class="topbar-right">
            <div class="dropdown" *ngIf="loginStatusValue">
              <a mat-raised-button class="dropbtn rounded-pill fs-4"><i class="bi bi-person"></i></a>
              <div class="card dropdown-content m-1">
                <div class="card-body m-3">
                  <h4 class="card-title text-center">{{userInformation}}</h4>
                  <div class="row">
                    <div class="col m-2">
                      <a mat-button routerLink="/" class="rounded-pill custom-button border-bottom pt-2 head" (click)="editProfile()">Edit
                        Profile</a>
                    </div>
                    <div class="col m-2">
                      <a mat-button routerLink="/" class="rounded-pill custom-button border-bottom pt-2 head"
                        (click)="onLogout()">Logout</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <ng-container *ngIf="orgCode==='SR'|| orgCode==='SKA'">
            <div class="d-none d-sm-block" *ngIf="!loginStatusValue">
              <a mat-raised-button routerLink="/login" class="rounded-pill head">LOGIN</a>
            </div>
            <div class="d-block d-sm-none me-2 login" *ngIf="!loginStatusValue">
              <a routerLink="/login"><img src="assets/user-circle.svg" alt=""></a>
            </div>
           </ng-container>
           
          </div>
        </div>

    </div>
  </section>
  <div *ngIf="currentUrl" class="scorecard">
    <app-score-card-carousel ></app-score-card-carousel>
</div>
</div>


