import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { CommonAlertsComponent } from 'src/app/common/common-alerts/common-alerts.component';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';
import { VideoService } from 'src/app/service/video.service';


@Component({
  selector: 'app-home-page',
  templateUrl:  './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {

  isLoading: boolean = true;
  news_id = 'All';
  category_id= 'All';
  videoList: any[] = [];
  liveAndCompleteList: any[] = [];
  liveList: any;
  completeList: any;
  videosData:any
  videos: any;
  categorys: any;
  allCategory: any;
  categoryId: any;
  videoArrey: any[]=[];
  limit = 10;
  dynamicData: any;
  sportsRuler: any;
  services: any;
  digitizeTournament: any;
  digitizeAcademies: any;
  players: any;
  teams: any;
  currentUrl: boolean = true;
  cricketLive: any[] = [];
  badmintonLive: any[] = [];
  teamOnePoint = 0;
  teamTwoPoint = 0;
  teamOneScore: any;
  teamtwoScore: any;
  tableTennisLive: any[] = [];
  completedStatus:string = 'C'
  tableTennisComplete: any[] = [];
  tennisComplete: any[] = [];
  tennisLive: any[] = [];
  cricketComplete: any[] = [];
  badmintonComplete: any[] = [];
  dialogRef: MatDialogRef<CommonAlertsComponent> | undefined ;
  orgCode:any;



  constructor(
    private videoService: VideoService,
    private scoreCardService: CricketScoreCardService,
    private changeDetection: ChangeDetectorRef,
    private commonService: CommonService,private route:Router,
    private orgFilter:OrgcodeFilterService,
  ) {
  }

  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath
    this.route.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url === '/') {
          this.currentUrl = true;
        } else {
          this.currentUrl = false;
        }
      }
    });
    this.getAllCategory();
    this. getAllVideos();
    this.getVideoList();
   // this.getCricketLiveMatch();
    this.getDynamicHomeContents();

    forkJoin({
      cricket: this.scoreCardService.getCricketCompletedMatch(this.limit),
      // badminton: this.commonService.getBadmintonMatches(this.completedStatus, this.limit),
      // tableTennis: this.commonService.getTableTennisMatches(this.completedStatus, this.limit),
      // tennis: this.commonService.getTennisMatches(this.completedStatus, this.limit),
    }).subscribe(({cricket, badminton, tableTennis, tennis}:any) => {

      this.cricketComplete = cricket.result;
      // this.cricketComplete.forEach((s:any)=>{
      const datas = cricket.result;
        this.cricketComplete = datas.map((s:any) => {
          s.Match_Date = moment(s.Match_Date).format("ll");
          s.Match_Time=moment(s.Match_Time,'HH:mm:ss').format('LT:mm A')
          s.team_1_group = [];
          s.team_2_group = [];
          s.matchInnings = [];
          s.leadRuns = 0;
          s.isLead = false;
          s.trailRuns = 0;
          s.team1Score = 0;
          s.team2Score = 0;
          s.teamScore = 0;
          s.currentBattingTeam = '';
          s.currentBowlingTeam = '';
         
    
          [1, 2, 3, 4].forEach(r => {
            if (r <= s.curr_innings) {
              s.matchInnings.push(r);
              const inningsStatus = s[`innings_${r}_status`]?.split(' ')[0];
              if (inningsStatus === s.Team1.toString()) {
                s.team_1_group.push(r);
              } else if (inningsStatus === s.Team2.toString()) {
                s.team_2_group.push(r);
              } 
              // else {
              //   if (s.curr_batting_team_id === s.Team1) {
              //     s.team_1_group.push(r);
              //   }
              //   if (s.curr_batting_team_id === s.Team2) {
              //     s.team_2_group.push(r);
              //   }
              // }
            }
          });
    
          s.teamScore = s[`innings_${s.curr_innings}_score`];
    
          s.team_1_group.forEach((r:any) => {
            s.team1Score += parseInt(s[`innings_${r}_score`], 10);
          });
    
          s.team_2_group.forEach((r:any) => {
            s.team2Score += parseInt(s[`innings_${r}_score`], 10);
          });
    
          // if (s.curr_batting_team_id === s.Team1) {
          //   s.currentBattingTeam = s.teamOne;
          //   s.currentBowlingTeam = s.teamTwo;
          //   if (parseInt(s.team1Score.toString(), 10) < parseInt(s.team2Score.toString(), 10)) {
          //     s.isLead = false;
          //     s.trailRuns = parseInt(s.team2Score.toString(), 10) - parseInt(s.team1Score.toString(), 10);
          //   } else {
          //     s.isLead = true;
          //     s.leadRuns = parseInt(s.team1Score.toString(), 10) - parseInt(s.team2Score.toString(), 10);
          //   }
          // }
    
          // if (s.curr_batting_team_id === s.Team2) {
          //   s.currentBattingTeam = s.teamTwo;
          //   s.currentBowlingTeam = s.teamOne;
          //   if (parseInt(s.team2Score.toString(), 10) < parseInt(s.team1Score.toString(), 10)) {
          //     s.isLead = false;
          //     s.trailRuns = parseInt(s.team1Score.toString(), 10) - parseInt(s.team2Score.toString(), 10);
          //   } else {
          //     s.isLead = true;
          //     s.leadRuns = parseInt(s.team2Score.toString(), 10) - parseInt(s.team1Score.toString(), 10);
          //   }
          // }
    
          return s;
        // });
        
      })
    });
  }

  getVideoList() {
    this.videoService.getTopVideoList(this.news_id).subscribe((response: any) => {
      if (response.status=="TRUE") {
      this.videoList = response.result;

        this.videoList.map((x: any) => {
          x.created_date_time = moment(x.created_date_time).format("LL");
          x.video_thumbnail_image = window.atob(x.video_thumbnail_image);
        })
        this.isLoading = false;
      }else{
        console.log("there is no video list available.");
      }
    });
  }

  getAllCategory() {
    this.videoService.getCategory().subscribe((response:any) => {
      if (response.status == "TRUE") {
        this.categorys = response;
        if (this.categorys.result.length > 0) {
          this.allCategory = this.categorys.result
          this.isLoading = false;
        } else {
          console.log("video categories not found.");
        }
      }
    })
  }


  getAllVideos(){
    this.videoService.getVideoListAll(this.category_id).subscribe((responce)=>{
      this.videosData=responce
      this.videos=this.videosData.result
      if(this.videos.length > 0){
      this.videos.forEach((data: any) => {
        try {
          if (data.video_thumbnail_image && typeof data.video_thumbnail_image === 'string') {
            data.video_thumbnail_image = window.atob(data.video_thumbnail_image);
          } else {
            data.video_thumbnail_image = "/assets/images/brokenImage.jpg";
          }
        } catch (error) {
          console.error("Error decoding thumbnail image:", error);
          data.video_thumbnail_image = "/assets/images/chepak.png";
        }
        data.created_date_time = moment(data.created_date_time).format("LL");
      });

      this.allCategory.map((data:any)=>{
        this.categoryId=this.videos.filter((items:any)=>items.vedio_category_id == data.vedio_category_id)
        this.videoArrey.push(this.categoryId)
      })
      this.isLoading = false;
    }else{
      console.log("there is no videos.");
    }
    })
  }


  getCricketLiveMatch() {
    this.scoreCardService.getCricketLiveMatch(this.limit).subscribe((data: any) => {
      if (data.result?.length < 0 || data.status == "NOROWS") {
        console.log("there is no live list");
      }
      else {
        this.liveList = data.result;

        this.liveList.map((data:any)=>{
          data.Match_Date=moment(data.Match_Date).format("LL");
        })
        this.isLoading = false;
      }
    });
  }
  getVideoDetail(videoID:any,catID:any){
    this.route.navigate([`${this.orgCode}/videodetail`, videoID,catID]);
  }

  customOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      992: {
        items: 3
      }
    },
    nav: true,
    navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>']
  };

  carousal = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      992: {
        items: 1
      }
    },
    nav: true,
    navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>']
  };

  banner=[
    {
      image:'/assets/images/bannerImage881648.webp'
    },
    {
      image:'/assets/images/bannerImage509968.webp'
    }
  ]
  getDynamicHomeContents() {
    this.commonService.getDynamicHomeContent().subscribe((response: any) => {
      if (response.status === 'TRUE') {
       this.dynamicData=response.result;
      }
    })
  }
  navigateToVideos(tab: any, section: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        section: section
      }
    };
    this.route.navigate(['/videos'], navigationExtras);
  }

  toCamelCaseWithSpace(input: string): string {
    return input.split(' ').map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }
  navigateToMatches( section: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        section: section
      }
    };
    this.route.navigate(['/matches'], navigationExtras);
  }
  toperOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      992: {
        items: 2
      }
    },
    nav: false,
  };

  }

