<div class="container my-3" *ngIf="videoDetail">
    <div class="card rounded-4 shadow border-0">
        <iframe class="rounded-4" *ngIf="videoDetail.video_url" style="width: 100%;height: 550px;"
            [src]="trustSrc(videoDetail.video_url)" frameborder="0"></iframe>
        <div class="card-body">
            <h5 class="card-text text-nowrap fs-6 fw-medium text-start">{{videoDetail.created_date_time}}</h5>
            <h3 class="card-title fs-5 fw-semibold">{{videoDetail.video_title}}</h3>
            <p class="card-text fs-5">{{videoDetail.video_desc}}</p>
        </div>
    </div>
</div>