<!-- <div class="loader-overlay" [ngClass]="{'active': isLoading}">
  <div class="loader spinner">hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii </div>
</div> -->
<div class="dot-spinner mt-4 mb-4">
  <div class="dot-spinner__dot" style="--dot-color: #ff0000;"></div> <!-- Red -->
  <div class="dot-spinner__dot" style="--dot-color: #ff7f00;"></div> <!-- Orange -->
  <div class="dot-spinner__dot" style="--dot-color: #ffff00;"></div> <!-- Yellow -->
  <div class="dot-spinner__dot" style="--dot-color: #00ff00;"></div> <!-- Green -->
  <div class="dot-spinner__dot" style="--dot-color: #0000ff;"></div> <!-- Blue -->
  <div class="dot-spinner__dot" style="--dot-color: #4b0082;"></div> <!-- Indigo -->
  <div class="dot-spinner__dot" style="--dot-color: #ff7f00;"></div> <!-- Orange -->
  <div class="dot-spinner__dot" style="--dot-color: #ffff00;"></div> <!-- Yellow -->
</div>