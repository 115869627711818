import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';
import { CommonService } from 'src/app/service/common.service';


@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css']
})
export class TournamentsComponent implements OnInit {
  tournamentList: any = [];
  tournamentsLiveList: boolean = true;
  currEventId: any;
  sportsId: any;
  tournamentDetail: boolean = false;
  SEARCHSTR: any;
  eventDetails: any;
  selectedType: any = 'Home';
  selectedScoreType: any = 'Live Scores';
  limit = 6;
  status: any;
  start =0;
  currentLiveMatchList: any;
  eventSubCatDetails: any=[] ;
  slectedLeaderboard: any ='Batting';
  type='Event';
  LeaderBoardDetails: any=[];
  leaderBoardTabDetails: any;
  TabId:any;
  currTabId: any;
  BattingAggregateDetails: any=[];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSize: number = 10;
  pageIndex: number = 0;
  pagedData: any[] = [];
  paginatedTeams: any;
  filteredTeams:any=[] ;
  allRecentResults: any=[];
  searchText: string = '';
   myFormGroup: FormGroup | any;

   pageEvent: any;
  tab:any;
  eventId: any;
  filteredTournamentList: any[] = [];
  searchTerm: string = '';
  allEventFixtures: any=[];
  panelOpenState = false;
  venue:any
  section: any;
  totalItems: any;
  tournamentTileList=['Cricket','Table Tennis','Batmindon','Tennis']
  activeTab1:any='Cricket';
  orgCode:any;

   constructor(private service: CommonService,private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router,private orgFilter:OrgcodeFilterService) {
    this.myFormGroup = new FormGroup({
      selectedType: new FormControl('Home')
    });
  }
  ngOnInit(): void {
    this.slectedLeaderboard='Batting';
    this.getHedareContent()
    this.getTournamentsList();
    this.myFormGroup = this.formBuilder.group({});
    this.liveScoreList.forEach(score => {
      this.myFormGroup.addControl(score, this.formBuilder.control(false));
    });
    this.filteredTournamentList = this.tournamentList;
    this.getRoutExtras()
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath;
    this.getLeaderUi(this.type,this.sportsId);
  }


  tournaments(eventId: number, sportsId: number) {
    this.status='C';
    this.slectedLeaderboard='Batting';
    this.SEARCHSTR = this.currEventId;
    this.currEventId = eventId;
    this.sportsId = sportsId;
   this.getLiveUserEvents(this.currEventId);
    this.currentLiveMatches(this.currEventId)
    this.getEventSubCat(this.currEventId,this.sportsId)
    this.getAllResutls(this.currEventId,this.status)
    this.showTournament()
    this.leaderBoardTabUI("Batting",3)
    this.router.navigate([this.orgCode,"tournament",eventId,1]);
     this.List('Home');
     this.getLeaderUi(this.type,this.sportsId);
     this.ListLiveSCore('Live Scores');
  }
  showTournament(){
    this.tournamentDetail=true
    this.tournamentsLiveList=false
  }
  getLiveUserEvents(currEventId:any){
    this.service.getUserEvents(currEventId).subscribe((events: any) => {
      this.eventDetails = events.result;
      });
  }
  currentLiveMatches(currEventId: any) {
    this.service.currentEventLiveMatch(currEventId).subscribe((matches: any) => {
      this.currentLiveMatchList = matches.result;
    })

  }

  getTournamentsList() {
    this.service.getTournaments().subscribe((tournaments: any) => {
      this.tournamentList = tournaments.result;
      if (this.tournamentList && this.tournamentList.length > 0) {
        // this.currEventId = this.tournamentList[0].Event_id;
        this.sportsId = this.tournamentList[0].Sport_id;
      }
    })
  }
  TournamentScoreList: string[] = [
    'Home',
    'Points Table',
    'fixture',
    'Leader Board',
    'Fact Sheet',
  ];
  liveScoreList: string[] = [
    'Live Scores',
    // 'Live Videos',
    'Results',
    'Fixture'
  ];

  List(value: string) {
    this.selectedType = value;
    if (value == 'fixture'|| 'Home') {
      this.status='S';
      this.getAllEventFixtures(this.currEventId, this.status);
    }else {
      this.getLeaderUi(this.type,this.sportsId)
    }
  }
  ListLiveSCore(value: string) {
    this.selectedScoreType = value;
  }
  getEventSubCat(currEventId:any,sportId:any){
    this.service.getEventSubCat(currEventId,sportId).subscribe((events:any) =>{
      if(events.status=== 'TRUE'){
        this.eventSubCatDetails = events.result;
      }
    })
  }
  setExpend(index: number) {
    this.start = index;
  }
  activeTab: string = 'Batting';

  leaderBoardTabUI(value: string,tabId:any) {
    this.paginatedTeams=[]
    this.currTabId=tabId;
    this.slectedLeaderboard=value;
    this.getLeaderTabUi(this.currTabId);
    this.getLeaderUi(this.type,this.sportsId);
  }

  getLeaderUi(type:any,sportId:any){
   this.service.getLeaderUi(type,sportId).subscribe((leaderBoard:any)=>{
    if(leaderBoard.status==='TRUE'){
      this.LeaderBoardDetails = leaderBoard.result;
    }
   })
  }

  getLeaderTabUi(currTabId:any){
    this.service.getLeaderTabUi(currTabId).subscribe((leaderBoardTab:any)=>{
      this.leaderBoardTabDetails = [];
          if(leaderBoardTab.status==='TRUE'){
      this.leaderBoardTabDetails=leaderBoardTab.result;
    } else{
      this.leaderBoardTabDetails = [];
    }
    })
  }
  getAllResutls(currEventId:any,status:any){
    this.service.getAllResults(currEventId,status).subscribe((response:any)=>{
        this.allRecentResults=response.result;
    })
  }
  search() {
    // if (this.searchText) {
      this.filteredTeams = this.BattingAggregateDetails.filter((team: { team_name: string  }) => {
        return team.team_name?.toLowerCase().includes(this.searchText?.toLowerCase());
      });
      this.updatePaginatedTeams();
      // this.searchText='';
    // }
  }
  searchTeam(){
    // if(this.searchText){
    
      this.filteredTeams = this.BattingAggregateDetails.filter((team: { Team_name: string  }) => {
        return team.Team_name?.toLowerCase().includes(this.searchText?.toLowerCase());
      });
      this.updatePaginatedTeams();
    }
  // }

  updatePaginatedTeams() {
    
    const startIndex = this.pageIndex * this.pageSize ;
    const endIndex =  startIndex + this.pageSize;
    this.paginatedTeams = this.filteredTeams.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize ;
    this.updatePaginatedTeams();
  }

  expend(tableId: any) {
    this.getBattingAggregate(this.currEventId, tableId);
  }

  getBattingAggregate(currEventId: any, tableId: any) {
    this.paginatedTeams=[];
    this.service.getBattingAggregate(currEventId, tableId).subscribe((battingAggregate: any) => {
      if(battingAggregate.status=='TRUE'){
        this.BattingAggregateDetails = battingAggregate.result.map((item: any) => ({
          ...item,
          WinningTeam: item['Winning Team']?.trim(),
          Opponents: item.Opponents?.trim(),
          match_result: item.match_result?.trim(),
          TargetSet: parseInt(item['Target Set']) || 0,
          TargetRuns:parseInt(item['Target Runs']) || 0,
          BallsDiff: item['Balls Diff'] || 0,
          Margin: item.Margin,
      }));
      this.filteredTeams = this.BattingAggregateDetails;
       this.searchText='';
      if (this.filteredTeams.length > 0) {
          this.updatePaginatedTeams();
      }
    }
    });

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredTournamentList = this.tournamentList.filter((tournament: { Event_name: string; }) =>
      tournament.Event_name.toLowerCase().includes(filterValue)
    );
  }
  getAllEventFixtures(eventId:any, status:any){
    this.service.getAllEventFixtures(eventId,status).subscribe((results:any)=>{
      if(results.status==='TRUE'){
        this.allEventFixtures=results.result;
      }
    })
  }
  getHedareContent(){
    this.route.paramMap.subscribe(params => {
      this. eventId = params.get('eventId');
      this. sportsId = params.get('sportsId');
      if(this.eventId!=="All" && this.sportsId !=undefined){
        this.tournaments(this.eventId, this.sportsId);
        }
        else{
          this.hideTournemt()
        }
    });
  }
  hideTournemt(){
    this.tournamentDetail=false;
    this.tournamentsLiveList=true;
    this.selectedScoreType='Live Scores';
    this.router.navigate([this.orgCode,"tournament",'All',1]);
     }

     getCurrentPageData() {
      const startIndex = this.pageIndex * this.pageSize;
      return this.allEventFixtures.slice(startIndex, startIndex + this.pageSize);
    }
    getRoutExtras(){
      this.route.paramMap.subscribe(params => {
        const navigation = window.history.state;
        if (navigation && navigation['tab']) {
          this.selectedType = navigation['tab'];
        }
          this.List(this.selectedType)
      });
    }

 navigateToScorecard(matchId:any){
  this.router.navigate([`${this.orgCode}/scorecardDetails`,matchId]);
 }
 resetPagination() {
  this.pageIndex = 0;
  this.pageSize = 10;
  this.paginatedTeams = [];
}

  searchTeamName(){
    this.filteredTeams = this.BattingAggregateDetails.filter((team: { TEAM: string  }) => {
      return team.TEAM?.toLowerCase().includes(this.searchText?.toLowerCase());
    });
    this.updatePaginatedTeams();
  }
  
  searchWinner(){
    this.filteredTeams = this.BattingAggregateDetails.filter((team: { Winner: string  }) => {
      return team.Winner?.toLowerCase().includes(this.searchText?.toLowerCase());
    });
    this.updatePaginatedTeams();
  }
  
  searchWinningTeam(){
    this.filteredTeams = this.BattingAggregateDetails.filter((team: { "Winning Team": string  }) => {
      return team["Winning Team"]?.toLowerCase().includes(this.searchText?.toLowerCase());
    });
    this.updatePaginatedTeams();
  }
}
