<div class="container  p-3 mb-5 bg-body-tertiary rounded " *ngIf="cricketLive?.length>0 || cricketComplete?.length>0">
    <div class="row" style="height:50%">
        <div class="col">
            <div class="carousel">
                <owl-carousel-o [options]="customOptions" class="overflow-hidden p-0">
                    <ng-container class="carousel-container">
                        <ng-template carouselSlide *ngFor="let data of allMatches; trackBy: trackByMatchId">
                            <app-score-card [scoreCard]="data"></app-score-card>
                        </ng-template>
                        <!-- <ng-template carouselSlide *ngFor="let data of badmintonLive">
                            <app-badminton-scorecard [data]="data"></app-badminton-scorecard>
                        </ng-template>
                         <ng-template carouselSlide *ngFor="let data of tennisLive">
                            <app-tennis-scorecard [data]="data"></app-tennis-scorecard>
                        </ng-template> -->
                        <!-- <ng-template carouselSlide *ngFor="let data of tableTennisLive">
                            <app-table-tennis-scorecard [data]="data"></app-table-tennis-scorecard>
                        </ng-template> -->
                        <!-- <ng-container *ngIf="cricketLive?.length<=0 && cricketComplete?.length>0">
                            <ng-template carouselSlide *ngFor="let data of cricketComplete">
                                <app-score-card [scoreCard]="data"></app-score-card>
                            </ng-template>
                        </ng-container> -->
                        <!-- <ng-template carouselSlide *ngFor="let data of tennisComplete">
                            <app-tennis-scorecard [data]="data"></app-tennis-scorecard>
                        </ng-template> -->
                        <!-- <ng-template carouselSlide *ngFor="let data of badmintonComplete">
                            <app-badminton-scorecard [data]="data"></app-badminton-scorecard>
                        </ng-template> -->
                        <!-- <ng-template carouselSlide *ngFor="let data of tableTennisComplete">
                            <app-table-tennis-scorecard [data]="data"></app-table-tennis-scorecard>
                        </ng-template> -->
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
        <div class="d-flex justify-content-end me-3">
           <div class="text-end more" (click)="more()">MORE <i class="bi bi-chevron-right"></i></div>
        </div>
    </div>
</div>
