<div class="container shadow-lg bg-body-tertiary rounded-4" *ngIf="videoArrey[2]" style="margin-top:5px;width: 98%;">
    <div class="interview carousel">
        <owl-carousel-o [options]="customOptions" class="overflow-hidden p-0">
            <ng-container class="carousel-container">
                <ng-template carouselSlide *ngFor="let data of videoArrey[2]?.slice(-4); let i = index;">
                    <app-home-section-carousal-card [video]="data"></app-home-section-carousal-card>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>