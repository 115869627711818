import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-fact-sheet',
  templateUrl: './fact-sheet.component.html',
  styleUrls: ['./fact-sheet.component.css']
})
export class FactSheetComponent {
  @Input() eventId:any;
  factSheet: any;
  statsCard: any=[];
  battingToppers: any;
  bowlingToppers: any;
  constructor(private service :CommonService){}

  ngOnInit(){
    this.getFactSheet();

  }
  getFactSheet(){
    this.service.getFactSheets(this.eventId).subscribe((response:any)=>{
      if(response.status == "TRUE"){
        this.factSheet = response.result;
        this.battingToppers = this.factSheet.battingToppers;
        this.bowlingToppers = this.factSheet.bowlingToppers;
        const battingEventRun = this.factSheet.battingFacts.battingEventRuns[0];
        const battingFactDtls = this.factSheet.battingFacts.battingFactDtls[0];
        const bowlingFacts = this.factSheet.bowlingFacts[0];

          const runs = {name:'Runs',value:battingEventRun.runs};
          const extras = {name:'Extras',value:battingEventRun.extras};
          const catches = {name:'Catches',value:battingFactDtls.catches};
          const fifties = {name:'Fifties',value:battingFactDtls.fifties};
          const fours = {name:'Fours',value:battingFactDtls.fours};
          const hundreds = {name:'Hundreds',value:battingFactDtls.hundreds};
          const matches = {name:'Matches',value:battingFactDtls.matches};
          const sixers = {name:'Sixers',value:battingFactDtls.sixers};
          const innings = {name:'Innings',value:battingFactDtls.innings};
          const stumpings = {name:'Stumpings',value:battingFactDtls.stumpings};
          const economy = {name:'Avg Economy',value:bowlingFacts.economy};
          const maidens = {name:'Maidens',value:bowlingFacts.maidens};
          const overs = {name:'Overs',value:bowlingFacts.overs};
          const strike_rate = {name:'Bowl StrikeRate',value:bowlingFacts.strike_rate};
          const wickets = {name:'Wickets',value:bowlingFacts.wickets};

          this.statsCard.push(runs,sixers,fours,fifties,hundreds,wickets,overs,matches,innings,maidens,catches,stumpings,extras,economy,strike_rate);
      }


    })

  }

}
