import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { CricketScoreCardService } from 'src/app/service/cricketScoreCard.service';

@Component({
  selector: 'app-bowler-scorecard',
  templateUrl: './bowler-scorecard.component.html',
  styleUrls: ['./bowler-scorecard.component.css']
})
export class BowlerScorecardComponent {
  @Input() innBowStats: any;
  wagonCoordinateForplayers: any;
  showWagon : boolean[] = [];
  @Input() matchId: any;
  @Input() innings:any
  wagonStatus: any='';
  showNoDataFound: boolean[] = [];
  pitchMapResponse: any;
  ballHit: any;
  wagonFor='player';
  constructor(private scorecardService: CricketScoreCardService,private service :CommonService) {
  }
  ngOnInit(): void {
  
  }
  openWagon(data : any, index : number) {  
    this.scorecardService.getWagonCoordinateForBowler(this.matchId, data).subscribe((data: any) => {
      if(data.status =="TRUE"){
      this.wagonCoordinateForplayers = data.result;
      this.ballHit=this.wagonCoordinateForplayers.filter((data:any)=>data.where_hit_x !='0' && data.where_hit_y !='0')
      this.pitchMapResponse = this.wagonCoordinateForplayers.filter((data:any)=> data.where_pitch_x != '0.0' && data.where_pitch_y != '0.0' &&  data.where_pitch_x != null && data.where_pitch_y != null );
      this.showWagon[index] = !this.showWagon[index] ;
      } else {
        this.ballHit = [];
        this.pitchMapResponse = [];
        this.showWagon[index] = !this.showWagon[index] ;
      }
    });

    // if (this.showWagon[index]) {
    //   this.showNoDataFound[index] = false;
    // } else {
    // this.showNoDataFound[index] = !this.showNoDataFound[index];
    // }

    
  }
  bowlersumm: any[] = [];
  runsInOver: string[] | undefined;
  bowlerInfo: any; // Define bowlerInfo to hold the current bowler's data

  
  bowlerInningsSumm(userId: any, index: number) {
    this.service.bowlerInningsSumm(this.matchId, userId).subscribe((data: any) => {
      if (data.status === "TRUE") {
        this.bowlersumm[index] = data.result; // Store data in the index
        console.log(this.bowlersumm[index]);
        
        this.runsInOver = this.bowlersumm[index]?.[0]?.runs_in_over.split(',');
      } else {
        this.showNoDataFound[index] = true;
      }
    });
  }

  isWicket(run: string): boolean {
    return isNaN(Number(run)) && (run.includes('Wk') || run.includes('B')|| run.includes('Lb'));
  }
  

  toggleDetails(userId: any, index: number) {
    // Toggle the visibility of the wagon details
    if (!this.showWagon[index]) {
      this.openWagon(userId, index);
    } else {
      this.showWagon[index] = false;
      this.ballHit = [];
      this.pitchMapResponse = [];
    }
  }
 
}
