<div class="container">
    <div class="carousel">
        <owl-carousel-o [options]="customOptions">
            <ng-container class="carousel-container">
                <ng-template carouselSlide *ngFor="let data of completeList" class="col-5">
                    <app-home-section-live-score-card [data]="data" style="display: flex ;justify-content: center;"></app-home-section-live-score-card>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>
