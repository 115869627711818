import { Component, Input } from '@angular/core';
import { OrgcodeFilterService } from 'src/app/common/orgcode-filter.service';

@Component({
  selector: 'app-mini-matches',
  templateUrl: './mini-matches.component.html',
  styleUrls: ['./mini-matches.component.css']
})
export class MiniMatchesComponent {
  @Input() data: any;
  @Input() length : any;
  @Input() selectedType : any;
  orgCode: any;


  constructor(private orgFilter:OrgcodeFilterService){
    
  }
  

  ngOnInit() {
    this.orgCode=this.orgFilter.storageItems.value[0].orgPath

  }
}
